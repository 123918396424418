// externals
import { Draft } from 'immer';

// libraries
import { OfferInfoState } from '@makemydeal/dr-dash-types';
import { AnyFSA } from '@makemydeal/dr-platform-shared';

export const storeLinksInState = (draft: Draft<OfferInfoState>, payload: any) => {
    draft.urls = {
        creditAppURL: payload.creditAppUrl,
        shopperURL: payload.shopperPageUrl,
        testDriveURL: payload.testDriveDeepLinkUrl,
        docUploadURL: payload.docUploadDeepLinkUrl
    };
};

export const shouldInitDraftSavedState = (action?: AnyFSA) => {
    const baggage = action?.payload?.baggage;
    const offer = action?.payload?.offer;
    if (!baggage?.length || !offer) {
        return false;
    }

    const baggageItem = baggage.find((item: { name: string }) => item.name === 'deal360.comparison');
    if (!baggageItem?.value?.content?.length) {
        return false;
    }

    const comparisonVersion = offer.dealExchangeVersionId;
    const activeComparison = baggageItem.value.content.find(
        (item: { isPublished: boolean; status: string }) => item.status === 'active' && !item.isPublished
    );

    return !!activeComparison && comparisonVersion === activeComparison.dealXgVersionId;
};

export const initDraftSavedSuccessState = (draft: Draft<OfferInfoState>, action?: AnyFSA) => {
    const draftSavedTime = action?.payload.offer?.createdOn;
    if (draftSavedTime) {
        draft.lastSuccessfulSaveDateString = draftSavedTime;
    }
};

export const updateOfferSavedSuccessState = (draft: Draft<OfferInfoState>, action?: AnyFSA) => {
    draft.lastSuccessfulSaveDateString = new Date().toISOString();
    if (action?.meta?.isDraft) {
        draft.isDraftBeingSaved = false;
        draft.hasDraftBeenSaved = true;
    } else {
        draft.hasBeenSaved = true;
        draft.isOfferBeingSaved = false;
    }
};

export const updateOfferSaveFailureState = (draft: Draft<OfferInfoState>, action?: AnyFSA) => {
    if (action?.meta?.isDraft) {
        draft.hasDraftBeenSaved = false;
        draft.isDraftBeingSaved = false;
    } else {
        draft.hasBeenSaved = false;
        draft.isOfferBeingSaved = false;
    }
};
