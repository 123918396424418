import { SetStateAction, useEffect, useMemo, Dispatch, useState, useCallback } from 'react';

import { Autocomplete, ContainerGrid, ContainerGridItem, StateCodes, useAssetsData } from '@makemydeal/dr-dash-components';
import { kebabCase } from '@makemydeal/dr-common-utils';

import { DatePickerEventValue } from '@interstate/components/DatePicker';
import { SelectInput } from '@interstate/components/SelectInput';
import { TextInput, TextInputEventValue, TextInputValue } from '@interstate/components/TextInput';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { NumericInput } from '@interstate/components/NumericInput';
import { PostalCodeInput } from '@interstate/components/PostalCodeInput';

import {
    LIEN_HOLDER,
    LIEN_HOLDER_ACCOUNT_NUMBER,
    LIEN_HOLDER_PHONE_NUMBER,
    LIEN_HOLDER_ADDRESS_BANK,
    LIEN_HOLDER_ADDRESS_CITY,
    LIEN_HOLDER_ADDRESS_STATE,
    LIEN_HOLDER_ADDRESS_ZIP,
    PAYOFF_DUE_DATE,
    PER_DIEM
} from '../../../constants';
import { LienholderFormData } from './types';
import {
    extractValueFromDatePickerEvent,
    getStateCodesSelectInputValues,
    getRequiredErrorMessage,
    useHandlePerDiemChange,
    tradeInContainerGridProps,
    tradeInContainerLargeFieldsProps
} from './utils';
import { LienholderAddressKeys } from './constants';
import { StyledDatePicker } from './LienholderForm.interstate.style';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';
import { Grid } from '@interstate/components/Grid';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint, logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { baseDealSelectors, offerReduxSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { AutocompleteOptions, LendersFormatted } from '@makemydeal/dr-dash-types';
import { PhoneInput } from '@interstate/components/PhoneInput';

export interface LienholderFormInputsProps {
    isTradeInCompleted: boolean;
    lienholderData: LienholderFormData;
    setLienholderData: Dispatch<SetStateAction<LienholderFormData>>;
    handleError: (state: boolean) => void;
}

const LienholderFormInputs = ({
    isTradeInCompleted,
    lienholderData,
    setLienholderData,
    handleError
}: LienholderFormInputsProps) => {
    const stateSelectInputValues = useMemo(getStateCodesSelectInputValues, StateCodes);

    const { lienholder, lienholderAccountNumber, lienholderPhoneNumber, lienholderAddress, payoffDueDate, perDiem } =
        lienholderData;

    // lenders context
    const { getLenders, lenders } = useAssetsData();

    const [lienholderErrors, setLienholderErrors] = useState<Record<string, boolean>>({});
    const [filteredLenders, setFilteredLenders] = useState<LendersFormatted[]>([]);
    const [isSearchingProvider, setIsSearchingProvider] = useState(false);

    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isEnableLenderSearch = useSelector(featureToggleSelectors.enableLenderSearch);
    const isTabletScreenSize = useMediaQuery({ query: `(max-width: ${BreakPoint.SCREEN_SM_MIN})` });
    const isBreakdownOpen = useSelector(baseDealSelectors.getBreakdownStatus);
    const isLargeScreen = useMediaQuery({ query: `(min-width: 1360px)` });
    const { xs, sm, md, lg, xl } = tradeInContainerGridProps(isBreakdownOpen, isLargeScreen);
    const largeFieldProps = tradeInContainerLargeFieldsProps(isBreakdownOpen, isLargeScreen);

    const sessionId = useSelector(offerReduxSelectors.getSessionId);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const dealXgVersion = useSelector(offerReduxSelectors.getDealXgVersion);
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const vin = useSelector(vehicleSelectors.getVehicleVin);

    const logNRAction = useCallback(
        (name: string, searchTerm: string, selection?: string) => {
            const action = {
                sessionId,
                dealXgId,
                dealXgVersion,
                dealerId,
                vin,
                searchTerm,
                ...(selection && { selection })
            };
            logNewRelicPageAction(name, {
                sessionId,
                dealXgId,
                dealXgVersion,
                dealerId,
                vin,
                searchTerm,
                ...(selection && { selection })
            });
        },
        [dealXgId, dealXgVersion, dealerId, sessionId, vin]
    );

    const onChange =
        <T extends TextInputEventValue | DatePickerEventValue>(
            field: keyof typeof lienholderData,
            valueExtractor?: (e: InterstateOnChangeEvent<T>) => number | Date | null
        ): InterstateOnChangeCallback<T> =>
        (e: InterstateOnChangeEvent<T>) => {
            const value = valueExtractor && valueExtractor(e);
            setLienholderData({
                ...lienholderData,
                [field]: valueExtractor ? value : e.target.value
            });
        };

    const onLienholderAddressChange = (field: keyof typeof lienholderAddress) => (e: InterstateOnChangeEvent<TextInputValue>) => {
        setLienholderData({
            ...lienholderData,
            lienholderAddress: {
                ...lienholderData.lienholderAddress,
                [field]: e.target.value
            }
        });
    };
    const { value: perDiemValue, handleChange: perDiemChange, handleBlur: perDiemBlur } = useHandlePerDiemChange(perDiem, onChange);

    const onPayoffDueDateChange = onChange<DatePickerEventValue>('payoffDueDate', (e) => extractValueFromDatePickerEvent(e));

    useEffect(() => {
        const hasErrors = Object.keys(lienholderErrors).length !== 0;
        handleError(hasErrors);
    }, [lienholderErrors, handleError]);

    useEffect(() => {
        const newErrors: Record<string, boolean> = {
            ...(!lienholderAccountNumber && { lienholderAccountNumber: true })
        };

        for (const key of LienholderAddressKeys) {
            if (!lienholderAddress[key]) {
                newErrors[key] = true;
            }
        }

        const hasAllErrors = Object.keys(newErrors).length === 5;

        if (hasAllErrors) {
            setLienholderErrors({});
        } else {
            setLienholderErrors(newErrors);
        }
    }, [lienholderAccountNumber, lienholderAddress]);

    useEffect(() => {
        if (isBaseDealScreenExperience && isEnableLenderSearch && !lenders.length) {
            getLenders();
        }
    }, []);

    useEffect(() => {
        setFilteredLenders(lenders);
    }, [lenders]);

    const onSearchProvider = (input: string) => {
        setIsSearchingProvider(true);

        // set local provider state
        onChange('lienholder')({ target: { value: input } });

        // filter provider list when input length is greater than 3
        if (input.length >= 3) {
            const filterData = lenders.filter((option) => option.label.toLowerCase().includes(input.toLowerCase()));
            setFilteredLenders(filterData);

            // log to NewRelic
            if (filterData.length > 0) {
                logNRAction('MV:LienholderForm - Found match', input);
            } else {
                logNRAction('MV:LienholderForm - No match', input);
            }
        } else {
            setFilteredLenders([]);
        }

        setIsSearchingProvider(false);
    };

    const changeLenderInformation = (e: AutocompleteOptions) => {
        const lender = e as LendersFormatted;
        const currentSearchTerm = lienholderData.lienholder;

        // clear filtered list
        setFilteredLenders([]);

        // set all the fields
        setLienholderData({
            ...lienholderData,
            lienholder: lender.label,
            lienholderPhoneNumber: lender.Phone ? lender.Phone.replace(/\D/g, '') : '',
            lienholderAddress: {
                ...lienholderData.lienholderAddress,
                address: lender.Address,
                postalCode: lender.ZIP,
                stateProvince: lender.State,
                city: lender.City
            }
        });

        // log to NewRelic
        logNRAction('MV:LienholderForm - Provider selected', currentSearchTerm, lender.label);
    };

    const providerWithAutocomplete = (
        <Autocomplete
            label={LIEN_HOLDER}
            name={kebabCase(LIEN_HOLDER)}
            menuOptions={filteredLenders}
            isLoading={isSearchingProvider}
            data-testid="lienholder"
            value={lienholder}
            onSearch={onSearchProvider}
            onSelect={changeLenderInformation}
            maxLength={50}
            disabled={!isTradeInCompleted}
        />
    );

    const minPayoffDueDate = new Date();
    minPayoffDueDate.setDate(minPayoffDueDate.getDate() + 1);

    return !isBaseDealScreenExperience ? (
        <>
            <ContainerGrid>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder"
                        label={LIEN_HOLDER}
                        name={kebabCase(LIEN_HOLDER)}
                        onChange={onChange('lienholder')}
                        value={lienholder}
                        maxLength={50}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder-account-number"
                        className="LienholderForm-accountNumber"
                        label={LIEN_HOLDER_ACCOUNT_NUMBER}
                        name={kebabCase(LIEN_HOLDER_ACCOUNT_NUMBER)}
                        onChange={onChange('lienholderAccountNumber')}
                        value={lienholderAccountNumber}
                        hasError={'lienholderAccountNumber' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'lienholderAccountNumber')}
                        maxLength={20}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <StyledDatePicker
                        id="payoff-due-date-input"
                        data-testid="payoff-due-date-input"
                        label={PAYOFF_DUE_DATE}
                        value={payoffDueDate as Date}
                        onChange={onPayoffDueDateChange}
                        minDate={minPayoffDueDate}
                        errorMessage=""
                        hasError={false}
                        sx={{ minWidth: '10rem' }}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="per-diem"
                        label={PER_DIEM}
                        name={kebabCase(PER_DIEM)}
                        onChange={perDiemChange}
                        onBlur={perDiemBlur}
                        placeholder="0.00"
                        value={perDiemValue}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
            </ContainerGrid>
            <ContainerGrid>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder-address-bank"
                        label={LIEN_HOLDER_ADDRESS_BANK}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_BANK)}
                        onChange={onLienholderAddressChange('address')}
                        value={lienholderAddress.address}
                        hasError={'address' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'address')}
                        maxLength={60}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder-address-city"
                        label={LIEN_HOLDER_ADDRESS_CITY}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_CITY)}
                        onChange={onLienholderAddressChange('city')}
                        value={lienholderAddress.city}
                        hasError={'city' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'city')}
                        maxLength={30}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={2}>
                    <SelectInput
                        data-testid="lienholder-address-state"
                        label={LIEN_HOLDER_ADDRESS_STATE}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_STATE)}
                        options={stateSelectInputValues}
                        onChange={onLienholderAddressChange('stateProvince')}
                        value={lienholderAddress.stateProvince}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'stateProvince')}
                        hasError={lienholderErrors.stateProvince || false}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={2}>
                    <PostalCodeInput
                        data-testid="lienholder-address-zip"
                        label={LIEN_HOLDER_ADDRESS_ZIP}
                        country={'US'}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_ZIP)}
                        onChange={onLienholderAddressChange('postalCode')}
                        value={lienholderAddress.postalCode}
                        hasError={'postalCode' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'postalCode')}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
            </ContainerGrid>
        </>
    ) : (
        <>
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }} rowSpacing={1} flexDirection="row">
                <Grid {...largeFieldProps}>
                    {isEnableLenderSearch && lenders.length ? (
                        providerWithAutocomplete
                    ) : (
                        <TextInput
                            data-testid="lienholder"
                            label={LIEN_HOLDER}
                            name={kebabCase(LIEN_HOLDER)}
                            onChange={onChange('lienholder')}
                            value={lienholder}
                            maxLength={50}
                            disabled={!isTradeInCompleted}
                        />
                    )}
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <label
                        style={{
                            fontSize: isTabletScreenSize ? '12px' : '14px',
                            whiteSpace: 'nowrap'
                        }}
                        htmlFor="lienholder-account-number"
                    >
                        {LIEN_HOLDER_ACCOUNT_NUMBER}
                    </label>
                    <TextInput
                        data-testid="lienholder-account-number"
                        id="lienholder-account-number"
                        className="LienholderForm-accountNumber"
                        displayLabel={false}
                        name={kebabCase(LIEN_HOLDER_ACCOUNT_NUMBER)}
                        onChange={onChange('lienholderAccountNumber')}
                        value={lienholderAccountNumber}
                        hasError={'lienholderAccountNumber' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'lienholderAccountNumber')}
                        maxLength={20}
                        disabled={!isTradeInCompleted}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <label
                        style={{
                            fontSize: isTabletScreenSize ? '12px' : '14px',
                            whiteSpace: 'nowrap'
                        }}
                        htmlFor="payoff-due-date-input"
                    >
                        {PAYOFF_DUE_DATE}
                    </label>
                    <StyledDatePicker
                        id="payoff-due-date-input"
                        data-testid="payoff-due-date-input"
                        value={payoffDueDate as Date}
                        onChange={onPayoffDueDateChange}
                        minDate={minPayoffDueDate}
                        errorMessage=""
                        hasError={false}
                        sx={{ minWidth: '5rem' }}
                        disabled={!isTradeInCompleted}
                        isBaseDealScreenExperience={isBaseDealScreenExperience}
                        displayLabel={false}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="per-diem"
                        label={PER_DIEM}
                        name={kebabCase(PER_DIEM)}
                        onChange={perDiemChange}
                        onBlur={perDiemBlur}
                        placeholder="0.00"
                        value={perDiemValue}
                        disabled={!isTradeInCompleted}
                    />
                </Grid>
                <Grid {...largeFieldProps}>
                    <TextInput
                        data-testid="lienholder-address-bank"
                        label={LIEN_HOLDER_ADDRESS_BANK}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_BANK)}
                        onChange={onLienholderAddressChange('address')}
                        value={lienholderAddress.address}
                        hasError={'address' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'address')}
                        maxLength={60}
                        disabled={!isTradeInCompleted}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <TextInput
                        data-testid="lienholder-address-city"
                        label={LIEN_HOLDER_ADDRESS_CITY}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_CITY)}
                        onChange={onLienholderAddressChange('city')}
                        value={lienholderAddress.city}
                        hasError={'city' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'city')}
                        maxLength={30}
                        disabled={!isTradeInCompleted}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <SelectInput
                        data-testid="lienholder-address-state"
                        label={LIEN_HOLDER_ADDRESS_STATE}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_STATE)}
                        options={stateSelectInputValues}
                        onChange={onLienholderAddressChange('stateProvince')}
                        value={lienholderAddress.stateProvince}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'stateProvince')}
                        hasError={lienholderErrors.stateProvince || false}
                        disabled={!isTradeInCompleted}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <PostalCodeInput
                        data-testid="lienholder-address-zip"
                        label={LIEN_HOLDER_ADDRESS_ZIP}
                        country={'US'}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_ZIP)}
                        onChange={onLienholderAddressChange('postalCode')}
                        value={lienholderAddress.postalCode}
                        hasError={'postalCode' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'postalCode')}
                        disabled={!isTradeInCompleted}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <PhoneInput
                        label={LIEN_HOLDER_PHONE_NUMBER}
                        name={kebabCase(LIEN_HOLDER_PHONE_NUMBER)}
                        onChange={onChange('lienholderPhoneNumber')}
                        value={lienholderPhoneNumber}
                        data-testid="lienholder-phone-number"
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'lienholderPhoneNumber')}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default LienholderFormInputs;
