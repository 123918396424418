// components
import { Grid } from '@interstate/components/Grid';
import { NumericInput } from '@interstate/components/NumericInput';

export type VehicleDataInputProps = {
    onSellingPriceChange: (value: string) => void;
    onOdometerChange: (value: string) => void;
    onMsrpChange: (value: string) => void;
    msrp?: string;
    odometer: string;
    minOdometerValue: number;
    sellingPrice: string;
    vin: string;
    isMsrpRequired: boolean;
};

export const VehicleDataInputs = (props: VehicleDataInputProps) => {
    const {
        odometer,
        sellingPrice,
        msrp,
        onOdometerChange,
        onSellingPriceChange,
        onMsrpChange,
        minOdometerValue,
        vin,
        isMsrpRequired
    } = props;

    return (
        <>
            <Grid
                container
                columnSpacing="8px"
                rowGap={1}
                sx={{
                    '& label': { whiteSpace: 'nowrap !important' }
                }}
            >
                <Grid xs={6}>
                    <NumericInput
                        placeholder="0.00"
                        name="MSRP"
                        label={isMsrpRequired ? 'MSRP' : 'Retail Price'}
                        value={msrp}
                        onChange={(e) => onMsrpChange(e.target.value)}
                        data-testid={isMsrpRequired ? `custom-msrp-${vin}` : `custom-retail-price-${vin}`}
                        required={isMsrpRequired}
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        inputPrefix={'$'}
                        autoInsertCommas
                        minValue={isMsrpRequired ? 0.01 : undefined}
                        customErrorMessages={{
                            lessThanMinValue: 'The value should be more than 0.'
                        }}
                    />
                </Grid>
                <Grid xs={6}>
                    <NumericInput
                        placeholder="0.00"
                        name="Selling Price"
                        label="Selling Price"
                        value={sellingPrice}
                        onChange={(e) => onSellingPriceChange(e.target.value)}
                        data-testid={`custom-selling-price-${vin}`}
                        required
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        inputPrefix={'$'}
                        autoInsertCommas
                        minValue={0.01}
                        customErrorMessages={{
                            lessThanMinValue: 'The value should be more than 0.'
                        }}
                    />
                </Grid>
                <Grid xs={12}>
                    <NumericInput
                        placeholder="0"
                        name="Odometer"
                        label={'Odometer'}
                        value={odometer || 0}
                        onChange={(e) => onOdometerChange(e.target.value)}
                        data-testid={`custom-odometer-${vin}`}
                        required
                        allowDecimal={false}
                        autoInsertCommas
                        minValue={minOdometerValue}
                        customErrorMessages={{
                            lessThanMinValue: 'The value should be more than 0.'
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};
