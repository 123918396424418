import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

import {
    CONTRACTING_CANCEL_FAILURE_EVENT,
    CONTRACTING_CLOSE_MODAL,
    CONTRACTING_IN_PROGRESS,
    CONTRACTING_OFFER_SAVE_FAILURE_EVENT,
    CONTRACTING_OPEN_MODAL,
    CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT,
    CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT,
    CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT,
    CONTRACTING_START_FAILURE_EVENT,
    CONTRACTING_STAGE_CANCEL_REQUEST,
    CONTRACTING_STAGE_START_REQUEST,
    CONTRACTING_STAGE_VERIFY_REQUEST,
    CONTRACTING_TIMEOUT_FAILURE,
    CONTRACTING_VERIFY_FAILURE_EVENT,
    CONTRACTING_VERIFY_SUCCESS_EVENT
} from '../actionTypes/contractingActionTypes';
import { ContractingState } from '@makemydeal/dr-dash-types';
import { OFFER_SAVE_REQUEST } from '../actionTypes/offerActionTypes';
import { nanoid } from 'nanoid';
import { isArray } from 'lodash';

export type ContractingReducer = Reducer<ContractingState>;

const initialState = {
    failureCurrentState: undefined,
    failureEvent: undefined,
    failureReasons: undefined,
    isInProgress: false,
    modalOpen: false,
    stage: undefined,
    startTime: null,
    status: undefined,
    successEvent: undefined,
    correlationId: undefined
};

/**
 * This function extracts the error reasons from the error structure returned by the storefront API.
 */
export const getStageRequestErrorReasons = (action: any) => {
    const errors = action.payload?.response?.errors;
    if (!errors || !isArray(errors) || !errors.length) {
        return undefined;
    }

    const reasons = [];
    for (const error of errors) {
        const properties = error?.properties;
        if (!properties?.length) {
            continue;
        }

        for (const prop of properties) {
            if (prop?.message) {
                reasons.push(prop.message);
            }
        }
    }

    return reasons;
};

export const getErrorReasons = (action: any) => {
    const errors = action.payload?.payload?.message?.errors;
    if (!errors || !errors.length) {
        return undefined;
    }

    const reasons = [];
    for (const error of errors) {
        if (error?.message) {
            reasons.push(error.message);
        }
    }

    return reasons;
};

/**
 *  Extract verification failure reasons from the error structure
 */
export const getVerificationFailureReasons = (eventPayload: any): string[] => {
    if (eventPayload?.error_reasons) {
        return eventPayload.error_reasons.flatMap((err: any[]) => (err[0]?.reason ? [err[0].reason] : []));
    } else if (Array.isArray(eventPayload?.message?.errors)) {
        return eventPayload.message.errors.flatMap((err: any) => (err.message ? [err.message] : []));
    }

    return [];
};

export const reducer = (state: ContractingState = initialState, action: any) =>
    produce(state, (draft: Draft<ContractingState>) => {
        switch (action.type) {
            case OFFER_SAVE_REQUEST: {
                if (action.meta?.source === CONTRACTING_IN_PROGRESS) {
                    draft.stage = 'saveOffer';
                }
                break;
            }

            case CONTRACTING_CANCEL_FAILURE_EVENT: {
                draft.failureEvent = action.type;
                draft.status = 'failure';
                draft.failureCurrentState = action.payload.payload?.message?.current_state;
                draft.failureReasons = undefined;
                draft.successEvent = undefined;

                break;
            }

            case CONTRACTING_START_FAILURE_EVENT: {
                draft.failureEvent = action.type;
                draft.status = 'failure';
                draft.failureCurrentState = undefined;
                draft.failureReasons = getErrorReasons(action);
                draft.successEvent = undefined;

                break;
            }

            case CONTRACTING_VERIFY_SUCCESS_EVENT: {
                if (action.payload.eventName === 'ContractingDecisions:Verified') {
                    draft.status = 'success';
                } else if (action.payload.eventName === 'ContractingDecisions:VerifiedWithWarnings') {
                    draft.status = 'warning';
                }
                draft.successEvent = action.type;
                draft.failureCurrentState = undefined;
                draft.failureEvent = undefined;
                draft.failureReasons = undefined;

                break;
            }

            case CONTRACTING_VERIFY_FAILURE_EVENT: {
                draft.failureEvent = action.type;
                draft.status = 'failure';
                draft.failureCurrentState = undefined;
                draft.successEvent = undefined;

                const reasons = getVerificationFailureReasons(action.payload.payload);

                draft.failureReasons = reasons.length ? reasons : undefined;

                break;
            }

            case CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT:
            case CONTRACTING_OFFER_SAVE_FAILURE_EVENT: {
                draft.failureEvent = action.type;
                draft.status = 'failure';
                draft.failureCurrentState = undefined;
                draft.failureReasons = undefined;
                draft.successEvent = undefined;

                break;
            }

            case CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT: {
                draft.failureEvent = action.type;
                draft.status = 'failure';
                draft.failureCurrentState = undefined;
                draft.failureReasons = getStageRequestErrorReasons(action);
                draft.successEvent = undefined;

                break;
            }

            case CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT: {
                draft.failureEvent = action.type;
                draft.status = 'failure';
                draft.failureCurrentState = undefined;
                draft.failureReasons = getStageRequestErrorReasons(action);
                draft.successEvent = undefined;

                break;
            }

            case CONTRACTING_TIMEOUT_FAILURE: {
                draft.failureEvent = action.type;
                draft.status = 'failure';
                draft.failureCurrentState = undefined;
                draft.failureReasons = [];
                draft.successEvent = undefined;
                break;
            }

            case CONTRACTING_OPEN_MODAL: {
                draft.modalOpen = true;
                break;
            }

            case CONTRACTING_CLOSE_MODAL: {
                draft.modalOpen = false;
                draft.isInProgress = false;
                draft.stage = undefined;
                draft.correlationId = undefined;
                draft.startTime = null;
                break;
            }

            case CONTRACTING_IN_PROGRESS: {
                draft.isInProgress = true;
                draft.correlationId = nanoid();
                if (state.startTime === null) {
                    draft.startTime = Date.now();
                }
                break;
            }

            case CONTRACTING_STAGE_CANCEL_REQUEST: {
                draft.stage = 'cancel';
                break;
            }

            case CONTRACTING_STAGE_START_REQUEST: {
                draft.stage = 'start';
                break;
            }

            case CONTRACTING_STAGE_VERIFY_REQUEST: {
                draft.stage = 'verify';
                break;
            }
        }
    });
