import styled, { css } from 'styled-components';

import { DatePicker } from '@interstate/components/DatePicker';

export const StyledLienholderActionGroup = styled.div`
    margin-top: 1rem;
`;

export const StyledDatePicker = styled(DatePicker)<{ isBaseDealScreenExperience?: boolean }>`
    min-width: ${(props) => (props.isBaseDealScreenExperience ? '5rem' : '10rem')} !important;
`;

export const StyledLienholderFormContainer = styled.div<{ isBaseDealScreenExperience?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .LienholderForm-accountNumber label {
        white-space: nowrap;
    }

    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            padding-left: 0.25rem;
        `}
`;
