import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const ContextualCommentsContainer = styled.div<{ showDealMentions: boolean }>`
    &.deal-comments-drawer {
        width: 100%;
        position: static;
        padding: 15px;
        z-index: 0;
    }
    &.deal-comments-baseFooter {
        width: 407px;
        right: 360px;
        position: absolute;
        padding: 15px;
        bottom: 60px;
    }

    &.deal-comments-footer {
        width: 407px;
        right: 235px;
        position: absolute;
        padding: 15px;
        bottom: 60px;
    }

    // Common styles
    background-color: #ffffff;
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    text-align: left;
    z-index: 1002;
    display: ${({ showDealMentions }) => (showDealMentions ? 'block' : 'none')};
    .contextual-comments-container-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        .contextual-comments-container-header-title {
            font-size: 22px;
            font-weight: 700;
            color: #333333;
            line-height: 25.3px;
        }
    }
    @media (max-width: ${BreakPoint.SCREEN_MD_MIN}) {
        width: 100% !important;
        right: 0 !important;
    }
`;
