import React, { FC, useState } from 'react';
import { Button } from '@interstate/components/Button';
import { Box } from '@interstate/components/Box';
import { Interstitial } from '@interstate/components/Interstitial';
import { dealTemplateActionCreators, dealTemplateSelectors } from '@makemydeal/dr-dash-store';
import TemplateSaveStatusDialog from './TemplateSaveStatusDialog.interstate';
import { useDispatch, useSelector } from 'react-redux';

type SaveStatusDialogProps = {
    disabled: boolean;
};

export const TemplateSaveButtonInterstate: FC<SaveStatusDialogProps> = ({ disabled }) => {
    const [showTemplateSaveStatus, setShowTemplateSaveStatus] = useState(false);
    const dispatch = useDispatch();

    const dealTemplateDraft = useSelector(dealTemplateSelectors.getDraftDealTemplate);
    const isSaving = dealTemplateDraft?.isSaving;

    const handleSave = () => {
        setShowTemplateSaveStatus(false);
        dispatch(dealTemplateActionCreators.saveTemplate());
    };

    return (
        <>
            {isSaving && <Interstitial data-testid="template-save-loader" fullScreen />}
            {showTemplateSaveStatus && (
                <TemplateSaveStatusDialog
                    handleSave={handleSave}
                    show={showTemplateSaveStatus}
                    onHide={() => setShowTemplateSaveStatus(false)}
                />
            )}
            <Box className="save-template-button-container">
                <Button
                    data-testid="save-template-button"
                    size="medium"
                    disabled={disabled}
                    onClick={() => setShowTemplateSaveStatus(true)}
                >
                    Save Template
                </Button>
            </Box>
        </>
    );
};
