// libraries
import { type AnyFSA } from '@makemydeal/dr-platform-shared';

// interfaces/types
import {
    type DashMiddleware,
    type DashStore,
    type DashNext,
    featureToggleSelectors,
    dealerSelectors
} from '@makemydeal/dr-shared-store';
import { SOCKET_EVENT_IGNORED, SOCKET_EVENT_RECEIVED } from '../actionTypes/socketActionTypes';
import { updateDealRefId } from '../actions/offerActionCreators';
import { offerSelectors } from '../selectors';

export const dealRefIdMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    const prevState = store.getState();
    next(action);
    const state = store.getState();
    switch (action.type) {
        case SOCKET_EVENT_RECEIVED:
        case SOCKET_EVENT_IGNORED: {
            const event = action.payload;
            const dealRefId = offerSelectors.getDealRefId(state);
            const prevDealRefId = offerSelectors.getDealRefId(prevState);
            const enablePatchDealRefId = featureToggleSelectors.enablePatchDealRefId(state);
            if (enablePatchDealRefId && !dealRefId && event?.topic === 'dealXgPatch' && !event.message?.includes('subscribe')) {
                const dealRefId = event.data?.coxAutoReferences?.dealRefId;
                if (dealRefId) {
                    store.dispatch(
                        updateDealRefId({
                            dealRefId: dealRefId
                        })
                    );
                }
            }
            if (dealerSelectors.getEnableGenerateContracts(state)) {
                if (!prevDealRefId && dealRefId) {
                    (window as any).drSocketManager.refresh();
                }
            }
            break;
        }
    }
};
