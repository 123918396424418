import React from 'react';
import { useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { BannerContainer } from '../common/Layout.style';
import RecalculateBanner from './recalculateBanner/RecalculateBanner.interstate';

const Banner: React.FC = () => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const enablePaymentRecalculationForDesking = useSelector(featureToggleSelectors.enablePaymentRecalculationForDesking);

    return (
        <BannerContainer isNewAppWidthEnabled={isABPencilEnabled}>
            {enablePaymentRecalculationForDesking && <RecalculateBanner />}
        </BannerContainer>
    );
};

export default Banner;
