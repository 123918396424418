import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@interstate/components/Typography';
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { CommonConsumer } from '@makemydeal/dr-platform-types';

import { useCustomerUI } from '../customerUI/useCustomerUI';
import { CoBuyerLink } from './CoBuyerLink';
import {
    HeaderBuyerContainer,
    HeaderCoBuyerInsuranceContainer,
    HeaderContentRow,
    InsuranceSeparator
} from './Header.style.interstate';
import { VehicleInsuranceLink } from './VehicleInsurance';
import hotkeys from 'hotkeys-js';

export const HeaderRowInterstate: FC = () => {
    const dispatch = useDispatch();
    const { showCustomerCard } = useCustomerUI();

    const shopper = useSelector(offerReduxSelectors.getShopperInfo);
    const commonConsumerId = useSelector(offerReduxSelectors.getShopperCommonConsumerId);

    const customerEditedCallback = (customer: CommonConsumer) => {
        dispatch(offerReduxActionCreators.updatedShopperLocationFromConsumer(shopper, customer));
    };
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const enableInsuranceProviderSearch = useSelector(featureToggleSelectors.enableInsuranceProviderSearch);
    const prNumber = offerSessionUtils.getPrNumber();

    useEffect(() => {
        if (!isBaseDealScreenExperience || !commonConsumerId) {
            return;
        }
        hotkeys('alt+b+i, CapsLock+alt+b+i', function (event) {
            event.preventDefault();
            showCustomerCard(true, { customerEditedCallback, readOnlyFields: true });
        });

        return () => {
            hotkeys.unbind('alt+b+i, CapsLock+alt+b+i');
        };
    }, [commonConsumerId, isBaseDealScreenExperience]);

    return (
        <HeaderContentRow data-testid="header-row-content">
            <HeaderBuyerContainer
                isBaseDealScreenExperience={isBaseDealScreenExperience}
                enableInsuranceProviderSearch={enableInsuranceProviderSearch}
            >
                <Typography
                    variant="h3"
                    tag={commonConsumerId ? 'a' : 'div'}
                    color="base.color.blue.700"
                    data-testid="shopper-name-link"
                    sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
                >
                    <div
                        className="shopper-name"
                        data-testid="shopper-name"
                        onClick={() => commonConsumerId && showCustomerCard(true, { customerEditedCallback, readOnlyFields: true })}
                    >
                        {shopper.firstName} {shopper.lastName}
                    </div>
                </Typography>
                {isBaseDealScreenExperience && enableInsuranceProviderSearch ? (
                    <HeaderCoBuyerInsuranceContainer>
                        <CoBuyerLink />
                        <InsuranceSeparator />
                        <VehicleInsuranceLink />
                    </HeaderCoBuyerInsuranceContainer>
                ) : (
                    <CoBuyerLink />
                )}
            </HeaderBuyerContainer>
            {prNumber && (
                <Typography data-testid="header-row-pr-number" variant="body-md" tag="div" color="base.color.red.700">
                    PR - {prNumber}
                </Typography>
            )}
        </HeaderContentRow>
    );
};
