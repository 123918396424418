import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const FooterButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    width: fit-content;
`;
