// externals
import { useSelector } from 'react-redux';

// selectors
import { dealerSelectors, featureToggleReducer, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { scopedStateSelectors, ScopedStateProvider } from '@makemydeal/shared-scoped-store';

// components
import { Typography } from '@interstate/components/Typography';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { OfficialCreditDecisionToggle } from './OfficialCreditDecisionToggle';
import { DeskingCard } from './DeskingCard';

import { HeaderRowWrapper, OfferCommentsWrapper, DealCentralContainer } from '../DeskingActivity.styles';
import { DeskingCardsContainer } from './DeskingCard.style';
import OfferCommentsInterstate from '../OfferComments.interstate';
import { RollToOptionsInterstate } from '@makemydeal/dr-dash-components';
import BaseDealHeader from '../baseDealActivity/BaseDealHeader';
import BaseDealContainer from '../baseDealActivity/baseDealContainer/BaseDealContainer';

export const DeskingActivityDealMultiple = () => {
    const scopesOrder = useSelector(scopedStateSelectors.getScopesOrder);
    const theme = useInterstateTheme();
    const isSingleCardView = scopesOrder.length === 1;
    const toggles = useSelector(featureToggleSelectors.getFeatureToggles);

    const enableGetRollToPayment = featureToggleReducer.isRollToPaymentEnabled(toggles);

    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    return (
        <>
            {isBaseDealScreenExperience && isDealCentralExperience ? (
                <DealCentralContainer>
                    <BaseDealHeader />
                    <BaseDealContainer />
                    <OfferCommentsWrapper marginTop="-4rem">
                        <OfferCommentsInterstate title="Offer Comments" textRows={1} />
                    </OfferCommentsWrapper>
                </DealCentralContainer>
            ) : (
                <>
                    <HeaderRowWrapper>
                        <Typography variant="h3">Current Working Offer</Typography>
                        {!isBaseDealScreenExperience && <OfficialCreditDecisionToggle disabled={!isSingleCardView} />}
                    </HeaderRowWrapper>
                    <DeskingCardsContainer cardsAmount={scopesOrder.length} theme={theme}>
                        {scopesOrder.map((scopeId) => (
                            <ScopedStateProvider scopeId={scopeId} key={scopeId}>
                                <DeskingCard scopeId={scopeId} />
                                {enableGetRollToPayment && <RollToOptionsInterstate />}
                            </ScopedStateProvider>
                        ))}
                    </DeskingCardsContainer>

                    <OfferCommentsWrapper>
                        <OfferCommentsInterstate
                            description="Notes will appear in the offer history and will be visible on Accelerate and Sales View"
                            textRows={1}
                        />
                    </OfferCommentsWrapper>
                </>
            )}
        </>
    );
};
