// externals
import { useSelector } from 'react-redux';

// libraries
import { Badge } from '@interstate/components/Badge';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// components
import { OfferBadge } from '../offerCard/OfferCard.style.interstate';
import { StyledOfferCardBadgesContainer } from '../offerCardContent/OfferCardContent.style';

type OfferCardBadgesProps = {
    hasMultiScenarios?: boolean;
    isCurrent?: boolean;
    isAccepted?: boolean;
    isUpdated?: boolean;
    scenarioCount?: number;
    toggleModal?: () => void;
};

export const OfferCardBadges = ({
    hasMultiScenarios,
    isCurrent,
    isAccepted,
    isUpdated,
    scenarioCount,
    toggleModal
}: OfferCardBadgesProps) => {
    const theme = useInterstateTheme();
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const displayedScenarioCount = scenarioCount && scenarioCount > 1 ? scenarioCount : null;

    const renderOfferCardBadges = () => {
        return (
            <>
                {isAccepted && (
                    <OfferBadge id="accepted-offer-badge" variant="success">
                        Accepted
                    </OfferBadge>
                )}
                {isUpdated && (
                    <OfferBadge id="updated-offer-badge" variant="success">
                        Updated
                    </OfferBadge>
                )}
            </>
        );
    };

    const renderOfferCardAbPencilBadges = () => {
        return (
            <StyledOfferCardBadgesContainer theme={theme}>
                {hasMultiScenarios && !!displayedScenarioCount && (
                    <Badge variant="info" data-testid="offer-card-primary-offer">
                        Primary
                    </Badge>
                )}
                {isCurrent && (
                    <Badge variant="info" data-testid="offer-card-current-offer">
                        Current
                    </Badge>
                )}
                {hasMultiScenarios && !!displayedScenarioCount && (
                    <Badge variant="transparent" data-testid="offer-card-scenario-count-offer" className="badge-scenario-count">
                        <span onClick={toggleModal} data-testid="offer-card-scenario-count-button">
                            {displayedScenarioCount}
                        </span>
                    </Badge>
                )}
                {isAccepted && (
                    <Badge variant="success" data-testid="offer-card-accepted-offer">
                        Accepted
                    </Badge>
                )}
                {isUpdated && (
                    <Badge variant="success" data-testid="offer-card-updated-offer">
                        Updated
                    </Badge>
                )}
            </StyledOfferCardBadgesContainer>
        );
    };

    return isABPencilEnabled ? renderOfferCardAbPencilBadges() : renderOfferCardBadges();
};
