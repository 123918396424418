// externals
import { nanoid } from 'nanoid';
import { useEffect, useState, useMemo } from 'react';

// interstate components
import { Alert } from '@interstate/components/Alert';

// types
import { RollToBasicObject } from '@makemydeal/dr-dash-types';
import {
    extractDownPaymentErrors,
    extractNegativeAmortizationErrors,
    extractSellingPriceErrors,
    extractTermsError,
    extractRateErrors
} from '../utils/rollToErrorParser';

type Props = {
    errors?: RollToBasicObject[];
};

type ErrorItem = {
    id: string;
    message: string;
};

const RollToCannotApplyError: React.FC<Props> = ({ errors = [] }) => {
    const [errorMessages, setErrorMessages] = useState<ErrorItem[]>([]);

    useEffect(() => {
        setErrorMessages((prevMessages) => {
            const newNegativeAmortizationErrors = extractNegativeAmortizationErrors(errors);
            const newDownPaymentErrors = extractDownPaymentErrors(errors);
            const newTermErrors = extractTermsError(errors);
            const newSellingPriceErrors = extractSellingPriceErrors(errors);
            const newRateErrors = extractRateErrors(errors);

            // we will combine old messages with new ones
            const combined = [
                ...prevMessages.map((item) => item.message),
                ...newNegativeAmortizationErrors,
                ...newDownPaymentErrors,
                ...newTermErrors,
                ...newSellingPriceErrors,
                ...newRateErrors
            ];

            // remove duplicates
            const uniqueMessages = Array.from(new Set(combined));

            // here we will have final error list by reusing existing IDs or creating new ones
            return uniqueMessages.map((msg) => {
                const existingItem = prevMessages.find((x) => x.message === msg);
                return existingItem ? existingItem : { id: nanoid(), message: msg };
            });
        });
    }, [JSON.stringify(errors)]);

    const title = 'Cannot apply changes';

    if (errorMessages.length === 0) {
        return null;
    }

    return (
        <Alert title={title} type="error" data-testid="roll-to-alert">
            {errorMessages.length === 1 ? (
                <span>{errorMessages[0].message}</span>
            ) : (
                <ul style={{ margin: 0, paddingLeft: '1rem' }}>
                    {errorMessages.map((errorItem) => (
                        <li key={errorItem.id}>{errorItem.message}</li>
                    ))}
                </ul>
            )}
        </Alert>
    );
};

export default RollToCannotApplyError;
