import { ITradeIn } from '@makemydeal/dr-platform-types';

export const LIENHOLDER_FORM_SHOW_MORE = 'Show more';
export const LIENHOLDER_FORM_SHOW_LESS = 'Show less';

export const LIENHOLDER_FORM_SUMMARY_ADDRESS = 'Address';

export const LIENHOLDER_FORM_ACTION_BUTTON_CANCEL = 'Cancel';
export const LIENHOLDER_FORM_ACTION_BUTTON_APPLY = 'Apply';

export const LIENHOLDER_FORM_HEADER_TITLE = 'Lienholder Information';
export const LIENHOLDER_FORM_HEADER_EDIT_LABEL = 'Edit';
export const LIENHOLDER_FORM_HEADER_ADD_LABEL = '+ Add Lienholder Information';

export enum LienholderFormActionGroupControlIds {
    ActionButtonsGroup = 'lienholder-form-action-buttons-group',
    ActionButtonCancel = 'lienholder-form-btn-action-cancel',
    ActionButtonApply = 'lienholder-form-btn-action-apply'
}

export enum LienholderFormHeaderControlIds {
    Header = 'lienholder-form-header',
    ActionButtonEdit = 'lienholder-form-header-btn-action-edit',
    ActionButtonAdd = 'lienholder-form-header-btn-action-add'
}

export enum LienholderSummaryControlIds {
    Summary = 'lienholder-form-summary',
    ActionButtonShowMoreOrLess = 'lienholder-form-summary-btn-action-show-more-less',
    Lienholder = 'lienholder-form-summary-lienholder',
    AccountNumber = 'lienholder-form-summary-account-number',
    PayoffDueDate = 'lienholder-form-summary-payoff-due-date',
    PerDiem = 'lienholder-form-summary-per-diem',
    Address = 'lienholder-form-summary-address'
}

export const LienholderAddressKeys: (keyof ITradeIn['lienHolderAddress'])[] = [
    'address',
    'city',
    'postalCode',
    'stateProvince'
] as (keyof ITradeIn['lienHolderAddress'])[];
