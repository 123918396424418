// externals
import { isPlainObject } from 'lodash';

// libraries
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

import useEditableProp from './useEditableProp';
import { CITY_TAX_NAME, COUNTY_TAX_NAME, CRT_TAX, LOCAL_TAX_NAME, MUT_TAX, STATE_TAX_NAME, TAX_ITEM_KEYS } from '../../constants';

import { ETaxNames, CategorizedTaxes, TaxDetail } from '../../types';

export const excludeUnamedTaxes = (taxes: paymentServicesTypes.TaxItems) => taxes.filter((tax) => tax.taxName);

export const hasTaxBreakdown = (taxItem: paymentServicesTypes.TaxItem): boolean => !!taxItem.taxBreakdown;

export const objectHasNumericValue = (data: Record<any, any> | undefined, options?: { includeZero?: boolean }) => {
    if (!isPlainObject(data)) {
        return false;
    }

    return Object.values(data!).some(
        (value) => typeof value === 'number' && isFinite(value) && (options?.includeZero ? true : value !== 0)
    );
};

export const categorizeTaxes = (taxItems: paymentServicesTypes.TaxItems, canOverride: boolean): CategorizedTaxes => {
    const categorizedTaxes = {} as CategorizedTaxes;
    const taxTypes = taxItems.map((item) => {
        return {
            item,
            type: item.taxName || item.class
        };
    });

    taxItems.forEach((item) => {
        let category = ETaxNames.ADDITIONAL;

        switch (item.taxName) {
            case CRT_TAX:
                category = ETaxNames.UPFRONT;
                break;

            case MUT_TAX:
                category = ETaxNames.MONTHLYUSE;
                break;

            default:
                category = canOverride ? ETaxNames.UPFRONT : ETaxNames.CAPITALIZED;
        }

        if (categorizedTaxes[category] == null) {
            categorizedTaxes[category] = [];
        }

        categorizedTaxes[category].push(item);
    });

    return categorizedTaxes;
};

export const getTaxOvverrideDisabled = (monthlyUseTaxTotal: number, capCostReductionTaxTotal: number): boolean => {
    return Boolean(monthlyUseTaxTotal || capCostReductionTaxTotal);
};

export const getTotalTaxRate = (
    taxBreakdown: paymentServicesTypes.TaxItemBreakdown,
    taxBreakdownLocal: paymentServicesTypes.TaxItemBreakdown
) => {
    if (!taxBreakdown) {
        return 0;
    }

    const { stateTaxRate = 0, countyTaxRate = 0, cityTaxRate = 0, localTaxRate = 0 } = taxBreakdown;

    if (taxBreakdownLocal) {
        const {
            stateTaxRate: localStateTaxRate = null,
            countyTaxRate: localCountyTaxRate = null,
            cityTaxRate: localCityTaxRate = null,
            localTaxRate: localLocalTaxRate = null
        } = taxBreakdownLocal;

        return (
            (localStateTaxRate ?? stateTaxRate) +
            (localCountyTaxRate ?? countyTaxRate) +
            (localCityTaxRate ?? cityTaxRate) +
            (localLocalTaxRate ?? localTaxRate)
        );
    }

    return stateTaxRate + countyTaxRate + cityTaxRate + localTaxRate;
};

type TaxRateKey = keyof Pick<
    paymentServicesTypes.TaxItemBreakdown,
    'localTaxRate' | 'cityTaxRate' | 'countyTaxRate' | 'stateTaxRate'
>;

export const isTaxRateKey = (key: string): key is TaxRateKey => {
    const rateKeys: TaxRateKey[] = ['localTaxRate', 'cityTaxRate', 'countyTaxRate', 'stateTaxRate'];
    return rateKeys.includes(key as TaxRateKey);
};

export const getHumanReadableTaxName = (taxName: string, mappingTable: Map<string, string>): string => {
    const lowerCaseTaxName = taxName.toLowerCase();

    if (mappingTable.has(lowerCaseTaxName)) {
        return mappingTable.get(lowerCaseTaxName) as string;
    }

    return taxName;
};

export const getTaxDetails = (taxItem: paymentServicesTypes.TaxItem, taxOverrideEnabled = false) => {
    const { stateTaxRate, stateTax, countyTaxRate, countyTax, cityTaxRate, cityTax, localTaxRate, localTax } =
        taxItem.taxBreakdown || /* istanbul ignore next */ {};

    const {
        stateTaxRate: isEditedStateTaxRate = false,
        stateTax: isEditedStateTax = false,
        countyTaxRate: isEditedCountyTaxRate = false,
        countyTax: isEditedCountyTax = false,
        cityTaxRate: isEditedCityTaxRate = false,
        cityTax: isEditedCityTax = false,
        localTaxRate: isEditedLocalTaxRate = false,
        localTax: isEditedLocalTax = false,
        taxAmount: isEditedTaxTotalAmount = false
    } = taxItem.manualChanges || {};

    const isSomeTaxAmountEdited = isEditedStateTax || isEditedCountyTax || isEditedCityTax || isEditedLocalTax;
    const isTaxRateEditable = !isSomeTaxAmountEdited && !isEditedTaxTotalAmount;

    const taxDetails: TaxDetail[] = [
        {
            name: STATE_TAX_NAME,
            rate: taxOverrideEnabled ? 0 : stateTaxRate,
            amount: taxOverrideEnabled ? 0 : stateTax,
            key: TAX_ITEM_KEYS.STATE_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.STATE_TAX,
            isEditedRate: isEditedStateTaxRate,
            isEditedAmount: isEditedStateTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount,
            isDisabled: taxOverrideEnabled ? true : false
        },
        {
            name: COUNTY_TAX_NAME,
            rate: taxOverrideEnabled ? 0 : countyTaxRate,
            amount: taxOverrideEnabled ? 0 : countyTax,
            key: TAX_ITEM_KEYS.COUNTY_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.COUNTY_TAX,
            isEditedRate: isEditedCountyTaxRate,
            isEditedAmount: isEditedCountyTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount,
            isDisabled: taxOverrideEnabled ? true : false
        },
        {
            name: CITY_TAX_NAME,
            rate: taxOverrideEnabled ? 0 : cityTaxRate,
            amount: taxOverrideEnabled ? 0 : cityTax,
            key: TAX_ITEM_KEYS.CITY_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.CITY_TAX,
            isEditedRate: isEditedCityTaxRate,
            isEditedAmount: isEditedCityTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount,
            isDisabled: taxOverrideEnabled ? true : false
        },
        {
            name: LOCAL_TAX_NAME,
            rate: taxOverrideEnabled ? 0 : localTaxRate,
            amount: taxOverrideEnabled ? 0 : localTax,
            key: TAX_ITEM_KEYS.LOCAL_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.LOCAL_TAX,
            isEditedRate: isEditedLocalTaxRate,
            isEditedAmount: isEditedLocalTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount,
            isDisabled: taxOverrideEnabled ? true : false
        }
    ];

    return taxDetails;
};

export const createTrueKeysObject = (keys: string[]): Record<string, boolean> => {
    return keys.reduce((obj: Record<string, boolean>, key: string) => {
        obj[key] = true;
        return obj;
    }, {});
};

export { useEditableProp };
