import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const ContentContainer = styled.div`
    padding: 8px;
    padding-right: 0;
    @media (max-width: ${BreakPoint.PHONE}) {
        padding: 0 8px;
    }
    overflow: hidden;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 24px 0;
`;
