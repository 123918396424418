// externals
import { useSelector } from 'react-redux';

// components
import { InterstateTableProps } from '@interstate/components/Table/Types/tableTypes';
import { Button } from '@interstate/components/Button';
import { CheckBox } from '@interstate/components/CheckBox';
import { Table } from '@interstate/components/Table';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import TableRowAlerts from '../vehicleProtectionTableAlerts/TableRowAlerts';

// libraries
import { vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

// utils
import { transformProductToModel, getFormattedProviderAddress } from '../../utils/vehicleProtectionTableUtils';

// types
import { VehicleProtectionTableModel } from '../../types/vehicleProtectionTable';
import { DealProductWithProductCode, DraftDealProduct } from '@makemydeal/dr-dash-types';

// styles
import {
    PRODUCT_PLAN_CLASSNAME,
    RATES_LOADING_SPINNER,
    SELECTED_ROW_CLASSNAME,
    VehicleProtectionTableActions,
    VehicleProtectionTableContainer,
    VppTableCheckBoxContainer
} from './VehicleProtectionTable.style';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { VppProductType } from '../vehicleProtection/constants';
import { VehicleProtectionTableCell } from './VehicleProtectionTableCell';

type VehicleProtectionTableProps = {
    products: DraftDealProduct[];
    onProductApply: (record: DealProductWithProductCode, index: number) => void;
    onProductApplyAll: () => void;
    onProductEdit: (record: DealProductWithProductCode, index: number) => void;
    id?: string;
};

const typeColumnPercent = '5.6%';
const milesColumnPercent = '6.8%';
const priceColumnPercent = '7.4%';
const providerColumnPercent = '10.6%';

const VehicleProtectionTable = ({
    products,
    onProductApply,
    onProductApplyAll,
    onProductEdit,
    id
}: VehicleProtectionTableProps) => {
    const isFetchingRates = useSelector(vehicleProtectionSelectors.isFetchingVppRates);
    const productAttributes = useSelector(vehicleProtectionSelectors.getVppProductAttributes);

    const theme = useInterstateTheme();

    const models = products.map((product) => transformProductToModel(product, isFetchingRates, productAttributes));
    const productsEligibleForSelection = useSelector(vehicleProtectionSelectors.getVppProductsEligibleForSelection);
    const isVppProviderAddressEnabled = useSelector(featureToggleSelectors.isVppProviderAddressEnabled);

    const columns: InterstateTableProps['columns'] = [
        {
            title: (
                <VppTableCheckBoxContainer>
                    <CheckBox
                        onChange={onProductApplyAll}
                        value="All"
                        data-testid={`select-all-product-checkbox-id`}
                        checked={
                            productsEligibleForSelection.length > 0 &&
                            productsEligibleForSelection.every((product) => product.selected)
                        }
                        disabled={products.length === 0}
                    />
                </VppTableCheckBoxContainer>
            ),
            width: '4rem',
            align: 'right',
            fixed: 'left',
            render: (keyValue, record: VehicleProtectionTableModel, index) => {
                const productCode = products[index].productCode;
                const unselectDisabled = !productCode || (record.selected && productAttributes[productCode]?.unselectDisabled);
                const selectDisabled = !productCode || (!record.selected && productAttributes[productCode]?.selectDisabled);
                return (
                    <VppTableCheckBoxContainer>
                        <CheckBox
                            data-testid={`${record.selected ? 'selected' : 'unselected'}-product-checkbox-id`}
                            onChange={() => onProductApply(products[index] as DealProductWithProductCode, index)}
                            value={String(index)}
                            checked={record.selected}
                            disabled={selectDisabled || unselectDisabled}
                        />
                    </VppTableCheckBoxContainer>
                );
            }
        },
        {
            title: '',
            width: '3rem',
            fixed: 'left',
            render: (keyValue, _record, index) => {
                const productCode = products[index].productCode;
                if (isFetchingRates) {
                    return (
                        <div className={RATES_LOADING_SPINNER} data-testid="rates-loading-spinner">
                            <LoadingIndicator size="small" />
                        </div>
                    );
                }
                return productCode && <TableRowAlerts productAttributes={productAttributes[productCode]}></TableRowAlerts>;
            }
        },
        {
            title: 'Type',
            dataIndex: 'productCategoryCode',
            width: typeColumnPercent,
            fixed: 'left',
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        {
            title: 'Provider',
            width: providerColumnPercent,
            render: (product: VehicleProtectionTableModel) => {
                const { productProviderName, productCategoryCode } = product;
                const shouldRenderProviderAddress = isVppProviderAddressEnabled && productCategoryCode === VppProductType.GAP;

                const tooltipContent = shouldRenderProviderAddress ? getFormattedProviderAddress(product) : productProviderName;

                return <VehicleProtectionTableCell value={productProviderName} tooltipContent={tooltipContent} />;
            }
        },
        {
            title: 'Plan/Coverage',
            dataIndex: 'productProviderPlan',
            className: PRODUCT_PLAN_CLASSNAME,
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        { title: 'Months', dataIndex: 'productCoverageLength', width: typeColumnPercent },
        {
            title: 'Miles',
            dataIndex: 'productCoverageMiles',
            width: milesColumnPercent,
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        {
            title: 'Deductible',
            dataIndex: 'productDeductible',
            width: milesColumnPercent,
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        {
            title: 'Cost',
            dataIndex: 'productCost',
            width: priceColumnPercent,
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        {
            title: 'Markup',
            dataIndex: 'markupValue',
            width: priceColumnPercent,
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        {
            title: 'Price',
            dataIndex: 'productPrice',
            width: priceColumnPercent,
            render: (keyValue) => <VehicleProtectionTableCell value={keyValue} tooltipContent={keyValue} />
        },
        {
            title: 'Actions',
            align: 'center',
            width: typeColumnPercent,
            fixed: 'right',
            render: (keyValue, _record: VehicleProtectionTableModel, index) => {
                const productCode = products[index].productCode;
                const editDisabled = !productCode || productAttributes[productCode]?.editingDisabled;
                return (
                    <VehicleProtectionTableActions>
                        <Button
                            buttonStyle={'aux'}
                            size={'small'}
                            data-testid="vpp-edit-button-action"
                            disabled={editDisabled}
                            onClick={() => onProductEdit(products[index] as DealProductWithProductCode, index)}
                        >
                            Edit
                        </Button>
                    </VehicleProtectionTableActions>
                );
            }
        }
    ];

    return (
        <VehicleProtectionTableContainer theme={theme}>
            <Table
                data-testid="vehicle-protection-table-id"
                id={id}
                columns={columns}
                data={models}
                highlightOnHover={false}
                emptyText="No products to display"
                enablePagination={false}
                dataDensity="small"
                rowClassName={(record: VehicleProtectionTableModel) => (record.selected ? SELECTED_ROW_CLASSNAME : '')}
                scrollX={1350}
            />
        </VehicleProtectionTableContainer>
    );
};

export default VehicleProtectionTable;
