import styled from 'styled-components';

export const DDJRouteContainer = styled.div`
    max-width: 1456px;
    margin: 0 auto;
    padding: 0 16px;
`;

export const DDJWrapper = styled.div`
    width: 100%;
    padding: 0px;
    padding-top: 40px;
    padding-bottom: 10px;
`;
