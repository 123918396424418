import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@interstate/components/Alert';
import { AuxButton } from '@interstate/components/Button';

//libraries
import { offerSelectors, paymentSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

//Styles
import { BannerWrapper } from '../Banner.interstate.style';

const Banner: React.FC = () => {
    const [showBanner, setShowBanner] = useState(false);
    const appplicationName = useSelector(offerSelectors.getApplicationName);
    const isPaymentsCalculated = useSelector(paymentSelectors.getPaymentsCalculated);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    // ensure that the banner is shown only once
    const hasBannerShown = useRef(false);

    useEffect(() => {
        if (appplicationName === 'Desking' && isPaymentsCalculated && !hasBannerShown.current) {
            setShowBanner(true);
            hasBannerShown.current = true;
        }
    }, [appplicationName, isPaymentsCalculated]);

    const handleBanner = () => {
        setShowBanner(false);
    };

    return (
        <Fragment>
            {showBanner && (
                <BannerWrapper className={isBaseDealScreenExperience ? 'isBaseDeal' : ''}>
                    <Alert
                        type="caution"
                        key={`alert-recalculate`}
                        role=""
                        data-testid={`alert-recalculate`}
                        title="Updates from Vin Desking"
                    >
                        This deal from Desking has been recalculated.
                        <AuxButton data-testid="cta-button" onClick={handleBanner}>
                            Okay, Got It
                        </AuxButton>
                    </Alert>
                </BannerWrapper>
            )}
        </Fragment>
    );
};

export default Banner;
