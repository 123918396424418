import styled from 'styled-components';
import { toRem } from '../utils/pixelUtils';

export const StyledColumnsContainer = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: -1 / 1;
    grid-auto-rows: max-content;

    gap: ${toRem(8)};
    border-style: solid;
    padding: ${toRem(16)};
    border-radius: ${toRem(4)};
    border: 1px solid #b2b2b2;
`;

export const OfferCompareContainer = styled.div<{ cardsAmount: number }>`
    display: grid;
    overflow: auto;
    grid-template-columns: ${({ cardsAmount }) => `repeat(${cardsAmount}, 299px)`};
    grid-template-rows:
        [header] auto
        [pricing] auto
        [addons] auto
        [finance-reductions] auto
        [financing-terms] auto
        [make-current] auto;

    padding: 0 ${toRem(8)};
    margin: 0 ${toRem(-8)};
    gap: ${toRem(16)};
`;
