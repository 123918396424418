// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { type ReduxNext } from '@makemydeal/dr-shared-store';

// consts/enums
import { PAYMENT_RECEIVED } from '../actionTypes/deskingActionTypes';
import {
    APPLY_ROLL_TO_OPTIONS,
    APPLY_ROLL_TO_OPTIONS_PAYMENT_FAILED,
    CLEAR_ROLL_TO_OPTIONS,
    REVERT_ROLL_TO_ORIGINAL,
    ROLL_TO_PAYMENT_TOGGLE,
    UPDATE_ROLL_ADJUST,
    UPDATE_ROLL_TARGET_BALANCE_DUE,
    UPDATE_ROLL_TARGET_PAYMENT,
    UPDATE_ROLL_TO_OPTIONS
} from '../actionTypes/rollToOptionsActionTypes';

// types
import { RollToActionValues } from '@makemydeal/dr-dash-types';
import { RequestRollToOptionsPayload } from '../reducers/rollToOptionsReducer';

// selectors
import { paymentSelectors } from '@makemydeal/dr-offer-redux';
import { rollToActionCreators } from '../actions';
import { compositeSelectors, offerReduxSelectors, offerSelectors, tradeInSelectors } from '../selectors';
import { handleRollToPayment } from '../utils/rollToPaymentDispatcher';
import { FINANCE } from '@makemydeal/dr-platform-types';

export const middleware = (store: any) => (next: ReduxNext) => async (action: AnyFSA) => {
    next(action);

    const state = store.getState();

    switch (action.type) {
        case UPDATE_ROLL_TARGET_BALANCE_DUE:
        case UPDATE_ROLL_TARGET_PAYMENT: {
            const term = offerReduxSelectors.getSelectedTermMonths(state);
            const rate = offerSelectors.getSellRateOverrideWithFallback(state);
            const downPayment = offerSelectors.getDownPayment(state);
            const sellingPrice = offerSelectors.getSellingPrice(state);
            const trade = tradeInSelectors.getTradeValue(state);
            const taxesAndFees = compositeSelectors.taxesAndFeesTotal(state);
            const addOns = compositeSelectors.getTotalAddons(state);
            const payment = offerSelectors.getMonthlyPayment(state);
            const currentOfferType = offerReduxSelectors.getCurrentOfferType(state);
            const profit = offerReduxSelectors.getSelectedPaymentProfitDetails(state);
            const balanceDue = currentOfferType === FINANCE ? offerSelectors.getAmountFinanced(state) : 0;

            const current: RollToActionValues = {
                addOns,
                downPayment,
                actionValue: action.type === UPDATE_ROLL_TARGET_PAYMENT ? payment : balanceDue,
                rate,
                sellingPrice,
                term,
                taxesAndFees,
                trade
            };

            const payload: RequestRollToOptionsPayload = {
                rollToItems: {
                    current,
                    adjust: current,
                    profit
                },
                balanceDue
            };
            store.dispatch({
                type: UPDATE_ROLL_TO_OPTIONS,
                payload
            });

            break;
        }
        case UPDATE_ROLL_ADJUST: {
            await handleRollToPayment({
                state,
                action,
                store,
                optionType: 'adjust'
            });
            break;
        }
        case ROLL_TO_PAYMENT_TOGGLE: {
            store.dispatch({
                type: CLEAR_ROLL_TO_OPTIONS
            });
            break;
        }

        case REVERT_ROLL_TO_ORIGINAL: {
            await handleRollToPayment({
                state,
                action,
                store,
                optionType: 'current'
            });
            break;
        }

        case PAYMENT_RECEIVED: {
            if (action.payload.actionForPayment === APPLY_ROLL_TO_OPTIONS) {
                if (paymentSelectors.getIsError(state)) {
                    const messages = paymentSelectors.getErrorMessages(state);
                    store.dispatch(rollToActionCreators.applyRollToOptionsError(action, messages));
                } else {
                    store.dispatch(rollToActionCreators.applyRollToOptionsSuccess(action));
                }
            }
            break;
        }

        case APPLY_ROLL_TO_OPTIONS_PAYMENT_FAILED: {
            store.dispatch(rollToActionCreators.applyRollToOptionsError(action));
            break;
        }

        default:
            break;
    }
};
