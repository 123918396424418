// externals
import { FC } from 'react';

// libraries
import { ArrowUturnLeftIcon, TrashIcon } from '@interstate/components/Icons';
import { Button } from '@interstate/components/Button';

// styles
import { AccessoryActionBaseIconContainer, AccessoryActionIconContainer } from '../../ManualAccessories.interstate.styles';
import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

export type ManualAccessoryActionIconProps = {
    deleted: boolean;
    index: number;
    restoreAccessory: (index: number) => void;
    removeAccessory: (index: number) => void;
};
export const ManualAccessoryActionIcon: FC<ManualAccessoryActionIconProps> = ({
    deleted,
    index,
    restoreAccessory,
    removeAccessory
}) => {
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const IconConatiner = isBaseDealScreenExperience ? AccessoryActionBaseIconContainer : AccessoryActionIconContainer;
    const buttonIcon = isBaseDealScreenExperience ? 'tertiary' : 'secondary';
    return (
        <IconConatiner>
            {deleted ? (
                <Button
                    buttonStyle={buttonIcon}
                    icon={<ArrowUturnLeftIcon />}
                    id={`manual-accessories-restore-icon-${index}`}
                    data-testid={`manual-accessories-restore-icon-${index}`}
                    onClick={() => restoreAccessory(index)}
                />
            ) : (
                <Button
                    buttonStyle={buttonIcon}
                    id={`manual-accessories-trash-icon-${index}`}
                    data-testid={`manual-accessories-trash-icon-${index}`}
                    icon={<TrashIcon />}
                    onClick={() => removeAccessory(index)}
                />
            )}
        </IconConatiner>
    );
};
