import { useSelector } from 'react-redux';

import { dateUtils } from '@makemydeal/dr-common-utils';
import {
    multiScenarioSelectors,
    offerInfoSelectors,
    offerReduxSelectors,
    smartOfferSaveSelectors
} from '@makemydeal/dr-dash-store';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { DocumentTextIcon } from '@interstate/components/Icons/DocumentTextIcon';
import { CheckmarkSolidIcon } from '@interstate/components/Icons/CheckmarkSolidIcon';
import { ExclamationTriangleIcon } from '@interstate/components/Icons/ExclamationTriangleIcon';
import { Typography } from '@interstate/components/Typography';

import {
    FooterDealInfoContainer,
    FooterDealInfoContent,
    FooterDealInfoDocumentIconContainer,
    FooterDealInfoExclamationIconContainer
} from './Footer.style.interstate';

export const FooterDealInfo = () => {
    const theme = useInterstateTheme();
    const showUnsavedChanges = useSelector(smartOfferSaveSelectors.currentOfferHasUnsavedChanges);
    const lastSuccessfulSaveDateString = useSelector(offerInfoSelectors.getLastSuccessfulSaveDateString);
    const activeComparison = useSelector(multiScenarioSelectors.getActiveComparison);

    const lastSavedDate = lastSuccessfulSaveDateString && new Date(lastSuccessfulSaveDateString);
    const createdOnFormattedDate = lastSavedDate && dateUtils.formatAsMMDDYYYY(lastSavedDate);
    const createdOnFormattedTime = lastSavedDate && dateUtils.formatAsTime(lastSavedDate);
    const isPublished = activeComparison?.isPublished ?? true;

    if (showUnsavedChanges) {
        return (
            <FooterDealInfoContainer theme={theme} data-testid="footer-deal-info">
                <FooterDealInfoContent>
                    <FooterDealInfoExclamationIconContainer>
                        <ExclamationTriangleIcon color="#ffc20e" />
                    </FooterDealInfoExclamationIconContainer>
                    <Typography variant="body-sm" tag="span">
                        Unsaved Changes
                    </Typography>
                </FooterDealInfoContent>
            </FooterDealInfoContainer>
        );
    } else if (!lastSavedDate) {
        return null;
    }
    return (
        <FooterDealInfoContainer theme={theme} data-testid="footer-deal-info">
            <FooterDealInfoContent>
                {!isPublished ? (
                    <FooterDealInfoDocumentIconContainer>
                        <DocumentTextIcon color="#005BA8" />
                    </FooterDealInfoDocumentIconContainer>
                ) : (
                    <FooterDealInfoDocumentIconContainer>
                        <CheckmarkSolidIcon color="#3c7b29" />
                    </FooterDealInfoDocumentIconContainer>
                )}
                <Typography variant="body-sm" tag="span">
                    {isPublished ? (
                        'Published'
                    ) : (
                        <>
                            <Typography variant="strong-sm" sx={{ fontWeight: 700 }}>
                                Draft Deal
                            </Typography>{' '}
                            - Saved
                        </>
                    )}{' '}
                    {createdOnFormattedDate} at {createdOnFormattedTime}
                </Typography>
            </FooterDealInfoContent>
        </FooterDealInfoContainer>
    );
};
