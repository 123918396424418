import { FlexCol, FlexGridCol, FlexRow } from '@makemydeal/dr-dash-components';

import { Typography } from '@interstate/components/Typography';

import { ActionButton } from './ActionButton';
import {
    LIENHOLDER_FORM_HEADER_ADD_LABEL,
    LIENHOLDER_FORM_HEADER_EDIT_LABEL,
    LIENHOLDER_FORM_HEADER_TITLE,
    LienholderFormHeaderControlIds
} from './constants';

export interface LienholderFormHeaderProps {
    isEditMode: boolean;
    isLienholderInfoAvailable: boolean;
    isEditDisabled: boolean;
    toggleEditMode: (mode: boolean) => void;
    isBaseDealScreenExperience?: boolean;
}

const LienholderFormHeader = ({
    isEditMode,
    isLienholderInfoAvailable,
    isEditDisabled,
    toggleEditMode,
    isBaseDealScreenExperience
}: LienholderFormHeaderProps) => (
    <FlexGridCol data-testid={LienholderFormHeaderControlIds.Header}>
        <FlexCol>
            <FlexRow justifyContent="space-between" alignItems="center">
                {(isLienholderInfoAvailable || isEditMode || isBaseDealScreenExperience) && (
                    <>
                        <Typography variant={isBaseDealScreenExperience ? 'h4' : 'h6'}>{LIENHOLDER_FORM_HEADER_TITLE}</Typography>
                        {!isBaseDealScreenExperience && !isEditMode && (
                            <ActionButton
                                label={LIENHOLDER_FORM_HEADER_EDIT_LABEL}
                                dataTestId={LienholderFormHeaderControlIds.ActionButtonEdit}
                                handleClick={() => toggleEditMode(true)}
                                disabled={isEditDisabled}
                            />
                        )}
                    </>
                )}
                {!isBaseDealScreenExperience && !isLienholderInfoAvailable && !isEditMode && (
                    <ActionButton
                        label={LIENHOLDER_FORM_HEADER_ADD_LABEL}
                        dataTestId={LienholderFormHeaderControlIds.ActionButtonAdd}
                        handleClick={() => toggleEditMode(true)}
                        disabled={isEditDisabled}
                    />
                )}
            </FlexRow>
        </FlexCol>
    </FlexGridCol>
);

export default LienholderFormHeader;
