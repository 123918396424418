import React from 'react';

import { StyledContainerGridItem } from './Card.style';
import { CSSProperties } from 'styled-components';

interface ContainerGridItemProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    xs?: number | 'min-content' | 'full' | 'hidden' | 'contents';
    md?: number | 'min-content' | 'full' | 'hidden' | 'contents';
    lg?: number | 'min-content' | 'full' | 'hidden' | 'contents';
    justifyContent?: 'start' | 'center' | 'end';
    alignSelf?: CSSProperties['alignSelf'];
}

export const ContainerGridItem = ({ children, xs, md, lg, justifyContent, alignSelf, ...divProps }: ContainerGridItemProps) => {
    const classes = [
        xs ? `container-grid-item-xs-${xs}` : null,
        md ? `container-grid-item-md-${md}` : null,
        lg ? `container-grid-item-lg-${lg}` : null
    ]
        .filter(Boolean)
        .join(' ');

    return (
        <StyledContainerGridItem className={classes} $justifyContent={justifyContent} $alignSelf={alignSelf} {...divProps}>
            {children}
        </StyledContainerGridItem>
    );
};
