/* istanbul ignore file */

// types
import {
    AnyAdjustValues,
    OptionTypes,
    RequestRollToPaymentsPayload,
    RollToActionValues,
    StateTree
} from '@makemydeal/dr-dash-types';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { ApplicationCodes, IPenciledInputs, OfferTypeEnum } from '@makemydeal/dr-platform-types';
import { RSAASuccessType } from 'redux-api-middleware';
import { RequestRollToOptionsPayload, RequestRollToPaymentUPQPayload } from '../reducers/rollToOptionsReducer';

// libraries
import { omit } from 'lodash';
import { RSAA } from 'redux-api-middleware';

// selectors
import { offerReduxSelectors, rollToSelectors } from '../selectors';

// utils
import { paymentPayloadUtils, utils } from '@makemydeal/dr-offer-redux';
import { apiConfigHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// constants
import { ROLL_TO_OPTIONS_LABELS } from '@makemydeal/dr-shared-types';
import {
    CLEAR_ROLL_TO_ERRORS,
    REQUEST_ROLL_TO_PAYMENTS,
    REQUEST_ROLL_TO_PAYMENTS_FAILURE,
    REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPQ,
    REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR,
    UPDATE_ROLL_TO_OPTIONS
} from '../actionTypes/rollToOptionsActionTypes';
import { buildPaymentPayloadHostSelectors } from './offerPayloadUtil';

const getUprSuccessActionType = ({
    targetPayment
}: {
    targetPayment: number;
}): RSAASuccessType<StateTree, RequestRollToPaymentsPayload | undefined> => ({
    type: REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR,
    payload: async (_action, _state, res) => {
        const data = await res.json();
        return {
            ...data,
            targetPayment
        };
    }
});

const getUpqSuccessActionType = ({
    currentOfferType,
    isFinanceOffer,
    isPaymentTab
}: {
    currentOfferType: string;
    isFinanceOffer: boolean;
    isPaymentTab: boolean;
}): RSAASuccessType<StateTree, RequestRollToPaymentUPQPayload | undefined> => ({
    type: REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPQ,
    payload: async (_action, state, res) => {
        const data = await res.json();
        const VppAndProtectionTotal = rollToSelectors.getVppAndProtectionTotal(state);
        const quote = data[currentOfferType]?.payments.at(0) || {};
        const payloadValues: RequestRollToPaymentUPQPayload = {
            profit: quote.profit,
            actionValue: isPaymentTab ? quote.monthlyPayment : quote.amountFinanced,
            totalTaxes: quote.totalTax,
            balanceDue: isFinanceOffer ? quote.amountFinanced : 0,
            totalFees: quote.totalFee,
            VppAndProtectionTotal
        };

        return payloadValues;
    }
});

const getIsOptionToUpdateAdjust = ({ optionType }: { optionType: OptionTypes }) => {
    return { isOptionToUpdateAdjust: optionType === 'adjust' };
};

export const handleRollToPayment = async (params: { state: StateTree; action: AnyFSA; store: any; optionType: OptionTypes }) => {
    const { state, action, store, optionType } = params;

    // Clear any existing errors before making new requests
    store.dispatch({ type: CLEAR_ROLL_TO_ERRORS });

    const rollToOptionsItems = rollToSelectors.getRollToOptionsItems(state);
    const currentValues = rollToOptionsItems.current;
    const payloadValues: AnyAdjustValues = action.payload && optionType === 'adjust' ? action.payload : currentValues;
    const offerDetails = offerReduxSelectors.getPenciledInputs(state);
    const targetPayment = rollToSelectors.getRollTargetPayment(state);
    const balanceDue = rollToSelectors.getBalanceDue(state);
    const adjustedValues = rollToSelectors.getAdjustedValues(state);
    const adjustedOptions = rollToSelectors.getAdjustedStatusOptions(state, payloadValues);
    const currentOfferType = offerReduxSelectors.getCurrentOfferType(state);

    const penciledInputs: IPenciledInputs = utils.buildRollToPenciledInputsState(offerDetails, adjustedOptions, currentOfferType);
    const actionValueType = action.meta?.actionValueType || ROLL_TO_OPTIONS_LABELS.PAYMENT;
    const isFinanceOffer = currentOfferType === OfferTypeEnum.FINANCE;
    const isPaymentTab = actionValueType === ROLL_TO_OPTIONS_LABELS.PAYMENT;
    const rollPaymentPayload = isPaymentTab
        ? {
              paymentRoll: targetPayment
          }
        : {
              amountFinancedRoll: balanceDue
          };

    const rollState = {
        ...state,
        offer: {
            ...state.offer,
            penciledInputs: {
                ...state.offer?.penciledInputs,
                ...penciledInputs
            }
        }
    };

    //host selectors might have some other options which not covered locally to construct a proper payload!!!
    const hostSelectors = buildPaymentPayloadHostSelectors();

    const payload = paymentPayloadUtils.createPaymentPayload(rollState, hostSelectors, {
        type: REQUEST_ROLL_TO_PAYMENTS,
        meta: {
            isRollToCall: true,
            sourceApplication: ApplicationCodes.MV,
            paymentRequestOverride: {
                [currentOfferType]: {
                    termMonths: adjustedOptions.term.value
                }
            }
        }
    });

    const locHref = locHrefUtil.getLocHref();

    const uprBffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'user-program-rolls');
    const uprEndpoint = uprBffApiUrlResult.url;

    const upqBffApiUrlResult = apiConfigHelper.buildBffApiUrl(
        MANAGER_VIEW_APP_PREFIX,
        locHref,
        'payment-orchestrator-with-dealer-id'
    );
    const upqEndpoint = upqBffApiUrlResult.url;

    const uprSuccessActionType = getUprSuccessActionType({ targetPayment });
    const upqSuccessActionType = getUpqSuccessActionType({ currentOfferType, isFinanceOffer, isPaymentTab });

    const rsaCall = (endpoint: string, successActionType: RSAASuccessType, rollToPayload: Record<string, unknown>) => ({
        [RSAA]: {
            endpoint: endpoint,
            method: 'POST',
            body: JSON.stringify(rollToPayload),
            headers: { 'Content-Type': 'application/json' },
            types: [REQUEST_ROLL_TO_PAYMENTS, successActionType, REQUEST_ROLL_TO_PAYMENTS_FAILURE]
        }
    });

    await Promise.all([
        store.dispatch(
            rsaCall(uprEndpoint, uprSuccessActionType, {
                ...payload,
                payment: {
                    ...payload.payment,
                    ...rollPaymentPayload
                }
            })
        ),
        store.dispatch(rsaCall(upqEndpoint, upqSuccessActionType, payload as any))
    ]);

    const newAdjustValues: RollToActionValues = omit(
        {
            ...adjustedValues,
            ...payloadValues
        },
        ['actionValue']
    );

    const { isOptionToUpdateAdjust } = getIsOptionToUpdateAdjust({ optionType });

    const requestRollToOptionsPayload: RequestRollToOptionsPayload = {
        rollToItems: {
            adjust: isOptionToUpdateAdjust ? newAdjustValues : currentValues
        }
    };

    store.dispatch({
        type: UPDATE_ROLL_TO_OPTIONS,
        payload: requestRollToOptionsPayload
    });
};
