// externals
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';

// libraries
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { SelectInput, SelectInputEventValue } from '@interstate/components/SelectInput';
import { Box } from '@interstate/components/Box';
import { extractNumberFromInputValue } from '@makemydeal/dr-shared-ui-utils';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { Rate, RatedProtectionProduct } from '@makemydeal/dr-platform-types';
import { formatNumber } from '@makemydeal/dr-common-utils';

// types
import { EditProductTermsProps } from '../../types';

// utils
import {
    getDeductibleOptions,
    getIntervalOptions,
    getMilesOptions,
    getTermsOptions,
    parseDeductible,
    hasCorrectTerm,
    hasCorrectMiles,
    hasCorrectDeductible,
    hasCorrectServiceInterval,
    hasCorrectPlan,
    findRate,
    getDeductibleValue
} from '../../utils/termsUtils';

const EditProductTerms = ({
    productProps,
    productRates,
    rateAttributes,
    onEdit,
    onValidationError
}: {
    productProps: EditProductTermsProps;
    productRates: Rate[];
    rateAttributes: RatedProtectionProduct['rateAttributes'];
    onEdit: (productProps: EditProductTermsProps) => void;
    onValidationError: (hasError: boolean) => void;
}) => {
    const isPlanSelectionEnabled = useSelector(featureToggleSelectors.isPlanSelectionForVppEnabled);

    const findRateWithPlan = useCallback(
        (attributes: Partial<Rate>) => {
            return findRate(productRates, {
                ...attributes,
                ...(isPlanSelectionEnabled ? { productProviderPlan: productProps.productProviderPlan } : {})
            });
        },
        [productRates, isPlanSelectionEnabled, productProps]
    );

    const handleMonthsChange = useCallback(
        (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
            const newMonths = extractNumberFromInputValue(event.target.value);
            const selectedRate = findRateWithPlan({
                productCoverageLength: newMonths
            }) as Rate;
            onEdit({
                productProviderPlan: selectedRate.productProviderPlan,
                productCoverageLength: newMonths,
                productCoverageMiles: selectedRate.productCoverageMiles,
                productDeductible: selectedRate.productDeductible,
                productDeductibleMethod: selectedRate.deductibleApplyType,
                productServiceInterval: selectedRate.productServiceInterval
            });
        },
        [onEdit, findRateWithPlan]
    );

    const handleMilesChange = useCallback(
        (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
            const newMiles = extractNumberFromInputValue(event.target.value);
            const selectedRate = findRateWithPlan({
                productCoverageLength: productProps.productCoverageLength,
                productCoverageMiles: newMiles
            }) as Rate;
            onEdit({
                productProviderPlan: selectedRate.productProviderPlan,
                productCoverageMiles: newMiles,
                productDeductible: selectedRate.productDeductible,
                productDeductibleMethod: selectedRate.deductibleApplyType,
                productServiceInterval: selectedRate.productServiceInterval
            });
        },
        [onEdit, productProps, findRateWithPlan]
    );

    const handleDeductibleChange = useCallback(
        (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
            const newDeductible = parseDeductible(String(event.target.value));
            const { deductibleValue: productDeductible, deductibleApplyType: productDeductibleMethod } = newDeductible;
            const selectedRate = findRateWithPlan({
                productCoverageLength: productProps.productCoverageLength,
                productCoverageMiles: productProps.productCoverageMiles,
                productDeductible,
                deductibleApplyType: productDeductibleMethod
            }) as Rate;
            onEdit({
                productProviderPlan: selectedRate.productProviderPlan,
                productDeductible,
                productDeductibleMethod,
                productServiceInterval: selectedRate.productServiceInterval
            });
        },
        [onEdit, productProps, findRateWithPlan]
    );

    const handleServiceIntervalChange = useCallback(
        (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
            const newInterval = event.target.value;
            onEdit({
                productServiceInterval: newInterval as string
            });
        },
        [onEdit]
    );

    const hasApplicablePlan = useMemo(
        () => (isPlanSelectionEnabled ? hasCorrectPlan(productRates, rateAttributes, productProps.productProviderPlan) : true),
        [productRates, rateAttributes, productProps.productProviderPlan, isPlanSelectionEnabled]
    );

    const hasApplicableTerm = useMemo(
        () => hasCorrectTerm(productRates, rateAttributes, productProps.productCoverageLength),
        [productRates, rateAttributes, productProps.productCoverageLength]
    );

    const hasApplicableMiles = useMemo(
        () => hasCorrectMiles(productRates, rateAttributes, productProps.productCoverageMiles),
        [productRates, rateAttributes, productProps.productCoverageMiles]
    );

    const hasApplicableDeductible = useMemo(
        () =>
            hasCorrectDeductible(
                productRates,
                rateAttributes,
                productProps.productDeductible,
                productProps.productDeductibleMethod
            ),
        [productRates, rateAttributes, productProps.productDeductible, productProps.productDeductibleMethod]
    );

    const hasApplicableServiceInterval = useMemo(
        () => hasCorrectServiceInterval(productRates, rateAttributes, productProps.productServiceInterval),
        [productRates, rateAttributes, productProps.productServiceInterval]
    );

    const termOptions = useMemo(
        () => getTermsOptions(productRates, productProps.productProviderPlan, isPlanSelectionEnabled),
        [productProps.productProviderPlan, productRates, isPlanSelectionEnabled]
    );
    const milesOptions = useMemo(
        () => getMilesOptions(productRates, productProps, isPlanSelectionEnabled),
        [productProps, productRates, isPlanSelectionEnabled]
    );
    const deductiblesOptions = useMemo(
        () => getDeductibleOptions(productRates, productProps, isPlanSelectionEnabled),
        [productProps, productRates, isPlanSelectionEnabled]
    );
    const intervalOptions = useMemo(
        () => getIntervalOptions(productRates, productProps, isPlanSelectionEnabled),
        [productProps, productRates, isPlanSelectionEnabled]
    );

    const hasProductIntervalErorr =
        hasApplicablePlan && hasApplicableTerm && hasApplicableMiles && hasApplicableDeductible && !hasApplicableServiceInterval;
    const hasProductTermMonthsError = hasApplicablePlan && !hasApplicableTerm;
    const hasProductTermMilesError = hasApplicableTerm && !hasApplicableMiles;
    const hasProductDeductibleError = hasApplicablePlan && hasApplicableTerm && hasApplicableMiles && !hasApplicableDeductible;

    const hasError = hasProductDeductibleError || hasProductIntervalErorr || hasProductTermMonthsError || hasProductTermMilesError;

    useEffect(() => {
        onValidationError(hasError);
    }, [hasError, onValidationError]);

    return (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
            {termOptions[0] && (
                <SelectInput
                    data-testid="edit-product-term-months"
                    label="Term Months"
                    name="productCoverageLength"
                    onChange={handleMonthsChange}
                    placeholder={String(productProps.productCoverageLength)}
                    value={
                        !isNil(productProps.productCoverageLength)
                            ? String(productProps.productCoverageLength)
                            : termOptions[0].label
                    }
                    hasError={hasProductTermMonthsError}
                    errorMessage={hasApplicableTerm ? '' : 'The term is invalid, please replace it with a valid value.'}
                    options={termOptions}
                    displayDeselectOption={false}
                />
            )}
            {milesOptions[0] && (
                <SelectInput
                    data-testid="edit-product-term-miles"
                    label="Term Miles"
                    name="productCoverageMiles"
                    onChange={handleMilesChange}
                    placeholder={
                        !isNil(productProps.productCoverageMiles) ? formatNumber(productProps.productCoverageMiles) : undefined
                    }
                    value={
                        !isNil(productProps.productCoverageMiles)
                            ? formatNumber(productProps.productCoverageMiles)
                            : milesOptions[0].value
                    }
                    options={milesOptions}
                    hasError={hasProductTermMilesError}
                    errorMessage={hasApplicableMiles ? '' : 'The mileage is invalid, please replace it with a valid value.'}
                    displayDeselectOption={false}
                />
            )}
            {deductiblesOptions[0] && (
                <SelectInput
                    data-testid="edit-product-deductible"
                    label="Deductible"
                    name="productDeductible"
                    onChange={handleDeductibleChange}
                    placeholder="Select"
                    value={
                        !(isNil(productProps.productDeductible) && isNil(productProps.productDeductibleMethod))
                            ? getDeductibleValue(productProps.productDeductible, productProps.productDeductibleMethod)
                            : ''
                    }
                    options={deductiblesOptions}
                    hasError={hasProductDeductibleError}
                    errorMessage={hasApplicableDeductible ? '' : 'The deductible is invalid, please replace it with a valid value.'}
                    displayDeselectOption={false}
                />
            )}
            {intervalOptions[0] && (
                <SelectInput
                    data-testid="edit-product-interval"
                    label="Interval"
                    name="productServiceInterval"
                    onChange={handleServiceIntervalChange}
                    placeholder={productProps.productServiceInterval}
                    value={
                        !isNil(productProps.productServiceInterval) ? productProps.productServiceInterval : intervalOptions[0].label
                    }
                    hasError={hasProductIntervalErorr}
                    errorMessage={
                        hasApplicableServiceInterval ? '' : 'The interval is invalid, please replace it with a valid value.'
                    }
                    options={intervalOptions}
                    displayDeselectOption={false}
                />
            )}
        </Box>
    );
};

export default EditProductTerms;
