import styled, { css } from 'styled-components';
import { Typography } from '@interstate/components/Typography';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { BaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';

export const CategoryTitle = styled(Typography)<{
    isBaseDealScreenExperience: boolean;
}>`
    margin-bottom: ${(props) => (!props.isBaseDealScreenExperience ? -1 : 16)}px !important;
`;

export const FeesHeaderContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #b2b2b2;
    color: #6d6d6d;
    font-size: 14px;
    padding: 12px 0px;
    gap: 16px;
    margin-bottom: 4px;

    @media (max-width: ${(props) => props.BaseBreakPoint.SMALL}) {
        display: none;
    }
`;

export const FieldHeader = styled.div<{ isLease?: boolean; isEmpty?: boolean; isAmount?: boolean }>`
    ${({ isEmpty, isLease }) =>
        isEmpty &&
        css`
            width: ${!isLease ? 32 : 129}px !important;
        `}

    ${({ isEmpty }) =>
        !isEmpty &&
        css`
            flex: 1;
        `}

    ${({ isAmount }) =>
        isAmount &&
        css`
            text-align: right;
        `}
`;
