// externals
import { MouseEventHandler } from 'react';

// CSS style components
import { titleStyle } from '../RollToStyles';

// interstate component
import { Button } from '@interstate/components/Button';

type RollToPaymentHeaderProps = {
    gridAction?: MouseEventHandler<HTMLButtonElement>;
    gridCategory?: string;
    actionMessage?: string;
    disabled?: boolean;
};

const RollToPaymentHeader: React.FC<RollToPaymentHeaderProps> = ({ gridCategory, gridAction, actionMessage, disabled }) => {
    return (
        <div style={titleStyle}>
            <h3>{gridCategory}</h3>
            {actionMessage && (
                <Button
                    disabled={disabled}
                    data-testid="roll-to-payment-header-button"
                    id="roll-to-payment-header-button"
                    size="small"
                    buttonStyle="tertiary"
                    onClick={gridAction}
                >
                    {actionMessage}
                </Button>
            )}
        </div>
    );
};

export default RollToPaymentHeader;
