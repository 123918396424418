// externals
import { v4 as generateUuid } from 'uuid';
import OktaAuth from '@okta/okta-auth-js';
import { AxiosRequestConfig } from 'axios';

// libraries
import { SESSION_ID_HEADER, TRACE_ID_HEADER } from '@makemydeal/dr-shared-bff-types';
import { toggleUtils, sessionUtils, offerSessionUtils, apiConfigConsts } from '@makemydeal/dr-shared-ui-utils';

// utils
import { axiosInstance } from './axiosInstance';
import { ApiUrlBuilder } from './types';
import { apiUrlBuilderFromAppPrefix } from './apiUrlBuilderFactory';

const FETCH_RELATIVE_PATH = '/api';
const HEADER_AUTHORIZATION_PREFIX = 'Bearer';

let oktaAuthInstance: OktaAuth;

export const setOktaAuthForAxiosInterceptor = (oktaAuth: OktaAuth): void => {
    oktaAuthInstance = oktaAuth;
};

/**
 *
 * @deprecated
 * Use buildAxiosRequestInterceptorWithConfig method instead
 */
export const buildAxiosRequestInterceptor = (appPrefix: string) => {
    return buildAxiosRequestInterceptorWithConfig(apiUrlBuilderFromAppPrefix(appPrefix));
};

export const buildAxiosRequestInterceptorWithConfig = (apiUrlBuilder: ApiUrlBuilder) => {
    const axiosRequestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const url = config.url;
        if (url) {
            const bffUrl = apiUrlBuilder.buildBffApiUrl('');

            // only go if it is a relative path /api or if it is bff endpoint
            if (url.startsWith(bffUrl.url) || url.startsWith(FETCH_RELATIVE_PATH)) {
                const token = oktaAuthInstance?.getAccessToken();
                // these are the headers we want to add
                const headers: { [name: string]: string } = {
                    ...toggleUtils.getToggleHeaderFromQueryParamsOrSession(),
                    ...(token && process.env.BYPASS_OKTA !== 'true'
                        ? { Authorization: `${HEADER_AUTHORIZATION_PREFIX} ${token}` }
                        : undefined),
                    [SESSION_ID_HEADER]: sessionUtils.getAppSessionId(),
                    [TRACE_ID_HEADER]: generateUuid()
                };

                // if this is a PR build, then we will through the PR environment and PR Number into headers
                const prNumber = offerSessionUtils.getPrNumber();
                if (prNumber) {
                    headers[apiConfigConsts.PR_NUMBER_API_HEADER] = prNumber;
                    const prEnv = offerSessionUtils.getPrEnvironmentName();
                    if (prEnv) {
                        headers[apiConfigConsts.PR_ENV_API_HEADER] = prEnv;
                    }
                }

                if (!config.headers) {
                    config.headers = headers;
                } else {
                    Object.keys(headers).forEach((header) => {
                        if (!config.headers[header]) {
                            config.headers[header] = headers[header];
                        }
                    });
                }
            }
        }
        return config;
    };
    return axiosRequestInterceptor;
};

let currentAxiosRequestInterceptor: any;

export const getCurrentAxiosRequestInterceptor = () => {
    if (!currentAxiosRequestInterceptor) {
        throw new Error('currentAxiosRequestInterceptor is undefined, call setupAuthAxiosIntercept first!');
    }
    return currentAxiosRequestInterceptor;
};

export const setupAuthAxiosIntercept = (input: string | ApiUrlBuilder) => {
    if (!currentAxiosRequestInterceptor) {
        currentAxiosRequestInterceptor =
            typeof input === 'string' ? buildAxiosRequestInterceptor(input) : buildAxiosRequestInterceptorWithConfig(input);
    }
    axiosInstance.interceptors.request.use(currentAxiosRequestInterceptor, (error) => Promise.reject(error));
};

export const resetCurrentAxiosRequestInterceptor = () => {
    currentAxiosRequestInterceptor = null;
};
