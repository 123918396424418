import { StateTree, DraftDealTemplate, DealTemplatesState, DealTemplate } from '@makemydeal/dr-dash-types';

export const getDealTemplate = (state: StateTree): DealTemplatesState | undefined => {
    return state.dealTemplates;
};

export const getDraftDealTemplate = (state: StateTree): DraftDealTemplate | undefined => {
    return getDealTemplate(state)?.draft;
};

export const getIsDealTemplateApplied = (state: StateTree): boolean => {
    return !!getDealTemplate(state)?.applied?.id;
};

export const getAppliedDealTemplateName = (state: StateTree): string | undefined => {
    return state.dealTemplates?.applied?.name;
};

export const getDealTemplateList = (state: StateTree): DealTemplate[] | undefined => {
    return getDealTemplate(state)?.list.value;
};

export const getIsDealTemplateListLoading = (state: StateTree): boolean | undefined => {
    return getDealTemplate(state)?.list.isLoading;
};

export const getIsDealTemplateRemoving = (state: StateTree): boolean | undefined => {
    return getDealTemplate(state)?.removing.isRemoving;
};

export const getIsDealTemplateRemoved = (state: StateTree): boolean | undefined => {
    return getDealTemplate(state)?.removing.isRemoved;
};

export const getIsDealTemplateRemovingError = (state: StateTree): boolean | undefined => {
    return getDealTemplate(state)?.removing.isError;
};

export const getDealTemplateRemovingName = (state: StateTree): string | undefined => {
    return getDealTemplate(state)?.removing.name;
};

export const getTemplateSlideOutStatus = (state: StateTree) => {
    return getDealTemplate(state)?.showTemplateSlideOut || false;
};
