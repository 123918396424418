// externals
import { FC, useMemo } from 'react';

// libraries
import { PriceInputInterstate, customInterstateValidationRules as rules } from '@makemydeal/dr-dash-components';

// interfaces/types
import {
    ColumnMapping,
    InputChangeInterstateEventHandler,
    ValidateInterstateValidationRulesHandler
} from '../manualAccessoriesTypes';

// consts/enums
import * as deskingConstants from '../../../../constants';

export type ManualAccessoryResidualFieldStateProps = {
    deleted: boolean;
    index: number;
    residual: number;
    residualColumnMapping: ColumnMapping<number>;
};

export type ManualAccessoryResidualFieldDispatchProps = {
    handleInputChange: InputChangeInterstateEventHandler;
    validateRules: ValidateInterstateValidationRulesHandler;
};

export type ManualAccessoryResidualFieldProps = ManualAccessoryResidualFieldDispatchProps & ManualAccessoryResidualFieldStateProps;

const getValidationRules = () => {
    const maxValue = rules.getMaxValueValidationRule(
        deskingConstants.ACCESSORIES_NUMERIC_MAX_VALUE,
        deskingConstants.ACCESSORIES_NUMERIC_MAX_VALUE_MESSAGE,
        true
    );

    return [maxValue];
};

export const ManualAccessoryResidualField: FC<ManualAccessoryResidualFieldProps> = ({
    deleted,
    index,
    residual = 0,
    residualColumnMapping: { fieldName },
    handleInputChange,
    validateRules
}) => {
    const validationRules = useMemo(getValidationRules, []);

    return (
        <PriceInputInterstate
            allowDecimal={true}
            displayLabel={true}
            data-testid={`${fieldName}-${index}`}
            label={deskingConstants.RESIDUAL}
            className="base-accessories-label"
            name={`${fieldName}-${index}`}
            onChange={(e) => {
                handleInputChange(e, fieldName);
            }}
            value={residual}
            disabled={deleted}
            getError={(_label, value) => {
                return validateRules(value, validationRules, fieldName, false);
            }}
        />
    );
};
