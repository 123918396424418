// libraries
import { paymentSelectors } from '@makemydeal/dr-offer-redux';

// types
import { PaymentFeesTypesState } from '../types';
import type { StateTree } from '@makemydeal/dr-dash-types';

export const getPaymentFeesTypesState = (state: StateTree): PaymentFeesTypesState => {
    return state.payment || {};
};

export const getPaymentFeesTypes = (state: StateTree) => {
    return getPaymentFeesTypesState(state).feesByCategory || [];
};

export const {
    isCalculatingPayment,
    getPaymentLocationId,
    /** If this returns true, it means a payment call failed to return calculations  */
    getIsError,
    getIsShopperUpdateFailed,
    getActionForPayment,
    getIsLenderChanged,
    getPaymentsCalculated
} = paymentSelectors;
