import { Skeleton, SkeletonProps } from '@interstate/components/Skeleton';

import { DeskingCardBlock } from './DeskingCard.style';
import {
    StyledSkeletonHeader,
    StyledSkeletonHeaderActionButtons,
    StyledSkeletonHeaderActions,
    StyledSkeletonLoader,
    StyledSkeletonVehicleActions,
    StyledSkeletonVehicleGroup,
    StyledSkeletonVehicleText,
    StyledSkeletonVehicleTextGroup
} from './DeskingCardSkeletonLoader.style';

type SkeletonVariant = SkeletonProps['variant'];

export const DeskingCardSkeletonLoader = () => {
    const buttonIsPrimarySkeleton: SkeletonVariant = { type: 'rounded', width: 74, height: 16 };
    const actionButtonSkeleton: SkeletonVariant = { type: 'circular', size: 23 };
    const vehicleImageSkeleton: SkeletonVariant = { type: 'rounded', width: 140, height: 73 };
    const cardBlockSkeleton: SkeletonVariant = { type: 'rounded', width: '100%', height: '100%' };
    const vehicleTextLinesSkeleton: SkeletonVariant[] = [
        { type: 'rounded', width: 240, height: 16 },
        { type: 'rounded', width: 90, height: 16 },
        { type: 'rounded', width: 97, height: 13 },
        { type: 'rounded', width: 178, height: 13 }
    ];
    const vehicleActionsSkeleton: SkeletonVariant[] = [
        { type: 'rounded', width: 140, height: 16 },
        { type: 'rounded', width: 70, height: 16 },
        { type: 'rounded', width: 70, height: 16 }
    ];
    const deskingCardGridRows = [
        'payment-details',
        'terms',
        'trade',
        'tax',
        'fees',
        'rebates',
        'vpp',
        'accessories',
        'comments',
        'actions'
    ];

    return (
        <StyledSkeletonLoader data-testid="desking-card-skeleton-loader">
            <StyledSkeletonHeader>
                <StyledSkeletonHeaderActions>
                    <Skeleton variant={buttonIsPrimarySkeleton} />

                    <StyledSkeletonHeaderActionButtons>
                        <Skeleton variant={actionButtonSkeleton} />
                        <Skeleton variant={actionButtonSkeleton} />
                    </StyledSkeletonHeaderActionButtons>
                </StyledSkeletonHeaderActions>

                <StyledSkeletonVehicleGroup>
                    <Skeleton variant={vehicleImageSkeleton} />

                    <StyledSkeletonVehicleText>
                        <StyledSkeletonVehicleTextGroup>
                            <Skeleton variant={vehicleTextLinesSkeleton[0]} />
                            <Skeleton variant={vehicleTextLinesSkeleton[1]} />
                        </StyledSkeletonVehicleTextGroup>

                        <StyledSkeletonVehicleTextGroup>
                            <Skeleton variant={vehicleTextLinesSkeleton[2]} />
                            <Skeleton variant={vehicleTextLinesSkeleton[3]} />
                        </StyledSkeletonVehicleTextGroup>
                    </StyledSkeletonVehicleText>
                </StyledSkeletonVehicleGroup>

                <StyledSkeletonVehicleActions>
                    <Skeleton variant={vehicleActionsSkeleton[0]} />
                    <Skeleton variant={vehicleActionsSkeleton[1]} />
                    <Skeleton variant={vehicleActionsSkeleton[2]} />
                </StyledSkeletonVehicleActions>
            </StyledSkeletonHeader>

            {deskingCardGridRows.map((rowName, index) => (
                <DeskingCardBlock rowName={rowName} rowNameEnd={deskingCardGridRows[index + 1]}>
                    <Skeleton variant={cardBlockSkeleton} />
                </DeskingCardBlock>
            ))}
        </StyledSkeletonLoader>
    );
};
