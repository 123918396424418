import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@interstate/components/Typography';
import { FlexCol, FlexRow } from '@makemydeal/dr-dash-components';
import {
    ManualIncentiveEditEntry,
    manualIncentivesActionCreators,
    manualIncentivesSelectors,
    offerReduxSelectors,
    programActionCreators,
    programsSelectors,
    transformToManualEditIncentive
} from '@makemydeal/dr-dash-store';

import { FieldRow } from './FieldRow';
import { DealCentralFieldRow } from './DealCentralFieldRow';
import { ManualRebatesFormContainer, ManualRebatesFormContainerForDealCentral, StyledHr } from './RebatesEdit.style';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { RebatesTotals } from '@makemydeal/dr-activities-desking';
import { manualIncentiveUtils } from '@makemydeal/dr-platform-shared';

export const createNewManualIncentiveEntry = (): ManualIncentiveEditEntry => ({
    program: '',
    code: undefined,
    manual: true,
    name: '',
    description: '',
    type: 'CustomerCash',
    amount: 0,
    deleted: false,
    originalAmount: 0,
    isStandardIncentive: false
});

export const RebatesEdit = () => {
    const dispatch = useDispatch();
    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives, isEqual);
    const incentivesToEdit = useSelector(manualIncentivesSelectors.getManualIncentiveEditList);
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    const quote = useSelector(programsSelectors.getProgramFromCurrentOffer);

    const appliedIncentiveTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const [currentEditedIncentivesTotal, setCurrentEditedIncentivesTotal] = useState(0);
    const [currentAppliedIncentiveTotal, setCurrentAppliedIncentiveTotal] = useState(0);
    const [dealerCashTotal, setDealerCashTotal] = useState(0);

    useEffect(() => {
        if (isDealCentralExperience) {
            dispatch(programActionCreators.selectProgramQuote(quote));
        }
    }, []);

    // Reset the error count to 0 on component load.
    useEffect(() => {
        dispatch(manualIncentivesActionCreators.updateShowValidation(false));
        const init = incentives.map(transformToManualEditIncentive);
        if (init.length === 0) init.push(createNewManualIncentiveEntry());
        dispatch(manualIncentivesActionCreators.initManualIncentivesEdit(init));
        return () => {
            dispatch(manualIncentivesActionCreators.resetManualIncentivesEdit());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDealerCashIncentives = (incentivesToEdit: ManualIncentiveEditEntry[]) =>
        incentivesToEdit.filter(manualIncentiveUtils.isDealerCash as any);
    const getCurrentEditedIncentives = (incentivesToEdit: ManualIncentiveEditEntry[]) =>
        incentivesToEdit.filter(manualIncentiveUtils.isNotDealerCash as any);

    useEffect(() => {
        if (isDealCentralExperience) {
            const dealerCashIncentives = getDealerCashIncentives(incentivesToEdit);
            const currentEditedIncentives = getCurrentEditedIncentives(incentivesToEdit);

            setCurrentEditedIncentivesTotal(manualIncentiveUtils.getIncentiveSum(currentEditedIncentives as any));
            setDealerCashTotal(manualIncentiveUtils.getIncentiveSum(dealerCashIncentives as any));
            setCurrentAppliedIncentiveTotal(appliedIncentiveTotal);
        }
    }, [incentivesToEdit]);

    const handleAddIncentives = () => {
        dispatch(manualIncentivesActionCreators.updateShowValidation(false));
        dispatch(manualIncentivesActionCreators.createManualIncentive(createNewManualIncentiveEntry()));
    };

    return isDealCentralExperience ? (
        <FlexCol>
            <ManualRebatesFormContainerForDealCentral id="manual-rebates-form-container">
                {incentivesToEdit.map((_incentive, index) => (
                    <DealCentralFieldRow key={`field-row-${index}`} index={index} />
                ))}
            </ManualRebatesFormContainerForDealCentral>
            <StyledHr height={'1px'} color={'#B2B2B2'} margin={'1rem 0px 1rem 0px'} />
            <FlexRow alignItems="center" justifyContent="flex-start">
                <Typography variant="body-md">
                    <a
                        id="btn-manual-rebates-add-rebate"
                        onClick={handleAddIncentives}
                        style={{ color: '#005BA8', fontWeight: '600' }}
                    >
                        + Add Incentive
                    </a>
                </Typography>
            </FlexRow>
            <StyledHr height={'2px'} color={'#B2B2B2'} margin={'1rem 0px 0px 0px'} />
            <RebatesTotals
                dealerCashTotal={dealerCashTotal}
                currentAppliedIncentiveTotal={currentAppliedIncentiveTotal}
                currentEditedIncentivesTotal={currentEditedIncentivesTotal}
                showEditTotals
                showRebatesTotal
                hideInfoIcon={true}
            />
        </FlexCol>
    ) : (
        <FlexCol gap="1rem">
            <FlexCol gap=".25rem">
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    Edit Incentives
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '.8rem', color: 'gray' }}>
                    Program number and code fields are optional.
                </Typography>
            </FlexCol>
            <ManualRebatesFormContainer id="manual-rebates-form-container">
                {incentivesToEdit.map((_incentive, index) => (
                    <FieldRow key={`field-row-${index}`} index={index} />
                ))}
            </ManualRebatesFormContainer>

            <FlexRow alignItems="center" justifyContent="end">
                <Typography variant="body-md">
                    <a id="btn-manual-rebates-add-rebate" onClick={handleAddIncentives}>
                        + Add Incentive
                    </a>
                </Typography>
            </FlexRow>
        </FlexCol>
    );
};
