import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled, { css } from 'styled-components';

export const ManualRebatesFormContainer = styled.div<{ isBaseDealScreenExperience?: boolean }>`
    .text-input-program {
        grid-area: program;

        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    .text-input-name {
        grid-area: name;

        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    .text-input-code {
        grid-area: code;

        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    .text-input-amount {
        grid-area: amount;
        min-width: 75px;
        @media (min-width: ${BreakPoint.SMALL}) {
            grid-area: unset;
        }
    }

    hr {
        bg-color: #cacfd9;
        width: 100%;
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        .text-input-amount input {
            text-align: right;
        }
    }

    /* override styles when on base deal screen */
    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            > div:last-child {
                padding-bottom: 0 !important;
            }
        `}
`;

export const FlexManualRebatesButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;

export const ManualRebatesTypeDisplayContainer = styled.div`
    .type-display {
        color: #6d727a;
        &:after {
            color: #dd2b2b;
            content: '*';
            display: inline-block;
            margin-left: 0.25rem;
        }
    }

    .type-value {
        color: ${({ theme }) => theme.tokens.BaseColorBlack};
        font-size: ${({ theme }) => theme.tokens.ComponentTextInputFontSize};
        margin-top: 4px;
    }
`;

export const TypeInputOrDisplayContainer = styled.div`
    grid-area: type;

    @media (min-width: ${BreakPoint.SMALL}) {
        grid-area: unset;
    }
`;

export const EditableRowsPlusAddButtonContainer = styled.div<{ isBaseDealScreenExperience?: boolean }>`
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #cacfd9;
    /* override styles when on base deal screen */
    ${(props) =>
        props.isBaseDealScreenExperience &&
        css`
            border-width: 0 0 2px 0;
            border-color: #b2b2b2;
        `}
`;

export const AddIncentiveContainer = styled.div`
    margin: 16px 0 8px 0;
`;
