import React, { FC } from 'react';
import { DeleteField } from './DeleteField';
import { LabelRow } from './LabelRow';
import { TextField } from './TextField';
import { TypeField } from './TypeField';
import { DealCentralcolumnMappings } from './columnMappings';
import { AmountField } from './AmountField';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { useSelector } from 'react-redux';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { ManualIncentiveEntry } from '@makemydeal/dr-platform-types';

const renderField = (fieldName: string, index: number, isDealCentralExperience: boolean, isSmallScreen: boolean) => {
    switch (fieldName) {
        case 'amount':
            return <AmountField index={index} />;
        case 'type':
            return <TypeField index={index} field={fieldName} isDealCentralExperience={isDealCentralExperience && isSmallScreen} />;
        case 'action':
            return <DeleteField index={index} buttonStyle="tertiary" />;
        default:
            return <TextField index={index} field={fieldName as keyof ManualIncentiveEntry} />;
    }
};

export const DealCentralFieldRow: FC<{
    index: number;
}> = ({ index }) => {
    const isSmallScreen = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    return (
        <>
            {!isSmallScreen && <LabelRow columns={DealCentralcolumnMappings} index={index} />}

            {DealCentralcolumnMappings.map(({ fieldName }, columnIndex) => (
                <React.Fragment key={`field-${columnIndex}`}>
                    {renderField(fieldName, index, isDealCentralExperience, isSmallScreen)}
                </React.Fragment>
            ))}
        </>
    );
};
