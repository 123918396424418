import type {
    AdjustItems,
    AdjustedValuesStatus,
    AnyAdjustValues,
    RollToActionValues,
    RollToActions,
    RollToBasicObject,
    RollToOptionsState,
    StateTree,
    TargetStatus
} from '@makemydeal/dr-dash-types';
import { getAccessoriesTotalAmount } from '../selectors/accessoriesSelectors';
import { getVppSummary } from './vehicleProtectionSelectors';

import { RollToOption, RollToOptions, RollToOptionsOverrides } from '@makemydeal/dr-platform-types';
import { createSelector } from 'reselect';
import { ROLL_TO_OPTIONS_ROOT } from '../constants';
import { getBuyRateOverrideByOfferTypeAndTerm, getCurrentOfferType, getFirstMonthPayment } from './offerRedux';

export const getRollToOptions = (state: StateTree): RollToOptionsState => {
    return state[ROLL_TO_OPTIONS_ROOT] || ({} as RollToOptionsState);
};

export const getRollTargetPayment = (state: StateTree): number => {
    const rollToOptions = getRollToOptions(state);
    return rollToOptions.targetPayment || 0;
};

export const getRollToItems = (state: StateTree): RollToActions | null | undefined => {
    const rollToOptions = getRollToOptions(state);
    return rollToOptions.rollToItems;
};

export const getBalanceDue = (state: StateTree): number => state.rollToOptions?.balanceDue || 0;

export const getRollToPaymentSlideOutExpanded = (state: StateTree): boolean => {
    const rollToOptions = getRollToOptions(state);
    return rollToOptions.rollToSlideOutExpanded || false;
};
export const getVppAndProtectionTotal = (state: StateTree): number => {
    const protectionTotal = getVppSummary(state).totalProductPrice || 0;
    // TODO: When accessories is implemented, make sure the total includes only capitalized accessories for lease
    const accessoriesTotal = getAccessoriesTotalAmount(state);
    return protectionTotal + accessoriesTotal;
};
export const getRollToUnsavedChanges = (state: StateTree): boolean => {
    const rollToOptions = getRollToOptions(state);
    return rollToOptions.hasUnsavedChanges || false;
};

export const hasRollToOptionsItems = (state: StateTree): boolean => {
    return Boolean(getRollToItems(state));
};

export const getRollToOptionsItems = (state: StateTree): RollToActions => {
    return getRollToItems(state) || {};
};

export const getAdjustedValues = (state: StateTree): RollToActionValues => {
    const rollToOptionsItems = getRollToOptionsItems(state);
    return rollToOptionsItems.adjust || {};
};

export const getCurrentValues = (state: StateTree): RollToActionValues => {
    const rollToOptionsItems = getRollToOptionsItems(state);
    return rollToOptionsItems.current || {};
};

export const isCalculating = (state: StateTree): boolean => {
    const rollToOptions = getRollToOptions(state);
    return Boolean(rollToOptions.isCalculating);
};

export const getRollToErrors = (state: StateTree): RollToBasicObject[] => {
    const rollToOptions = getRollToOptions(state);
    return rollToOptions.errors || [];
};

export const hasRollToErrors = (state: StateTree): boolean => {
    const errors = getRollToErrors(state);
    return Boolean(errors.length);
};

export const getTargetStatus = (state: StateTree): TargetStatus | undefined => {
    const rollToOptions = getRollToOptions(state);
    return rollToOptions.targetStatus;
};

export const getAdjustedStatus = createSelector(
    getAdjustedValues,
    getCurrentValues,
    (currentValues, adjustedValues): AdjustedValuesStatus => {
        const status: AdjustedValuesStatus = {};

        status.term = currentValues.term !== adjustedValues.term;
        status.rate = currentValues.rate !== adjustedValues.rate;
        status.trade = currentValues.trade !== adjustedValues.trade;
        status.downPayment = currentValues.downPayment !== adjustedValues.downPayment;
        status.sellingPrice = currentValues.sellingPrice !== adjustedValues.sellingPrice;

        return status;
    }
);

export const getAdjustedBuyRateOption = (state: StateTree, sellRate: RollToOption, term?: number): RollToOption => {
    const offerType = getCurrentOfferType(state);
    const buyRate = getBuyRateOverrideByOfferTypeAndTerm(state, offerType, term, true);
    const sellRateValue = sellRate?.value ?? 0;

    const buyRateOption: RollToOption = { adjusted: false, value: buyRate };

    if (!buyRate || buyRate > sellRateValue) {
        if (buyRate != null) {
            buyRateOption.value = buyRate > sellRateValue ? sellRateValue : buyRate;
        } else {
            buyRateOption.value = undefined;
        }
        buyRateOption.adjusted = Boolean(sellRate?.adjusted);
    }
    return buyRateOption;
};

export const hasAdjustedValues = createSelector(getCurrentValues, getAdjustedValues, (current, adjust): boolean => {
    const found = Object.keys(current).find((key: string) => {
        const item = key as AdjustItems;
        return current[item] !== adjust[item];
    });

    return Boolean(found);
});

export const adjustOptions = (adjustedOptions: RollToOptions, adjusted: AnyAdjustValues): RollToOptions => {
    Object.keys(adjustedOptions)
        .filter((key: string) => {
            const item = key as AdjustItems;
            return adjusted[item] != null && adjusted[item] !== adjustedOptions[item].value;
        })
        .forEach((key: string) => {
            const item = key as AdjustItems;
            adjustedOptions[item] = {
                adjusted: adjustedOptions[item].value !== adjusted[item],
                value: adjusted[item]
            };
        });

    return adjustedOptions;
};

export const getAdjustedOptions = createSelector(getAdjustedStatus, getAdjustedValues, (status, values): RollToOptions => {
    const options = {
        term: { adjusted: status.term, value: values.term },
        rate: { adjusted: status.rate, value: values.rate },
        trade: { adjusted: status.trade, value: values.trade },
        downPayment: { adjusted: status.downPayment, value: values.downPayment },
        sellingPrice: { adjusted: status.sellingPrice, value: values.sellingPrice }
    };
    return options;
});

export const getAdjustedStatusOptions = (state: StateTree, adjusted?: AnyAdjustValues): RollToOptions => {
    const adjustedOptions = getAdjustedOptions(state);

    if (adjusted != null) {
        adjustOptions(adjustedOptions, adjusted);
    }

    adjustedOptions.buyRate = getAdjustedBuyRateOption(state, adjustedOptions.rate, adjustedOptions.term?.value);

    return adjustedOptions;
};

export const getApplyRollToPayload = createSelector(
    hasAdjustedValues,
    getFirstMonthPayment,
    getAdjustedStatusOptions,
    (hasAdjustments, monthPayment, options): RollToOptionsOverrides => {
        return {
            target: { name: 'monthlyPayment', value: monthPayment },
            options,
            hasAdjustments
        };
    }
);
