import styled, { css } from 'styled-components';

export const CenteredLoadingIndicator = styled.div.attrs<{ $hideOverlay?: boolean; 'data-testid'?: string }>((props) => ({
    ['data-testid']: props['data-testid']
}))`
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${(props) =>
        !props.$hideOverlay &&
        css`
            & #interstitial-overlay-root {
                background-color: transparent;
            }

            & #interstitial-content-root {
                box-shadow: none;
            }
        `}
`;
