// externals
import type { Reducer } from 'redux';

// libraries
import { BootstrapState } from '@makemydeal/dr-dash-types';

// interfaces/types
import { AllInitAppActions } from '../actions/bootstrapAppActionCreators';
import { applicationLoadErrorActionTypes, bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import {
    DASH_MENU_LIFECYCLE_INIT_FINISH,
    DASH_MENU_LIFECYCLE_INIT_START,
    DASH_MENU_SKIPPED
} from '../actionTypes/vehicleProtectionActionTypes';
import { SimpleFSA } from '@makemydeal/dr-platform-shared';

export type BootstrapReducer = Reducer<BootstrapState>;

export const BOOTSTRAP_INITIAL_STATE: BootstrapState = {
    lazyInitCompleted: false
};

type BootstrapReducerActions = AllInitAppActions | SimpleFSA<typeof DASH_MENU_SKIPPED>;

export const reducer: Reducer<BootstrapState> = (
    state: BootstrapState = BOOTSTRAP_INITIAL_STATE,
    action: BootstrapReducerActions
) => {
    switch (action.type) {
        case applicationLoadErrorActionTypes.APPLICATION_LOAD_ERROR: {
            return {
                ...state,
                lazyInitCompleted: false
            };
        }
        case bootstrapActionTypes.BOOTSTRAP_REQUEST: {
            return { ...state, lazyInitCompleted: false };
        }
        case bootstrapActionTypes.BOOTSTRAP_FAILURE: {
            return {
                ...state,
                lazyInitCompleted: false
            };
        }
        case DASH_MENU_LIFECYCLE_INIT_START: {
            return {
                ...state,
                lazyInitCompleted: false
            };
        }
        case DASH_MENU_SKIPPED:
        case DASH_MENU_LIFECYCLE_INIT_FINISH: {
            return {
                ...state,
                lazyInitCompleted: true
            };
        }
        default: {
            return state;
        }
    }
};
