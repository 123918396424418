import { FC, PropsWithChildren, useState } from 'react';
import { DEFAULT_EXPANDABLE_ID, DEFAULT_EXPANDABLE_STATUS, EXPANDABLE_ROOT } from '../../constants';
import { ExpandableAreaContext } from './Context';

/**
 * Establish a collapsible area provider track collapsible state remotely. This should exits at the closest DOM level possible to the CollapsibleArea and CollapsibleExpandableAreaSection. If you are using multiple collapsibles in the same area, use a single provider and specify differentiating collapsibleId's to tie them together.
 */
export const ExpandableAreaProvider: FC<PropsWithChildren> = ({ children }) => {
    const [toggleByKey, setToggleByKey] = useState<Record<string, boolean>>({ [DEFAULT_EXPANDABLE_ID]: DEFAULT_EXPANDABLE_STATUS });
    const [isExpandAll, setIsExpandAll] = useState(false);

    const hasId = (expandableId?: string): boolean => {
        return Boolean(Object.keys(toggleByKey).find((id) => id === expandableId));
    };

    const isExpanded = (expandableId: string = DEFAULT_EXPANDABLE_ID): boolean => {
        return Boolean(toggleByKey[expandableId]);
    };

    const isAllExpanded = (): boolean => {
        return isExpandAll;
    };

    const doToggle = (expandableId: string = DEFAULT_EXPANDABLE_ID, value?: boolean): void => {
        setToggleByKey((old) => {
            const updated = { ...old };

            if (value != null) {
                updated[expandableId] = value;
            } else {
                updated[expandableId] = !updated[expandableId]; // flip logic; also assumes value might be undefined.
            }

            return updated;
        });
    };

    const doToggleAll = (): void => {
        setToggleByKey((old) => {
            const updated = { ...old };
            for (const key in updated) {
                if (key !== 'main' && key !== EXPANDABLE_ROOT) updated[key] = !isExpandAll;
            }
            return updated;
        });

        setIsExpandAll(!isExpandAll);
    };

    return (
        <ExpandableAreaContext.Provider
            value={{
                hasId,
                doToggle,
                isExpanded,
                doToggleAll,
                isAllExpanded
            }}
        >
            {children}
        </ExpandableAreaContext.Provider>
    );
};
