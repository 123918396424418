import React, { FC } from 'react';
import { Alert } from '@interstate/components/Alert';

type TemplateExistWarningToastProps = {
    templateName: string;
};
export const TemplateExistAlert: FC<TemplateExistWarningToastProps> = ({ templateName }) => (
    <Alert type="caution" data-testid="template-exist-warning-toast" title="Template Already Exists">
        {`You have replaced an existing template with the same details, titled '${templateName}'.`}
    </Alert>
);
