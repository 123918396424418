// interfaces
import type { InitSuccessPayload } from '@makemydeal/dr-dash-bff-types';

// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { FSAWithMeta } from '@makemydeal/dr-platform-shared';
import { FSA, SimpleFSA } from '@makemydeal/dr-platform-shared';
import type { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';
import {
    apiConfigHelper,
    BffApiUrlResult,
    locHrefUtil,
    offerSessionUtils,
    getDocumentReferrer
} from '@makemydeal/dr-shared-ui-utils';
import { StateTree } from '@makemydeal/dr-dash-types';
import { BootstrapResponseMeta } from '@makemydeal/dr-shared-types';

// consts/enums
import { APPLICATION_LOAD_ERROR } from '../actionTypes/applicationLoadErrorActionTypes';
import {
    BOOTSTRAP_REQUEST,
    BOOTSTRAP_SUCCESS,
    BOOTSTRAP_FAILURE,
    BOOTSTRAP_INITIAL_LOAD
} from '../actionTypes/bootstrapActionTypes';

// selectors
import { getSelectedOfferDealXgId, getSelectedOfferDealXgVersion } from '../selectors/selectedOfferSelectors';

// consts/enums
import { INIT_PENCIL_SUCCESS } from '../actionTypes/initActionTypes';

/**
 * List of actions that are applicable for use in the reducer- add interfaces to the
 * AllFeatureToggleActions type by using the `|` character to separate them.
 * See `AllSharedInitAppActions` for a more complete example.
 */
export type AllFeatureToggleActions = BootstrapAppInitSuccessAction;

export type AllSharedInitAppActions =
    | BootstrapInitialLoadAction
    | BootstrapAppAction
    | BootstrapSuccessAction
    | BootstrapFailureAction
    | BootstrapAppLoadErrorAction
    | BootstrapAppInitSuccessAction;

export type BootstrapAppLoadErrorPayload = {
    message: string;
};

export type BootstrapAppInitSuccessAction = FSA<typeof INIT_PENCIL_SUCCESS, InitSuccessPayload>;

export type BootstrapAppLoadErrorAction = FSA<typeof APPLICATION_LOAD_ERROR, BootstrapAppLoadErrorPayload>;

export type BootstrapAppAction = SimpleFSA<typeof BOOTSTRAP_REQUEST>;

export type BootstrapSuccessAction = FSA<typeof BOOTSTRAP_SUCCESS, InitSuccessPayload>;

export type BootstrapFailureAction = FSAWithMeta<typeof BOOTSTRAP_FAILURE, unknown, BootstrapResponseMeta>;

export type BootstrapInitialLoadAction = SimpleFSA<typeof BOOTSTRAP_INITIAL_LOAD>;

export const initialLoad = (): BootstrapInitialLoadAction => ({ type: BOOTSTRAP_INITIAL_LOAD });

export const fetchBootstrapData = (appPrefix: string, options?: any) => {
    const { withCurrentDealVersion, dealXgVersion, loadDraftScenario = false, updatePrevOffer = undefined } = options || {};
    return (dispatch: any, getState: () => StateTree) => {
        const state = getState();
        dispatch(
            fetchBootstrapDataWorker(appPrefix, state, withCurrentDealVersion, dealXgVersion, loadDraftScenario, updatePrevOffer)
        );
    };
};

export const fetchBootstrapDataWorker = (
    appPrefix: string,
    state: StateTree,
    withCurrentDealVersion?: boolean,
    dealExchangeVersion?: string,
    loadDraftScenario?: boolean,
    updatePrevOffer?: boolean
) => {
    // get the endppoint from the domain called
    let endpoint: string;
    let bffApiUrlResult: BffApiUrlResult;

    const locHref = locHrefUtil.getLocHref();

    const dealXgId = getSelectedOfferDealXgId(state);
    const dealXgVersion = dealExchangeVersion || getSelectedOfferDealXgVersion(state);

    let url: URL;
    if (dealXgId) {
        // if we get dealXgID, then use it to load the offer.
        // if version is specified, provide it, otherwise we'll get the latest
        bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, 'initDealXg');
        const base = `${bffApiUrlResult.url}/${dealXgId}`;

        url = new URL(base);
        if (dealXgVersion) {
            url = new URL(`${base}/${dealXgVersion}`);
        }

        if (dealXgVersion && withCurrentDealVersion) {
            url.searchParams.set('withCurrentDealVersion', 'true');
        }

        if (loadDraftScenario) {
            url.searchParams.set('loadDraftScenario', 'true');
        }
    } else {
        // if we fall here it means we do not have the right values
        return {
            type: APPLICATION_LOAD_ERROR,
            payload: {
                message: '"dealXgId" is not available.'
            }
        };
    }

    if (bffApiUrlResult.environmentName !== bffApiUrlResult.defaultEnvironmentName) {
        /* eslint-disable-next-line no-console */
        console.log(
            'OVERRIDE APPLIED: ' +
                `Environment ${bffApiUrlResult.defaultEnvironmentName} --> ${bffApiUrlResult.overriddenEnvironmentName}`
        );
    }

    const environmentType = offerSessionUtils.identifyEnvironment(bffApiUrlResult.environmentName);
    offerSessionUtils.setEnvironmentType(environmentType);

    const referrer = getDocumentReferrer();

    const request: RSAACallRequest = {
        endpoint: url.toString(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-dr-referrer': referrer },
        types: [
            BOOTSTRAP_REQUEST,
            {
                type: BOOTSTRAP_SUCCESS,
                meta: { updatePrevOffer }
            },
            {
                type: 'BOOTSTRAP_FAILURE',
                meta:
                    // this is RSAA standard code; we'll assert this coverage behavior in the reducer.
                    // istanbul ignore next
                    (_action: any, _state: any, res: any) => {
                        if (res) {
                            return {
                                status: res.status,
                                statusText: res.statusText
                            };
                        } else {
                            return {
                                statusText: 'Network request failed'
                            };
                        }
                    }
            }
        ]
    };

    return {
        [RSAA]: request
    } as RSAACallAction;
};
