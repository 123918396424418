// libraries
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { paymentMiddleware, paymentTypes } from '@makemydeal/dr-offer-redux';
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { bootstrapActionTypes, featureToggleSelectors, initActionTypes } from '@makemydeal/dr-shared-store';

// interfaces/types
import type { DashMiddleware, DashNext, DashStore } from '@makemydeal/dr-shared-store';

// config
import { getServicesBff } from '../selectors/config';

// utils
import { HostSelectors } from '@makemydeal/dr-offer-redux/dist/paymentTypes';
import { ApplicationCodes } from '@makemydeal/dr-platform-types';
import { PAYMENT_RECEIVED } from '../actionTypes/deskingActionTypes';
import { UPDATED_CREDIT_DECISION_ENABLED } from '../actionTypes/offerActionTypes';
import { applyProgramEndDays } from '../actions/offerActionCreators';
import { applyDealerOverrides } from '../actions/offerRedux';
import { getPaymentFeesTypes } from '../actions/paymentFeesTypesActionCreators';
import { offerReduxSelectors } from '../selectors';
import { buildPaymentPayloadHostSelectors } from '../utils/offerPayloadUtil';

// keep a cached version of the middleware once we're able to build it.
export let cachedPaymentReduxMiddleware: ((store: DashStore) => (next: DashNext) => (action: AnyFSA) => void) | undefined;

export const paymentMiddlewareClearCache = () => (cachedPaymentReduxMiddleware = undefined);

export const getMiddleware =
    (selectors: HostSelectors = {}): DashMiddleware =>
    (store: DashStore) =>
    (next: DashNext) =>
    (action: AnyFSA) => {
        // this will only configure when all the values we need are here from the configuration.  It will not reconfigure
        // if that configuration changes (but thats not a valid use case)
        switch (action.type) {
            case initActionTypes.INIT_SUCCESS: {
                store.dispatch(applyProgramEndDays());
                store.dispatch(getPaymentFeesTypes());
                break;
            }
            case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
                // load up the middleware
                // get what we can from state.  This typically will be the bff endpoint only since its placed as part of initial state
                const state = store.getState();
                const bff = getServicesBff(state);

                const hostSelectors = buildPaymentPayloadHostSelectors({
                    ...selectors,
                    ...{
                        isBaseDealScreenExperience: featureToggleSelectors.useDealScreenExperience
                    }
                });

                // now we will not have the toggles, static images or the gateway in state
                // so we will have to replace them
                const paymentConfig: paymentTypes.IPaymentConfig = {
                    paymentUrl: urlBuilder.buildFromConfig(bff, 'payment-orchestrator-with-dealer-id'),
                    selectors: hostSelectors,
                    sourceApplication: ApplicationCodes.MV
                };

                cachedPaymentReduxMiddleware = paymentMiddleware(paymentConfig);
                break;
            }
        }

        // once we have the cached version, pass the call to it
        if (cachedPaymentReduxMiddleware) {
            cachedPaymentReduxMiddleware(store)(next)(action);
            switch (action.type) {
                case PAYMENT_RECEIVED: {
                    const state = store.getState();
                    const isUserProgramQuotes = offerReduxSelectors.getIsUserProgramQuotes(state);

                    const currentOfferType = offerReduxSelectors.getCurrentOfferType(state);
                    // we want to avoid double payment call when using PQ with payment grid
                    const isPaymentGridEnabled = offerReduxSelectors.getPaymentGridToggle(state, currentOfferType);

                    // if we are using UPQ, we can avoid this double payment call
                    if (isUserProgramQuotes || isPaymentGridEnabled) {
                        break;
                    } else {
                        if (
                            (action.meta.originalAction.type === UPDATED_CREDIT_DECISION_ENABLED &&
                                action.meta.originalAction.meta.excludeOverrides) ||
                            action.meta.originalAction.meta.missingRequiredPSFields
                        ) {
                            store.dispatch(applyDealerOverrides());
                        }
                    }
                }
            }
        } else {
            // since the paymentRedux middleware calls next, we do not want it called twice
            // this will end up causing a middleware to be skipped
            next(action);
        }
    };
