// externals
import { FC, PropsWithChildren, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Action } from '@interstate/components/Action';
import { CardHeader } from '@interstate/components/Card';
import { Typography } from '@interstate/components/Typography';
import { Grid } from '@interstate/components/Grid';
import { LoadingSectionParent, PaymentLoading } from '@makemydeal/dr-dash-components';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import { accessoriesActionCreators, accessoriesSelectors } from '@makemydeal/dr-dash-store';

// consts/enums
import { ACCESSORIES, ADD_ACCESSORIES } from '../../constants';

// components/hooks
import { AccessoriesForm } from './manualAccessories/interstate/AccessoriesForm.interstate';
import InterstateAccessoriesTable from './manualAccessories/AccessoriesTable.interstate';
import { OptionalActionLinkContainer } from './ManualAccessories.interstate.styles';
import { useScrollIntoView } from '../../utils/useScrollIntoView';
import { StretchHeightCard } from '../deskingActivity/DeskingCard.style';

const AccessoriesCard: FC<PropsWithChildren> = (props) => {
    const elementRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const isAccessoriesEnabled = useSelector(featureToggleSelectors.isAccessoriesEnabled);
    const isDealerAccessoriesCatalogEnabled = useSelector(dealerSelectors.isAccessoriesCatalogEnabled);
    const isFormOpen = useSelector(accessoriesSelectors.getFormStatus);
    const accessoriesList = useSelector(accessoriesSelectors.getAccessoriesList);
    const uniqueScrollRequestId = useSelector(accessoriesSelectors.getUniqueScrollRequestId);
    const hasCatalogAccessories = useSelector(accessoriesSelectors.getHasCatalogAccessories);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const useAccessoriesCatalogActivity = isDealerAccessoriesCatalogEnabled;

    const hasAccessories = accessoriesList.length > 0;
    const title = !isFormOpen || isBaseDealScreenExperience ? ACCESSORIES : ADD_ACCESSORIES;
    const getManualAccessoriesActionLabel = () => {
        if (isFormOpen) {
            return '';
        }

        return hasAccessories ? 'Edit Manually' : 'Add Manually';
    };

    useScrollIntoView(elementRef, uniqueScrollRequestId);

    useEffect(() => {
        if (isBaseDealScreenExperience && isAccessoriesEnabled) {
            dispatch(accessoriesActionCreators.openForm());
        }
    }, [dispatch, isBaseDealScreenExperience, isAccessoriesEnabled]);

    return isAccessoriesEnabled ? (
        <LoadingSectionParent>
            <PaymentLoading testId="manual-accessory-loading-indicator" />
            <StretchHeightCard
                isBaseDealScreenExperience={isBaseDealScreenExperience}
                data-testid="accessories-card"
                header={
                    <div ref={elementRef}>
                        <Grid container justifyContent={'space-between'}>
                            <CardHeader
                                className="base-tab-title"
                                title={isBaseDealScreenExperience ? <Typography variant="h3">{title}</Typography> : title}
                            />
                            {!isBaseDealScreenExperience && (
                                <div>
                                    {useAccessoriesCatalogActivity && (
                                        <OptionalActionLinkContainer>
                                            <Action
                                                data-testid="accessories-card-catalog-link"
                                                disabled={!hasCatalogAccessories}
                                                onClick={() =>
                                                    dispatch(accessoriesActionCreators.redirectToAccessoriesCatalogPage())
                                                }
                                            >
                                                <Typography
                                                    variant="anchor-block-sm"
                                                    tag={hasCatalogAccessories ? 'a' : 'div'}
                                                    color={hasCatalogAccessories ? 'base.color.blue.700' : 'base.color.gray.700'}
                                                >
                                                    Add From Catalog
                                                </Typography>
                                            </Action>
                                        </OptionalActionLinkContainer>
                                    )}
                                    <Action
                                        data-testid="accessories-card-enabled-accessory-link"
                                        onClick={() => dispatch(accessoriesActionCreators.openForm())}
                                    >
                                        <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                            {getManualAccessoriesActionLabel()}
                                        </Typography>
                                    </Action>
                                </div>
                            )}
                        </Grid>
                    </div>
                }
                content={
                    <>
                        {isFormOpen && <AccessoriesForm />}
                        {!isFormOpen && hasAccessories && <InterstateAccessoriesTable />}
                    </>
                }
            />
        </LoadingSectionParent>
    ) : null;
};

export default AccessoriesCard;
