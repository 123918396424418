// externals
import { useSelector } from 'react-redux';

// libraries
import { Typography } from '@interstate/components/Typography';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import { CompareColumnDetailProps } from '../types/compareColumnDetailProps';

// constants
import * as constants from '../../constants';

// components
import Addons from '../../dealSummary/sections/Addons.interstate';
import Discount from '../../dealSummary/sections/Discount.interstate';
import { EmptyField } from '../../dealSummary/sections/EmptyField';
import NetSellingPrice from '../../dealSummary/sections/NetSellingPrice.interstate';
import NetTrade from '../../dealSummary/sections/NetTrade.interstate';
import MSRP from '../../dealSummary/sections/MSRP.interstate';
import Rebates from '../../dealSummary/sections/Rebates.interstate';
import CashDown from '../../dealSummary/sections/CashDown.interstate';
import SellingPrice from '../../dealSummary/sections/SellingPrice.interstate';
import TotalAmountDue from '../../dealSummary/sections/TotalAmountDue.interstate';
import TotalTax from '../../dealSummary/sections/TotalTax.interstate';
import { StyledListBordered, StyledListNotBordered, ColumnDetailsBlock, OfferCompareCardBlock } from './CompareColumnDetail.style';

export const CompareCashColumnDetail = ({ deal, hasDifference, deltaList }: CompareColumnDetailProps) => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const StyledList = isABPencilEnabled ? StyledListBordered : StyledListNotBordered;

    return (
        <ColumnDetailsBlock>
            <OfferCompareCardBlock rowName="pricing">
                <Typography variant="h4">{constants.PRICE}</Typography>
                <StyledList>
                    <MSRP deal={deal} />
                    <Discount deal={deal} hasDelta={deltaList.isDiscountDelta} />
                    <SellingPrice deal={deal} hasDelta={deltaList.isSellingPriceDelta} />
                </StyledList>
            </OfferCompareCardBlock>

            <OfferCompareCardBlock rowName="addons">
                <Typography variant="h4">{constants.ADD_ONS}</Typography>
                <StyledList>
                    <Addons deal={deal} hasDelta={deltaList.isAddOnsDelta} />
                    <TotalTax deal={deal} hasDelta={deltaList.isTotalTaxDelta} />
                    <NetSellingPrice deal={deal} hasDelta={deltaList.isNetSellingPriceDelta} />
                </StyledList>
            </OfferCompareCardBlock>

            <OfferCompareCardBlock rowName="finance-reductions">
                <Typography variant="h4">{constants.REDUCTIONS}</Typography>
                <StyledList>
                    <NetTrade deal={deal} hasDelta={deltaList.isNetTradeDelta} />
                    <Rebates deal={deal} hasDelta={deltaList.isRebatesDelta} />
                    <CashDown deal={deal} hasDelta={deltaList.isCashDownDelta} />
                </StyledList>
            </OfferCompareCardBlock>

            <OfferCompareCardBlock rowName="financing-terms">
                <Typography variant="h4">{constants.CASH_TERMS}</Typography>
                <StyledList>
                    {hasDifference && (
                        <>
                            <EmptyField />
                            <EmptyField />
                            <EmptyField />
                        </>
                    )}
                    <TotalAmountDue deal={deal} hasDelta={deltaList.isTotalDueDelta} />
                </StyledList>
            </OfferCompareCardBlock>
        </ColumnDetailsBlock>
    );
};
