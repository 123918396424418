import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';
import { SlideOut } from '@interstate/components/SlideOut';
import { DealTemplateList } from '../../dealTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { configSelectors, dealTemplateActionCreators, dealTemplateSelectors } from '@makemydeal/dr-dash-store';
import { useMediaQuery } from 'react-responsive';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

const TemplatesCard = () => {
    const dispatch = useDispatch();

    const templateListDrawerShown = useSelector(dealTemplateSelectors.getTemplateSlideOutStatus);
    const setShowTemplateListDrawer = (show: boolean) => dispatch(dealTemplateActionCreators.setTemplateSlideOutStatus(show));

    const isDealTemplatesViewEnabled = useSelector(configSelectors.isDealTemplatesViewEnabled);

    const theme = useInterstateTheme();
    const isTabletSize = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenLg} ) and (max-width: 1167px)` });
    const isLargeSize = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenXl})` });

    const onHideDrawer = () => {
        setShowTemplateListDrawer(false);
        dispatch(dealTemplateActionCreators.dropRemoveDealTemplateData());
    };

    const getPanelWidth = () => {
        if (isLargeSize) return '786px';
        if (isTabletSize) return '504px';
        return '480px';
    };

    if (!isDealTemplatesViewEnabled) {
        return null;
    }

    return (
        <>
            <Typography variant="h3" sx={{ marginBottom: '0px', marginTop: '10px' }}>
                Templates
            </Typography>
            <Action
                data-testid="link-to-show-templates-drawer"
                sx={{ textAlign: 'left' }}
                onClick={() => {
                    setShowTemplateListDrawer(true);
                }}
            >
                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                    Deal Templates
                    <ChevronRightIcon fontSize={10} />
                </Typography>
            </Action>
            <SlideOut
                data-testid="deal-templates-drawer"
                header="Deal Templates"
                show={templateListDrawerShown}
                position="right"
                panelWidth={getPanelWidth()}
                onHide={() => onHideDrawer()}
            >
                <DealTemplateList />
            </SlideOut>
        </>
    );
};

export default TemplatesCard;
