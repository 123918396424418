import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxSelectors, offerReduxActionCreators, offerActionTypes, compositeSelectors } from '@makemydeal/dr-dash-store';

import { GENERIC_LAST_FIELD_CHANGED_ERROR, LEASE_TERM, TERM } from '../../constants';
import { parseSelectedTermMonths, addValueSuffix, removeValueSuffix } from './utils';
import { TextInput } from '@interstate/components/TextInput';

const LeaseTermField = ({ isSummaryTab }: { isSummaryTab?: boolean }) => {
    const dispatch = useDispatch();
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const selectedTermId = useSelector((state) => offerReduxSelectors.getTermIdByOfferType(state, offerType));
    const failed = useSelector(compositeSelectors.getLastFailureActionIfError(offerActionTypes.SELECTED_TERM));
    const failedMessage = failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined;
    const formattedTerm = useMemo(() => addValueSuffix(parseSelectedTermMonths(selectedTermId) || ''), [selectedTermId]);
    const [termNumber, setTermNumber] = useState(formattedTerm);

    useEffect(() => {
        if (termNumber !== formattedTerm) setTermNumber(formattedTerm);
    }, [formattedTerm]);

    useEffect(() => {
        if (isDealCentralExperience) setTermNumber(addValueSuffix(parseSelectedTermMonths(selectedTermId) || ''));
    }, [selectedTermId]);

    const updateSelectedTerm = (termId: string) =>
        dispatch(offerReduxActionCreators.selectedTerm(termId, { suppressAnalytics: true, payment: true }));

    const handleInputChanged = (e: any) => {
        if (e.target.value === termNumber || e.target.value.length > 3 || isNaN(Number(e.target.value))) {
            return;
        }
        setTermNumber(Number(e.target.value).toString());
    };

    const handleInputFocus = () => {
        setTermNumber(removeValueSuffix(termNumber));
    };

    const handleInputBlur = () => {
        const term = parseSelectedTermMonths(selectedTermId);
        if (term !== termNumber) {
            updateSelectedTerm(`finance_term_${termNumber}`);
        }

        setTermNumber(addValueSuffix(termNumber));
    };

    return (
        <TextInput
            data-testid="CMDTermInput"
            label={isBaseDealScreenExperience && isSummaryTab ? `${TERM} (Monthly)` : LEASE_TERM}
            name={LEASE_TERM}
            hasError={failed}
            errorMessage={failedMessage}
            onChange={handleInputChanged}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            value={termNumber}
        />
    );
};

export default LeaseTermField;
