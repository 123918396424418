import React, { ComponentProps, ReactNode } from 'react';
import { StyledDetailedSummarySection } from './DealSummaryItem.style';
import { Typography } from '@interstate/components/Typography';
import { Grid } from '@interstate/components/Grid';
import LineItemRow from './LineItemRow';

interface DealSummaryDetailedViewProps {
    label: string;
    value: string;
    containerTestId: string;
    valueId: string;
    expandibleButtonStyles: ComponentProps<typeof Grid>['sx'];
    expandibleAreaStyles: ComponentProps<typeof Grid>['sx'];
    children: ReactNode;
}

const DealSummaryDetailedView: React.FC<DealSummaryDetailedViewProps> = ({
    label,
    value,
    containerTestId,
    valueId,
    expandibleButtonStyles,
    expandibleAreaStyles,
    children
}) => {
    return (
        <>
            <StyledDetailedSummarySection hasExpandableButtonStyles={expandibleButtonStyles}>
                <LineItemRow
                    styles={{
                        pl: '1.857rem',
                        pb: '0.571rem',
                        pt: '0.571rem',
                        ...expandibleButtonStyles
                    }}
                >
                    <Typography
                        tag="span"
                        sx={{
                            flexGrow: 1
                        }}
                        variant="body-sm"
                        color="sem.color.on-surface.muted"
                        data-testid={containerTestId}
                    >
                        {label}
                    </Typography>
                    <Typography
                        tag="span"
                        variant="body-lg"
                        color="sem.color.on-surface.default"
                        data-testid={valueId}
                        sx={{
                            fontWeight: 600,
                            fontSize: '1.143rem'
                        }}
                    >
                        {value}
                    </Typography>
                </LineItemRow>
            </StyledDetailedSummarySection>
            <Grid
                sx={{
                    pb: 2,
                    pr: 2,
                    pl: '1.857rem',
                    ...expandibleAreaStyles
                }}
            >
                {children}
            </Grid>
        </>
    );
};

export default DealSummaryDetailedView;
