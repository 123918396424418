import { getOktaToken } from './common';

type CallbackWithArgs = (args: unknown) => void;

const SUBSCRIBE = 'subscribe';
const CMD = 'CMD';

type Toggles = {
    enableOptionalPbcUi?: boolean;
    enableGenerateContracts?: boolean;
};

type Selectors = {
    getDealRefId?: () => string;
};

type SocketManagerProps = {
    wsUrl: string;
    messageCallback: CallbackWithArgs;
    dealExchangeId: string; // required for MV to load
    selectors: Selectors;
    toggles?: Toggles;
};

export default class SocketManager {
    static KEEP_ALIVE_TIMEOUT = 5 * 60 * 1000;
    static RECREATE_TIMEOUT = 115 * 60 * 1000;

    socket: WebSocket | null;
    keepAliveTimer: any | null;
    recreateTimer: any | null;
    connectionString: string | null;
    dealExchangeId: string | null;
    messageCallback: CallbackWithArgs;
    websocketUrl: string | null;
    enableOptionalPbcUi: boolean | undefined;
    enableGenerateContracts: boolean;

    selectors: Selectors = {};

    constructor(props: SocketManagerProps) {
        this.socket = null;
        this.keepAliveTimer = null;
        this.recreateTimer = null;
        this.connectionString = null;
        this.dealExchangeId = props.dealExchangeId;
        this.selectors = props.selectors;
        this.messageCallback = props.messageCallback;
        this.websocketUrl = props.wsUrl;
        this.enableOptionalPbcUi = props.toggles?.enableOptionalPbcUi || false;
        this.enableGenerateContracts = props.toggles?.enableGenerateContracts || false;

        // Explicitly bind the web socket event listeners to the class instance (instead of the web socket)
        this._handleOpen = this._handleOpen.bind(this);
        this._handleMessage = this._handleMessage.bind(this);
        this._handleError = this._handleError.bind(this);
        this._handleClose = this._handleClose.bind(this);
    }

    start() {
        this.connectionString = this._getConnectionString();

        if (this.connectionString) {
            this.socket = new WebSocket(this.connectionString);

            this.socket.addEventListener('open', this._handleOpen);
            this.socket.addEventListener('message', this._handleMessage);
            this.socket.addEventListener('error', this._handleError);
            this.socket.addEventListener('close', this._handleClose);
        }
    }

    stop() {
        this.socket?.close();
    }

    refresh() {
        this._keepAlive();
    }

    _getConnectionString() {
        if (this.websocketUrl) {
            const token = getOktaToken() || 'knownorigin';
            return `wss://${this.websocketUrl}?token=${token}`;
        }

        return null;
    }

    /**
     * NOTE: this function doesn't need "bind" because it is an arrow function.
     */
    _keepAlive = () => {
        this.socket?.send(JSON.stringify({ action: SUBSCRIBE, topic: 'dealXgCreate', data: this.dealExchangeId, metadata: CMD }));
        this.socket?.send(JSON.stringify({ action: SUBSCRIBE, topic: 'dealXgPatch', data: this.dealExchangeId, metadata: CMD }));
        this.socket?.send(JSON.stringify({ action: SUBSCRIBE, topic: 'pushToFI', data: this.dealExchangeId, metadata: CMD }));
        if (this.enableOptionalPbcUi) {
            this.socket?.send(
                JSON.stringify({ action: SUBSCRIBE, topic: 'mv_pushToDms', data: this.dealExchangeId, metadata: CMD })
            );
        }
        if (this.enableGenerateContracts && this.selectors.getDealRefId!()) {
            this.socket?.send(
                JSON.stringify({ action: SUBSCRIBE, topic: 'drContracting', data: this.selectors.getDealRefId!(), metadata: CMD })
            );
        }
    };

    _handleOpen(event: Event) {
        this.socket?.send(JSON.stringify({ action: SUBSCRIBE, topic: 'dealXgCreate', data: this.dealExchangeId, metadata: CMD }));
        this.socket?.send(JSON.stringify({ action: SUBSCRIBE, topic: 'dealXgPatch', data: this.dealExchangeId, metadata: CMD }));
        this.socket?.send(JSON.stringify({ action: SUBSCRIBE, topic: 'pushToFI', data: this.dealExchangeId, metadata: CMD }));
        if (this.enableOptionalPbcUi) {
            this.socket?.send(
                JSON.stringify({ action: SUBSCRIBE, topic: 'mv_pushToDms', data: this.dealExchangeId, metadata: CMD })
            );
        }
        if (this.enableGenerateContracts && this.selectors.getDealRefId!()) {
            this.socket?.send(
                JSON.stringify({ action: SUBSCRIBE, topic: 'drContracting', data: this.selectors.getDealRefId!(), metadata: CMD })
            );
        }

        this.keepAliveTimer = setInterval(() => {
            this._keepAlive();
        }, SocketManager.KEEP_ALIVE_TIMEOUT);

        this.recreateTimer = setInterval(() => {
            this.stop();
            this.start();
        }, SocketManager.RECREATE_TIMEOUT);
    }

    _handleMessage(event: MessageEvent) {
        let data;

        try {
            data = JSON.parse(event.data);
        } catch (err) {
            data = event.data;
        }

        this.messageCallback(data);
    }

    _handleError(event: Event) {
        // eslint-disable-next-line no-console
        console.warn(event);
    }

    _handleClose(event: CloseEvent) {
        clearInterval(this.keepAliveTimer as any);
        clearInterval(this.recreateTimer as any);
    }
}
