import styled, { css } from 'styled-components';
import { ToggleChip } from '@interstate/components/Chip';
import { AuxButton } from '@interstate/components/Button';

export const LoadingIndicatorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    #ids-spinner-root {
        margin-bottom: 20px;
    }
`;

export const SelectActionChip = styled(ToggleChip)<{ isApplied: boolean }>`
    border-radius: 714.214rem !important;
    min-width: 2.857rem;
    border: 0.0714rem solid;
    height: 1.428rem !important;
    width: 4.214rem;
    background-color: #fff !important;
    color: #000000 !important;
    .MuiChip-label {
        padding: 0 !important;
    }

    ${({ isApplied }) =>
        isApplied &&
        css`
            background-color: rgba(0, 52, 104, 1) !important;
            color: #fff !important;
            width: 5.29rem !important;
        `};
`;

export const AddIncentiveButton = styled(AuxButton)`
    font-size: 1rem !important;
`;

export const TableWrapperForDealCentral = styled.div<{ appliedProgramKey: string }>`
    .interstate-chevron-right-icon,
    .interstate-chevron-down-icon,
    [data-ids-react-component-name='ids-react-noTag'] {
        display: none;
    }

    [data-testid='ids-table-incentives-table'] {
        .ant-table-cell.ant-table-cell-fix-right::after,
        .ant-table-cell.ant-table-cell-fix-right {
            box-shadow: none !important;
            border-left: none !important;
        }
    }

    .ant-table-expanded-row-fixed {
        border-left: 4px solid #003468 !important;
    }
    .ant-table-expanded-row-fixed.ant-table-expanded-row-fixed {
        background-color: #ebf6ff !important;
    }

    [data-row-key='${(props) => props.appliedProgramKey}'] > .ant-table-row-expand-icon-cell {
        border-left: 4px solid #003468 !important;
    }

    [data-row-key='${(props) => props.appliedProgramKey}'] > .ant-table-cell {
        background-color: #ebf6ff !important;
    }

    & table tr th:nth-of-type(2),
    & table tr th:last-of-type {
        pointer-events: none;

        svg {
            display: none;
        }
    }
`;
