// externals
import React, { useCallback } from 'react';

// CSS style components
import {
    firstColumnStyle,
    gridPairsContainerStyle,
    gridHeaderStyle,
    leftAlignGridHeaderStyle,
    rightAlignColumnStyle,
    visualLineStyle,
    leftAlignBoldGridHeaderStyle
} from '../RollToStyles';

type Row = {
    label: string;
    value?: number | string | undefined;
    dividerBelow?: boolean;
};

type RollToPaymentGridComponentProps = {
    rows: Row[];
    headers?: string | string[];
};

const RollToPairsGrid: React.FC<RollToPaymentGridComponentProps> = (props: RollToPaymentGridComponentProps) => {
    const { rows } = props;
    const boldHeader = typeof props.headers === 'string';
    const headers = boldHeader ? [props.headers, ''] : (props.headers as string[]);

    const getHeaderStyle = useCallback(
        (index: number) => {
            if (boldHeader) {
                return leftAlignBoldGridHeaderStyle;
            }
            if (index) {
                return gridHeaderStyle;
            }
            return leftAlignGridHeaderStyle;
        },
        [boldHeader]
    );

    return (
        <div style={gridPairsContainerStyle}>
            {headers &&
                headers.map((header, index) => (
                    <div key={index} style={getHeaderStyle(index)}>
                        {header}
                    </div>
                ))}
            {rows.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    <div style={firstColumnStyle} key="label">
                        {row.label}
                    </div>
                    <div data-testid={'roll-to-payment-' + row.label} style={rightAlignColumnStyle} key="value">
                        {row.value}
                    </div>
                    {row.dividerBelow && <div style={visualLineStyle}></div>}
                </React.Fragment>
            ))}
        </div>
    );
};

export default RollToPairsGrid;
