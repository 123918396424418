//
import { RequestRollToPaymentsPayload, StateTree } from '@makemydeal/dr-dash-types';
import { BasicObject, paymentPayloadUtils } from '@makemydeal/dr-offer-redux';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { ApplicationCodes, OfferType, RollToOptionsOverrides } from '@makemydeal/dr-platform-types';
import { apiConfigHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { RSAA, RSAASuccessType } from 'redux-api-middleware';
import {
    APPLY_ROLL_TO_OPTIONS,
    APPLY_ROLL_TO_OPTIONS_FAILURE,
    APPLY_ROLL_TO_OPTIONS_SUCCESS,
    REQUEST_ROLL_TO_PAYMENTS,
    REQUEST_ROLL_TO_PAYMENTS_FAILURE,
    REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR,
    RESET_ROLL_TO_OPTIONS,
    REVERT_ROLL_TO_ORIGINAL,
    ROLL_TO_PAYMENT_TOGGLE,
    SET_UNSAVED_CHANGES,
    UPDATE_ROLL_ADJUST,
    UPDATE_ROLL_TARGET_BALANCE_DUE,
    UPDATE_ROLL_TARGET_PAYMENT
} from '../actionTypes/rollToOptionsActionTypes';

// selectors
import { offerReduxSelectors } from '../selectors';
import { tradeShopperActivity } from '../shopperActivities';
import { buildPaymentPayloadHostSelectors } from '../utils/offerPayloadUtil';

export const getRollToPayment =
    ({ option }: { option: 'paymentRoll' | 'amountFinancedRoll' }) =>
    (targetValue: number) =>
    (dispatch: any, getState: any) => {
        const state = getState();

        const hostSelectors = buildPaymentPayloadHostSelectors();
        const payload = paymentPayloadUtils.createPaymentPayload(state, hostSelectors, {
            type: REQUEST_ROLL_TO_PAYMENTS,
            meta: {
                isRollToCall: true,
                sourceApplication: ApplicationCodes.MV
            }
        });

        const locHref = locHrefUtil.getLocHref();
        const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'user-program-rolls');
        const endpoint = bffApiUrlResult.url;

        const rollToPayload = {
            ...payload,
            payment: {
                ...payload.payment,
                [option]: targetValue
            }
        };
        /* istanbul ignore next */
        const uprSuccessActionType: RSAASuccessType<StateTree, RequestRollToPaymentsPayload | undefined> = {
            type: REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR,
            payload: async (_action, _state, res) => {
                const data = await res.json();
                return {
                    ...data,
                    [option === 'paymentRoll' ? 'targetPayment' : 'balanceDue']: targetValue
                };
            }
        };

        const action = option === 'paymentRoll' ? updateRollTargetPayment : updateRollTargetBalanceDue;

        dispatch(action(targetValue));
        dispatch({
            [RSAA]: {
                endpoint,
                method: 'POST',
                body: JSON.stringify(rollToPayload),
                headers: { 'Content-Type': 'application/json' },
                types: [REQUEST_ROLL_TO_PAYMENTS, uprSuccessActionType, REQUEST_ROLL_TO_PAYMENTS_FAILURE]
            }
        });
    };

export const updateRollToAdjust = (newValue: any, meta: Record<'actionValueType', string>) => {
    return {
        type: UPDATE_ROLL_ADJUST,
        payload: newValue,
        meta
    };
};

export const updateRollTargetPayment = (newValue: number) => {
    return {
        type: UPDATE_ROLL_TARGET_PAYMENT,
        payload: newValue
    };
};

export const updateRollTargetBalanceDue = (newValue: number) => {
    return {
        type: UPDATE_ROLL_TARGET_BALANCE_DUE,
        payload: newValue
    };
};

export const toggleRollToPayment = () => {
    return {
        type: ROLL_TO_PAYMENT_TOGGLE
    };
};

export const setUnsavedChanges = (hasUnsavedChanges: boolean) => ({
    type: SET_UNSAVED_CHANGES,
    payload: hasUnsavedChanges
});

export const revertRollToOriginal = (meta: Record<'actionValueType', string>) => {
    return {
        type: REVERT_ROLL_TO_ORIGINAL,
        meta
    };
};

export const applyRollToOptions = (payload: RollToOptionsOverrides, offerType: OfferType) => {
    const termValue = payload.options?.term?.value;

    return {
        type: APPLY_ROLL_TO_OPTIONS,
        meta: {
            isRollToCall: true,
            sourceApplication: ApplicationCodes.MV,
            middleware: {
                payment: true
            },
            ...(termValue && {
                paymentRequestOverride: {
                    [offerType]: {
                        termMonths: termValue
                    }
                }
            })
        },
        payload
    };
};

export const applyRollToOptionsSuccess =
    (action: AnyFSA) =>
    async (dispatch: any, getState: any): Promise<void> => {
        const state = getState();
        const monthlyPayment = offerReduxSelectors.getFirstMonthPayment(state);
        const payload = action.meta.originalAction.payload || {};
        const trade = payload.options?.trade || {};

        // tradeIn value
        if (trade.adjusted) {
            dispatch(tradeShopperActivity.tradeActionCreators.allowanceChanged(trade.value, true));
        }
        // close slideOut
        dispatch(toggleRollToPayment());

        dispatch({
            type: APPLY_ROLL_TO_OPTIONS_SUCCESS,
            payload: {
                name: payload.target?.name,
                current: monthlyPayment,
                previous: payload.target?.value
            }
        });
    };

export const applyRollToOptionsError =
    (action: AnyFSA, messages?: BasicObject[]) =>
    async (dispatch: any, getState: any): Promise<void> => {
        dispatch({
            type: APPLY_ROLL_TO_OPTIONS_FAILURE,
            payload: messages
        });
    };

export const resetRollToOptions = () => {
    return {
        type: RESET_ROLL_TO_OPTIONS
    };
};
