// libraries
import hotkeys from 'hotkeys-js';

// components
import { Button } from '@interstate/components/Button';
import { UpdateOfferDialog } from '../UpdateOfferDialog/UpdateOfferDialog';
import ReadyToSendDialogInterstate from '../readyToSendDialog/ReadyToSendDialog.interstate';
import { StatusIndicator } from '@interstate/components/StatusIndicator';
import { ChevronDownIcon, ChatBubbleLeftRightIcon } from '@interstate/components/Icons';
import CheckoutAnywhereDrawer from '../dealActivity/activities/CheckoutAnywhereDrawer';
import { ShowWithFeatureToggle } from '../utils/ShowWithFeatureToggle';
import { PrintPdfLoadingModal } from '../printPdfLoadingModal/PrintPdfLoadingModal';
import { DropdownsContainer, SnackbarWrapper } from './Footer.style.interstate';
import { Snackbar } from '@interstate/components/Snackbar';
import PushToDms from '../dealActivity/activities/PushToDms.interstate';
import { ShareToSalesViewDialog } from '../ShareToSalesView/ShareToSalesViewDialog.interstate';
import { ShareToCustomerDialogInterstate } from '../ShareToCustomerDialog/ShareToCustomerDialog.interstate';
import { useCustomerUI } from '../customerUI/useCustomerUI';
import PushToRouteOneDialog from '../dealActivity/pushToRouteOne/PushToRouteOneDialog';

// externals
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// libraries
import { dealerSelectors, featureToggleSelectors, selectedOfferSelectors } from '@makemydeal/dr-shared-store';

import {
    contractingSelectors,
    contractingActionCreators,
    deskingActionCreators,
    offerSelectors,
    configSelectors,
    dealXgDetailsSelectors,
    appSelectors,
    offerActionCreators,
    printPdfActionCreator,
    pushToDarwinActionCreators,
    navigationActionCreators,
    offerInfoSelectors,
    baseDealAnalyticsActionCreators,
    offerReduxSelectors,
    creditAppSelectors,
    dealMentionsActionCreators,
    dealMentionsHooks
} from '@makemydeal/dr-dash-store';
import { apiConfigConsts, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { Menu, MenuReferenceType } from '@interstate/components/Menu';
import { Action, DEAL_REVIEW_AND_SUBMISSION_DIALOG_HEADER, DEAL_REVIEW_AND_SUBMISSION_DIALOG_PROMPT } from '../constants';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { CASH } from '@makemydeal/dr-platform-types';
import DealComments from '../dealComments/DealComments';

enum ActionTypes {
    UPDATE = 'UPDATE',
    DARWIN = 'DARWIN',
    BEGIN_CHECKOUT = 'BEGIN_CHECKOUT',
    MANAGE_DOCUMENTS = 'MANAGE_DOCUMENTS',
    OPEN_IN_FI = 'OPEN_IN_FI',
    DMS = 'DMS',
    PRINT = 'PRINT',
    DEAL_REVIEW = 'DEAL_REVIEW',
    GENERATE_CONTRACT = 'GENERATE_CONTRACT',
    DESKING = 'DESKING',
    ROUTE_ONE = 'ROUTE_ONE'
}

enum ShareActionTypes {
    SHARE_TO_SHOW_ROOM = 'ShareToShowroom',
    GET_DEAL_LINK = 'GetDealLink',
    SHARE_TO_CUSTOMER = 'ShareToCustomer'
}

const actionCreatorMap = {
    [Action.CONTINUE_IN_DESKING]: deskingActionCreators.continueInDesking,
    [Action.SEND_TO_ECOMMERCE]: deskingActionCreators.sendToEcommerce
} as const;

const readyToSendDialogContentMap = {
    [Action.CONTINUE_IN_DESKING]: {
        dialogHeader: 'Ready to Continue in Desking',
        dialogPrompt: 'Clicking "Yes" will update the offer and open Connect Desking. Are you sure you want to continue?'
    },
    [Action.SEND_TO_ECOMMERCE]: {
        dialogHeader: 'Ready to Send to Ecommerce?',
        dialogPrompt: 'Clicking "Yes" will send you to Ecommerce. Are you sure you want to continue?'
    }
} as const;

const ActionsInterstate = () => {
    const actionsEnabled = useSelector(appSelectors.isLazyInitCompleteForSmartInit);
    const isLatestPushToDmsPlusEnabled = useSelector(dealXgDetailsSelectors.getLatestPushToDmsPlus);
    const enableSendProposalToDJ = useSelector(featureToggleSelectors.enableSendProposalToDJ);
    const dealRefId = useSelector(offerSelectors.getDealRefId) || offerSessionUtils.getSessionValue(apiConfigConsts.FS_DEAL_REF_ID);
    const isDarwinEnabled = useSelector(dealerSelectors.isDarwinEnabled);
    const enablePushToDarwin = useSelector(featureToggleSelectors.isPushToDarwinEnabled) && isDarwinEnabled;
    const enablePushToDms = useSelector(dealerSelectors.getDMSIntegrationToggle).toLowerCase() !== 'off';
    const enablePatchDealRefId = useSelector(featureToggleSelectors.enablePatchDealRefId);
    const enableBeginCheckout = useSelector(dealerSelectors.enableCheckoutAnywhereToggle);
    const enableDigitalDealJacket = useSelector(featureToggleSelectors.enableDigitalDealJacket);
    const isCDL3InMVEnabled = useSelector(featureToggleSelectors.isCDL3EnabledInMV);
    const toggleFsDealRefId = useSelector(featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const configUrl = useSelector(configSelectors.getDealertrackUrl);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const pushToDmsRef = useRef<{ handleConfirmPushToDmsShow: () => void }>();
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const dispatch = useDispatch();
    const isGenerateContractsEnabled = useSelector(dealerSelectors.getEnableGenerateContracts);
    const isGenerateContractingUiOptionEnabled = useSelector(contractingSelectors.isGenerateContractingUiOptionEnabled);
    const isContinueToDeskingDisabled = useSelector(dealerSelectors.isContinueToDeskingDisabled);
    const copyToDeskingURL = useSelector(configSelectors.getCopyToDeskingURL);
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    const enablePushToR1 = useSelector(featureToggleSelectors.enablePushToR1);
    const creditProvider = useSelector(dealerSelectors.getCreditProvider);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const enablePushToR1CTA = enablePushToR1 && creditProvider !== 'dealertrack' && offerType !== CASH;
    const creditAppCompleted = useSelector(creditAppSelectors.isCreditAppCompleted);
    const enableDealComments = useSelector(dealerSelectors.isDealCommentsMVEnabled);

    const [showPushToDropdown, setPushToDropdown] = useState<null | HTMLElement>(null);
    const [actionsDropdown, setActionsDropdown] = useState<null | HTMLElement>(null);
    const [showUpdateOffer, setShowUpdateOffer] = useState(false);
    const [showReadyToSendDialogSave, setShowReadyToSendDialogSave] = useState(false);
    const openPushToDropdown = Boolean(showPushToDropdown);
    const [openActionsDropdown, setOpenActionsDropdown] = useState(false);
    const [showUpdateOfferForBeginCheckout, setShowUpdateOfferForBeginCheckout] = useState(false);
    const [isCheckoutAnywhereDrawerEnabled, setIsCheckoutAnywhereDrawerEnabled] = useState(false);
    const [showUpdateOfferForFni, setShowUpdateOfferForFni] = useState(false);
    const [showUpdateOfferForDdj, setShowUpdateOfferForDdj] = useState(false);
    const [successNotification, setSuccessNotification] = useState(false);
    const [openFni, setOpenFni] = useState(false);
    const [waitForWS, setWaitForWS] = useState(true);
    const [openDdj, setOpenDdj] = useState(false);
    const [currentAction, setCurrentAction] = useState<Action>(Action.CONTINUE_IN_DESKING);
    const [showShareDropdown, setShareDropdown] = useState<null | HTMLElement>(null);
    const openShareDropdown = Boolean(showShareDropdown);
    const crmIntegrationToggle = useSelector(dealerSelectors.getCrmIntegrationToggle);
    const { showCustomerCard } = useCustomerUI();
    const largerThanSM = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    const [showShareToSalesViewDialog, setShowShareToSalesViewDialog] = useState(false);
    const [showShareToCustomerDialog, setShowShareToCustomerDialog] = useState(false);
    const [showGetDealLinkDialog, setShowGetDealLinkDialog] = useState(false);
    const [showPushToR1Dialog, setShowPushToR1Dialog] = useState(false);

    const [showReadyToSendDialog, setShowReadyToSendDialog] = useState(false);
    const initDealExchangeDealVersion = useSelector(selectedOfferSelectors.getSelectedOfferDealXgVersion);
    const latestDealExchangeVersion = useSelector(offerInfoSelectors.getDealXgVersion);
    const redirectionLink = useSelector(dealXgDetailsSelectors.getRedirectionLink);
    const dealExchangeDealId = useSelector(selectedOfferSelectors.getSelectedOfferDealXgId);
    const showMentionsDot = dealMentionsHooks.useDealMentionsRedDot();
    const readyToSendDialogHeader =
        currentAction === Action.SEND_TO_ECOMMERCE
            ? DEAL_REVIEW_AND_SUBMISSION_DIALOG_HEADER
            : readyToSendDialogContentMap[currentAction].dialogHeader;
    const readyToSendDialogPrompt =
        currentAction === Action.SEND_TO_ECOMMERCE
            ? DEAL_REVIEW_AND_SUBMISSION_DIALOG_PROMPT
            : readyToSendDialogContentMap[currentAction].dialogPrompt;

    const closeReadyToSendDialog = useCallback(() => {
        setShowReadyToSendDialog(false);
    }, [setShowReadyToSendDialog]);

    const dispatchAction = useCallback(
        (action: Action) => {
            const actionCreator = actionCreatorMap[action];
            if (actionCreator) {
                dispatch(actionCreator());
            }
        },
        [dispatch]
    );

    const handleActionClick = useCallback(
        (action: Action) => {
            setCurrentAction(action);
            setShowReadyToSendDialog(true);
            dispatchAction(action);
        },
        [setCurrentAction, setShowReadyToSendDialog, dispatchAction]
    );

    const handleContinueInDesking = useCallback(() => handleActionClick(Action.CONTINUE_IN_DESKING), [handleActionClick]);
    const handleSendToEcommerce = useCallback(() => handleActionClick(Action.SEND_TO_ECOMMERCE), [handleActionClick]);

    const handleShareToCustomer = () => {
        dispatch(offerActionCreators.saveOffer());
        if (crmIntegrationToggle) {
            showCustomerCard(true, { tab: 'inbox', messageContentSelector: urlSelector, waitForOfferSave: true });
        } else {
            setShowShareToCustomerDialog(true);
        }
    };

    const handleShareToSalesView = () => {
        setShowShareToSalesViewDialog(true);
    };

    const handleGetDealLink = () => {
        dispatch(offerActionCreators.saveOffer());
        setShowGetDealLinkDialog(true);
    };

    const handleContinueToDeskingSaved = useCallback(() => {
        closeReadyToSendDialog();
        window.open(copyToDeskingURL + dealExchangeDealId, '_blank');
    }, [closeReadyToSendDialog, copyToDeskingURL, dealExchangeDealId]);

    const handleSendToEcommerceSaved = useCallback(() => {
        closeReadyToSendDialog();
        const dealExchangeVersion = latestDealExchangeVersion || initDealExchangeDealVersion;
        window.location.assign(`${redirectionLink}/${dealExchangeDealId}/version/${dealExchangeVersion}`);
    }, [closeReadyToSendDialog, latestDealExchangeVersion, initDealExchangeDealVersion, redirectionLink, dealExchangeDealId]);

    // istanbul ignore next # this is invoked WAY down the pipeline and out of scope here.
    const urlSelector = (state: any) => {
        const url = offerInfoSelectors.getShopperURL(state);
        return `\n${url}`;
    };

    const handleReadyToSendSaved = useCallback(() => {
        switch (currentAction) {
            case Action.CONTINUE_IN_DESKING:
                return handleContinueToDeskingSaved();
            case Action.SEND_TO_ECOMMERCE:
                return handleSendToEcommerceSaved();
        }
    }, [currentAction, handleContinueToDeskingSaved, handleSendToEcommerceSaved]);

    const handleSaveOfferForBeginCheckout = () => {
        if (isCDL3InMVEnabled) {
            dispatch(deskingActionCreators.beginCheckoutClicked());
        }

        dispatch(offerActionCreators.saveOffer());
        setShowUpdateOfferForBeginCheckout(true);
    };

    useEffect(() => {
        //istanbul ignore next
        if (dealRefId) {
            if (openFni) {
                setWaitForWS(false);
                openFniInNewTab();
                setOpenFni(false);
            }
            if (openDdj) {
                setWaitForWS(false);
                dispatch(navigationActionCreators.navigateToDDJ());
                setOpenDdj(false);
            }
        }
    }, [dealRefId]);

    useEffect(() => {
        if (!showUpdateOffer) return;
        //istanbul ignore next

        if (!offerIsBeingSaved && offerHasBeenSaved) {
            setSuccessNotification(true);
            setShowUpdateOffer(false);
            return;
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]);

    // hotkey for save
    useEffect(() => {
        hotkeys('alt+s,CapsLock+alt+s', { keyup: true, keydown: false }, function (event) {
            event.preventDefault();
            if (actionsEnabled) confirmAndSaveRequest(true);
        });

        return () => {
            hotkeys.unbind('alt+s,CapsLock+alt+s');
        };
    }, [actionsEnabled]);

    const handlePushToButtonClose = () => setPushToDropdown(null);

    const handleActionsButtonClose = () => setOpenActionsDropdown(false);

    const updateOfferDialogOnHideCallback = () => setShowUpdateOffer(false);

    //istanbul ignore next
    const snackbarOnCloseCallback = () => setSuccessNotification(false);

    const handlePushToDarwin = () => {
        dispatch(pushToDarwinActionCreators.pushToDarwin());
    };

    const handleSaveOffer = () => {
        dispatch(offerActionCreators.saveOffer());
        setShowUpdateOffer(true);
    };

    const parseUnifiDeepLinkFromConfigUrl = (configUrl: string) => {
        if (configUrl.length > 0) {
            const fsProvider = toggleFsDealRefId ? 'FD' : 'DRS';
            configUrl = `${configUrl}/dealjacket_deal_summary/${fsProvider}/${dealRefId}/`;
        }
        return configUrl;
    };

    const openFniInNewTab = () => {
        const url: string = parseUnifiDeepLinkFromConfigUrl(configUrl);
        window.open(url, '_blank');
    };

    const openUniFiDeepLinkUrl = () => {
        if (enablePatchDealRefId && !dealRefId) {
            setOpenFni(true);
            dispatch(offerActionCreators.saveOffer());
            setShowUpdateOfferForFni(true);
        } else {
            openFniInNewTab();
        }
    };

    const handleManageDocumentsClick = () => {
        if (enablePatchDealRefId && !dealRefId) {
            setOpenDdj(true);
            dispatch(offerActionCreators.saveOffer());
            setShowUpdateOfferForDdj(true);
        } else {
            dispatch(navigationActionCreators.navigateToDDJ());
        }
    };

    const handlePushToR1DialogShow = () => {
        setShowPushToR1Dialog(true);
    };

    const handleHidePushToR1Dialog = () => {
        setShowPushToR1Dialog(false);
    };

    const handleMenuActions = (actionType: ActionTypes | ShareActionTypes) => {
        switch (actionType) {
            case ActionTypes.DARWIN:
                handlePushToDarwin();
                handlePushToButtonClose();
                break;
            case ActionTypes.DMS:
                // This is tested in FinishDealCard PushToDm component unit test
                // istanbul ignore next
                pushToDmsRef.current?.handleConfirmPushToDmsShow();
                handlePushToButtonClose();
                break;
            case ActionTypes.BEGIN_CHECKOUT:
                handleSaveOfferForBeginCheckout();
                handleActionsButtonClose();
                break;
            case ActionTypes.MANAGE_DOCUMENTS:
                handleManageDocumentsClick();
                handleActionsButtonClose();
                break;
            case ActionTypes.OPEN_IN_FI:
                openUniFiDeepLinkUrl();
                handleActionsButtonClose();
                break;
            case ActionTypes.PRINT:
                handleSavePrint();
                handleActionsButtonClose();
                break;
            case ActionTypes.DEAL_REVIEW:
                handleSendToEcommerce();
                handleActionsButtonClose();
                break;
            case ActionTypes.DESKING:
                handleContinueInDesking();
                handlePushToButtonClose();
                break;
            case ShareActionTypes.SHARE_TO_CUSTOMER:
                handleShareToCustomer();
                handleShareButtonClose();
                break;
            case ShareActionTypes.GET_DEAL_LINK:
                handleGetDealLink();
                handleShareButtonClose();
                break;
            case ShareActionTypes.SHARE_TO_SHOW_ROOM:
                handleShareToSalesView();
                handleShareButtonClose();
                break;
            case ActionTypes.ROUTE_ONE:
                handlePushToR1DialogShow();
                handlePushToButtonClose();
                break;
        }
    };

    const confirmAndSaveRequest = useCallback(
        (isSourceHotKeys?: boolean) => {
            if (isSourceHotKeys) {
                dispatch(baseDealAnalyticsActionCreators.clickedUpdateHotKey());
            } else {
                dispatch(baseDealAnalyticsActionCreators.clickedUpdate());
            }

            if (isLatestPushToDmsPlusEnabled) {
                setShowReadyToSendDialogSave(true);
            } else {
                handleSaveOffer();
            }
        },
        [isLatestPushToDmsPlusEnabled]
    );

    const handleOpenDealMentions = () => {
        dispatch(dealMentionsActionCreators.setShowDealMentions(true));
    };

    const handleCloseDealMentions = () => {
        dispatch(dealMentionsActionCreators.setShowDealMentions(false));
    };

    // istanbul ignore next
    const handleSave = () => setShowReadyToSendDialogSave(false);

    const setShowReadyToSendDialogSaveOnNoOrHide = useCallback(
        () => setShowReadyToSendDialogSave(false),
        [setShowReadyToSendDialogSave]
    );

    const handleShareButtonClose = useCallback(() => setShareDropdown(null), [setShareDropdown]);

    const getToDealLinkDialogOnHide = useCallback(() => setShowGetDealLinkDialog(false), [setShowGetDealLinkDialog]);
    const shareToCustomerDialogOnHide = useCallback(() => setShowShareToCustomerDialog(false), [setShowShareToCustomerDialog]);

    const shareToSalesViewOnHideCallback = useCallback(() => setShowShareToSalesViewDialog(false), [setShowShareToSalesViewDialog]);
    const shareToSalesViewOnOfferSaveHide = useCallback(() => {
        setSuccessNotification(true);
        setShowShareToSalesViewDialog(false);
    }, [setSuccessNotification, setShowShareToSalesViewDialog]);

    const createDeskingOption = (isContinueToDeskingDisabled: boolean, isDealCentralExperience: boolean) => {
        if (!isContinueToDeskingDisabled && isDealCentralExperience) {
            return [
                {
                    label: 'Desking',
                    value: ActionTypes.DESKING,
                    id: 'desking-option',
                    onSelect: () => {
                        handleMenuActions(ActionTypes.DESKING);
                    }
                }
            ];
        }
        return [];
    };

    const createRouteOneOption = (enablePushToR1CTA: boolean, creditAppCompleted: boolean, isDealCentralExperience: boolean) => {
        if (enablePushToR1CTA && creditAppCompleted && isDealCentralExperience) {
            return [
                {
                    label: 'Route One',
                    value: ActionTypes.ROUTE_ONE,
                    id: 'route-one-option',
                    onSelect: () => {
                        handleMenuActions(ActionTypes.ROUTE_ONE);
                    }
                }
            ];
        }
        return [];
    };

    const pushToOptions = useMemo(
        () => [
            {
                label: 'DMS',
                value: ActionTypes.DMS,
                id: 'dms-option',
                disabled: !enablePushToDms,
                onSelect: () => {
                    handleMenuActions(ActionTypes.DMS);
                }
            },
            {
                label: 'Darwin',
                value: ActionTypes.DARWIN,
                id: 'darwin-option',
                disabled: !enablePushToDarwin,
                onSelect: () => {
                    handleMenuActions(ActionTypes.DARWIN);
                }
            },
            ...createDeskingOption(isContinueToDeskingDisabled, isDealCentralExperience),
            ...createRouteOneOption(enablePushToR1CTA, creditAppCompleted, isDealCentralExperience)
        ],
        [
            enablePushToDarwin,
            isContinueToDeskingDisabled,
            isDealCentralExperience,
            enablePushToR1CTA,
            creditAppCompleted,
            handleMenuActions
        ]
    );

    const actions = useMemo(() => {
        const baseActions = [
            isGenerateContractsEnabled && {
                label: 'Generate Contract',
                value: ActionTypes.GENERATE_CONTRACT,
                disabled: !isGenerateContractingUiOptionEnabled,
                id: 'generate-contract-option',
                onSelect: () => {
                    dispatch(contractingActionCreators.contractingOpenModal());
                }
            },
            isEcommerceDeal &&
                !isGenerateContractsEnabled && {
                    label: 'Deal Review',
                    value: ActionTypes.DEAL_REVIEW,
                    id: 'deal-review-option',
                    onSelect: () => {
                        handleMenuActions(ActionTypes.DEAL_REVIEW);
                    }
                },
            {
                label: 'Begin Checkout',
                value: ActionTypes.BEGIN_CHECKOUT,
                id: 'begin-checkout-option',
                disabled: !enableBeginCheckout,
                onSelect: () => {
                    handleMenuActions(ActionTypes.BEGIN_CHECKOUT);
                }
            },
            {
                label: 'Manage Documents',
                value: ActionTypes.MANAGE_DOCUMENTS,
                id: 'manage-documents-option',
                disabled: !enableDigitalDealJacket,
                onSelect: () => {
                    handleMenuActions(ActionTypes.MANAGE_DOCUMENTS);
                }
            },
            {
                label: 'Open In F&I',
                value: ActionTypes.OPEN_IN_FI,
                id: 'F&I-option',
                disabled: enablePatchDealRefId ? false : !dealRefId,
                divider: true,
                onSelect: () => {
                    handleMenuActions(ActionTypes.OPEN_IN_FI);
                }
            },
            {
                label: 'Print',
                value: ActionTypes.PRINT,
                id: 'print-option',
                onSelect: () => {
                    handleMenuActions(ActionTypes.PRINT);
                }
            }
        ].flatMap((item) => (typeof item === 'boolean' || !item ? [] : [item]));

        return baseActions;
    }, [
        dealRefId,
        enableBeginCheckout,
        enablePatchDealRefId,
        isEcommerceDeal,
        isGenerateContractsEnabled,
        isGenerateContractingUiOptionEnabled
    ]);

    const shareOptions = useMemo(
        () => [
            {
                label: 'Share to Customer',
                value: ShareActionTypes.SHARE_TO_CUSTOMER,
                id: 'share-to-customer-option',
                onSelect: () => {
                    handleMenuActions(ShareActionTypes.SHARE_TO_CUSTOMER);
                    dispatch(deskingActionCreators.sendToCustomerClicked());
                }
            },
            {
                label: 'Share to Sales View',
                value: ShareActionTypes.SHARE_TO_SHOW_ROOM,
                id: 'share-to-showroom-option',
                onSelect: () => {
                    handleMenuActions(ShareActionTypes.SHARE_TO_SHOW_ROOM);
                }
            },
            {
                label: 'Get Deal Link',
                value: ShareActionTypes.GET_DEAL_LINK,
                id: 'get-deal-link-option',
                onSelect: () => {
                    handleMenuActions(ShareActionTypes.GET_DEAL_LINK);
                }
            }
        ],
        [handleMenuActions]
    );

    const handleSavePrint = () => {
        if (enableSendProposalToDJ && !dealRefId) {
            dispatch(offerActionCreators.saveOffer());
        }
        dispatch(printPdfActionCreator.printPdf());
    };

    const handlePushToButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setPushToDropdown(e.currentTarget);
    };

    const handleShareClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            setShareDropdown(e.currentTarget);
        },
        [setShareDropdown]
    );

    const handleActionsButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setOpenActionsDropdown(true);
        setActionsDropdown(e.currentTarget);
    };

    return (
        <>
            <PushToDms ref={pushToDmsRef} isBaseScreen={true} />
            {enableDealComments && (
                <div data-testid="deal-comments-button-container">
                    <StatusIndicator indicatorContent={showMentionsDot ? ('!' as any) : undefined} variant="standard">
                        <Button
                            data-testid="action-open-deal-mentions"
                            disabled={false}
                            buttonStyle="secondary"
                            onClick={handleOpenDealMentions}
                            icon={<ChatBubbleLeftRightIcon />}
                        />
                    </StatusIndicator>
                    <DealComments onClose={handleCloseDealMentions} styleVariant="deal-comments-baseFooter" />
                </div>
            )}
            <DropdownsContainer>
                <Button
                    endIcon={<ChevronDownIcon />}
                    buttonStyle="secondary"
                    disabled={!actionsEnabled}
                    className="actions-dropdown-button"
                    data-testid="action-dropdown"
                    onClick={handleActionsButtonClick}
                >
                    Actions
                </Button>

                <Menu
                    referenceProps={{
                        open: openActionsDropdown,
                        anchorEl: actionsDropdown as MenuReferenceType,
                        onClose: handleActionsButtonClose
                    }}
                    position={'top-end'}
                    menuOptions={actions}
                    width={'185px'}
                />

                <Button
                    endIcon={<ChevronDownIcon />}
                    buttonStyle="secondary"
                    disabled={!actionsEnabled}
                    className="push-to-dropdown-button"
                    data-testid="push-to-dropdown"
                    onClick={handlePushToButtonClick}
                >
                    Push to...
                </Button>

                <Menu
                    referenceProps={useMemo(
                        () => ({
                            open: openPushToDropdown,
                            anchorEl: showPushToDropdown as MenuReferenceType,
                            onClose: handlePushToButtonClose
                        }),
                        [openPushToDropdown, showPushToDropdown]
                    )}
                    position={'top-end'}
                    menuOptions={useMemo(
                        () =>
                            pushToOptions.map((item) => ({
                                ...item
                            })),
                        [pushToOptions]
                    )}
                    width={'185px'}
                />

                {isDealCentralExperience && (
                    <>
                        <Button
                            endIcon={<ChevronDownIcon />}
                            buttonStyle="secondary"
                            disabled={!actionsEnabled}
                            className="share-dropdown-button"
                            data-testid="share-dropdown"
                            onClick={handleShareClick}
                        >
                            Share
                        </Button>

                        <Menu
                            referenceProps={{
                                open: openShareDropdown,
                                anchorEl: showShareDropdown as MenuReferenceType,
                                onClose: handleShareButtonClose
                            }}
                            position={'top-end'}
                            menuOptions={shareOptions}
                            width={largerThanSM ? '185px' : '100%'}
                        />
                    </>
                )}

                <Button
                    data-testid="update-offer-button"
                    size="medium"
                    disabled={!actionsEnabled}
                    onClick={() => confirmAndSaveRequest()}
                >
                    Save
                </Button>
            </DropdownsContainer>
            <UpdateOfferDialog show={showUpdateOffer} onHide={updateOfferDialogOnHideCallback} />
            <ShareToSalesViewDialog
                show={showShareToSalesViewDialog}
                onHide={shareToSalesViewOnHideCallback}
                onOfferSaveHide={shareToSalesViewOnOfferSaveHide}
            />
            <ShareToCustomerDialogInterstate
                prefix="share-to-customer"
                show={showShareToCustomerDialog}
                onHide={shareToCustomerDialogOnHide}
                header="Share To Customer"
                dialogPrompt="Choose how you would like to send this offer to the shopper"
            />
            <ShareToCustomerDialogInterstate
                prefix="get-deal-link"
                show={showGetDealLinkDialog}
                onHide={getToDealLinkDialogOnHide}
                header="Get Deal Link"
            />

            <ReadyToSendDialogInterstate
                data-testid="ready-to-send-dialog"
                dialogHeader={readyToSendDialogHeader}
                dialogPrompt={readyToSendDialogPrompt}
                show={showReadyToSendDialog}
                onHide={closeReadyToSendDialog}
                onNo={closeReadyToSendDialog}
                onSaved={handleReadyToSendSaved}
                promptSx={{ padding: '0px' }}
                action={currentAction}
            />
            {!showReadyToSendDialog && (
                <ReadyToSendDialogInterstate
                    data-testid="ready-to-send-save-dialog"
                    dialogHeader={'Warning'}
                    dialogPromptHeader={'Important Note:'}
                    dialogPrompt={`Pushing again will overwrite the existing deal in your DMS.`}
                    show={showReadyToSendDialogSave}
                    onHide={setShowReadyToSendDialogSaveOnNoOrHide}
                    onNo={setShowReadyToSendDialogSaveOnNoOrHide}
                    onSaved={handleSave}
                    promptSx={{
                        paddingLeft: '0',
                        b: {
                            paddingRight: '5px'
                        }
                    }}
                />
            )}
            <SnackbarWrapper>
                <Snackbar
                    data-testid="update-offer-dialog-success"
                    show={successNotification}
                    message="Deal successfully saved."
                    position="bottom-center"
                    onClose={snackbarOnCloseCallback}
                    type="success"
                    className="save-snackbar-position"
                />
            </SnackbarWrapper>
            <UpdateOfferDialog
                show={showUpdateOfferForBeginCheckout}
                onHide={() => setShowUpdateOfferForBeginCheckout(false)}
                onSuccess={() => setIsCheckoutAnywhereDrawerEnabled(true)}
            />
            {isCheckoutAnywhereDrawerEnabled && (
                <CheckoutAnywhereDrawer
                    setShowUpdateOffer={setShowUpdateOfferForBeginCheckout}
                    setIsOpenSlider={setIsCheckoutAnywhereDrawerEnabled}
                    isOpenSlider={isCheckoutAnywhereDrawerEnabled}
                />
            )}
            <PushToRouteOneDialog
                data-testid="push-to-route-one-dialog"
                show={showPushToR1Dialog}
                onHide={handleHidePushToR1Dialog}
            />
            <PrintPdfLoadingModal />
            <ShowWithFeatureToggle name="enablePatchDealRefId">
                <UpdateOfferDialog
                    show={showUpdateOfferForFni}
                    onHide={() => setShowUpdateOfferForFni(false)}
                    waitForWS={waitForWS}
                />
            </ShowWithFeatureToggle>
            <ShowWithFeatureToggle name="enablePatchDealRefId">
                <UpdateOfferDialog
                    show={showUpdateOfferForDdj}
                    onHide={() => setShowUpdateOfferForDdj(false)}
                    waitForWS={waitForWS}
                />
            </ShowWithFeatureToggle>
        </>
    );
};

export default ActionsInterstate;
