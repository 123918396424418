import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { OnPrimaryHandlerCallback, SlideoutDrawerFooter } from '@makemydeal/dr-dash-components';
import { manualIncentivesActionCreators, manualIncentivesSelectors, offerActionCreators } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import { getRequiredFields } from './RebateEdit/columnMappings';

const requiredFields = getRequiredFields();

export const RebatesEditFooter = ({ isDealCentralExperience }: { isDealCentralExperience?: boolean }) => {
    const dispatch = useDispatch();
    const incentivesTotal = useSelector(manualIncentivesSelectors.getCurrentEditedRebateIncentivesTotal);
    const errorCount = useSelector(manualIncentivesSelectors.getManualIncentivesTotalRequired(requiredFields));
    const dealerCashTotal = useSelector(manualIncentivesSelectors.getCurrentEditedDealerCashIncentivesTotal);

    const newIncentives = useSelector(manualIncentivesSelectors.getCurrentEditedNonDeletedIncentives);

    const onIncentivesApplyClicked = (finish: OnPrimaryHandlerCallback) => {
        dispatch(manualIncentivesActionCreators.updateShowValidation(true));

        if (errorCount) {
            finish(false); // NOTE: keep slideout open
            return;
        }

        dispatch(
            offerActionCreators.updateManualIncentives({
                manualIncentives: newIncentives,
                dealerCashTotal,
                totalRebates: incentivesTotal
            })
        );

        finish(true); // done success
    };

    return (
        <SlideoutDrawerFooter
            secondaryText="Cancel"
            primaryText="Apply"
            showSecondary={true}
            text={`Total Selected: ${formatDollarsAndCents(incentivesTotal)}`}
            onPrimary={onIncentivesApplyClicked}
            isDealCentralExperience={isDealCentralExperience}
        />
    );
};
