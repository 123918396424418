import { useDispatch, useSelector } from 'react-redux';

import {
    calculationDetailsSelectors,
    vehicleProtectionActionCreators,
    vehicleProtectionSelectors
} from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { LoadingSectionParent, PaymentLoading } from '@makemydeal/dr-dash-components';
import { VehicleProtectionCardUIInterstate } from './vehicleProtectionCard/VehicleProtectionCardUI.interstate';

export const VehicleProtectionPreview = () => {
    const dispatch = useDispatch();
    const products = useSelector(vehicleProtectionSelectors.getVppProducts);
    const isFieEngine = useSelector(calculationDetailsSelectors.isFieCalculationEngineService);
    const { totalProductPrice, productMonthlyPayment } = useSelector(vehicleProtectionSelectors.getVppSummary);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    if (!isFieEngine) {
        return null;
    }

    const monthlyPayment = formatDollarsAndCents(productMonthlyPayment);
    const totalPrice = formatDollarsAndCents(totalProductPrice);

    const menuProducts = products.filter((product) => product.selected);

    const hasSelection = menuProducts.length;

    let actionButtonLabel = 'Start';
    if (isBaseDealScreenExperience) {
        actionButtonLabel = 'Edit';
    } else if (hasSelection) {
        actionButtonLabel = 'View/Edit';
    }

    const redirectToFullVppPage = () => {
        dispatch(vehicleProtectionActionCreators.redirectToVehicleProtectionPage());
    };

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="vpp-preview-loading-indicator" />
            <VehicleProtectionCardUIInterstate
                monthlyPayment={monthlyPayment}
                totalPrice={totalPrice}
                actionLabel={actionButtonLabel}
                onActionClick={redirectToFullVppPage}
                hideContent={!hasSelection}
            />
        </LoadingSectionParent>
    );
};
