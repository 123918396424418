// externals
import React, { memo } from 'react';

// libraries
import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import { FeeCategoryItemsProps } from './FeeCategoryItems';

// components
import { FeesFormItems } from './FeesFormItems';
import { CategoryTitle, FeesHeaderContainer, FieldHeader } from './FeesFormCategoryItems.style';
import { Box } from '@interstate/components/Box';

//utils
import { useFeesFormContext } from './useFeesFormContext';
import { getFeeDetailsRows } from './utils';
import { DELETED_BY_ACTION_FEE_AMOUNT } from './constants';
import { useBaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';

export const FeesFormCategoryItems: React.FC<Omit<FeeCategoryItemsProps, 'offerType'>> = ({
    categoryTitle,
    feeItems,
    dataTestId,
    categoryType
}) => {
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const BaseBreakPoint = useBaseDealBreakpoint();

    const { isLease } = useFeesFormContext();
    const availableRows = getFeeDetailsRows({ feeItems, isLease: !!isLease }).filter(
        (detail) => detail.amount !== DELETED_BY_ACTION_FEE_AMOUNT || detail.isEdited !== false
    );

    const renderFeesFormHeader = () => {
        return availableRows.length && isBaseDealScreenExperience ? (
            <FeesHeaderContainer data-testid={'edit-fees-fields-header'} BaseBreakPoint={BaseBreakPoint}>
                <FieldHeader>Code (Required)</FieldHeader>
                <FieldHeader>Name (Required)</FieldHeader>
                <FieldHeader isAmount>Amount (Required)</FieldHeader>
                <FieldHeader isLease={isLease} isEmpty />
            </FeesHeaderContainer>
        ) : null;
    };

    return (
        <Box data-testid={dataTestId} marginTop={3} sx={{ overflowX: 'auto' }} className="deal-fees-group">
            <CategoryTitle variant="h5" isBaseDealScreenExperience={isBaseDealScreenExperience}>
                {categoryTitle}
            </CategoryTitle>
            {renderFeesFormHeader()}
            <FeesFormItems feeItems={feeItems} categoryType={categoryType} />
        </Box>
    );
};

export default memo(FeesFormCategoryItems);
