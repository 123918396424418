// middleware
import {
    accessoriesMiddleware,
    analyticsMiddleware,
    changeVehicleMiddleware,
    consumerInsightsMiddleware,
    contractingMiddleware,
    dealHistoryMiddleware,
    dealRefIdMiddleware,
    docUploadMiddleware,
    dpmMiddleware,
    notificationMiddleware,
    paymentGridMiddleware,
    paymentMiddleware,
    printPdfMiddleware,
    pushToDarwinMiddleware,
    pushToDmsMiddleware,
    pushToRouteOneMiddleware,
    vAutoMiddleware,
    vAutoTriforceMiddleware,
    vehicleProtectionMiddleware
} from '@makemydeal/dr-dash-store';
import { navigationMiddleware } from '../middleware/navigationMiddleware';
import { postBootstrapMiddleware } from '../middleware/postBootstrapMiddleware';
import { toggleStatsMiddleware } from '../middleware/toggleStatsMiddleware';

// config
import { tradeConfiguration } from '../activities/trade';
import { programsConfiguration } from '../activities/programs';
import { changeVehicleConfiguration } from '../activities/change-vehicle';

// TODO: DASH - restore middleware needed
// import { dataIslandMiddlewareFactory } from '../common/dataIslandMiddlewareFactory';
// import { initOfferReduxMiddleware } from '../middleware/initOfferRedux';
// import { offerMiddleware } from '../middleware/offer/offer';

// // config
// TODO: DASH - restore config imports if needed
// import config /* , { isP202RedesignEnabled, isDocUploadEnabled, isFinishMyDealEnabled, isAccessoriesEnabled } */ from '../../../config';

// TODO: DASH - restore if needed
// import { DataIslandModel } from './../common/dataIslandMiddlewareFactory/dataIslandModel';

// TODO: DASH - restore if needed
// const dataIslandMiddleware = dataIslandMiddlewareFactory(
//     config.analytics.dataIslandKey,
//     config.analytics.propertyToTrack,
//     (action, state) => new DataIslandModel(action, state)
// );

// Use both manager view specific middleware and the internal one from the activity-change-vehicle
const changeVehicleMiddlewares = [changeVehicleConfiguration.middleware, changeVehicleMiddleware.middleware];

export const getRemainingMiddleware = () => {
    return [
        postBootstrapMiddleware,
        // TODO: DASH - restore if needed
        // dataIslandMiddleware,
        navigationMiddleware,
        // TODO: DASH - restore if needed
        // initOfferReduxMiddleware as any,
        accessoriesMiddleware.middleware,
        toggleStatsMiddleware,
        analyticsMiddleware.middleware,
        dealHistoryMiddleware.middleware,
        dpmMiddleware.middleware,
        ...changeVehicleMiddlewares,
        paymentGridMiddleware.middleware,
        paymentMiddleware.getMiddleware(),
        tradeConfiguration.middleware,
        notificationMiddleware.middleware,
        docUploadMiddleware.middleware,
        pushToDmsMiddleware.middleware,
        pushToDarwinMiddleware.middleware,
        pushToRouteOneMiddleware.middleware,
        vehicleProtectionMiddleware.middleware,
        programsConfiguration.middleware,
        consumerInsightsMiddleware.middleware,
        vAutoMiddleware.middleware,
        vAutoTriforceMiddleware.middleware,
        printPdfMiddleware.middleware,
        dealRefIdMiddleware,
        contractingMiddleware.middleware
    ];
};
