// externals
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { SocketManager } from '@makemydeal/dr-shared-network';

// libraries
import { bootstrapActionTypes, dealerSelectors } from '@makemydeal/dr-shared-store';
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// actions
import { socketActionCreators } from '../actions';

// selectors
import { configSelectors, offerInfoSelectors, offerReduxSelectors, offerSelectors } from '../selectors';

const { BOOTSTRAP_SUCCESS } = bootstrapActionTypes;

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    switch (action.type) {
        case BOOTSTRAP_SUCCESS: {
            const wsUrl = configSelectors.getWebsocketUrl(action.payload);
            const state = store.getState();
            const enableOptionalPbcUi = featureToggleSelectors.enableOptionalPbcUi(state);
            const enableGenerateContracts = dealerSelectors.getEnableGenerateContracts(state);

            const manager = new SocketManager({
                wsUrl,
                messageCallback: /* istanbul ignore next */ (payload: any = {}) => {
                    const state = store.getState();
                    store.dispatch(socketActionCreators.dispatchEvent(state, payload));
                },
                dealExchangeId: offerReduxSelectors.getDealXgId(state),
                selectors: {
                    getDealRefId: /* istanbul ignore next */ () => {
                        const state = store.getState();
                        return offerSelectors.getDealRefId(state);
                    }
                },
                toggles: {
                    enableOptionalPbcUi,
                    enableGenerateContracts
                }
            });

            // use this instead of creating IWindow interface
            (window as any).drSocketManager = manager;
            manager.start();

            break;
        }
        default:
            break;
    }
};
