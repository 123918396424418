/* eslint-disable react/no-multi-comp */
// externals
import { type FC } from 'react';

// libraries
import { Action } from '@interstate/components/Action/Action/Action';
import { EachElement } from '@makemydeal/dr-shared-components';
import { useSelector } from 'react-redux';

// types
import type { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import type { CustomFeeItem, Detail } from './types';

import { useFeesFormContext } from './useFeesFormContext';

// styles
import { FeeDetailRow } from './FeeDetailRow';
import { ActionStyle } from './FeesForm.style';

// consts/enums/utils
import { getFeeDetailsRows, isLastRow } from './utils';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

export type FeeItemSummaryDetailedProps = {
    feeItems: CustomFeeItem[];
    categoryType: paymentServicesTypes.FeeCategory;
};

const AddFees: FC<{ handleAddFee: () => void; categoryType: paymentServicesTypes.FeeCategory }> = ({
    handleAddFee,
    categoryType
}) => {
    const isDealScreenExperienceEnabled = useSelector(featureToggleSelectors.useDealScreenExperience);

    return (
        <ActionStyle isBaseDealScreenExperience={isDealScreenExperienceEnabled}>
            <Action size="sm" onClick={handleAddFee} data-testid={`add-fee-action-${categoryType}`}>
                {isDealScreenExperienceEnabled ? `+ Add ${categoryType} Fees` : '+ Add Fees'}
            </Action>
        </ActionStyle>
    );
};

export const FeesFormItems: FC<FeeItemSummaryDetailedProps> = ({ feeItems, categoryType }) => {
    const { isLease, handleAddFee } = useFeesFormContext();
    const feeDetailRows: Detail[] = getFeeDetailsRows({ feeItems, isLease: !!isLease });

    if (!feeDetailRows.length)
        return <AddFees handleAddFee={() => handleAddFee({ category: categoryType, feeItems })} categoryType={categoryType} />;

    return (
        <EachElement
            of={feeDetailRows}
            render={(detail, index) => (
                <>
                    <FeeDetailRow key={`fee-detail-row-${index}`} detail={detail} index={index} isLease={isLease} />
                    {isLastRow({ index, rows: feeDetailRows }) && (
                        <AddFees
                            handleAddFee={() => handleAddFee({ category: categoryType, feeItems })}
                            categoryType={categoryType}
                        />
                    )}
                </>
            )}
        />
    );
};

export default FeesFormItems;
