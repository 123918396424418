// externals
import { rem } from 'polished';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import * as dashStore from '@makemydeal/dr-dash-store';
import * as sharedStore from '@makemydeal/dr-shared-store';

// components
import { Action } from '@interstate/components/Action';
import { Box } from '@interstate/components/Box';
import { InterstateCreditBureauSeparator } from './CreditBureau.styles';
import SelfReportedCreditRatingWithScore from './common/SelfReportedCreditRatingWithScore';
import { Typography } from '@interstate/components/Typography';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { useEffect, useState } from 'react';
import { UpdateOfferDialog } from '../UpdateOfferDialog/UpdateOfferDialog';

const CreditBureauWithScore = () => {
    const dispatch = useDispatch();

    const dealRefIdFallback = useSelector(dashStore.offerSelectors.getDealRefId);
    const dealRefId: string = useSelector(dashStore.offerReduxSelectors.getDealRefId) || dealRefIdFallback;
    const dealRefIdFS: string = useSelector(dashStore.offerReduxSelectors.getDealRefIdFS);
    const fsDealRefId: string = useSelector(dashStore.offerReduxSelectors.getFsDealRefId);
    const dealIdExists = fsDealRefId.length > 0 || dealRefIdFS.length > 0 || dealRefId.length > 0;

    const fsDealRefIdEnabled: boolean = useSelector(sharedStore.featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const creditBureauEnabled: boolean = useSelector(sharedStore.featureToggleSelectors.isCreditBureauEnabled);
    const isCreditProviderRouteOne: boolean = useSelector(sharedStore.dealerSelectors.isCreditProviderRouteOne);

    const isBaseDealScreenExperience = useSelector(sharedStore.featureToggleSelectors.useDealScreenExperience);
    const [openCreditBureau, setOpenCreditBureau] = useState(false);
    const [showOfferSaveForCreditBureau, setShowOfferSaveForCreditBureau] = useState(false);
    const [waitForWS, setWaitForWS] = useState(true);

    useEffect(() => {
        if (openCreditBureau && dealIdExists) {
            setWaitForWS(false);
            dispatch(dashStore.navigationActionCreators.navigateToCreditBureau());
            setOpenCreditBureau(false);
        }
    }, [dealIdExists]);

    const showCreditBureau = (dealIdExists && fsDealRefIdEnabled && creditBureauEnabled) || isBaseDealScreenExperience;

    const handleCreditBureauClick = () => {
        if (isBaseDealScreenExperience && !dealIdExists) {
            setOpenCreditBureau(true);
            dispatch(dashStore.offerActionCreators.saveOffer());
            setShowOfferSaveForCreditBureau(true);
        } else {
            dispatch(dashStore.navigationActionCreators.navigateToCreditBureau());
        }
    };

    return showCreditBureau ? (
        <Box display="flex" flexDirection="column" padding={isBaseDealScreenExperience ? 0 : rem(16)} position={'relative'}>
            <InterstateCreditBureauSeparator isBaseDealScreenExperience={isBaseDealScreenExperience} />
            <Box display="flex" justifyContent="space-between">
                <Typography tag="span" variant="h5">
                    Self Reported Credit Score
                </Typography>

                <Action
                    data-testid="credit-bureau-link"
                    size="sm"
                    onClick={handleCreditBureauClick}
                    sx={{ display: 'flex', justifyContent: 'start' }}
                >
                    {!isCreditProviderRouteOne && 'Credit Bureau Scores'}
                </Action>
            </Box>
            <br />
            <SelfReportedCreditRatingWithScore />
            <UpdateOfferDialog
                show={showOfferSaveForCreditBureau}
                onHide={() => setShowOfferSaveForCreditBureau(false)}
                waitForWS={waitForWS}
            />
        </Box>
    ) : null;
};

export default CreditBureauWithScore;
