import { ExpandableAreaProvider } from '../common/ExpandableArea/Provider';
import { Card } from '@interstate/components/Card';

export const AllScenarioMockWrapper = () => {
    return (
        <ExpandableAreaProvider>
            <div
                style={{ alignSelf: 'stretch', height: '100%' }}
                className="offer-empty-column-container"
                data-testid="offer-empty-column-container"
            >
                <Card className="offer-compare-column" sx={{ border: 'none' }} />
            </div>
        </ExpandableAreaProvider>
    );
};
