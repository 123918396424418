import { StateTree } from '@makemydeal/dr-dash-types';
import { BaseTabs } from '../actionTypes/baseDealTypes';

export const getActiveTab = (state: StateTree) => {
    return state.baseDeal.activeTab || BaseTabs.SUMMARY;
};

export const getBreakdownStatus = (state: StateTree) => {
    return state.baseDeal.showBreakdown || false;
};

export const getVehicleInsuranceInfo = (state: StateTree) => {
    return state.baseDeal.vehicleInsuranceInfo || null;
};

export const getVehicleInsuranceStatus = (state: StateTree) => {
    return state.baseDeal.showVehicleInsurance || false;
};

export const getBreakdownSlideoutStatus = (state: StateTree) => {
    return state.baseDeal.showBreakdownSlideout || false;
};

export const getIncentivesSlideoutTab = (state: StateTree) => {
    return state.baseDeal.incentivesSlideout.activeTab || 'included';
};
