import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { CASH } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors, pushToDarwinActionCreators } from '@makemydeal/dr-dash-store';
import { CardHeader } from '@interstate/components/Card';
import { VehicleProtectionCardProps } from './VehicleProtectionCardProps';
import { VehicleProtectionPreviewTable } from './VehicleProtectionPreviewTable';
import VehicleProtectionPreviewCard from './VehicleProtectionPreviewCard';
import { Action } from '@interstate/components/Action';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { ArrowTopRightOnSquareIcon, PlusIcon, PencilSquareIcon } from '@interstate/components/Icons';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { Button } from '@interstate/components/Button';

import {
    ActionsContainer,
    StyledAction,
    StyledVppCardContent,
    VppPreviewSummaryContainer,
    VppPreviewSummaryRow
} from './VehicleProtectionCardUI.interstate.style';
import { StretchHeightCard } from '../../deskingActivity/DeskingCard.style';
import { useBaseDealBreakpoint } from '../../../utils/useBaseDealBreakpoint';

export const VehicleProtectionCardUIInterstate = ({
    actionLabel,
    hideContent,
    monthlyPayment,
    onActionClick,
    totalPrice
}: VehicleProtectionCardProps) => {
    const dispatch = useDispatch();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isFinanceOrLease = !(currentOfferType === CASH);
    const isDarwinEnabled = useSelector(dealerSelectors.isDarwinEnabled);
    const enablePushToDarwin = useSelector(featureToggleSelectors.isPushToDarwinEnabled) && isDarwinEnabled;
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const BaseBreakPoint = useBaseDealBreakpoint();

    // Base Screen - action icons
    const isMobileSize = useMediaQuery({ query: `(max-width: ${BaseBreakPoint.SMALL})` });

    const getPreviewTable = () => {
        if (isBaseDealScreenExperience && isMobileSize) {
            return <VehicleProtectionPreviewCard />;
        }
        return <VehicleProtectionPreviewTable />;
    };

    const getActions = () => {
        if (isBaseDealScreenExperience) {
            return (
                <ActionsContainer isBaseDealScreenExperience={isBaseDealScreenExperience}>
                    {enablePushToDarwin && (
                        <Button
                            data-testid="vpp-open-in-darwin"
                            buttonStyle="tertiary"
                            onClick={() => dispatch(pushToDarwinActionCreators.openInDarwin())}
                            size="small"
                            startIcon={<ArrowTopRightOnSquareIcon />}
                        >
                            Open in Darwin
                        </Button>
                    )}
                    <Button
                        data-testid="vpp-preview-edit-link"
                        buttonStyle="tertiary"
                        onClick={onActionClick}
                        size="small"
                        startIcon={hideContent ? <PlusIcon /> : <PencilSquareIcon />}
                    >
                        {hideContent ? 'Add' : 'Edit'}
                    </Button>
                </ActionsContainer>
            );
        }

        return (
            <ActionsContainer isBaseDealScreenExperience={isBaseDealScreenExperience}>
                {enablePushToDarwin && (
                    <StyledAction
                        data-testid="vpp-open-in-darwin"
                        onClick={() => dispatch(pushToDarwinActionCreators.openInDarwin())}
                    >
                        <Typography variant="anchor-block-sm" color="base.color.blue.700">
                            Open in Darwin
                        </Typography>
                    </StyledAction>
                )}
                <Action onClick={onActionClick} data-testid="vpp-preview-edit-link">
                    <Typography variant="anchor-block-sm" color="base.color.blue.700">
                        {actionLabel}
                    </Typography>
                </Action>
            </ActionsContainer>
        );
    };

    const content = (
        <StyledVppCardContent isBaseDealScreenExperience={isBaseDealScreenExperience}>
            {getPreviewTable()}

            <VppPreviewSummaryContainer isBaseDealScreenExperience={isBaseDealScreenExperience} BaseBreakPoint={BaseBreakPoint}>
                <VppPreviewSummaryRow>
                    <Typography variant="body-sm">Total</Typography>
                    <Typography tag="span" variant="strong-sm" data-testid="vpp-card-total-cost">
                        {totalPrice}
                    </Typography>
                </VppPreviewSummaryRow>
                {isFinanceOrLease && (
                    <VppPreviewSummaryRow>
                        <Typography variant="body-sm">Monthly Payment</Typography>
                        <Typography tag="span" variant="strong-sm" data-testid="vpp-card-monthly-payment">
                            {monthlyPayment}
                        </Typography>
                    </VppPreviewSummaryRow>
                )}
            </VppPreviewSummaryContainer>
        </StyledVppCardContent>
    );

    return (
        <StretchHeightCard
            data-testid="vpp-preview"
            isBaseDealScreenExperience={isBaseDealScreenExperience}
            header={
                <Grid container justifyContent={'space-between'} alignItems={'baseline'}>
                    {!isBaseDealScreenExperience ? (
                        <CardHeader title="Vehicle Protection" />
                    ) : (
                        <CardHeader className="base-tab-title" title={<Typography variant="h3">Vehicle Protection</Typography>} />
                    )}
                    {getActions()}
                </Grid>
            }
            content={hideContent ? null : content}
        />
    );
};
