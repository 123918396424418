import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dealTemplateActionCreators, dealTemplateSelectors } from '@makemydeal/dr-dash-store';
import { TemplateExistAlert } from './TemplateExists.Interstate';
import { TemplateSuccessAlert } from './TemplateSuccess.Interstate';
import { TemplateErrorAlert } from './TemplateError.interstate';
import { useToastDisappear } from './utils/useToastDisappear';

export const Wrapper = ({ timeout = 10000 }) => {
    const dispatch = useDispatch();
    const setShowTemplateListDrawer = (show: boolean) => dispatch(dealTemplateActionCreators.setTemplateSlideOutStatus(show));

    const draftDealTemplate = useSelector(dealTemplateSelectors.getDraftDealTemplate);
    const { showToast, triggerToast } = useToastDisappear(timeout);

    useEffect(() => {
        if (draftDealTemplate?.isSaved || draftDealTemplate?.isError) {
            triggerToast();
        }
    }, [draftDealTemplate, triggerToast]);

    if (draftDealTemplate?.isSaved && showToast) {
        if (draftDealTemplate.overwritten) {
            return <TemplateExistAlert templateName={draftDealTemplate.name!} />;
        }
        return <TemplateSuccessAlert templateName={draftDealTemplate.name!} showDrawer={setShowTemplateListDrawer} />;
    }

    if (draftDealTemplate?.isError && showToast) {
        return <TemplateErrorAlert />;
    }

    return null;
};
