import { XMarkIcon } from '@interstate/components/Icons';
import { Typography } from '@interstate/components/Typography';
import { ContextualCommentsContainer } from './DealComments.style';
import { configSelectors, dealMentionsSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';
import { useClickOutside } from '../common/useClickOutside';
import { useRef } from 'react';
import { HostEnvironment, hostUrlHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

type DealCommentsProps = {
    onClose?: () => void;
    styleVariant: 'deal-comments-drawer' | 'deal-comments-baseFooter' | 'deal-comments-footer';
};

const DealComments = ({ onClose, styleVariant }: DealCommentsProps) => {
    const showDealMentions = useSelector(dealMentionsSelectors.getShowDealMentions);
    const dealExchangeDealId = useSelector(offerReduxSelectors.getDealXgId);
    const commonOrgId = useSelector(offerReduxSelectors.getCommonOrgId);
    const environment = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());
    const isProd = (env: HostEnvironment) => env === HostEnvironment.Prod || env === HostEnvironment.DarkProd;
    const partition = isProd(environment) ? 'prod' : 'non-prod';
    const baseUrl = useSelector(configSelectors.getLaunchUrl);
    const mentionsDivRef = useRef<HTMLDivElement>(null);

    useClickOutside(mentionsDivRef, onClose ?? (() => {}));

    return (
        <ContextualCommentsContainer
            data-testid={styleVariant}
            className={styleVariant}
            showDealMentions={!onClose || showDealMentions}
            ref={mentionsDivRef}
        >
            <div className="contextual-comments-container-header">
                <Typography
                    sx={{
                        marginBottom: '0',
                        fontSize: '22px'
                    }}
                    variant="h2"
                >
                    Deal Comments
                </Typography>
                {!!onClose && (
                    <XMarkIcon
                        color="gray"
                        fontSize="22px"
                        onClick={onClose}
                        style={{ cursor: 'pointer' }}
                        data-testid="deal-comments-close-icon"
                    />
                )}
            </div>
            <div style={{ height: '547px' }}>
                <enterprise-product-experience-contextual-comments
                    reference-id={dealExchangeDealId}
                    common-org-id={commonOrgId}
                    namespace="deal-central-deal"
                    partition={partition}
                    link={`${baseUrl}?dealXgId=${dealExchangeDealId}&showDealMessages=true`}
                    description="Deal Comments"
                    style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                />
            </div>
        </ContextualCommentsContainer>
    );
};
export default DealComments;
