/**
 * PURPOSE: Provide reducers that are required for the initial boostrap of the application.  These reducers cannot
 *   be selectively loading based on feature toggle flags.  For the the other reducers that can be conditionally
 *   added take a look at `remainingReducers.ts`.
 */

// externals
import { reducer as formReducer } from 'redux-form';

// libraries
import { navigationReducer } from '@makemydeal/dr-activities-common';
import type { InitConfigurationState } from '@makemydeal/dr-dash-types';
import {
    locHrefUtil,
    apiConfigHelper,
    MANAGER_VIEW_APP_PREFIX,
    offerSessionUtils,
    apiConfigConsts
} from '@makemydeal/dr-shared-ui-utils';

// reducers
import { configReducer, selectedOfferReducer, sharedBootstrapReducer, featureToggleReducer } from '@makemydeal/dr-shared-store';
import { vdpSourceReducer, manualIncentivesReducer } from '@makemydeal/dr-dash-store';

export const initialState: InitConfigurationState = {
    urls: {},
    staticAssets: {
        legacyThemeJson: ''
    },
    staticImages: {
        host: '',
        protocol: ''
    },
    services: {
        websockets: {
            url: ''
        },
        bff: {
            host: '',
            protocol: ''
        },
        gateway: {
            host: '',
            protocol: ''
        },
        kbb: {
            tradeInApiKey: ''
        },
        shortio: {
            apiKey: '',
            endpoint: ''
        },
        google: {
            googleTagManagerId: ''
        }
    },
    additionalProps: {
        dealTemplatesView: false
    }
};

const handleBootstrapInitialLoad: configReducer.BuildConfigReducerBootstrapInitialLoadCallback<InitConfigurationState> = (
    appPrefix,
    action,
    state
) => {
    // Get urls based on windows location
    const bffApiConfig = apiConfigHelper.buildBffApiConfig(appPrefix, locHrefUtil.getLocHref());
    const assetsBaseUrl = apiConfigHelper.buildThemeAssetBasePath(appPrefix, locHrefUtil.getLocHref());

    const sessionAdditionalProps = offerSessionUtils.getAdditionalToggles();
    const dealTemplatesView = sessionAdditionalProps[apiConfigConsts.DEAL_TEMPLATES_VIEW] !== undefined;

    const shallowCloned = { ...state };
    shallowCloned.services = {
        ...state.services,
        bff: {
            ...state.services.bff,
            ...bffApiConfig.configObj
        }
    };
    shallowCloned.staticAssets = {
        ...state.staticAssets,
        legacyThemeJson: `${assetsBaseUrl}/css/global-blue/theme-global-blue.json`
    };
    shallowCloned.additionalProps = {
        ...state.additionalProps,
        dealTemplatesView
    };

    return shallowCloned;
};

/**
 * Global Initial Reducers:
 * Initial Reducers are loaded to the store from it's creation.
 * Global Reducers represent data that is shared between all deals that could be open in parallel
 * Example: Configuration and Feature Toggles, they will be the same, no matter which deal is opened (All deals belonging to the same dealer)
 */
export const globalInitialReducers = {
    sharedBootstrap: sharedBootstrapReducer.reducer,
    config: configReducer.buildConfigReducer(MANAGER_VIEW_APP_PREFIX, initialState, handleBootstrapInitialLoad),
    featureToggles: featureToggleReducer.reducer,
    // TODO: DASH - we should try to deprecate redux forms soon
    form: formReducer,
    navigation: navigationReducer
};

/**
 * Scoped Initial Reducers:
 * Initial Reducers are loaded to the store from it's creation.
 * Scoped Reducers represent data that is isolated between deals that could be open in parallel
 * Example: Manual Incentives, since each deal will potentially have their own set of individual Manual Incentives
 */
export const scopedInitialReducers = {
    selectedOffer: selectedOfferReducer.reducer,
    vdpSource: vdpSourceReducer.reducer,
    manualIncentives: manualIncentivesReducer.reducer
};
