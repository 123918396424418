import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dealTemplateSelectors } from '@makemydeal/dr-dash-store';
import { DeleteTemplateSuccessAlert } from './DeleteTemplateSuccess.Interstate';
import { DeleteTemplateErrorAlert } from './DeleteTemplateError.interstate';
import { useToastDisappear } from './utils/useToastDisappear'; // Import the custom hook

export const TemplateRemovedAlert = ({ timeout = 10000 }) => {
    const isRemoved = useSelector(dealTemplateSelectors.getIsDealTemplateRemoved);
    const isRemovingError = useSelector(dealTemplateSelectors.getIsDealTemplateRemovingError);
    const templateName = useSelector(dealTemplateSelectors.getDealTemplateRemovingName);

    const { showToast, triggerToast } = useToastDisappear(timeout);

    useEffect(() => {
        if (isRemoved || isRemovingError) {
            triggerToast();
        }
    }, [isRemoved, isRemovingError, triggerToast]);

    if (isRemoved && showToast) {
        return <DeleteTemplateSuccessAlert templateName={templateName || ''} />;
    }

    if (isRemovingError && showToast) {
        return <DeleteTemplateErrorAlert templateName={templateName || ''} />;
    }

    return null;
};
