// override fetch so we can inject in our security/session id
// this is used so we do not need to change all of the fetch calls throughout the application
// and libraries we depend on. If other parts of the app use axios, we would do the global
// overrides in this file too.

// externals
import intercept from 'fetch-intercept';
import { v4 as generateUuid } from 'uuid';
import OktaAuth from '@okta/okta-auth-js';

// libraries
import { TRACE_ID_HEADER, SESSION_ID_HEADER } from '@makemydeal/dr-shared-bff-types';
import { toggleUtils, sessionUtils, offerSessionUtils, apiConfigConsts } from '@makemydeal/dr-shared-ui-utils';
import { ApiUrlBuilder } from './types';
import { apiUrlBuilderFromAppPrefix } from './apiUrlBuilderFactory';

const FETCH_RELATIVE_PATH = '/api';
const HEADER_AUTHORIZATION_PREFIX = 'Bearer';

let oktaAuthInstance: OktaAuth;

export const setOktaAuthForFetchInterceptor = (oktaAuth: OktaAuth): void => {
    oktaAuthInstance = oktaAuth;
};

/**
 *
 * @deprecated
 * Use getFetchInterceptorWithConfig method instead
 */
export const getFetchInterceptor = (appPrefix: string): intercept.FetchInterceptor => {
    return getFetchInterceptorWithConfig(apiUrlBuilderFromAppPrefix(appPrefix));
};

export const getFetchInterceptorWithConfig = (apiUrlBuilder: ApiUrlBuilder): intercept.FetchInterceptor => {
    return {
        request(url, config) {
            if (!url || !url.startsWith) {
                // Notifications (outside our app) were broken and returning an error "TypeError: t.startsWith is not a function".
                // If url being passed in is either not a string or some kind of undefined/null value then we can't perform our
                // check anyway, so we may as well just return this back to the standard fetch implementation as is without
                // performing any kind of intercept.
                return [url, config];
            }

            // only do this for BFF endpoints
            const bffUrl = apiUrlBuilder.buildBffApiUrl('');

            // only go if it is a relative path /api or if it is bff endpoint
            if (url.startsWith(bffUrl.url) || url.startsWith(FETCH_RELATIVE_PATH)) {
                const token = oktaAuthInstance?.getAccessToken();
                // these are the headers we want to add
                const headers: { [name: string]: string } = {
                    ...toggleUtils.getToggleHeaderFromQueryParamsOrSession(),
                    ...(token && process.env.BYPASS_OKTA !== 'true'
                        ? { Authorization: `${HEADER_AUTHORIZATION_PREFIX} ${token}` }
                        : undefined),
                    [SESSION_ID_HEADER]: sessionUtils.getAppSessionId(),
                    [TRACE_ID_HEADER]: generateUuid()
                };

                // if this is a PR build, then we will through the PR environment and PR Number into headers
                const prNumber = offerSessionUtils.getPrNumber();
                if (prNumber) {
                    headers[apiConfigConsts.PR_NUMBER_API_HEADER] = prNumber;
                    const prEnv = offerSessionUtils.getPrEnvironmentName();
                    if (prEnv) {
                        headers[apiConfigConsts.PR_ENV_API_HEADER] = prEnv;
                    }
                }

                // add the new headers here, only if they do not exist another way.
                let newConfig = config;

                if (!config || !config.headers) {
                    // we do not have a config or we do not have headers
                    newConfig = {
                        ...(config || {}),
                        headers
                    };
                } else {
                    // only add this header.  If it exists, leave it.
                    Object.keys(headers).forEach((key) => {
                        if (!newConfig.headers[key]) {
                            newConfig.headers[key] = headers[key];
                        }
                    });
                }
                return [url, newConfig];
            } else if (url.toLowerCase().includes(document.location.hostname.toLowerCase())) {
                // for requests to this host name
                const prNumber = offerSessionUtils.getPrNumber();
                if (prNumber) {
                    url = url.includes('?') ? `${url}&pr=${prNumber}` : `${url}?pr=${prNumber}`;
                }
            }

            return [url, config];
        }
    };
};

export const setupAuthFetchIntercept = (input: string | ApiUrlBuilder) => {
    const interceptor = typeof input === 'string' ? getFetchInterceptor(input) : getFetchInterceptorWithConfig(input);
    return intercept.register(interceptor);
};
