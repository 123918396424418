import styled from 'styled-components';

export const AddIncentivesTabs = styled.div`
    [id='add-incentives-tabs'] {
        border-bottom: none !important;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
    }
    [id*='add-incentives-tabs-tab-id-'] {
        flex: 1;
        border: 1px solid rgba(178, 178, 178, 1);
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 40px;
        max-height: 40px;
    }
    [id='add-incentives-tabs-tab-id-1'] {
        border-radius: 0 4px 4px 0;
        border-left: none;
    }
    [id='add-incentives-tabs-tab-id-0'] {
        border-radius: 4px 0 0 4px;
    }
    #add-incentives-tabs > div > div {
        gap: 0px;
    }
`;
