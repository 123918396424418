// externals
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// selectors
import { scopedStateSelectors } from '@makemydeal/shared-scoped-store';

// components
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { StyledDeskingCard } from './DeskingCard.style';
import { DeskingCardSkeletonLoader } from './DeskingCardSkeletonLoader';
import { DeskingCardContent } from './DeskingCardContent';

export interface DeskingCardProps {
    scopeId: string;
}

export const DeskingCard = memo(({ scopeId }: DeskingCardProps) => {
    const theme = useInterstateTheme();
    const scopesOrder = useSelector(scopedStateSelectors.getScopesOrder);
    const primaryScopeId = useSelector(scopedStateSelectors.getPrimaryScopeId);
    const initializedScopes = useSelector(scopedStateSelectors.getInitializedScopesOrder);

    const isLargeScreen = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenLg})` });

    const isInitialized = initializedScopes.includes(scopeId);
    const isPrimaryScope = primaryScopeId === scopeId;
    const isSingleCardView = scopesOrder.length === 1;

    return (
        <StyledDeskingCard
            data-testid={`deal-content-card-${scopeId}`}
            isSingleCardView={isSingleCardView}
            isLargeScreen={isLargeScreen}
            isPrimaryScope={isPrimaryScope}
            theme={theme}
        >
            {isInitialized ? (
                <DeskingCardContent scopeId={scopeId} isLargeScreen={isLargeScreen} isSingleCardView={isSingleCardView} />
            ) : (
                <DeskingCardSkeletonLoader />
            )}
        </StyledDeskingCard>
    );
});
