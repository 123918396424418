import {
    CLICKED_SUMMARY_TAB,
    VIEWED_SUMMARY_TAB,
    CLICKED_SUMMARY_TAB_HOTKEY,
    CLICKED_TRADEIN_TAB,
    VIEWED_TRADEIN_TAB,
    CLICKED_TRADEIN_TAB_HOTKEY,
    CLICKED_FEES_TAB,
    VIEWED_FEES_TAB,
    CLICKED_FEES_TAB_HOTKEY,
    CLICKED_TAXES_TAB,
    VIEWED_TAXES_TAB,
    CLICKED_TAXES_TAB_HOTKEY,
    CLICKED_PROTECTION_TAB,
    VIEWED_PROTECTION_TAB,
    CLICKED_PROTECTION_TAB_HOTKEY,
    CLICKED_INCENTIVES_TAB,
    VIEWED_INCENTIVES_TAB,
    CLICKED_INCENTIVES_TAB_HOTKEY,
    CLICKED_LENDER_TAB,
    VIEWED_LENDER_TAB,
    CLICKED_LENDER_TAB_HOTKEY,
    CLICKED_ACCESSORIES_TAB,
    VIEWED_ACCESSORIES_TAB,
    CLICKED_ACCESSORIES_TAB_HOTKEY,
    CLICKED_NEXT_EVENT_HOTKEY,
    CLICKED_PREVIOUS_EVENT_HOTKEY,
    CLICKED_BREAKDOWN_EXPAND_HOTKEY,
    CLICKED_BREAKDOWN_COLLAPSE_HOTKEY,
    CLICKED_UPDATE,
    CLICKED_UPDATE_HOTKEY,
    CLICKED_PREVIOUS_TAB_HOTKEY,
    CLICKED_NEXT_TAB_HOTKEY,
    VIEWED_BREAKDOWN_COLLAPSE,
    VIEWED_BREAKDOWN_EXPAND,
    CLICKED_BREAKDOWN_EXPAND,
    CLICKED_BREAKDOWN_COLLAPSE
} from '../actionTypes/baseDealAnalyticsActionTypes';

export const summaryTabClicked = () => ({
    type: CLICKED_SUMMARY_TAB
});

export const summaryTabViewed = () => ({
    type: VIEWED_SUMMARY_TAB
});

export const summaryTabClickedWithHotkey = () => ({
    type: CLICKED_SUMMARY_TAB_HOTKEY
});

export const tradeInTabClicked = () => ({
    type: CLICKED_TRADEIN_TAB
});

export const tradeInTabViewed = () => ({
    type: VIEWED_TRADEIN_TAB
});

export const tradeInTabClickedWithHotkey = () => ({
    type: CLICKED_TRADEIN_TAB_HOTKEY
});

export const feesTabClicked = () => ({
    type: CLICKED_FEES_TAB
});

export const feesTabViewed = () => ({
    type: VIEWED_FEES_TAB
});

export const feesTabClickedWithHotkey = () => ({
    type: CLICKED_FEES_TAB_HOTKEY
});

export const taxesTabClicked = () => ({
    type: CLICKED_TAXES_TAB
});

export const taxesTabViewed = () => ({
    type: VIEWED_TAXES_TAB
});

export const taxesTabClickedWithHotkey = () => ({
    type: CLICKED_TAXES_TAB_HOTKEY
});

export const protectionTabClicked = () => ({
    type: CLICKED_PROTECTION_TAB
});

export const protectionTabViewed = () => ({
    type: VIEWED_PROTECTION_TAB
});

export const protectionTabClickedWithHotkey = () => ({
    type: CLICKED_PROTECTION_TAB_HOTKEY
});

export const incentivesTabClicked = () => ({
    type: CLICKED_INCENTIVES_TAB
});

export const incentivesTabViewed = () => ({
    type: VIEWED_INCENTIVES_TAB
});

export const incentivesTabClickedWithHotkey = () => ({
    type: CLICKED_INCENTIVES_TAB_HOTKEY
});

export const lenderTabClicked = () => ({
    type: CLICKED_LENDER_TAB
});

export const lenderTabViewed = () => ({
    type: VIEWED_LENDER_TAB
});

export const lenderTabClickedWithHotkey = () => ({
    type: CLICKED_LENDER_TAB_HOTKEY
});

export const accessoriesTabClicked = () => ({
    type: CLICKED_ACCESSORIES_TAB
});

export const accessoriesTabViewed = () => ({
    type: VIEWED_ACCESSORIES_TAB
});

export const accessoriesTabClickedWithHotkey = () => ({
    type: CLICKED_ACCESSORIES_TAB_HOTKEY
});

export const nextEventHotkey = () => ({
    type: CLICKED_NEXT_EVENT_HOTKEY
});

export const previousEventHotkey = () => ({
    type: CLICKED_PREVIOUS_EVENT_HOTKEY
});

export const breakdownExpand = () => ({
    type: CLICKED_BREAKDOWN_EXPAND
});

export const breakdownExpandHotkey = () => ({
    type: CLICKED_BREAKDOWN_EXPAND_HOTKEY
});

export const breakdownCollapse = () => ({
    type: CLICKED_BREAKDOWN_COLLAPSE
});

export const breakdownCollapseHotkey = () => ({
    type: CLICKED_BREAKDOWN_COLLAPSE_HOTKEY
});

export const viewedBreakdownExpand = () => ({
    type: VIEWED_BREAKDOWN_EXPAND
});

export const viewedBreakdownCollapse = () => ({
    type: VIEWED_BREAKDOWN_COLLAPSE
});

export const clickedUpdate = () => ({
    type: CLICKED_UPDATE
});

export const clickedUpdateHotKey = () => ({
    type: CLICKED_UPDATE_HOTKEY
});

export const clickedPreviousTabHotKey = () => ({
    type: CLICKED_PREVIOUS_TAB_HOTKEY
});

export const clickedNextTabHotKey = () => ({
    type: CLICKED_NEXT_TAB_HOTKEY
});
