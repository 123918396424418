// consts/enums
import { VehicleInsurance } from '@makemydeal/dr-platform-types';
import {
    SET_BREAKDOWN,
    SAVE_VEHICLE_INSURANCE_INFO,
    SHOW_VEHICLE_INSURANCE,
    TAB_CHANGE,
    SHOW_BREAKDOWN_SLIDEOUT,
    INCENTIVES_SLIDEOUT_TAB_CHANGE
} from '../actionTypes/baseDealActionTypes';

export const setBreakdownStatus = (show: boolean) => ({
    type: SET_BREAKDOWN,
    payload: { showBreakdown: show }
});

export const saveVehicleInsuranceInfo = (vehicleInsuranceInfo: VehicleInsurance) => {
    return {
        type: SAVE_VEHICLE_INSURANCE_INFO,
        payload: {
            ...vehicleInsuranceInfo
        }
    };
};

export const setVehicleInsuranceStatus = (show: boolean) => ({
    type: SHOW_VEHICLE_INSURANCE,
    payload: { showVehicleInsurance: show }
});

export const handleTabChange = (tab: string) => {
    return { type: TAB_CHANGE, payload: { activeTab: tab } };
};

export const setBreakdownSlideoutStatus = (show: boolean) => ({
    type: SHOW_BREAKDOWN_SLIDEOUT,
    payload: { showBreakdownSlideout: show }
});

export const setIncentivesSlideoutTab = (tab: 'included' | 'manualUpdates') => ({
    type: INCENTIVES_SLIDEOUT_TAB_CHANGE,
    payload: { activeTab: tab }
});
