// externals
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { offerReduxSelectors, offerSelectors, rollToActionCreators, rollToSelectors } from '@makemydeal/dr-dash-store';

// feature toggles
import * as sharedStore from '@makemydeal/dr-shared-store';

// interstate components
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { TabProps, Tabs } from '@interstate/components/Tabs';

// custom internal components
import RollToSlideOut from './RollToSlideOut';
import RollToOptionTarget from './rollTo/RollToOptionTarget';
import RollToPaymentOptions from './rollTo/RollToPaymentOptions';
import RollToPaymentProfit from './rollTo/RollToPaymentProfit';

// CSS style components
import { CenteredLoadingIndicator } from '../common/Loading.style';

// utils
import { OfferTypeEnum } from '@makemydeal/dr-platform-types';
import { ROLL_TO_OPTIONS_LABELS } from '@makemydeal/dr-shared-types';
import { getDisplayOfferType } from '../utils/formatUtils';
import { activateTabActionLabelsLookup } from './rollTo/constants/activateTabActionLabels';

const RollToOptionsInterstate = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<number>(0);
    const showRollOptions = useSelector(rollToSelectors.getRollToPaymentSlideOutExpanded);
    const isGetRollToBalanceDueEnabled = useSelector(sharedStore.featureToggleSelectors.isGetRollToBalanceDueEnabled);
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType) as OfferTypeEnum;
    const getSelectedTermAmountFinanced = useSelector(offerReduxSelectors.getSelectedTermAmountFinanced);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const isCalculating = useSelector(rollToSelectors.isCalculating);
    const cashAmountDue = useSelector(offerReduxSelectors.getCashTotalAmountDueAfterCredits);

    const currentBalanceDue = currentOfferType === OfferTypeEnum.CASH ? cashAmountDue : getSelectedTermAmountFinanced;
    const isRollToBalanceDueOfferType = [OfferTypeEnum.CASH, OfferTypeEnum.FINANCE].includes(currentOfferType);

    const rollToOptionsTabs: TabProps[] = [
        {
            label: ROLL_TO_OPTIONS_LABELS.PAYMENT,
            active: activeTab === 0,
            component: (
                <RollToOptionTarget
                    key={activeTab}
                    displayProps={{
                        option: ROLL_TO_OPTIONS_LABELS.PAYMENT,
                        currentValue: monthlyPayment,
                        instruction: `Adjust Target ${ROLL_TO_OPTIONS_LABELS.PAYMENT} to review your Roll-To Options.`
                    }}
                    dispatchAction={rollToActionCreators.getRollToPayment({ option: 'paymentRoll' })}
                    clearAction={rollToActionCreators.resetRollToOptions}
                />
            )
        }
    ];

    if (isGetRollToBalanceDueEnabled && isRollToBalanceDueOfferType) {
        rollToOptionsTabs.push({
            label: ROLL_TO_OPTIONS_LABELS.BALANCE_DUE,
            active: activeTab === 1,
            component: (
                <RollToOptionTarget
                    key={activeTab}
                    displayProps={{
                        option: ROLL_TO_OPTIONS_LABELS.BALANCE_DUE,
                        currentValue: currentBalanceDue,
                        instruction: `Adjust ${ROLL_TO_OPTIONS_LABELS.BALANCE_DUE} to review your Roll-To Options.`
                    }}
                    dispatchAction={rollToActionCreators.getRollToPayment({ option: 'amountFinancedRoll' })}
                    clearAction={rollToActionCreators.resetRollToOptions}
                />
            )
        });
    }

    const toggleSlideOut = useCallback(() => {
        dispatch(rollToActionCreators.toggleRollToPayment());
    }, [dispatch]);

    const handleChangeActiveTab = useCallback(
        (tab: number) => {
            setActiveTab(tab);
            dispatch(rollToActionCreators.resetRollToOptions());
        },
        [dispatch]
    );

    if (!showRollOptions) return null;

    return (
        <RollToSlideOut
            show={showRollOptions}
            onHide={toggleSlideOut}
            header={`Roll-To Calculator: ${getDisplayOfferType(currentOfferType)} Deal`}
        >
            <div id="roll-to-options-wrapper" data-testid="roll-options-wrapper">
                {isCalculating && (
                    <CenteredLoadingIndicator
                        data-testid="roll-to-options-loading-indicator"
                        $hideOverlay
                        style={{
                            position: 'fixed',
                            zIndex: 999
                        }}
                    >
                        <LoadingIndicator size="large" />
                    </CenteredLoadingIndicator>
                )}
                <Tabs
                    id="roll-to-options-tabs"
                    sx={{
                        position: 'sticky !important',
                        top: '0',
                        zIndex: '10',
                        backgroundColor: '#FFF'
                    }}
                    tabs={rollToOptionsTabs}
                    onActivate={handleChangeActiveTab}
                />
                <div style={{ marginTop: '1rem' }} />
                <RollToPaymentOptions activeTab={activateTabActionLabelsLookup[activeTab]} />
                <div style={{ marginTop: '1rem' }} />
                <RollToPaymentProfit activeTab={activateTabActionLabelsLookup[activeTab]} />
            </div>
        </RollToSlideOut>
    );
};

export default RollToOptionsInterstate;
