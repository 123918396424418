/* eslint-disable react/no-multi-comp */
// TODO: use centralized component between here and activities-desking
import { ComponentProps, FC, ReactNode, useCallback } from 'react';

// constants
import { SUMMARY_ACCORDION_ID, ZERO_DOLLARS, DEAL_SUMMARY_DETAILS_EXPAND_ID } from '../../constants';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';
import { ExpandableAreaProvider, useCheckExpandableAreaProvider } from '../../common/ExpandableArea';
import { skewWords } from '../../utils/formatUtils';
import { StyledListItem } from './DealSummaryItem.style';
import { DealSummaryProvider, useDealSummary } from '../../common/DealSummaryProvider';

import DealSummaryDetailedView from './DealSummaryDetailedView';
import DealSummaryExpandableView from './DealSummaryExpandableView';
import LineItemRow from './LineItemRow';

export interface IDealSummaryItemProps {
    label: string;
    value: string;
    children?: ReactNode;
    expandibleButtonStyles?: ComponentProps<typeof Grid>['sx'];
    expandibleAreaStyles?: ComponentProps<typeof Grid>['sx'];
    labelStyles?: ComponentProps<typeof Typography>['sx'];
    valueStyles?: ComponentProps<typeof Typography>['sx'];
    /**
     * @deprecated Unused prop
     */
    showLargeValue?: boolean;
    isEmpty?: boolean;
    /**
     * @deprecated Unused prop
     */
    isExpanded?: boolean;
    hasDelta?: boolean;
}

export const DealSummaryItem = ({
    label,
    value,
    children,
    isEmpty = false,
    hasDelta = false,
    expandibleButtonStyles,
    expandibleAreaStyles,
    labelStyles,
    valueStyles
}: IDealSummaryItemProps) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    const hasContextProvider = useCheckExpandableAreaProvider();
    const { showDetailedView } = useDealSummary();

    const dealSummaryItemPrefix = skewWords(['deal', 'summary', 'item']);
    const kebabLabel = kebabCase(label);
    const labelId = skewWords([dealSummaryItemPrefix, 'label', kebabLabel]);
    const valueId = skewWords([dealSummaryItemPrefix, 'value', kebabLabel]);
    const containerTestId = skewWords([dealSummaryItemPrefix, kebabLabel]);
    const expandableOfferId = skewWords([offerType, containerTestId]);
    const className = isEmpty ? skewWords(['empty', 'item']) : '';
    const expandableId = SUMMARY_ACCORDION_ID;
    const nonZeroValue = isEnhancedDealDetailsEnabled || value !== ZERO_DOLLARS;
    const redrawDeepMenu = nonZeroValue && children;
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isDealSummaryDetailsExpandId = isBaseDealScreenExperience && containerTestId === DEAL_SUMMARY_DETAILS_EXPAND_ID;

    const createDealSummarySection = useCallback(
        (children: ReactNode): ReactNode => {
            return (
                <>
                    {!showDetailedView ? (
                        <DealSummaryExpandableView
                            containerTestId={containerTestId}
                            expandableId={expandableId}
                            expandableOfferId={expandableOfferId}
                            isBaseDealScreenExperience={isBaseDealScreenExperience}
                            isDealSummaryDetailsExpandId={isDealSummaryDetailsExpandId}
                            label={label}
                            value={value}
                            valueId={valueId}
                            labelStyles={labelStyles}
                            valueStyles={valueStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                        >
                            {children}
                        </DealSummaryExpandableView>
                    ) : (
                        <DealSummaryDetailedView
                            label={label}
                            value={value}
                            containerTestId={containerTestId}
                            valueId={valueId}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                        >
                            {children}
                        </DealSummaryDetailedView>
                    )}
                </>
            );
        },
        [
            containerTestId,
            expandableId,
            expandableOfferId,
            expandibleAreaStyles,
            expandibleButtonStyles,
            isBaseDealScreenExperience,
            isDealSummaryDetailsExpandId,
            label,
            labelStyles,
            showDetailedView,
            value,
            valueId,
            valueStyles
        ]
    );

    const createExpandableAreaProvider = useCallback(
        (children: ReactNode): ReactNode => {
            return !showDetailedView ? (
                <ExpandableAreaProvider>{createDealSummarySection(children)}</ExpandableAreaProvider>
            ) : (
                <DealSummaryProvider showDetailedView={showDetailedView}>{createDealSummarySection(children)}</DealSummaryProvider>
            );
        },
        [createDealSummarySection, showDetailedView]
    );

    const deepMenu = useCallback(
        (children: ReactNode, hasContextProvider: boolean) => {
            return (
                <StyledListItem className={`${className} ${hasDelta ? 'has-delta' : ''}`} hasDelta={hasDelta}>
                    {hasContextProvider ? createDealSummarySection(children) : createExpandableAreaProvider(children)}
                </StyledListItem>
            );
        },
        [hasContextProvider, className, hasDelta, createDealSummarySection, createExpandableAreaProvider]
    );

    if (redrawDeepMenu) {
        return deepMenu(children, hasContextProvider);
    }

    return (
        <StyledListItem data-testid={containerTestId} hasDelta={hasDelta} className={`${className} ${hasDelta ? 'has-delta' : ''}`}>
            <LineItemRow
                styles={{
                    pl: '1.857rem',
                    ...(isBaseDealScreenExperience && !showDetailedView && { pt: '0.714rem', pb: '0.714rem' }),
                    ...expandibleButtonStyles
                }}
            >
                <Typography
                    tag="span"
                    variant="body-sm"
                    color={
                        isBaseDealScreenExperience && !showDetailedView
                            ? 'sem.color.on-surface.default'
                            : 'sem.color.on-surface.muted'
                    }
                    data-testid={labelId}
                    sx={{
                        flexGrow: 1,
                        ...(!showDetailedView && { ...labelStyles })
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    tag="span"
                    variant="body-lg"
                    color="sem.color.on-surface.default"
                    data-testid={valueId}
                    sx={{
                        ...(!showDetailedView && { ...valueStyles }),
                        ...(isBaseDealScreenExperience || showDetailedView ? { fontWeight: 600, fontSize: '1.143rem' } : {})
                    }}
                >
                    {value}
                </Typography>
            </LineItemRow>
        </StyledListItem>
    );
};

export default DealSummaryItem;
