import { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { Table } from '@interstate/components/Table';
import { CASH, FINANCE, LEASE, OfferType } from '@makemydeal/dr-platform-types';
import {
    leaseOnlyColumns,
    columns,
    cashExemptColumns,
    financeOnlyColumns,
    leaseColumnsOrder,
    financeColumnsOrder,
    nestedColumnForDealCentral
} from './programColumns';
import { removeColumns, reorderColumnsByDataIndex } from '../../utils/mapUtils';
import { ActionColumn } from './ActionColumn';
import { useDispatch, useSelector } from 'react-redux';
import { baseDealActionCreators, offerReduxSelectors, programPayloadUtil } from '@makemydeal/dr-dash-store';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { PencilSquareIcon } from '@interstate/components/Icons';
import { SlideoutDrawerHeader, StyledHeader, useSlideoutDrawer } from '@makemydeal/dr-dash-components';
import { RebatesEditFooter } from '../IncentivesCard/RebatesEditFooter';
import { RebatesEdit } from '../IncentivesCard/RebateEdit/RebatesEdit';
import { createIncentiveSubHeaderText, getIncentiveDisplayType } from '../IncentivesCard/utils';
import { TableWrapperForDealCentral } from './styles';
import { IPaymentTerm } from '@makemydeal/dr-platform-shared';
import { SelectActionColumn } from './SelectActionColumn';
import { ProgramQuote } from '@makemydeal/dr-dash-types';
import { IncentivesTabs } from './AddIncentivesTabs';

type ProgramQuotes = Array<ProgramQuote & { key: string }>;

type ProgramsListViewProps = { data: ProgramQuote[]; offerType: OfferType };

export const ProgramsListView: FC<ProgramsListViewProps> = ({ data, offerType }) => {
    const { showContent } = useSlideoutDrawer();
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    const programEndDate: string = useSelector(offerReduxSelectors.getLenderProgramEndDate);
    const apr = useSelector(offerReduxSelectors.getApr);
    const term = useSelector((state) => offerReduxSelectors.getSelectedTerm(state, offerType));
    const termMonths = term?.months;
    const lenderLegalName = useSelector(offerReduxSelectors.getLenderLegalName);
    const subHeaderText = offerType !== CASH ? createIncentiveSubHeaderText(termMonths, apr as number, programEndDate) : '';
    const selectedTerm: IPaymentTerm = useSelector(offerReduxSelectors.getSelectedTerm);
    const annualMiles = useSelector(offerReduxSelectors.getAnnualMiles);
    const dispatch = useDispatch();

    // istanbul ignore next
    const handleEditClicked: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(baseDealActionCreators.setIncentivesSlideoutTab('manualUpdates'));
        showContent(IncentivesTabs, {
            header: (
                <StyledHeader>
                    <SlideoutDrawerHeader text={`${lenderLegalName} Incentives`} subHeaderText={subHeaderText} />
                </StyledHeader>
            ),
            footer: <RebatesEditFooter isDealCentralExperience={isDealCentralExperience} />,
            testId: 'incentives-editor'
        });
    };

    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives).map((incentive) => ({
        ...incentive,
        type: getIncentiveDisplayType(incentive),
        'edit-icon': (
            <PencilSquareIcon
                onClick={handleEditClicked as MouseEventHandler}
                alignmentBaseline="auto"
                color="#005BA8"
                width={'1.2rem'}
                height={'1.2rem'}
                cursor={'pointer'}
            />
        )
    }));

    const hasIncentives = incentives.length > 0;

    const dealCentralTableData: ProgramQuotes = data.map((item, index) => ({
        ...item,
        key: index.toString()
    }));

    const appliedProgramKey =
        dealCentralTableData.find(
            (value) => programPayloadUtil.isSelectedProgram(selectedTerm, value, offerType, annualMiles) || offerType === CASH
        )?.key || '';

    const openRow = appliedProgramKey;

    const cols = useMemo(() => {
        const renderColumns = [...columns];
        renderColumns[renderColumns.length - 1].render = ActionColumn;
        renderColumns[renderColumns.length - 1].width = '150px';

        if (isDealCentralExperience) {
            renderColumns[0].render = SelectActionColumn;
            renderColumns[0].width = '106px';
            renderColumns[renderColumns.length - 1].width = '132px';
        } else {
            renderColumns.shift();
        }

        if (offerType === CASH) {
            return removeColumns(renderColumns, cashExemptColumns);
        }
        if (offerType === FINANCE) {
            return reorderColumnsByDataIndex(
                removeColumns(renderColumns, [leaseOnlyColumns, ...financeOnlyColumns.slice(0, financeOnlyColumns.length - 1)]),
                financeColumnsOrder
            );
        }
        if (offerType === LEASE) {
            return reorderColumnsByDataIndex(removeColumns(renderColumns, financeOnlyColumns), leaseColumnsOrder);
        }
        renderColumns[renderColumns.length - 1].render = ActionColumn;
        return renderColumns;
    }, [offerType]);

    const getRowClassName = (record: ProgramQuote) => {
        return programPayloadUtil.isSelectedProgram(selectedTerm, record, offerType, annualMiles) || offerType === CASH
            ? 'selected-row'
            : '';
    };

    return isDealCentralExperience ? (
        <TableWrapperForDealCentral appliedProgramKey={appliedProgramKey}>
            <Table
                data-testid="programs-list-view-for-deal-central"
                columns={cols}
                rowClassName={getRowClassName}
                data={dealCentralTableData}
                dataDensity="small"
                scrollX={1800}
                sortableDetails={{ defaultSortOrder: 'ascend' }}
                expandedRowComponent={() => (
                    <Table
                        data-testid="incentives-table"
                        className="incentives-table"
                        scrollX={976}
                        columns={nestedColumnForDealCentral}
                        data={incentives}
                        borderType="body"
                        bordered={true}
                    />
                )}
                expandRowOnClick={true}
                highlightOnHover={false}
                expandedRows={hasIncentives ? [openRow.toString()] : []}
            />
        </TableWrapperForDealCentral>
    ) : (
        <Table columns={cols} data={data} dataDensity="small" scrollX={1800} sortableDetails={{ defaultSortOrder: 'ascend' }} />
    );
};
