// externals
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { DealHistory as DealHistoryType } from '@makemydeal/dr-dash-types';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { Box } from '@interstate/components/Box';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// components
import {
    adjustOfferCardHeights,
    OfferCardInterstate,
    OfferCompareColumnInterstate,
    SliderCarousel
} from '@makemydeal/dr-dash-components';
import DealHistoryFooterInterstate from './dealHistory/footerContainer/DealHistoryFooter.interstate';

// selectors
import { dealHistoryActionCreators, dealHistorySelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// styling
import { StyledSliderCarouselContainer } from './dealHistory/DealHistory.interstate.style';
import { StyledColumnsContainer, OfferCompareContainer } from './DealHistoryActivity.interstate.style';

const DealHistoryActivityInterstate = () => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();
    const deals = useSelector(dealHistorySelectors.getDealHistoryOffers);
    const workingOffer = useSelector(offerSelectors.getWorkingOffer);
    const selectedDeals = useSelector(dealHistorySelectors.getSelectedDealHistory);
    const defaultOffer = useSelector(dealHistorySelectors.getDefaultDealHistory);
    const mostRecentDealerOffer = useSelector(dealHistorySelectors.getLatestDealerOffer);
    const mostRecentShopperOffer = useSelector(dealHistorySelectors.getLatestShopperOffer);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const hasMultiScenarios = useSelector(featureToggleSelectors.enableMultiScenarioPersistence);

    const isDefaultOffer = (deal: DealHistoryType) => {
        return deal.offer.offerId === defaultOffer.offer?.offerId;
    };

    const isMostRecentDealerOffer = (deal: DealHistoryType) => {
        return deal.offer.offerId === mostRecentDealerOffer?.offer?.offerId;
    };

    const isMostRecentShopperOffer = (deal: DealHistoryType) => {
        return deal.offer.offerId === mostRecentShopperOffer?.offer?.offerId;
    };

    const onDealClick = (deal: DealHistoryType) =>
        dispatch(dealHistoryActionCreators.dealHistorySelectedUpdate(deal.offer.offerId as string));

    useLayoutEffect(() => {
        if (isABPencilEnabled) {
            adjustOfferCardHeights('#deal-history-offer-cards-carousel-scroller');
        }
    }, [isABPencilEnabled, deals, defaultOffer, mostRecentDealerOffer, mostRecentShopperOffer]);

    return (
        <Grid pb="104px">
            <Box paddingTop={5} paddingBottom={2}>
                <Typography variant="h1" id="deal-history-page-title" data-testid="deal-history-page-title">
                    View and Compare Offer History
                </Typography>
            </Box>

            <StyledSliderCarouselContainer theme={theme}>
                <SliderCarousel id="deal-history-offer-cards-carousel">
                    {deals.map((deal) => (
                        <OfferCardInterstate
                            deal={deal}
                            isDealHistory={true}
                            key={deal.offer.offerId}
                            isDefaultOffer={isDefaultOffer(deal)}
                            isMostRecentDealerOffer={isMostRecentDealerOffer(deal)}
                            isMostRecentShopperOffer={isMostRecentShopperOffer(deal)}
                            hasMultiScenarios={hasMultiScenarios}
                        />
                    ))}
                </SliderCarousel>
            </StyledSliderCarouselContainer>

            <OfferCompareContainer cardsAmount={selectedDeals.length + 1}>
                <StyledColumnsContainer data-testid={'current-offer-compare-column'} key={'offer-wrapper-main'}>
                    <OfferCompareColumnInterstate deal={workingOffer} isCurrent={true} isDealHistory={true} />
                </StyledColumnsContainer>
                {selectedDeals &&
                    selectedDeals.map((deal) => (
                        <StyledColumnsContainer
                            isSelected={deal.isSelected}
                            onClick={() => onDealClick(deal)}
                            data-testid={'selected-offer-compare-column'}
                            key={'offer-wrapper' + deal.offer.offerId}
                        >
                            <OfferCompareColumnInterstate
                                isCurrent={false}
                                deal={deal}
                                isDealHistory={true}
                                isDefaultOffer={isDefaultOffer(deal)}
                                isMostRecentDealerOffer={isMostRecentDealerOffer(deal)}
                                isMostRecentShopperOffer={isMostRecentShopperOffer(deal)}
                                scenarioCount={dealHistorySelectors.getDealScenarioCount(deal) || 0}
                                hasMultiScenarios={hasMultiScenarios}
                            />
                        </StyledColumnsContainer>
                    ))}
            </OfferCompareContainer>
            <DealHistoryFooterInterstate />
        </Grid>
    );
};

export default DealHistoryActivityInterstate;
