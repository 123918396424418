import React, { FC } from 'react';
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { StyledLink } from './AlertComponent.style';
import { Box } from '@interstate/components/Box';

type TemplateSuccessToastProps = {
    templateName: string;
    showDrawer: (show: boolean) => void;
};
export const TemplateSuccessAlert: FC<TemplateSuccessToastProps> = ({ templateName, showDrawer }) => (
    <Alert
        sx={{
            '.MuiAlert-message a': {
                color: '#005ba8'
            }
        }}
        disableSuccessFade
        type="success"
        data-testid="template-success-toast"
        title="Template Successfully Saved"
    >
        <Box display="flex">
            <Typography variant="body-sm">{`The deal template '${templateName}' has been saved to your template library. View`}</Typography>
            <StyledLink data-testId="link-to-show-templates-drawer" onClick={() => showDrawer(true)}>
                Deal Templates
            </StyledLink>
        </Box>
    </Alert>
);
