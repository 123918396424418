// externals
import { useEffect, useRef, useState } from 'react';
import { NumericInput } from '@interstate/components/NumericInput';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';
import { paymentActionCreators } from '@makemydeal/dr-offer-redux';
import { usePaymentCall } from '../../utils/usePaymentCall';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// consts
import { DAYS_TO_FIRST_PAYMENT, MIN_DAYS_TO_FIRST_PAYMENT, MAX_DAYS_TO_FIRST_PAYMENT } from '../../constants';
import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

const DaysToFirstPaymentField = () => {
    const isPaymentError = useSelector(offerReduxSelectors.getIsError);
    const actionForPayment = useSelector(offerReduxSelectors.getActionForPayment);
    const termDaysToPayment = useSelector(offerReduxSelectors.getDaysToFirstPaymentBySelectedTerm);
    const isCalculatingPayment = useSelector(offerReduxSelectors.isCalculatingPayment);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const {
        value: inputDaysToFirstPayment,
        handleChange,
        handleBlur
    } = usePaymentCall(offerReduxSelectors.getDaysToFirstPaymentBySelectedTerm, paymentActionCreators.updateDaysToFirstPayment);

    const mounted = useRef(false);

    const [errorMsg, setErrorMsg] = useState('');
    const [daysToFirstPayment, setDaysToFirstPayment] = useState(termDaysToPayment);

    const inputDays = parseInt(inputDaysToFirstPayment);

    useEffect(() => {
        if (!isCalculatingPayment) {
            const errors = isPaymentError && actionForPayment === 'UPDATED_DAYS_TO_FIRST_PAYMENT';
            if (errors) {
                setDaysToFirstPayment(inputDays);
                setErrorMsg(`Valid Days: from ${MIN_DAYS_TO_FIRST_PAYMENT} to ${MAX_DAYS_TO_FIRST_PAYMENT}`);
            } else if (termDaysToPayment && termDaysToPayment !== inputDays) {
                setDaysToFirstPayment(-1);
                setErrorMsg('');
            } else {
                setDaysToFirstPayment(termDaysToPayment || inputDays);
                setErrorMsg('');
            }
        }
    }, [isCalculatingPayment, isPaymentError]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }

        setDaysToFirstPayment(termDaysToPayment);
        setErrorMsg(`Input adjusted to ${termDaysToPayment} Days`);
    }, [daysToFirstPayment === -1]);

    return (
        <>
            {isBaseDealScreenExperience && (
                <label style={{ fontSize: '12px' }} data-testid="first-payment-date" htmlFor={kebabCase(DAYS_TO_FIRST_PAYMENT)}>
                    Days to 1st Payment
                </label>
            )}
            <NumericInput
                id={kebabCase(DAYS_TO_FIRST_PAYMENT)}
                name={kebabCase(DAYS_TO_FIRST_PAYMENT)}
                value={daysToFirstPayment}
                label={DAYS_TO_FIRST_PAYMENT}
                onBlur={handleBlur}
                onChange={handleChange}
                hasError={Boolean(errorMsg)}
                displayLabel={!isBaseDealScreenExperience}
                data-testid={kebabCase(DAYS_TO_FIRST_PAYMENT)}
                errorMessage={errorMsg}
            />
        </>
    );
};

export default DaysToFirstPaymentField;
