import { Tabs } from '@interstate/components/Tabs';
import { useEffect, useState } from 'react';
import { IncentiveSelectorForm } from '../IncentiveSelector/IncentiveSelectorForm';
import { AddIncentivesTabs } from './AddIncentivesTabs.styles';
import { RebatesEdit } from '../IncentivesCard/RebateEdit/RebatesEdit';
import { baseDealActionCreators, baseDealSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';

export const IncentivesTabs = () => {
    const reduxActiveTab = useSelector(baseDealSelectors.getIncentivesSlideoutTab);
    const [activeTab, setActiveTab] = useState<string>('included');
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveTab(reduxActiveTab);
    }, [reduxActiveTab]);

    const getTabs = (activeTab: string) => {
        return [
            { component: <IncentiveSelectorForm />, label: 'Included', value: 'included' },
            {
                component: <RebatesEdit />,
                label: 'Manually Add/Edit',
                value: 'manualUpdates'
            }
        ].map((item) => ({
            ...item,
            active: activeTab === item.value
        }));
    };
    return (
        <AddIncentivesTabs>
            <Tabs
                data-testid="add-incentives-tabs"
                id="add-incentives-tabs"
                onActivate={(val) => {
                    dispatch(baseDealActionCreators.setIncentivesSlideoutTab(val));
                }}
                tabs={getTabs(activeTab)}
            />
        </AddIncentivesTabs>
    );
};
