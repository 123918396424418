// external library
import { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// interstate components
import { SlideOut } from '@interstate/components/SlideOut';

// action and selectors
import { offerReduxSelectors, rollToActionCreators, rollToSelectors } from '@makemydeal/dr-dash-store';

// custom internal components
import RollToDiscardWarning from './rollTo/RollToDiscardWarning';
import RollToCannotApplyError from './RollToCannotApplyError';

// error boundary component
import RollToAlertBoundary from './RollToAlertBoundary';
import { Box } from '@interstate/components/Box';

type RollToSlideOutProps = {
    show: boolean;
    onHide: MouseEventHandler;
    header?: string;
    children: ReactNode;
};

const RollToSlideOut: React.FC<RollToSlideOutProps> = ({ show, onHide, header, children }) => {
    const dispatch = useDispatch();
    const [hasError, setError] = useState(false);
    const [showDiscardWarning, setShowDiscardWarning] = useState(false);
    const errors = useSelector(rollToSelectors.getRollToErrors);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const hasRollToErrors = useSelector(rollToSelectors.hasRollToErrors);
    const hasAdjustedValues = useSelector(rollToSelectors.hasAdjustedValues);
    const hasUnsavedChanges = useSelector(rollToSelectors.getRollToUnsavedChanges);
    const applyRollToPayload = useSelector(rollToSelectors.getApplyRollToPayload);
    const disabledApplyButton = hasRollToErrors || !hasAdjustedValues || showDiscardWarning;

    useEffect(() => {
        setError(hasRollToErrors);
    }, [hasRollToErrors]);

    const handleCancel = (e: React.MouseEvent) => {
        if (hasUnsavedChanges) {
            setShowDiscardWarning(true);
        } else {
            onHide(e);
        }
    };

    const handleKeepEditing = () => {
        setShowDiscardWarning(false);
    };

    const handleDiscardChanges = (e: React.MouseEvent) => {
        setShowDiscardWarning(false);
        onHide(e);
    };

    const handleApplyChanges = () => {
        if (!disabledApplyButton) {
            dispatch(rollToActionCreators.applyRollToOptions(applyRollToPayload, offerType));
        }
    };

    return (
        <SlideOut
            data-testid="roll-to-slideout"
            show={show}
            position="right"
            panelWidth="40%"
            onHide={handleCancel}
            header={header}
            sx={{
                '.ids-SlideOut-content': {
                    padding: '2rem',
                    display: 'flex',
                    flexDirection: 'column'
                },
                '#ids-SlideOut-footer': {
                    marginTop: 'auto'
                }
            }}
            footer={{
                layout: 'rightAligned',
                options: [
                    {
                        action: handleCancel,
                        label: 'Cancel'
                    }
                ],
                primary: {
                    action: handleApplyChanges,
                    label: 'Apply',
                    disabled: disabledApplyButton
                }
            }}
        >
            <RollToAlertBoundary header="Error">
                {hasError && <RollToCannotApplyError errors={errors} />}
                {showDiscardWarning && (
                    <RollToDiscardWarning handleDiscardChanges={handleDiscardChanges} handleKeepEditing={handleKeepEditing} />
                )}
                <Box
                    sx={{
                        overflowY: 'auto'
                    }}
                    data-testid="roll-to-slideout-content"
                >
                    {children}
                </Box>
            </RollToAlertBoundary>
        </SlideOut>
    );
};

export default RollToSlideOut;
