/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, useMemo } from 'react';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Box } from '@interstate/components/Box';
import { TrashIcon } from '@interstate/components/Icons';
import { dateUtils } from '@makemydeal/dr-common-utils';

import { dealTemplateActionCreators, dealTemplateSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '@interstate/components/Table';
import { TemplateRemoveDrawer } from '../removeTemplate/TemplateRemoveDrawer.interstate';
import { RemovingTemplateData } from '@makemydeal/dr-dash-types';
import { TemplateRemovedAlert } from '../alerts/TemplateRemovedAlert';

interface TableData {
    id: string;
    name: string;
    creator: {
        name: string;
        date: string;
    };
    modifier?: {
        name: string;
        date: string;
    };
}

const MetaInfoCell = ({ name, date, cell, id }: { name?: string; date?: string; cell: string; id: string }) => {
    return (
        <Box data-testid={`template-${cell}-${id}`}>
            {name && <Typography variant="body-md">{name}</Typography>}
            {date && <Typography variant="body-md">{date}</Typography>}
        </Box>
    );
};

export const TemplateList = () => {
    const dispatch = useDispatch();

    const dealTemplateList = useSelector(dealTemplateSelectors.getDealTemplateList);
    const isLoading = useSelector(dealTemplateSelectors.getIsDealTemplateListLoading);
    const isTemplateRemoving = useSelector(dealTemplateSelectors.getIsDealTemplateRemoving);
    const isTemplateRemoved = useSelector(dealTemplateSelectors.getIsDealTemplateRemoved);
    const [templateToRemove, setTemplateToRemove] = useState<RemovingTemplateData | null>(null);

    useEffect(() => {
        dispatch(dealTemplateActionCreators.getDealTemplates());
    }, [isTemplateRemoved]);

    const onRemoveTemplate = (template: RemovingTemplateData) => {
        dispatch(dealTemplateActionCreators.removeDealTemplate(template));
        setTemplateToRemove(null);
    };

    const tableData: TableData[] = useMemo(() => {
        return dealTemplateList
            ? dealTemplateList.map((template) => {
                  const { id, name, createdByName, updatedByName, createdAt, updatedAt } = template;
                  return {
                      id,
                      name,
                      creator: {
                          name: createdByName,
                          date: dateUtils.formatAsMMDDYYYY(new Date(createdAt))
                      },
                      modifier:
                          updatedByName && updatedAt
                              ? {
                                    name: updatedByName,
                                    date: dateUtils.formatAsMMDDYYYY(new Date(updatedAt))
                                }
                              : undefined
                  };
              })
            : [];
    }, [dealTemplateList]);

    const columns = [
        {
            dataIndex: 'name',
            title: 'Template',
            render: (name: TableData['name'], record: TableData) => {
                const { id } = record;
                return (
                    <Action data-testid={`template-name-${id}`}>
                        <Typography variant="anchor-block-sm" color="base.color.blue.700">
                            {name}
                        </Typography>
                    </Action>
                );
            }
        },
        {
            dataIndex: 'creator',
            title: 'Creator/Date Created',
            render: (creator: TableData['creator'], record: TableData) => {
                const { id } = record;
                return MetaInfoCell({ ...creator, cell: 'creator', id });
            }
        },
        {
            dataIndex: 'modifier',
            title: 'Modifier/Date Modified',
            render: (modifier: TableData['modifier'], record: TableData) => {
                const { id } = record;
                if (!modifier)
                    return (
                        <Box data-testid={`template-modifier-${id}`}>
                            <Typography variant="body-md">-</Typography>
                        </Box>
                    );
                return MetaInfoCell({ ...modifier, cell: 'modifier', id });
            }
        },
        {
            dataIndex: 'actions',
            title: 'Actions',
            width: '15%',
            render: (_: any, record: TableData) => {
                const { id, name } = record;
                const dataTestId = `delete-deal-template-${id}`;
                return (
                    <Box sx={{ px: 1 }}>
                        <Action
                            actionIcon={{ icon: <TrashIcon />, start: true }}
                            id={dataTestId}
                            data-testid={dataTestId}
                            size="lg"
                            onClick={() => {
                                setTemplateToRemove({ id, name });
                            }}
                        />
                    </Box>
                );
            }
        }
    ];

    return (
        <>
            <TemplateRemovedAlert />
            <Table
                data-testid="deal-template-list"
                data={tableData}
                columns={columns}
                isLoading={isLoading || isTemplateRemoving}
                emptyText="No Deal Templates are found"
            />
            {!!templateToRemove && (
                <TemplateRemoveDrawer
                    template={templateToRemove}
                    hideHandler={() => setTemplateToRemove(null)}
                    removeHandler={() => onRemoveTemplate(templateToRemove)}
                />
            )}
        </>
    );
};
