import { useSelector, useDispatch } from 'react-redux';

import { Switch } from '@interstate/components/Switch';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';

import { PAYMENT_GRID } from '../../constants';
import { FC, useEffect } from 'react';
import { StateTree } from '@makemydeal/dr-dash-types';
import { OfferType } from '@makemydeal/dr-platform-types';
import { SwitchEventValue } from '@interstate/components/Switch/Types/switch';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents/Types/InterstateInputEventTypes';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { StyledPaymentGridForDealCentralScreen } from './paymentGridToggle.styles';

interface PaymentGridToggleProps {
    currentOfferType: OfferType;
    disabled?: boolean;
}

const PaymentGridToggle: FC<PaymentGridToggleProps> = ({ currentOfferType, disabled }) => {
    const dispatch = useDispatch();
    const paymentGridEnabled: boolean = useSelector((state: StateTree) =>
        offerReduxSelectors.getPaymentGridToggle(state, currentOfferType)
    );
    const isCreditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);

    useEffect(() => {
        if (isCreditDecisionEnabled && paymentGridEnabled) {
            dispatch(offerReduxActionCreators.updatePaymentGridToggle(false));
        }
    }, [isCreditDecisionEnabled, paymentGridEnabled, dispatch]);
    const isDealCentralScreen = useSelector(dealerSelectors.isDealCentralExperience);

    const isChecked = disabled ? false : paymentGridEnabled;

    const handleToggleClick = (e: InterstateOnChangeEvent<SwitchEventValue>) => {
        const checked = e.target.value === 'checked';

        logNewRelicPageAction(`MV:PaymentGrid - Payment Grid toggle ${checked}`, {
            mvOfferType: currentOfferType,
            mvFieldEdited: 'mv:payment:payment-grid-toggle',
            mvFieldValue: checked,
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updatePaymentGridToggle(checked));
    };

    const id = 'payment-grid-toggle';
    return isDealCentralScreen ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledPaymentGridForDealCentralScreen>
                <Switch
                    name={id}
                    checked={isChecked}
                    label={PAYMENT_GRID}
                    labelSx={{
                        marginBottom: '1.714rem',
                        paddingTop: '0px',
                        justifyContent: 'space-between'
                    }}
                    layout={'horizontal'}
                    onClick={handleToggleClick}
                    disabled={disabled || !!isCreditDecisionEnabled}
                    data-testid={id}
                    labelAlignment="left"
                    className="payment-grid-toggle"
                />
            </StyledPaymentGridForDealCentralScreen>
        </div>
    ) : (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Switch
                name={id}
                checked={isChecked}
                label={PAYMENT_GRID}
                labelSx={{
                    marginBottom: '16px',
                    width: 'unset'
                }}
                layout="vertical"
                onClick={handleToggleClick}
                disabled={disabled || !!isCreditDecisionEnabled}
                data-testid={id}
            />
        </div>
    );
};

export default PaymentGridToggle;
