// externals
import React from 'react';

// interstate components
import { Alert } from '@interstate/components/Alert';

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

class RollToAlertBoundary extends React.Component<React.PropsWithChildren<{ header: string }>, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return {
            hasError: true,
            error,
            errorInfo: null
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ error, errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return <Alert title="An error occurred with Roll-To" type="error"></Alert>;
        }
        return this.props.children;
    }
}

export default RollToAlertBoundary;
