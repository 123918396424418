import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dealMentionsSelectors } from '../selectors';
import { dealMentionsActionCreators } from '../actions';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

type CommentStreamInput = {
    namespace: string;
    referenceId: string;
    commonOrgId: string;
};

type CommentEvent = Event & {
    detail: {
        count: number;
        commentStream: CommentStreamInput;
    };
};

export const useDealMentionsRedDot = () => {
    const dispatch = useDispatch();
    const showMentionsDot = useSelector(dealMentionsSelectors.getShowRedDot);
    const enableDealComments = useSelector(dealerSelectors.isDealCommentsMVEnabled);

    useEffect(() => {
        if (!enableDealComments) {
            return;
        }
        const handleCommentsUpdated: EventListener = (event) => {
            const { detail } = event as CommentEvent;
            if (detail.count > 0) {
                dispatch(dealMentionsActionCreators.setShowMentionsRedDot(true));
            }
        };

        const commentElement = document.querySelector('enterprise-product-experience-contextual-comments');
        commentElement?.addEventListener('commentsUpdated', handleCommentsUpdated);

        return () => {
            commentElement?.removeEventListener('commentsUpdated', handleCommentsUpdated);
        };
    }, [dispatch, enableDealComments]);

    return showMentionsDot;
};
