import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Accordion, AccordionDetails, AccordionSummary } from '@interstate/components/AccordionGroup';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Typography } from '@interstate/components/Typography';
import {
    formatMultiCardViewPaymentDetails,
    formatPaymentDetailsCashDown,
    OpenRollToPaymentSlideOutButton
} from '@makemydeal/dr-dash-components';
import {
    deskingGlobalActionCreators,
    deskingGlobalSelectors,
    offerAdditionalSelectors,
    offerReduxSelectors,
    offerSelectors
} from '@makemydeal/dr-dash-store';

import { Box } from '@interstate/components/Box';
import { CASH } from '@makemydeal/dr-platform-types';
import InterstateDealSummary from '../dealSummary/DealSummary.interstate';
import { StyledPaymentTitle } from './DerskingCardTitle.style';

export const DeskingCardPaymentDetailsAccordion = () => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const selectedTermId = useSelector((state) => offerReduxSelectors.getTermIdByOfferType(state, currentOfferType));
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const cashAmountDue = useSelector(offerAdditionalSelectors.getMvCashAmountDue);
    // TODO: This must be scoped to avoid conflicts with other accordions, open and close all accordions instead the corresponding offer payment details accordion
    const paymentDetailsAccordionExpanded = useSelector(deskingGlobalSelectors.getPaymentDetailsAccordionExpanded);
    const amountToBePaidByCustomer = useSelector((state) => offerReduxSelectors.getDownPaymentByOfferType(state, currentOfferType));

    const paymentDetailCashDownLabel = useMemo(() => {
        return formatPaymentDetailsCashDown(currentOfferType, amountToBePaidByCustomer);
    }, [currentOfferType, amountToBePaidByCustomer]);

    const paymentDetailLabel = useMemo(() => {
        return formatMultiCardViewPaymentDetails(currentOfferType, monthlyPayment, cashAmountDue, selectedTermId);
    }, [currentOfferType, monthlyPayment, cashAmountDue, selectedTermId]);

    const onAccordionChange = () => {
        // TODO: This must be scoped to avoid conflicts with other accordions, open and close all accordions instead the corresponding offer payment details accordion
        dispatch(deskingGlobalActionCreators.togglePaymentDetailsAccordion());
    };

    return (
        <>
            <Accordion
                contained={true}
                onChange={onAccordionChange}
                expanded={paymentDetailsAccordionExpanded}
                data-testid="desking-card-payment-details-accordion"
                sx={{ height: '100%' }}
            >
                <AccordionSummary
                    chevronPosition="left"
                    sx={{ padding: theme.tokens.ComponentCardPadding, alignItems: 'flex-start' }}
                >
                    <StyledPaymentTitle theme={theme} data-testid="desking-card-payment-details" className={'multi-card-view'}>
                        <Typography data-testid="desking-card-payment-details-title" variant="h5">
                            Payment Details
                        </Typography>
                        <Box style={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                            <Typography data-testid="desking-card-payment-details-label" variant="h4" sx={{ fontWeight: 600 }}>
                                {paymentDetailLabel}
                            </Typography>
                            {currentOfferType !== CASH && (
                                <Typography
                                    className="no-inherit-cash-down-label"
                                    data-testid="desking-card-payment-details-cash-down-label"
                                    variant="body-xs"
                                >
                                    {paymentDetailCashDownLabel}
                                </Typography>
                            )}
                        </Box>
                    </StyledPaymentTitle>
                    <OpenRollToPaymentSlideOutButton />
                </AccordionSummary>
                <AccordionDetails>
                    <InterstateDealSummary />
                </AccordionDetails>
            </Accordion>
        </>
    );
};
