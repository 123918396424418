import React from 'react';
import { useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { TableHead as InterstateTableHead, TableRow } from '@interstate/components/SimpleTable';
import { StyledTableCell, StyledTableCellWithBorder, StyledTableCellWithoutBorder } from './TaxCard.style';

export type SimpleTableHeadColumn = {
    heading: string;
    options?: {
        align?: string;
        width?: string;
    };
};

export type SimpleTableHeadProps = {
    columns: SimpleTableHeadColumn[];
};
export const TableHead: React.FC<SimpleTableHeadProps> = ({ columns }) => {
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const StyledTableCellBorder = isBaseDealScreenExperience ? StyledTableCellWithBorder : StyledTableCellWithoutBorder;
    return (
        <InterstateTableHead>
            <TableRow>
                {columns.map(({ heading, options }, index) => (
                    <StyledTableCellBorder key={`head-cell-${index}`} $width={options?.width}>
                        <StyledTableCell $textAlign={options?.align}>{heading}</StyledTableCell>
                    </StyledTableCellBorder>
                ))}
            </TableRow>
        </InterstateTableHead>
    );
};
export default TableHead;
