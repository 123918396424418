import React, { FC } from 'react';
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';
import { useExpandableArea } from '../../common/ExpandableArea';

const ChevronIcon: FC<{
    expandableId: string;
    isDealSummaryDetailsExpandId?: boolean;
    isBaseDealScreenExperience?: boolean;
}> = ({ expandableId, isDealSummaryDetailsExpandId, isBaseDealScreenExperience }) => {
    const { isExpanded, isAllExpanded } = useExpandableArea();
    const expanded = isDealSummaryDetailsExpandId ? isAllExpanded() : isExpanded(expandableId);

    return (
        <ChevronRightIcon
            style={{
                transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                marginRight: isBaseDealScreenExperience ? '1.071rem' : '0.286rem'
            }}
            className="chevron-icon"
            data-testid={expandableId}
        />
    );
};

export default ChevronIcon;
