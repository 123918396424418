import React from 'react';
import { useSelector } from 'react-redux';
import { printPdfSelectors } from '@makemydeal/dr-dash-store';
import { Snackbar } from '@interstate/components/Snackbar';
import { SnackbarWrapper } from '../baseDealFooter/Footer.style.interstate';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

export const PrintPdfLoadingModal = () => {
    const isLoading = useSelector(printPdfSelectors.isLoading);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    return (
        <SnackbarWrapper>
            <Snackbar
                data-testid="print-pdf-loading-message"
                position="bottom-center"
                show={isLoading}
                message="We're currently printing your offer details"
                autoHide={false}
                className={isBaseDealScreenExperience ? 'print-snackbar-position' : ''}
            />
        </SnackbarWrapper>
    );
};
