import { ITradeInState } from '@makemydeal/dr-activities-common';

import * as tradeActionTypes from '../../actionTypes/tradeActionTypes';
import { TradeAction } from '../../types';

export const tradeFlowComplete = () => {
    return {
        type: tradeActionTypes.TRADE_FLOW_COMPLETED
    };
};

export const updateTradeIn = (payload: ITradeInState) => ({
    type: tradeActionTypes.UPDATE_TRADE_IN,
    payload
});

export const allowanceChanged = (payload: number, skipPayment: boolean = false): TradeAction => {
    return {
        type: tradeActionTypes.ALLOWANCE_CHANGED,
        payload,
        meta: {
            middleware: {
                skipPayment
            }
        }
    };
};

export const payoffChanged = (payload: number): TradeAction => {
    return {
        type: tradeActionTypes.PAYOFF_CHANGED,
        payload
    };
};

export const acvChanged = (payload: number): TradeAction => {
    return {
        type: tradeActionTypes.ACV_CHANGED,
        payload
    };
};
