import {
    baseDealActionCreators,
    baseDealAnalyticsActionCreators,
    deskingSelectors,
    tradeActionTypes,
    tradeInSelectors,
    tradeShopperActivity
} from '@makemydeal/dr-dash-store';
import { GENERIC_LAST_FIELD_CHANGED_ERROR, usePaymentCall } from '@makemydeal/dr-dash-components';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { useDispatch, useSelector } from 'react-redux';
import { NumericInput } from '@interstate/components/NumericInput';
import { BaseTabs } from '../../baseDealTypes';

const Payoff = () => {
    const {
        value: payoff,
        handleChange: changePayoff,
        handleBlur: payoffBlur
    } = usePaymentCall(tradeInSelectors.getTradeInAmountOwed, tradeShopperActivity.tradeActionCreators.payoffChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const failedPayoff = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.PAYOFF_CHANGED));
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(baseDealActionCreators.handleTabChange(BaseTabs.TRADE_IN));
        dispatch(baseDealAnalyticsActionCreators.tradeInTabClicked());
    };

    return (
        <>
            <label
                data-testid={`payoff-input-label`}
                style={{
                    color: '#005BA8',
                    cursor: 'pointer',
                    marginTop: '-10px'
                }}
                onClick={handleClick}
            >
                Payoff
            </label>
            <NumericInput
                inputPrefix={'$'}
                autoInsertCommas
                allowDecimal
                decimalMaxLength={2}
                decimalMinLength={2}
                data-testid="payoff"
                name={kebabCase('payoff')}
                onChange={changePayoff}
                placeholder="10,000.00"
                value={payoff}
                hasError={failedPayoff}
                errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                onBlur={payoffBlur}
                displayLabel={false}
            />
        </>
    );
};

export default Payoff;
