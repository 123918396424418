// interfaces/types
import { CalculationEndpointType, VehicleInsurance } from '@makemydeal/dr-platform-types';
import type { SurchargeOverrides } from '@makemydeal/dr-shared-types';

export type DealXgDetails = {
    globalCustomerId?: string;
};

// TODO: Reconcile DealXgDetails and AcceleronDealXgDetails - are they meant to be the same thing - i.e. is DealXgDetails just
//   missing some of the fields and we can add them to it and remove this interface below?  Also, if this type is really for
//   another service (acceleron) then this type may actually belong in dash-gateway-types
export type AcceleronDealXgDetails = {
    globalCustomerId?: string;
    redirectionLink?: string;
    omsOrderNumber?: boolean;
};

export type DealLifecycle = {
    status?: string;
    createdDate?: string;
    contractSignedDate?: string;
    buyerAcceptedDate?: string;
    soldDate?: string;
    deliveredDate?: string;
    approvedDate?: string;
    recappedDate?: string;
    finalizedDate?: string;
    fundedDate?: string;
    unwoundDate?: string;
    cancelledDate?: string;
};

export type AcceleronData = {
    activityMilestone: any;
    config?: any;
    dealer: any;
    errors: any[] | any;
    featureToggles: any;
    menu: any;
    meta?: any;
    offer: any;
    success?: boolean;
    terms: any;
    vehicle: any;
    calculationDetails?: CalculationDetails;
    vehicleInsurance?: VehicleInsurance;
};

export enum DcVppVehicleUsage {
    PERSONAL = 'Personal',
    BUSINESS = 'Business',
    AGRICULTURAL = 'Agricultural'
}

export type DealVehicleProtection = {
    products: DealVehicleProtectionProduct[];
    surchargesOverrides: SurchargeOverrides;
};

export type DealVehicleProtectionProduct = {
    selected: boolean;
    productName?: string;
    productPrice: number;
    productCategoryCode: string;
    profitLocation?: string;
    productCode?: string;
    productCost?: number;
    providerCode?: string;
    productProviderName?: string;
    productDeductible?: number;
    productCoverage?: string;
    productCoverageEndDate?: string;
    productCoverageLength?: number;
    productCoverageMiles?: number;
    productMaxCoverage?: number;
    productProviderPlan?: string;
    isWeOwe?: boolean;
    weOweItemId?: number;
    maxPriceAllowedByLender?: number;
    priceReducedToLenderMax?: number;
    productTaxRate?: number;
    productTaxAmount?: number;
    isProductTaxCapitalized?: boolean;
    isProductPriceCapitalized?: boolean;
    productResidualValue?: number;
    productResidualType?: string;
};

export enum ProductCalculationEngineService {
    COX_FIE = 'CoxFIExpress',
    PROVIDER_EXCHANGE_NETWORK = 'ProviderExchangeNetwork',
    ROUTE_1 = 'RouteOne',
    STONE_EAGLE = 'StoneEagle',
    OTHER = 'Other'
}

export type ProductRequestOverrides = {
    estimatedContractDate?: string;
    estimatedInServiceDate?: string;
    estimatedPurchaseDate?: string;
    includesWarranty?: boolean;
    includesSealsAndGasketsMaintenance?: boolean;
};

export type CalculationSourceIds = {
    partnerCode?: string;
    partnerDealerId?: string | number;
};

export type CalculationDetails = {
    payment?: {
        calculationEngine?: {
            service?: string;
            endpoint?: CalculationEndpointType;
        };
        calculationSourceIds?: CalculationSourceIds;
        calculationSettings?: {
            useDealerDefaultTaxes?: boolean;
            useDealerDefaultDealerBasedTaxes?: boolean;
            useDealerDefaultLenderFees?: boolean;
            useDealerDefaultDealerFees?: boolean;
            useDealerDefaultGovFees?: boolean;
            useCalculatedGovFees?: boolean;
            cashApplyMethod?: string;
        };
        calculationOverrides?: {
            buyRate?: boolean;
            creditTier?: boolean;
            lenderDiscount?: boolean;
            retailPrice?: boolean;
            totalSecurityDeposit?: boolean;
            residualValue?: boolean;
            residualType?: string;
            sellRate?: boolean;
        };
    };
    products?: {
        calculationEngine?: {
            service?: ProductCalculationEngineService;
            endpoint?: string;
        };
        calculationSourceIds?: CalculationSourceIds;
        calculationOverrides?: ProductRequestOverrides;
    };
};
