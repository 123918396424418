// externals
import type { Reducer } from 'redux';

// consts/enums
import { BaseTabs } from '../actionTypes/baseDealTypes';
import {
    SET_BREAKDOWN,
    TAB_CHANGE,
    SAVE_VEHICLE_INSURANCE_INFO,
    SHOW_VEHICLE_INSURANCE,
    SHOW_BREAKDOWN_SLIDEOUT,
    INCENTIVES_SLIDEOUT_TAB_CHANGE
} from '../actionTypes/baseDealActionTypes';

// libraries
import { initActionTypes } from '@makemydeal/dr-shared-store';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

type BaseDealState = {
    activeTab: string;
    showBreakdown: boolean;
    vehicleInsuranceInfo: null;
    showVehicleInsurance: boolean;
    showBreakdownSlideout: boolean;
    incentivesSlideout: {
        activeTab: 'included' | 'manualUpdates';
    };
};

export const BASE_DEAL_INITIAL_STATE: BaseDealState = {
    activeTab: BaseTabs.SUMMARY,
    showBreakdown: false,
    vehicleInsuranceInfo: null,
    showVehicleInsurance: false,
    showBreakdownSlideout: false,
    incentivesSlideout: {
        activeTab: 'included'
    }
};

export type BaseDealReducer = Reducer<BaseDealState>;

export const reducer: Reducer<BaseDealState> = (state: BaseDealState = BASE_DEAL_INITIAL_STATE, action: any) => {
    switch (action.type) {
        case TAB_CHANGE:
            return {
                ...state,
                activeTab: action.payload.activeTab
            };
        case SET_BREAKDOWN:
            return {
                ...state,
                showBreakdown: action.payload.showBreakdown
            };
        case SAVE_VEHICLE_INSURANCE_INFO: {
            return {
                ...state,
                vehicleInsuranceInfo: action.payload
            };
        }
        case SHOW_VEHICLE_INSURANCE:
            return {
                ...state,
                showVehicleInsurance: action.payload.showVehicleInsurance
            };

        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                vehicleInsuranceInfo: action.payload.vehicleInsurance
            };
        }

        case SHOW_BREAKDOWN_SLIDEOUT:
            return {
                ...state,
                showBreakdownSlideout: action.payload.showBreakdownSlideout
            };

        case INCENTIVES_SLIDEOUT_TAB_CHANGE: {
            return {
                ...state,
                incentivesSlideout: {
                    activeTab: action.payload.activeTab
                }
            };
        }

        default:
            return state;
    }
};
