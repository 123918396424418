// externals
import { isEqual } from 'lodash';
import { FC, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Custom internal components
import RollToPaymentGrid from './RollToPaymentGrid';
import RollToPaymentHeader from './RollToPaymentHeader';

// interstate components
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue, TextInputValue } from '@interstate/components/TextInput';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// selectors and actions
import { offerReduxSelectors, rollToActionCreators, rollToSelectors, tradeInSelectors } from '@makemydeal/dr-dash-store';

// types
import { OfferTypeEnum } from '@makemydeal/dr-platform-types';
import { ROLL_TO_OPTIONS_LABELS } from '@makemydeal/dr-shared-types';
import { debounce } from 'lodash';
import { Row } from '../../types/RollToPaymentOptionRow';
import { ADJUSTABLE_FIELDS } from './constants/adjustableFields';

const headers = ['', 'Current', 'Roll-To', 'Adjust'];
const defaultDebounceDuration = 1000;
// empty cell display
const emptyCell = '--';

type RollToPaymentOptionsProps = {
    activeTab: ROLL_TO_OPTIONS_LABELS;
};

const RollToPaymentOptions: FC<RollToPaymentOptionsProps> = ({ activeTab }) => {
    const dispatch = useDispatch();
    const values = useRef<Record<number, number | undefined> | null>(null);
    const rollToOptionsItems = useSelector(rollToSelectors.getRollToOptionsItems);
    const hasRollToOptions = useSelector(rollToSelectors.hasRollToOptionsItems);
    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const isLease = offerType === OfferTypeEnum.LEASE;
    const isBalanceDueTab = activeTab === ROLL_TO_OPTIONS_LABELS.BALANCE_DUE;
    const isPaymentTab = activeTab === ROLL_TO_OPTIONS_LABELS.PAYMENT;
    const { rollTo, current, adjust } = rollToOptionsItems;

    // term
    const currentTerm = current?.term || 0;
    const adjustTerm = adjust?.term || 0;
    const rollToTerm = rollTo?.term || 0;

    // rate
    const currentRate = current?.rate || 0;
    const adjustRate = adjust?.rate || 0;
    const rollToRate = rollTo?.rate || 0;

    // down payment
    const currentDownPayment = current?.downPayment || 0;
    const adjustDownPayment = adjust?.downPayment || 0;
    const rollToDownPayment = rollTo?.downPayment || 0;

    // selling price
    const currentSellingPrice = current?.sellingPrice || 0;
    const adjustSellingPrice = adjust?.sellingPrice || 0;
    const rollToSellingPrice = rollTo?.sellingPrice || 0;

    // trade allowance
    const currentTradeAllowance = current?.trade || 0;
    const adjustAllowance = adjust?.trade || 0;
    const rollToTradeAllowance = rollTo?.trade || 0;

    // taxes & fees
    const currentTaxesAndFeesAsStr = formatDollarsAndCents(current?.taxesAndFees?.toString() || '');
    const adjustTaxesAndFeesAsStr = formatDollarsAndCents(adjust?.taxesAndFees?.toString());

    // add ons
    const addOnsAsStr = formatDollarsAndCents(current?.addOns?.toString() || '');
    const adjustAddOnsAsStr = formatDollarsAndCents(adjust?.addOns?.toString() || '');

    // action values
    const currentActionValue = formatDollarsAndCents(current?.actionValue?.toString() || '');
    const adjustActionValue = formatDollarsAndCents(adjust?.actionValue?.toString() || '');
    const rollToActionValue = formatDollarsAndCents(rollTo?.actionValue?.toString() || '');

    const rollToRows = useMemo(() => {
        let rows: Row[] = [
            {
                label: ADJUSTABLE_FIELDS.TERM,
                current: currentTerm,
                rollTo: rollToTerm,
                key: 'term',
                adjust: adjustTerm,
                isAdjustableField: true
            },
            {
                label: ADJUSTABLE_FIELDS.APR,
                current: currentRate,
                rollTo: rollToRate,
                key: 'rate',
                adjust: adjustRate,
                isAdjustableField: true
            },
            {
                label: ADJUSTABLE_FIELDS.DOWN_PAYMENT,
                current: currentDownPayment,
                rollTo: rollToDownPayment,
                adjust: adjustDownPayment,
                key: 'downPayment',
                isAdjustableField: true
            },
            {
                label: ADJUSTABLE_FIELDS.SELLING_PRICE,
                current: currentSellingPrice,
                rollTo: rollToSellingPrice,
                adjust: adjustSellingPrice,
                key: 'sellingPrice',
                isAdjustableField: true
            },
            {
                label: ADJUSTABLE_FIELDS.TRADE_1_ALLOWANCE,
                current: currentTradeAllowance,
                rollTo: rollToTradeAllowance,
                adjust: adjustAllowance,
                key: 'trade',
                isAdjustableField: true
            },
            {
                label: 'Total Taxes and Fees',
                current: currentTaxesAndFeesAsStr,
                rollTo: emptyCell,
                adjust: adjustTaxesAndFeesAsStr,
                key: 'taxesAndFees'
            },
            {
                label: 'Total Add Ons',
                current: addOnsAsStr,
                rollTo: emptyCell,
                adjust: adjustAddOnsAsStr,
                dividerBelow: true,
                key: 'addOns'
            },
            {
                // @note the label must be dynamic, since depends on the current tab
                label: activeTab,
                current: currentActionValue,
                rollTo: rollToActionValue,
                adjust: adjustActionValue,
                key: 'actionValue',
                bold: true
            }
        ];

        if (!isTradeInCompleted) {
            rows = rows.filter((rollToRow) => rollToRow.key !== 'trade');
        }
        if (isLease && (isPaymentTab || isBalanceDueTab)) {
            rows = rows.filter((rollToRow) => rollToRow.key !== 'term');
        }
        if (!isLease && isBalanceDueTab) {
            rows = rows.filter((rollToRow) => rollToRow.key !== 'rate');
        }

        return rows;
    }, [
        currentTerm,
        rollToTerm,
        adjustTerm,
        currentRate,
        rollToRate,
        adjustRate,
        currentDownPayment,
        rollToDownPayment,
        adjustDownPayment,
        currentSellingPrice,
        rollToSellingPrice,
        adjustSellingPrice,
        currentTradeAllowance,
        rollToTradeAllowance,
        adjustAllowance,
        currentTaxesAndFeesAsStr,
        adjustTaxesAndFeesAsStr,
        addOnsAsStr,
        adjustAddOnsAsStr,
        activeTab,
        currentActionValue,
        rollToActionValue,
        adjustActionValue,
        isTradeInCompleted,
        isPaymentTab,
        isBalanceDueTab,
        isLease
    ]);

    const isCurrentEqualToAdjust = useMemo(() => isEqual(current, adjust), [adjust, current]);

    const handleUpdateRollToAdjust = debounce(
        (newValues: Record<string, number | undefined>) => {
            dispatch(
                rollToActionCreators.updateRollToAdjust(newValues, {
                    actionValueType: activeTab
                })
            );
            dispatch(rollToActionCreators.setUnsavedChanges(true));
        },
        defaultDebounceDuration,
        { trailing: true }
    );

    const handleChangeAdjustedValue = (index: number, event: InterstateOnChangeEvent<TextInputEventValue>) => {
        const newValue = event.target.value.toString();

        const isInvalidValue = typeof newValue === 'undefined' || newValue === '' || isNaN(parseFloat(newValue));

        if (isInvalidValue) {
            handleUpdateRollToAdjust.cancel();
            return;
        }

        const newRollToValues = {
            ...values.current,
            [rollToRows[index].key]: parseFloat(newValue)
        };

        values.current = newRollToValues as Record<number, number | undefined>;

        handleUpdateRollToAdjust(newRollToValues);
    };

    const handleRevertToOriginal = () => {
        dispatch(
            rollToActionCreators.revertRollToOriginal({
                actionValueType: activeTab
            })
        );
        dispatch(rollToActionCreators.setUnsavedChanges(false));
        values.current = null;
    };

    if (!hasRollToOptions) return null;

    return (
        <>
            <RollToPaymentHeader
                gridCategory={'Roll-To Payment'}
                gridAction={handleRevertToOriginal}
                actionMessage={'Revert To Original'}
                disabled={isCurrentEqualToAdjust}
            />
            <RollToPaymentGrid headers={headers} rows={rollToRows} getAdjustedFieldInputValue={handleChangeAdjustedValue} />
        </>
    );
};

export default RollToPaymentOptions;
