import { useMemo } from 'react';
import { isEqual } from 'lodash';

import { kebabCase } from '@makemydeal/dr-common-utils';
import { GENERIC_LAST_FIELD_CHANGED_ERROR, MILES_LABEL, MILES_PER_YEAR } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { SelectInput } from '@interstate/components/SelectInput';
import { offerReduxSelectors, offerReduxActionCreators, deskingSelectors, offerActionTypes } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const MilesPerYearField = ({ isSummaryTab }: { isSummaryTab?: boolean }) => {
    const dispatch = useDispatch();
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const selected = useSelector(offerReduxSelectors.getAnnualMiles);
    const annualMilesOptions = useSelector(offerReduxSelectors.getAnnualMilesOptions, isEqual);
    const leaseMileageOptions = annualMilesOptions.map(({ id, name }) => ({
        id,
        label: name.toString(),
        value: id.toString()
    }));

    const handleValueChange = (event: any) => {
        const selectedMileage = event.target.value;

        if (selectedMileage) {
            dispatch(offerReduxActionCreators.selectedAnnualMiles(selectedMileage));
        }
    };

    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.SELECTED_ANNUAL_MILES));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    return (
        <SelectInput
            id="MilesPerYear"
            label={isBaseDealScreenExperience && isSummaryTab ? `${MILES_LABEL}/yr` : MILES_PER_YEAR}
            name={kebabCase(MILES_PER_YEAR)}
            onChange={handleValueChange}
            value={selected.toString()}
            options={leaseMileageOptions}
            errorMessage={failedMessage}
            hasError={failedMessage ? true : false}
            displayDeselectOption={false}
            data-testid={kebabCase(MILES_PER_YEAR)}
        />
    );
};

export default MilesPerYearField;
