import { Grid } from '@interstate/components/Grid';
import { ComponentProps, ReactNode } from 'react';

type LineItemRowProps = {
    styles?: ComponentProps<typeof Grid>['sx'];
    children: ReactNode;
};

const LineItemRow = ({ children, styles }: LineItemRowProps) => (
    <Grid
        container
        sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingY: '0.857rem',
            pr: '1.143rem',
            ...styles
        }}
    >
        {children}
    </Grid>
);

export default LineItemRow;
