import { memo } from 'react';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import IconInfoOutline from '@cx/ui/Icons/IconInfoOutline';

import { IncentiveTotalsContainer, IncentiveTotalsRow, IncentiveTotalsColumn } from './RebatesTotals.interstate.styles';
import { RebatesTotalsProps } from './RebatesTotalsProps';
import { Typography } from '@interstate/components/Typography';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useBaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';

export const RebatesTotals = memo(function RebatesTotals({
    dealerCashTotal = 0,
    currentEditedIncentivesTotal = 0,
    currentAppliedIncentiveTotal = 0,
    showEditTotals = false,
    showRebatesTotal = false,
    hideInfoIcon
}: RebatesTotalsProps) {
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const BaseBreakPoint = useBaseDealBreakpoint();
    const typographyVariantLabel = !isBaseDealScreenExperience ? 'body-sm' : 'body-md';
    const typographyVariantValue = !isBaseDealScreenExperience ? 'strong-sm' : 'strong-md';

    return (
        <IncentiveTotalsContainer>
            <IncentiveTotalsColumn isBaseDealScreenExperience={isBaseDealScreenExperience} BaseBreakPoint={BaseBreakPoint}>
                {showEditTotals && (
                    <>
                        <IncentiveTotalsRow>
                            <Typography variant={typographyVariantLabel}>Dealer Cash Total</Typography>
                            <Typography variant={typographyVariantValue} data-testid="incentives-dealer-cash-total">
                                {formatDollarsAndCents(dealerCashTotal)}
                            </Typography>
                        </IncentiveTotalsRow>
                        <IncentiveTotalsRow>
                            <Typography variant={typographyVariantLabel}>Incentives Total</Typography>
                            <Typography variant={typographyVariantValue} data-testid="incentives-total">
                                {formatDollarsAndCents(currentEditedIncentivesTotal)}
                            </Typography>
                        </IncentiveTotalsRow>
                    </>
                )}
                {showRebatesTotal && (
                    <IncentiveTotalsRow>
                        <Typography variant={typographyVariantLabel} sx={{ alignItems: 'center', display: 'flex' }}>
                            Current total
                            {!hideInfoIcon && <IconInfoOutline />}
                        </Typography>
                        <Typography variant={typographyVariantValue} data-testid="incentives-current-total">
                            {formatDollarsAndCents(currentAppliedIncentiveTotal)}
                        </Typography>
                    </IncentiveTotalsRow>
                )}
            </IncentiveTotalsColumn>
        </IncentiveTotalsContainer>
    );
});
