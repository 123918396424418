// externals
import { FC, useMemo } from 'react';

// libraries
import { TextInput } from '@interstate/components/TextInput';
import { customInterstateValidationRules as rules } from '@makemydeal/dr-dash-components';

// interfaces/types
import {
    ColumnMapping,
    InputChangeInterstateEventHandler,
    ValidateInterstateValidationRulesHandler
} from '../manualAccessoriesTypes';

// consts/enums
import * as deskingConstants from '../../../../constants';

export type ManualAccessoryIdFieldStateProps = {
    deleted: boolean;
    index: number;
    code: string | undefined;
    idColumnMapping: ColumnMapping<string>;
};

export type ManualAccessoryIdFieldDispatchProps = {
    handleInputChange: InputChangeInterstateEventHandler;
    validateRules: ValidateInterstateValidationRulesHandler;
};

export type ManualAccessoryIdFieldProps = ManualAccessoryIdFieldDispatchProps & ManualAccessoryIdFieldStateProps;

export const ACCESSORY_ID_MAX_LENGTH = 128;

const getValidationRules = () => {
    const maxLength = rules.getMaxLengthValidationRule(
        ACCESSORY_ID_MAX_LENGTH,
        `Value should be less than ${ACCESSORY_ID_MAX_LENGTH} symbols.`
    );

    return [maxLength];
};

export const ManualAccessoryIdField: FC<ManualAccessoryIdFieldProps> = ({
    deleted,
    index,
    code,
    idColumnMapping: { fieldName },
    handleInputChange,
    validateRules
}) => {
    const validationRules = useMemo(getValidationRules, []);

    return (
        <TextInput
            displayLabel={true}
            data-testid={`${fieldName}-${index}`}
            label={deskingConstants.ID}
            className="base-accessories-label"
            name={`${fieldName}-${index}`}
            onChange={(e) => {
                handleInputChange(e, fieldName);
            }}
            value={code as string}
            disabled={deleted}
            getError={(_label, value) => {
                return validateRules(value, validationRules, fieldName, false);
            }}
        />
    );
};
