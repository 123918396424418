// externals
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// components
import { LoadingSectionParent, PaymentLoading, FlexCol } from '@makemydeal/dr-dash-components';
import { CardHeader } from '@interstate/components/Card';

import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Badge } from '@interstate/components/Badge';
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { Alert } from '@interstate/components/Alert';
import TriforceWidget from './triforceWidget/TriforceWidget';
import { Button } from '@interstate/components/Button';

// styling
import {
    DetailContainer,
    StyledApplyChangesButtonContainer,
    TradeCheckboxContainer,
    TradeInSeparator,
    VehicleDetailContent
} from './trade.interstate.style';

// selectors
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import {
    configSelectors,
    tradeInSelectors,
    tradeInCardSelectors,
    vAutoTriforceSelectors,
    tradeInActionCreators
} from '@makemydeal/dr-dash-store';

// constants
import { LEASE, LEASE_TRADE, INCLUDE_TRADE_WITH_OFFER, APPLY_CHANGES } from '../../constants';
import { LienholderAddressKeys } from './lienholderForm/constants';

// actions
import { tradeInCardActionCreators } from '@makemydeal/dr-dash-store';

import ManualTradeIn from './manualTradeIn/ManualTradeIn.interstate';

import { TradeInValuation } from './valuation/TradeInValuation';
import { StretchHeightCard } from '../deskingActivity/DeskingCard.style';

// utils
import { hasEmptyField } from './manualTradeIn/utils';
import { getLienholderFormData } from './lienholderForm/utils';

// interfaces/types
import { LienholderFormData } from './lienholderForm/types';

export interface TradeCardProps {
    isEditDisabled?: boolean;
}

const TradeCard = ({ isEditDisabled = false }: TradeCardProps) => {
    const dispatch = useDispatch();
    const isTradeActivityEnabledAfterBootstrap = useSelector(featureToggleSelectors.isTradeActivityEnabledAfterBootstrap);

    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);

    const vin = useSelector(tradeInSelectors.getVin);
    const showVinDecodeError = useSelector(tradeInSelectors.getErrorDecodingVin);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isManualFormOpen = useSelector(tradeInCardSelectors.isManualFormOpen) || isBaseDealScreenExperience;
    const ownershipType = useSelector(tradeInSelectors.getTradeOwnershipType);
    const isLeaseTrade = ownershipType === LEASE ? true : false;
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);
    const vehicleMileage = useSelector(tradeInSelectors.getTradeMileage);
    const shouldIncludeTradeIn = useSelector(tradeInCardSelectors.getShouldIncludeTradeIn);
    const includeTradeChecked = shouldIncludeTradeIn ? 'includeTrade' : '';

    const vAutoTriforceWidgetData = useSelector(vAutoTriforceSelectors.getVAutoTriforceData);
    const vAutoTriforceScriptUrl = useSelector(configSelectors.getVAutoTriforceScriptUrl);
    const sourceLienholderData = useSelector(tradeInSelectors.getLienholderFull);
    const [lienholderData, setLienholderData] = useState<LienholderFormData>(getLienholderFormData(sourceLienholderData));
    const [enableApplyChangesButton, setEnableApplyChangesButton] = useState(true);
    const { lienholderAccountNumber, lienholderAddress } = lienholderData;

    const handleTrade = () => {
        if (isTradeActivityEnabledAfterBootstrap) {
            dispatch(tradeInCardActionCreators.toggleManualTradeForm());
        }
    };

    const getTradeInTitle = (): string => {
        if (isTradeInCompleted && !isManualFormOpen) {
            return 'View/Edit';
        } else if (!isTradeInCompleted && !isManualFormOpen) {
            return 'Add';
        } else {
            return '';
        }
    };

    const handleCheckboxChange: InterstateOnChangeCallback<CheckBoxEventValue> = (
        event: InterstateOnChangeEvent<CheckBoxEventValue>
    ) => {
        /* istanbul ignore next */
        const isTradeInIncluded = event.target.value?.checked;
        dispatch(tradeInCardActionCreators.includeTradeChanged(!!isTradeInIncluded));
    };

    const areRequiredFieldsEmpty = hasEmptyField({
        vehicleMake,
        vehicleMileage,
        vehicleModel,
        vehicleTrim,
        vehicleYear
    });

    const handleApplyChanges = () => {
        dispatch(tradeInActionCreators.tradeDetailsManualEntrySave());
        dispatch(
            tradeInActionCreators.updateTradeInLienholderFull({
                lienHolder: lienholderData.lienholder,
                lienHolderAccountNumber: lienholderData.lienholderAccountNumber,
                lienHolderPhoneNumber: lienholderData.lienholderPhoneNumber,
                lienHolderAddress: lienholderData.lienholderAddress,
                goodThroughDate: lienholderData.payoffDueDate && lienholderData.payoffDueDate.toDateString(),
                perDiemInterest: lienholderData.perDiem
            })
        );
    };

    useEffect(() => {
        if (isBaseDealScreenExperience) {
            const newErrors: Record<string, boolean> = {
                ...(!lienholderAccountNumber && { lienholderAccountNumber: true })
            };

            for (const key of LienholderAddressKeys) {
                if (!lienholderAddress[key]) {
                    newErrors[key] = true;
                }
            }

            const errorLength = Object.keys(newErrors).length;

            if (errorLength > 0 && errorLength < 5) {
                setEnableApplyChangesButton(false);
            } else {
                setEnableApplyChangesButton(true);
            }
        }
    }, [lienholderAccountNumber, lienholderAddress]);

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="trade-loading-indicator" />
            <StretchHeightCard
                data-testid="trade-card"
                containername="trade-card"
                header={
                    <CardHeader
                        title={
                            isBaseDealScreenExperience ? (
                                <Typography variant="h3">Trade In </Typography>
                            ) : (
                                <Typography variant="h5">Trade-In</Typography>
                            )
                        }
                        action={
                            <Action onClick={handleTrade} data-testid="trade-card-edit-action" disabled={isEditDisabled}>
                                <Typography
                                    variant="anchor-block-sm"
                                    tag={isEditDisabled ? 'div' : 'a'}
                                    color={isEditDisabled ? 'base.color.gray.400' : 'base.color.blue.700'}
                                >
                                    {!isBaseDealScreenExperience && getTradeInTitle()}
                                </Typography>
                            </Action>
                        }
                    />
                }
                content={
                    <FlexCol>
                        {showVinDecodeError && (
                            <Alert type="error" role="alert">
                                <Typography variant="body-sm" tag="span">
                                    There was a problem decoding the VIN. Please try again.
                                </Typography>
                            </Alert>
                        )}
                        {isLeaseTrade && !isManualFormOpen && !isBaseDealScreenExperience && (
                            <Badge sx={{ width: 'fit-content' }}>
                                <Typography variant="body-sm" tag="span">
                                    {LEASE_TRADE}
                                </Typography>
                            </Badge>
                        )}
                        {!isManualFormOpen && !isBaseDealScreenExperience && (
                            <>
                                {isTradeInCompleted ? (
                                    <VehicleDetailContent data-testid="vehicle-detail-content">
                                        <DetailContainer>
                                            <Typography
                                                variant="body-md"
                                                tag="span"
                                                data-testid="trade-card-subtitle"
                                            >{`${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`}</Typography>
                                            {vin && (
                                                <Typography variant="body-md" tag="span" data-testid="trade-card-subtitle-vin">
                                                    {`VIN: ${vin}`}
                                                </Typography>
                                            )}
                                        </DetailContainer>
                                        {vAutoTriforceWidgetData && (
                                            <TriforceWidget
                                                displayData={vAutoTriforceWidgetData}
                                                scriptUrl={vAutoTriforceScriptUrl}
                                            />
                                        )}
                                    </VehicleDetailContent>
                                ) : (
                                    <Typography variant="body-md" tag="div">
                                        No vehicle information
                                    </Typography>
                                )}
                            </>
                        )}
                        <ManualTradeIn />
                        {isManualFormOpen && !isBaseDealScreenExperience && <TradeInSeparator />}
                        <TradeInValuation
                            isEditDisabled={isEditDisabled}
                            lienholderData={lienholderData}
                            setLienholderData={setLienholderData}
                        />
                        <TradeInSeparator />
                        <TradeCheckboxContainer>
                            <CheckBox
                                checked={shouldIncludeTradeIn}
                                label={INCLUDE_TRADE_WITH_OFFER}
                                onChange={handleCheckboxChange}
                                value={includeTradeChecked}
                                disabled={isBaseDealScreenExperience ? false : !isTradeInCompleted}
                            />
                            <Typography variant="body-xxs" tag="div">
                                *Vehicle information is required to include the trade with the offer.
                            </Typography>
                        </TradeCheckboxContainer>
                        {isBaseDealScreenExperience && (
                            <StyledApplyChangesButtonContainer>
                                <Button
                                    id="btn-trade-apply-changes"
                                    buttonStyle="secondary"
                                    disabled={areRequiredFieldsEmpty || !enableApplyChangesButton}
                                    data-testid="btn-trade-apply-changes"
                                    onClick={handleApplyChanges}
                                >
                                    {APPLY_CHANGES}
                                </Button>
                            </StyledApplyChangesButtonContainer>
                        )}
                    </FlexCol>
                }
                isBaseDealScreenExperience={isBaseDealScreenExperience}
            />
        </LoadingSectionParent>
    );
};

export default TradeCard;
