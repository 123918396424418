import { Box } from '@interstate/components/Box';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const StyledPriceInputContainer = styled(Box)`
    @media (min-width: ${BreakPoint.PHONE}) {
        margin-right: 0.5rem;
    }
`;

export const StyledPriceInputBaseContainer = styled(Box)`
    padding-top: 1rem;
    margin-right: 0.8rem;
`;

export const StyledTaxesContainer = styled(Box)<{ isBaseDealScreenExperience?: boolean }>`
    border-top: ${({ theme, isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience ? 'none' : `${theme.tokens.BaseSizeBorderPx} solid ${theme.tokens.SemColorBorderMuted}`};
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
`;
