// external
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { offerReduxSelectors, rollToSelectors } from '@makemydeal/dr-dash-store';

// types
import { OfferTypeEnum } from '@makemydeal/dr-platform-types';

// custom internal components
import RollToPairsGrid from './RollToPairsGrid';
import RollToPaymentHeader from './RollToPaymentHeader';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { ROLL_TO_OPTIONS_LABELS } from '@makemydeal/dr-shared-types';

export const enum ActivityText {
    Show = 'Show All',
    Hide = 'Hide All',
    Masking = '*******'
}

type RollToPaymentProfitProps = {
    activeTab: ROLL_TO_OPTIONS_LABELS;
};

const RollToPaymentProfit: FC<RollToPaymentProfitProps> = ({ activeTab }) => {
    const [showProfit, setShowProfit] = useState(false);
    const { profit } = useSelector(rollToSelectors.getRollToOptionsItems);
    const totalAmountDue = useSelector(rollToSelectors.getBalanceDue);
    const hasRollToOptions = useSelector(rollToSelectors.hasRollToOptionsItems);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const tradeProfit = profit?.tradeInProfit;
    const vehicleProfit = profit?.vehicleProfit;
    const frontProfit = profit?.frontEndProfit;
    const reserveProfit = profit?.reserveProfit || 0;
    const backProfit = profit?.backEndProfit || 0;

    const showHideProfits = () => {
        setShowProfit(!showProfit);
    };

    const getDisplayValue = (value: number | string | undefined = ''): number | string => {
        return showProfit ? formatDollarsAndCents(value) : ActivityText.Masking;
    };

    const actionMessage = showProfit ? ActivityText.Hide : ActivityText.Show;

    if (!hasRollToOptions) {
        return null;
    }

    return (
        <>
            <RollToPaymentHeader />
            <RollToPaymentHeader gridCategory={'Profit'} gridAction={showHideProfits} actionMessage={actionMessage} />
            <RollToPairsGrid
                headers={'Front'}
                rows={[
                    { label: 'Trade', value: getDisplayValue(tradeProfit) },
                    { label: 'Vehicle', value: getDisplayValue(vehicleProfit), dividerBelow: true },
                    { label: 'Total Front', value: getDisplayValue(frontProfit) }
                ]}
            />
            <RollToPaymentHeader />
            <RollToPairsGrid
                headers={'Back'}
                rows={[
                    { label: 'Finance Reserve', value: getDisplayValue(reserveProfit), dividerBelow: true },
                    { label: 'Total Back', value: getDisplayValue(reserveProfit + backProfit) }
                ]}
            />
            <RollToPaymentHeader />
            {offerType === OfferTypeEnum.FINANCE && activeTab === ROLL_TO_OPTIONS_LABELS.BALANCE_DUE && (
                <>
                    <RollToPaymentHeader gridCategory={'Balance Due'} />
                    <RollToPairsGrid rows={[{ label: 'Total Balance Due', value: getDisplayValue(totalAmountDue.toString()) }]} />
                </>
            )}
        </>
    );
};

export default RollToPaymentProfit;
