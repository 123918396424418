import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled, { css } from 'styled-components';
import { Box } from '@interstate/components/Box';
import { BaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';

export const FlexFeesButtonContainer = styled.div`
    padding-top: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1rem;

    @media (min-width: ${BreakPoint.SMALL}) {
        flex-direction: row;
    }
`;

export const FeesFormItemsContainer = styled.div<{
    isBaseDealScreenExperience?: boolean;
    BaseBreakPoint?: BaseDealBreakpoint;
}>`
    display: flex;
    padding-top: 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;

    hr {
        margin: 1rem 0 0;
        border-bottom: 1px solid var(--Border-default, #b2b2b2);
    }

    .delete-fee-button {
        width: 5.5rem;
        height: 2.78rem;
    }

    .edit-fee--code-cell {
        flex-basis: 45%;
    }

    .edit-fee--name-cell {
        flex-basis: 80%;
    }

    .edit-fee--amount-cell {
        flex-basis: 50%;
    }

    label {
        white-space: nowrap !important;
    }

    ${({ isBaseDealScreenExperience, BaseBreakPoint }) =>
        isBaseDealScreenExperience &&
        BaseBreakPoint &&
        css`
            padding-top: 0;
            margin-bottom: 0;

            @media only screen and (max-width: ${BaseBreakPoint.SMALL}) {
                margin-bottom: 11px;
                gap: 16px;
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            &,
            & .edit-fee---upfront-container {
                gap: 11px 8px;
                @media only screen and (max-width: ${BaseBreakPoint.SMALL}) {
                    gap: 16px;
                }
            }

            .delete-fee-button {
                width: 32px;
                padding: 0 !important;
                top: 5px;
                @media only screen and (max-width: ${BaseBreakPoint.SMALL}) {
                    top: 24px;
                }
            }

            .edit-fee--amount-cell input {
                text-align: right;
                @media only screen and (max-width: ${BaseBreakPoint.SMALL}) {
                    text-align: left;
                }
            }
            .edit-fee--upfront-cell label {
                top: 0.8rem;
                @media only screen and (max-width: ${BaseBreakPoint.SMALL}) {
                    top: 2.25rem;
                }
            }

            .edit-fee--code-cell,
            .edit-fee--name-cell,
            .edit-fee--amount-cell,
            .edit-fee--code-cell-dropdown {
                flex-basis: 33.33%;
                @media only screen and (min-width: calc(${BaseBreakPoint.SMALL} + 1px)) {
                    label {
                        display: none;
                    }
                }
            }
        `}
`;

export const ActionStyle = styled.div<{ isBaseDealScreenExperience: boolean }>`
    margin: 1rem 0;

    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            margin: 0.75rem 0 0.55rem 0;
            @media (max-width: ${BreakPoint.SMALL}) {
                margin: 1.4rem 0 0.85rem 0;
            }
        `}

    button > span,
    .ind-action {
        font-size: 1.25rem;
        font-weight: 600;
    }
`;

export const EditFeesRowDivider = styled.hr`
    margin: 1rem 0 0;
`;

export const TotalSummaryContainer = styled(Box)<{ BaseBreakPoint: BaseDealBreakpoint }>`
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 2px solid #b2b2b2;
    padding: 16px 16px;
    gap: 8px;
    justify-content: flex-end;
    margin-bottom: 24px;
    margin-top: 18px;

    @media (max-width: ${(props) => props.BaseBreakPoint.SMALL}) {
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 16px;
    }
`;
