import { MouseEventHandler } from 'react';
import {
    Card,
    CardHeader,
    CardLink,
    CardSubtitle,
    FlexCol,
    OnPrimaryHandlerCallback,
    SlideoutDrawerFooter,
    SlideoutDrawerHeader,
    useSlideoutDrawer,
    StyledHeader,
    StyledIncentivesHeader
} from '@makemydeal/dr-dash-components';
import {
    offerReduxSelectors,
    programActionCreators,
    programsSelectors,
    formatRate,
    offerActionCreators
} from '@makemydeal/dr-dash-store';
import { IncentiveSelectorForm } from '../IncentiveSelector/IncentiveSelectorForm';
import { FooterInformation } from '../IncentiveSelector/FooterInformation';
import { useDispatch, useSelector } from 'react-redux';
import { formatInteger } from '../../utils/mapUtils';
import { createIncentiveSubHeaderText } from './utils';
import { formatDate } from '@makemydeal/dr-common-utils';
import { CASH } from '@makemydeal/dr-platform-types';
import { RebatesView } from './RebatesView';
import { RebatesEdit } from './RebateEdit/RebatesEdit';
import { RebatesEditFooter } from './RebatesEditFooter';
import { Typography } from '@interstate/components/Typography';

export const IncentivesCard = () => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const term = useSelector((state) => offerReduxSelectors.getSelectedTerm(state, offerType));
    const lenderLegalName = useSelector(offerReduxSelectors.getLenderLegalName);

    const programEndDate: string = useSelector(offerReduxSelectors.getLenderProgramEndDate);

    const apr = useSelector(offerReduxSelectors.getApr);
    const termMonths = term?.months;
    const { showContent } = useSlideoutDrawer();
    const subHeaderText = offerType !== CASH ? createIncentiveSubHeaderText(termMonths, apr, programEndDate) : '';
    const quote = useSelector(programsSelectors.getProgramFromCurrentOffer);
    const headerText = `${quote.lenderLegalName || 'Cash'} Incentives`; // cannot test for Cash until the story for Add Incentives CTA is complete

    // istanbul ignore next
    const handleSlideoutApply = (callback: OnPrimaryHandlerCallback) => {
        dispatch(
            offerActionCreators.updateManualIncentives({
                manualIncentives: undefined,
                dealerCashTotal: 0,
                totalRebates: 0
            })
        ); // reset manual (always)
        dispatch(programActionCreators.applySelectedProgram(quote));
        callback(true);
    };

    // istanbul ignore next
    const handleCloseOrCancel = () => {
        dispatch(programActionCreators.clearSelected()); // deselect program quote on close
    };
    const handleAddIncentivesClicked: MouseEventHandler<HTMLButtonElement> = (_e) => {
        dispatch(programActionCreators.selectProgramQuote(quote));

        showContent(IncentiveSelectorForm, {
            onClose: handleCloseOrCancel, // top-right close button
            header: <StyledIncentivesHeader>{headerText}</StyledIncentivesHeader>,
            footer: (
                <SlideoutDrawerFooter
                    showSecondary
                    primaryText="Apply"
                    text={<FooterInformation />}
                    onPrimary={handleSlideoutApply} // apply
                    onSecondary={handleCloseOrCancel} // cancel
                />
            ),
            testId: 'incentives-selector'
        });
    };
    // istanbul ignore next
    const handleEditClicked: MouseEventHandler<HTMLButtonElement> = (_e) => {
        showContent(RebatesEdit, {
            header: (
                <StyledHeader>
                    <SlideoutDrawerHeader text={`${lenderLegalName} Incentives`} subHeaderText={subHeaderText} />
                </StyledHeader>
            ),
            footer: <RebatesEditFooter />,
            testId: 'incentives-editor'
        });
    };
    const programName = term?.lenderProgram?.programName;

    if (offerType !== CASH && programName == null) {
        return <p>No program available.</p>;
    }
    return (
        <Card htmlId="incentives-card" display="block">
            <CardHeader>
                <Typography variant="h2" sx={{ 'font-weight': '600' }}>
                    {programName || 'Cash'}
                </Typography>
                <div>
                    <CardLink data-testid="add-incentives-link" onClick={handleAddIncentivesClicked}>
                        Add Incentives
                    </CardLink>
                    <CardLink data-testid="edit-link" onClick={handleEditClicked}>
                        Edit
                    </CardLink>
                </div>
            </CardHeader>
            <FlexCol gap="1rem;">
                {offerType !== CASH && (
                    <CardSubtitle>
                        {formatInteger(term.months)} mos at {formatRate(term.sellRate, term.rateType)} {term.rateType}
                        {term.lenderProgram.programEndDate && (
                            <span> (expires {formatDate(new Date(term.lenderProgram.programEndDate))})</span>
                        )}
                    </CardSubtitle>
                )}
                <RebatesView />
            </FlexCol>
        </Card>
    );
};
