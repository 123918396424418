import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { tradeInSelectors, tradeInActionCreators } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

import LienholderFormHeader from './LienholderFormHeader.interstate';
import LienholderSummary from './LienholderSummary.interstate';
import LienholderActionGroup from './LienholderActionGroup.interstate';
import LienholderFormInputs from './LienholderFormInputs.interstate';

import { LienholderFormData } from './types';
import { checkLienholderInfoAvailability, getLienholderFormData } from './utils';
import { StyledLienholderFormContainer } from './LienholderForm.interstate.style';

export interface LienholderFormProps {
    isEditDisabled: boolean;
    lienholderData: LienholderFormData;
    setLienholderData: Dispatch<SetStateAction<LienholderFormData>>;
}

const LienholderForm = ({ isEditDisabled, lienholderData, setLienholderData }: LienholderFormProps) => {
    const dispatch = useDispatch();

    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const sourceLienholderData = useSelector(tradeInSelectors.getLienholderFull);

    const prevSourceLienholderData = useRef(sourceLienholderData);

    const [isEditMode, setEditMode] = useState(false);
    const [disableApplyButton, setDisableApplyButton] = useState(true);

    const handleCancelButton = () => {
        setLienholderData(getLienholderFormData(sourceLienholderData));
        setEditMode(false);
    };

    const handleErrors = useCallback(
        (error: boolean) => {
            const hasNoChange = isEqual(getLienholderFormData(sourceLienholderData), lienholderData);
            setDisableApplyButton(hasNoChange || error);
        },
        [lienholderData, sourceLienholderData]
    );

    useEffect(() => {
        const isEqual = JSON.stringify(sourceLienholderData) === JSON.stringify(prevSourceLienholderData.current);
        if (!isEqual) {
            setLienholderData(getLienholderFormData(sourceLienholderData));
        }
        prevSourceLienholderData.current = sourceLienholderData;
    }, [sourceLienholderData]);

    const handleApplyButton = () => {
        setEditMode(false);
        dispatch(
            tradeInActionCreators.updateTradeInLienholderFull({
                lienHolder: lienholderData.lienholder,
                lienHolderAccountNumber: lienholderData.lienholderAccountNumber,
                lienHolderPhoneNumber: lienholderData.lienholderPhoneNumber,
                lienHolderAddress: lienholderData.lienholderAddress,
                goodThroughDate: lienholderData.payoffDueDate && lienholderData.payoffDueDate.toDateString(),
                perDiemInterest: lienholderData.perDiem
            })
        );
    };

    const isLienholderInfoAvailable = useMemo(() => checkLienholderInfoAvailability(sourceLienholderData), [sourceLienholderData]);

    return (
        <StyledLienholderFormContainer isBaseDealScreenExperience={isBaseDealScreenExperience}>
            <LienholderFormHeader
                isEditMode={isEditMode}
                isEditDisabled={isEditDisabled}
                isLienholderInfoAvailable={isLienholderInfoAvailable}
                toggleEditMode={(mode: boolean) => {
                    setEditMode(mode);
                }}
                isBaseDealScreenExperience={isBaseDealScreenExperience}
            />
            {!isEditMode && isLienholderInfoAvailable && !isBaseDealScreenExperience && (
                <LienholderSummary data={sourceLienholderData} />
            )}
            {(isEditMode || isBaseDealScreenExperience) && (
                <>
                    <LienholderFormInputs
                        isTradeInCompleted={isBaseDealScreenExperience || isTradeInCompleted}
                        lienholderData={lienholderData}
                        setLienholderData={setLienholderData}
                        handleError={handleErrors}
                    />
                    {!isBaseDealScreenExperience && (
                        <LienholderActionGroup
                            disableApplyButton={disableApplyButton}
                            handleApply={handleApplyButton}
                            handleCancel={handleCancelButton}
                        />
                    )}
                </>
            )}
        </StyledLienholderFormContainer>
    );
};

export default LienholderForm;
