// externals
import { FC, useState } from 'react';

// libraries
import { Box } from '@interstate/components/Box';
import { TextInput } from '@interstate/components/TextInput';
import { Typography } from '@interstate/components/Typography';

// hooks
import { useFeesFormContext } from './useFeesFormContext';

// types utils constants
import { NOT_INCLUDED_IN_CALC_LABEL } from '../common/taxesAndFeesConsts';
import { CellFinanceFeeNameProps } from './types';
import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

export const FinanceNameCell: FC<CellFinanceFeeNameProps> = ({ detail, index }) => {
    const { handleInputChange, getIsIncludeInCalc, setDisableFeeSubmit } = useFeesFormContext();
    const includedInCalc = getIsIncludeInCalc({ category: detail.category, includedInCalc: !!detail.includedInCalc });
    const isNameEmpty = !detail.name?.trim();
    const [touched, setTouched] = useState(false);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    return (
        <Box display="flex" flexDirection="column" className="edit-fee--name-cell">
            <TextInput
                name="feeName"
                label="Name"
                data-testid={`edit-fee--name-cell-${detail.name}`}
                value={detail.name}
                disabled={detail.isDeleted}
                required={true}
                errorMessage={isNameEmpty ? 'Name is required' : undefined}
                hasError={isBaseDealScreenExperience ? touched && isNameEmpty : isNameEmpty}
                onChange={(e) => {
                    handleInputChange({ category: detail.category, field: 'feeName', index, value: e.target.value });
                    setDisableFeeSubmit(isNameEmpty);
                }}
                onBlur={() => setTouched(true)}
            />
            {includedInCalc && (
                <Typography sx={{ color: 'gray' }} variant="body-sm">
                    {NOT_INCLUDED_IN_CALC_LABEL}
                </Typography>
            )}
        </Box>
    );
};
