import { baseDealAnalyticsActionCreators } from '@makemydeal/dr-dash-store';
import { BaseTabs } from '../baseDealTypes';

const {
    summaryTabClicked,
    summaryTabClickedWithHotkey,
    summaryTabViewed,
    tradeInTabClicked,
    tradeInTabClickedWithHotkey,
    tradeInTabViewed,
    feesTabClicked,
    feesTabViewed,
    feesTabClickedWithHotkey,
    taxesTabClicked,
    taxesTabViewed,
    taxesTabClickedWithHotkey,
    protectionTabClicked,
    protectionTabViewed,
    protectionTabClickedWithHotkey,
    incentivesTabClicked,
    incentivesTabViewed,
    incentivesTabClickedWithHotkey,
    lenderTabClicked,
    lenderTabViewed,
    lenderTabClickedWithHotkey,
    accessoriesTabClicked,
    accessoriesTabViewed,
    accessoriesTabClickedWithHotkey
} = baseDealAnalyticsActionCreators;

type ActionReturn = () => { type: string };

export const tabToCdl3ActionMap: Record<BaseTabs, { click: ActionReturn; view: ActionReturn; hotkey: ActionReturn }> = {
    summary: {
        click: summaryTabClicked,
        view: summaryTabViewed,
        hotkey: summaryTabClickedWithHotkey
    },
    'trade-in': {
        click: tradeInTabClicked,
        view: tradeInTabViewed,
        hotkey: tradeInTabClickedWithHotkey
    },
    fees: {
        click: feesTabClicked,
        view: feesTabViewed,
        hotkey: feesTabClickedWithHotkey
    },
    taxes: {
        click: taxesTabClicked,
        view: taxesTabViewed,
        hotkey: taxesTabClickedWithHotkey
    },
    protection: {
        click: protectionTabClicked,
        view: protectionTabViewed,
        hotkey: protectionTabClickedWithHotkey
    },
    incentives: {
        click: incentivesTabClicked,
        view: incentivesTabViewed,
        hotkey: incentivesTabClickedWithHotkey
    },
    lender: {
        click: lenderTabClicked,
        view: lenderTabViewed,
        hotkey: lenderTabClickedWithHotkey
    },
    accessories: {
        click: accessoriesTabClicked,
        view: accessoriesTabViewed,
        hotkey: accessoriesTabClickedWithHotkey
    }
};
