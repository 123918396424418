import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

//styles
import { InfoContainer, LogoContainer, UpdateDealBannerContainer, UpdateDealBannerWrapper } from './UpdatedDealBanner.style';

//libraries
import { offerInfoSelectors } from '@makemydeal/dr-dash-store';

//components
import IconWarning from '@cx/ui/Icons/IconWarning';
import { UPDATED_DEAL_BANNER_LABELS } from '../constants';
import { Box } from '@interstate/components/Box';
import { Alert } from '@interstate/components/Alert';
import { Action } from '@interstate/components/Action';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

const UpdatedDealBanner = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [lastPersona, setLastPersona] = useState('');
    const [lastMilestones, setLastMilestones] = useState([] as string[]);
    const socketEventNotification = useSelector(offerInfoSelectors.getSocketEventNotification);
    const updatedMilestones = useSelector(offerInfoSelectors.getSocketEventUpdatedMilestones);
    const isPaymentUpdate = useSelector(offerInfoSelectors.getSocketEventIsPaymentUpdate);
    const persona = useSelector(offerInfoSelectors.getSocketEventAppName);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const milestoneLabels = updatedMilestones.reduce((prev, current) => {
        const label = UPDATED_DEAL_BANNER_LABELS[current];
        if (label) prev.push(label);
        return prev;
    }, [] as string[]);

    useEffect(() => {
        if (socketEventNotification) {
            setLastPersona(persona);
            setLastMilestones((prev) => uniq(prev.concat(milestoneLabels)));
            setShowBanner(true);
        }
    }, [socketEventNotification]);

    const handleBanner = () => {
        setShowBanner(false);
        setLastPersona('');
        setLastMilestones([]);
    };

    const alertInfo = () => (
        <div data-testid="banner-info-container">
            {lastMilestones.length > 0 ? (
                <span>
                    This offer has updates to:{' '}
                    {lastMilestones.map((label, index) => (
                        <Fragment key={index}>
                            {index !== 0 && (lastMilestones.length - 1 === index ? ' and ' : ', ')}
                            <b>{label}</b>
                        </Fragment>
                    ))}
                    .{isPaymentUpdate ? " See what's changed via Quick Compare." : ''}
                </span>
            ) : (
                <span>{'This offer has updates.'}</span>
            )}
        </div>
    );
    return (
        <>
            {showBanner && (
                <>
                    {isBaseDealScreenExperience && (
                        <Box sx={{ padding: '1.2rem 1rem', maxWidth: '1920px', margin: 'auto' }}>
                            <Alert
                                data-testid="interstate-banner-container"
                                title={`Updates from ${lastPersona}`}
                                type="caution"
                                displayCloseButton
                            >
                                {alertInfo()}
                                <div>
                                    <Action data-testid="interstate-hide-banner-action" size="sm" onClick={handleBanner}>
                                        Okay, Got It
                                    </Action>
                                </div>
                            </Alert>
                        </Box>
                    )}
                    {!isBaseDealScreenExperience && (
                        <UpdateDealBannerWrapper>
                            <UpdateDealBannerContainer>
                                <LogoContainer>
                                    <IconWarning className="iconDealUpdateBanner" />
                                </LogoContainer>
                                <InfoContainer>
                                    <div data-testid="banner-title-container">
                                        <b>Updates from {lastPersona}</b>
                                    </div>
                                    {alertInfo()}
                                    <div>
                                        <a onClick={handleBanner} data-testid="hide-banner">
                                            Okay, Got It
                                        </a>
                                    </div>
                                </InfoContainer>
                            </UpdateDealBannerContainer>
                        </UpdateDealBannerWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default UpdatedDealBanner;
