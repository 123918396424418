import { ManualIncentiveEditEntry } from '@makemydeal/dr-platform-types';

export type ColumnMapping = {
    label: string;
    fieldName: keyof ManualIncentiveEditEntry | 'action';
    required: boolean;
};

export const columnMappings: ColumnMapping[] = [
    {
        label: '',
        fieldName: 'action',
        required: false
    },
    {
        label: 'Program',
        fieldName: 'program',
        required: false
    },
    {
        label: 'Incentive Name',
        fieldName: 'name',
        required: true
    },
    {
        label: 'Code',
        fieldName: 'code',
        required: false
    },
    {
        label: 'Type',
        fieldName: 'type',
        required: true
    },
    {
        label: 'Amount',
        fieldName: 'amount',
        required: true
    }
];

export const DealCentralcolumnMappings: ColumnMapping[] = [
    {
        label: 'Program',
        fieldName: 'program',
        required: false
    },
    {
        label: 'Incentive Name',
        fieldName: 'name',
        required: false
    },
    {
        label: 'Code',
        fieldName: 'code',
        required: false
    },
    {
        label: 'Type',
        fieldName: 'type',
        required: false
    },
    {
        label: 'Amount',
        fieldName: 'amount',
        required: false
    },
    {
        label: '',
        fieldName: 'action',
        required: false
    }
];

export function getRequiredFields() {
    return columnMappings.filter((column) => column.required).map((column) => column.fieldName);
}
