import { formatDollarsAndCents, formatNumber, isValidNumber } from '@makemydeal/dr-common-utils';
import { VehicleProtectionTableModel } from '../types/vehicleProtectionTable';
import { DraftDealProduct, VppProductAttributes } from '@makemydeal/dr-dash-types';
import { isUnlimitedMileage } from './termsUtils';
import { FETCHING_RATE_FIELD_MESSAGE, UNLIMITED_MILEAGE_LABEL } from '../constants';

export const getFormattedProviderAddress = (product: Partial<VehicleProtectionTableModel>) => {
    const { productProviderAddress, productProviderCity, productProviderPostalCode, productProviderState, productProviderName } =
        product;
    return [
        productProviderName,
        productProviderAddress,
        productProviderCity,
        [productProviderState, productProviderPostalCode].filter(Boolean).join(' ')
    ]
        .filter(Boolean)
        .join(', ');
};
export function transformProductToModel(
    protectionProduct: DraftDealProduct,
    isFetchingRates: boolean,
    productAttributes?: Record<string, VppProductAttributes>
): VehicleProtectionTableModel {
    const isProductRatesApplied = 'productPrice' in protectionProduct;
    const shouldDisplayFetchingMessages = isFetchingRates && !isProductRatesApplied;

    const currentProductAttributes =
        typeof protectionProduct.productCode === 'string' && productAttributes?.[protectionProduct.productCode];
    const productWithoutRates = currentProductAttributes && currentProductAttributes.productWithoutRates;

    const getDefaultValue = (defaultValue: string) => {
        return shouldDisplayFetchingMessages ? FETCHING_RATE_FIELD_MESSAGE : defaultValue;
    };

    const formatMileage = (mileage?: number) => {
        if (isUnlimitedMileage(mileage)) {
            return getDefaultValue(productWithoutRates ? '-' : UNLIMITED_MILEAGE_LABEL);
        }

        return formatNumber(mileage as number);
    };

    const formatDeductible = (productDeductible: number | undefined, deductibleApplyType: string | undefined): string => {
        if (productWithoutRates) {
            return '-';
        }

        if (!isValidNumber(productDeductible as number)) {
            return deductibleApplyType || getDefaultValue('N/A');
        }

        const formattedNumericValue = formatDollarsAndCents(productDeductible);

        return deductibleApplyType ? `${formattedNumericValue} ${deductibleApplyType}` : formattedNumericValue;
    };

    return {
        key: protectionProduct.productCode,
        selected: protectionProduct.selected ?? false,
        productCategoryCode: protectionProduct.productCategoryCode ?? getDefaultValue('-'),
        productName: protectionProduct.productName ?? getDefaultValue('-'),
        productProviderName: protectionProduct.productProviderName ?? getDefaultValue('-'),
        markupValue: formatDollarsAndCents(protectionProduct.productMarkup?.markupDollarAmount, getDefaultValue('-')),
        productPrice: formatDollarsAndCents(protectionProduct.productPrice, getDefaultValue('-')),
        productCost: formatDollarsAndCents(protectionProduct.productCost, getDefaultValue('-')),
        productDeductible: formatDeductible(protectionProduct.productDeductible, protectionProduct.productDeductibleMethod),
        productProviderPlan: protectionProduct.productProviderPlan ?? getDefaultValue('-'),
        productCoverageMiles: formatMileage(protectionProduct.productCoverageMiles),
        productProviderAddress: protectionProduct.productProviderAddress?.addressLine1 || undefined,
        productProviderCity: protectionProduct.productProviderAddress?.city || undefined,
        productProviderState: protectionProduct.productProviderAddress?.stateProvince || undefined,
        productProviderPostalCode: protectionProduct.productProviderAddress?.postalCode || undefined,
        productCoverageLength: protectionProduct.productCoverageLength
            ? formatNumber(protectionProduct.productCoverageLength)
            : getDefaultValue('-')
    };
}
