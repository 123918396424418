// externals
import styled from 'styled-components';

export const TradeInSeparator = styled.div`
    border-bottom: 1.6px solid #c8c8c8;
    flex-grow: 1;
`;

export const TradeCheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const TradeDetailsFooter = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 16px;
`;

export const VehicleDetailContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
`;

export const StyledApplyChangesButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 1.25rem;
    margin-bottom: 3.125rem;
`;
