import { locHrefUtil, apiConfigHelper, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { nanoid } from 'nanoid';
import { RSAA } from 'redux-api-middleware';
import {
    SCENARIOS_FETCH_FAILURE,
    SCENARIOS_FETCH_REQUEST,
    SCENARIOS_FETCH_SUCCESS,
    SCENARIOS_INGEST_COMPLETE
} from '../actionTypes/multiScenarioActionTypes';
import { MAKE_CURRENT_COMPLETED } from '../actionTypes/offerActionTypes';
import { initActionTypes, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { scopedStateSelectors, scopedActionsCreators, scopedActions } from '@makemydeal/shared-scoped-store';

import * as offerReduxSelectors from '../selectors/offerRedux';
import * as offerReduxActionCreators from '../actions/offerRedux';
import * as multiScenarioSelectors from '../selectors/multiScenarioSelectors';
import * as dealTemplateSelectors from '../selectors/dealTemplateSelectors';
import * as offerInfoSelectors from '../selectors/offerInfoSelectors';
import { getOrderForTemplateScenarios, getOrderFromRetrievedScenarios } from '../utils/multiScenarioUtils';
import type { BasicObject } from '@makemydeal/dr-offer-redux';

export const fetchScenarios = (
    dealXgId: string,
    scenarioIds: string[],
    params?: { [key: string]: string | boolean },
    dealXgVersion?: string
) => {
    const scenarioIdQuery = scenarioIds.join(',');
    const locHref = locHrefUtil.getLocHref();
    let relativePath = `scenarios/${dealXgId}?scenarioIds=${scenarioIdQuery}`;

    if (params) {
        relativePath = `${relativePath}&${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`;
    }

    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, relativePath);
    const endpoint = bffApiUrlResult.url;
    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                SCENARIOS_FETCH_REQUEST,
                { type: SCENARIOS_FETCH_SUCCESS, ...(dealXgVersion && { meta: { dealXgVersion } }) },
                SCENARIOS_FETCH_FAILURE
            ]
        }
    };
};

/**
 * Thunk action creator that dispatches actions for loading additional scenarios
 */
export const handleFetchMultipleScenarios =
    () =>
    (dispatch: any, getState: any): void => {
        const state = getState();

        if (featureToggleSelectors.enableMultiScenarioPersistence(state)) {
            const activeComparison = multiScenarioSelectors.getActiveComparison(state);
            const shouldLoadIfDraftComparison =
                activeComparison?.isPublished || featureToggleSelectors.enableDraftScenarioPersistence(state);

            const templateApplied = dealTemplateSelectors.getIsDealTemplateApplied(state);

            if (activeComparison && shouldLoadIfDraftComparison) {
                if (featureToggleSelectors.enable9boxPersistence(state) && activeComparison.isPublished) {
                    const primaryScopeId = scopedStateSelectors.getPrimaryScopeId(state);
                    const primaryScenario = activeComparison.scenarios.find(({ isPrimary }) => isPrimary);
                    if (primaryScenario) {
                        dispatch(handleLoadScenarioPaymentGrid(primaryScopeId, primaryScenario));
                    }
                }

                if (activeComparison.scenarios.length > 1) {
                    const dealXgId = offerReduxSelectors.getDealXgId(state);
                    const nonPrimaryScenarioIds = activeComparison.scenarios
                        .filter(({ isPrimary }) => !isPrimary)
                        .map(({ id }) => id);
                    const useDxgScenarioEndpoint = multiScenarioSelectors.isComparisonSavedViaScenarioEndpoint(activeComparison);

                    dispatch(fetchScenarios(dealXgId, nonPrimaryScenarioIds, { useDxgScenarioEndpoint, templateApplied }));
                }
            }
        }
    };

/**
 * Accepts full alternate scenarios retrieved from DXG and adds them to the scope state, then initializes the scopes
 * @param nonPrimaryScenarios
 * @returns
 */
export const addAlternateScenariosToState =
    (nonPrimaryScenarios: any[]) =>
    (dispatch: any, getState: any): void => {
        const state = getState();
        const activeComparison = multiScenarioSelectors.getActiveComparison(state);

        if (activeComparison && nonPrimaryScenarios.length) {
            const retrievedNonPrimaryScenarioIds = nonPrimaryScenarios.map(({ offer }: any) => offer.dealExchangeVersionId);

            // Hydrate each scenario
            for (const scenario of nonPrimaryScenarios) {
                const scenarioId = scenario.offer.dealExchangeVersionId;
                const newAction = scopedActions.createScopedAction(scenarioId, {
                    type: initActionTypes.INIT_PENCIL_SUCCESS,
                    payload: scenario
                });
                dispatch(newAction);
            }

            dispatch(scopedActionsCreators.initializeScopes(retrievedNonPrimaryScenarioIds));

            // Hydrate PaymentGrid for each scenario after initializing them
            if (featureToggleSelectors.enable9boxPersistence(state)) {
                for (const scenario of nonPrimaryScenarios) {
                    const scenarioId = scenario.offer.dealExchangeVersionId;
                    const baggageScenario = activeComparison.scenarios.find(({ id }) => id === scenarioId);
                    dispatch(handleLoadScenarioPaymentGrid(scenarioId, baggageScenario!));
                }
            }
            dispatch({ type: SCENARIOS_INGEST_COMPLETE });
        }
    };

/**
 * Accepts full template scenarios retrieved from template service and adds them to the scope state, then initializes the scopes
 * @param nonPrimaryScenarios
 * @returns
 */
export const addTemplateScenariosToState =
    (nonPrimaryScenarios: any[]) =>
    (dispatch: any, getState: any): void => {
        const state = getState();
        const activeComparison = multiScenarioSelectors.getActiveComparison(state);
        if (activeComparison && nonPrimaryScenarios.length) {
            const order = scopedStateSelectors.getScopesOrder(state);
            const [_, ...generatedIds] = order;

            // Hydrate each scenario
            nonPrimaryScenarios.forEach((scenario, index) => {
                const newAction = scopedActions.createScopedAction(generatedIds[index], {
                    type: initActionTypes.INIT_PENCIL_SUCCESS,
                    payload: scenario
                });
                dispatch(newAction);
            });

            dispatch(scopedActionsCreators.initializeScopes(order));

            // Hydrate PaymentGrid for each scenario after initializing them
            if (featureToggleSelectors.enable9boxPersistence(state)) {
                nonPrimaryScenarios.forEach((_, index) => {
                    const scenarioId = generatedIds[index];
                    // The first scenario is primary, non primary starts from index 1
                    const baggageScenario = activeComparison.scenarios[index + 1];
                    if (baggageScenario) {
                        dispatch(handleLoadScenarioPaymentGrid(scenarioId, baggageScenario));
                    }
                });
            }
            dispatch({ type: SCENARIOS_INGEST_COMPLETE });
        }
    };

/**
 * Thunk action creator that dispatches actions for loading PaymentGrid/NineBox for individual scenarios
 */
export const handleLoadScenarioPaymentGrid =
    (scopeId: string, baggageScenario: Record<string, any>) => (dispatch: any, getState: any) => {
        const state = getState();
        // Can't use optional chaining due to transpilation for Jest
        const paymentGrid = baggageScenario && baggageScenario.paymentGrid;
        if (paymentGrid) {
            const scopeState = scopedStateSelectors.getScopeStateById(state, scopeId);
            const offerType = scopeState && scopeState.offer.offerType;

            if (offerType) {
                // Initialize axes
                const cashDown = paymentGrid.rows.map(({ dueAtSigning }: any) => dueAtSigning);
                const terms = paymentGrid.columns.map(({ term }: any) => term);
                dispatch(
                    scopedActions.createScopedAction(scopeId, offerReduxActionCreators.initializePaymentGridTerms(offerType, terms))
                );
                dispatch(
                    scopedActions.createScopedAction(
                        scopeId,
                        offerReduxActionCreators.initializePaymentGridCashdown(offerType, cashDown)
                    )
                );

                // Initialize overrides
                const lenderPrograms: BasicObject[] = paymentGrid.columns.filter(({ lenderProgram }: any) => lenderProgram);
                const hasLenderPrograms = Boolean(lenderPrograms.length);
                const paymentGridCells = paymentGrid.cells as any[];
                const [selectedTermIndex, selectedDownPaymentIndex] = paymentGridCells
                    .flatMap((row: any[], downPaymentIndex: number) =>
                        row.flatMap((cell, termIndex: number) => (cell.isSelected ? [[termIndex, downPaymentIndex]] : []))
                    )
                    .pop()!;
                paymentGrid.columns.forEach(({ term, lenderProgram, mfOverride, aprOverride }: any, index: number) => {
                    const sellRate = mfOverride ?? aprOverride;
                    if (sellRate != null) {
                        const updateOverrideAction = offerReduxActionCreators.updateSellRateOverride(sellRate, term, {
                            isSelectedTerm: index === selectedTermIndex,
                            doesInitializePaymentGrid: true,
                            paymentGridLenderProgram: lenderProgram,
                            usePaymentGridMV: true
                        });
                        dispatch(scopedActions.createScopedAction(scopeId, updateOverrideAction));
                    }
                });

                // Enable PaymentGrid
                const downPaymentList: number[] = paymentGrid.rows.map(({ dueAtSigning }: any) => dueAtSigning);
                const termMonthsList: number[] = paymentGrid.columns.map(({ term }: any) => term);
                dispatch(
                    scopedActions.createScopedAction(
                        scopeId,
                        offerReduxActionCreators.updatePaymentGridToggle(true, {
                            downPaymentList,
                            termMonthsList,
                            currentDownPayment: downPaymentList[selectedDownPaymentIndex],
                            currentTermMonth: termMonthsList[selectedTermIndex],
                            hasLenderPrograms
                        })
                    )
                );
            }
        }
    };

/**
 * Thunk action creator that dispatches actions for fetching additional scenarios for "make current"
 */
export const handleFetchMakeCurrentMultipleScenarios = () => (dispatch: any, getState: any) => {
    const state = getState();

    if (featureToggleSelectors.enableMultiScenarioPersistence(state)) {
        const dealXgVersion = offerReduxSelectors.getDealXgVersion(state);
        const compObj = multiScenarioSelectors.getComparisonByDealVersion(dealXgVersion, state);

        if (compObj && compObj.scenarios?.length > 1) {
            const dealXgId = offerReduxSelectors.getDealXgId(state);
            const nonPrimaryScenarioIds = compObj.scenarios.filter(({ isPrimary }) => !isPrimary).map(({ id }) => id);
            const useDxgScenarioEndpoint = multiScenarioSelectors.isComparisonSavedViaScenarioEndpoint(compObj);

            dispatch(fetchScenarios(dealXgId, nonPrimaryScenarioIds, { useDxgScenarioEndpoint }));
        } else {
            dispatch({ type: MAKE_CURRENT_COMPLETED });
            dispatch(addMakeCurrentAlternateScenariosToState([]));
        }
    }
};

/**
 * Add all alternate scenarios retrieved from DXG to the scope state for "make current", then initializes the scopes.
 */
export const addMakeCurrentAlternateScenariosToState =
    (nonPrimaryScenarios: any[]) =>
    (dispatch: any, getState: any): void => {
        if (nonPrimaryScenarios) {
            const nonPrimaryScenarioIds = nonPrimaryScenarios.map(({ offer }: any) => offer?.dealExchangeVersionId);

            // Add each non-primary scenario to state
            for (const scenario of nonPrimaryScenarios) {
                const scenarioId = scenario.offer?.dealExchangeVersionId;
                const newAction = scopedActions.createScopedAction(scenarioId, {
                    type: initActionTypes.INIT_PENCIL_SUCCESS,
                    payload: scenario
                });
                dispatch(newAction);
            }

            dispatch(scopedActionsCreators.initializeScopes(nonPrimaryScenarioIds));
        }
    };

export const addUninitializedScenariosToState =
    () =>
    (dispatch: any, getState: any): void => {
        const state = getState();
        const dealXgVersion = offerReduxSelectors.getDealXgVersion(state);
        const comparison = offerInfoSelectors.getIsMakingCurrent(state)
            ? multiScenarioSelectors.getComparisonByDealVersion(dealXgVersion, state)
            : multiScenarioSelectors.getActiveComparison(state);

        if (comparison) {
            const primaryScopeId = scopedStateSelectors.getPrimaryScopeId(state);
            const isDealTemplate = dealTemplateSelectors.getIsDealTemplateApplied(state);
            const nonPrimaryScenarioIds = comparison.scenarios.filter(({ isPrimary }) => !isPrimary).map(({ id }) => id);

            const order = isDealTemplate
                ? getOrderForTemplateScenarios(comparison, primaryScopeId)
                : getOrderFromRetrievedScenarios(comparison, nonPrimaryScenarioIds, primaryScopeId);

            // Must have the scope state partially populated BEFORE fully hydrating with the INIT_PENCIL_SUCCESS dispatch
            dispatch(scopedActionsCreators.createUninitializedScopesFromOrder(order));
        }
    };

export const removeAlternateScenariosFromState =
    () =>
    (dispatch: any): void => {
        dispatch(scopedActionsCreators.deleteNonPrimaryScopes());
    };
