import { Alert } from '@interstate/components/Alert';
import { rollToSelectors } from '@makemydeal/dr-dash-store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const RollToChangeAlert = () => {
    const targetStatus = useSelector(rollToSelectors.getTargetStatus);

    const [target, setTarget] = useState(targetStatus);

    useEffect(() => setTarget(targetStatus), [targetStatus]);

    return (
        <>
            {typeof target === 'object' && (
                <Alert type="success" sx={{ marginTop: '40px' }} data-testid="roll-success">
                    {`Roll-To Monthly Payment on the Current working offer updated from ${target.previous} to ${target.current}`}
                </Alert>
            )}
        </>
    );
};

export default RollToChangeAlert;
