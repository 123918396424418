import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

import AccessoriesCard from './activities/AccessoriesCard';
import CreditAppCard from './activities/CreditAppCard';
import { DocUploadCard } from './activities/DocUploadCard';
import FinishDealCard from './activities/FinishDealCard.interstate';
import IncentivesCard from './activities/IncentivesCard';
import ReservationCard from './activities/ReservationCard';
import TestDriveCard from './activities/TestDriveCard';
import TradeCard from './activities/TradeCard';
import TemplatesCard from './activities/TemplatesCard';
import VppActivityVehicleProtectionCard from './activities/VppActivityVehicleProtectionCard';
import DigitalDealJacketLink from './activities/DigitalDealJacketLink';
import { ShowWithFeatureToggle } from '../utils/ShowWithFeatureToggle';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

export const DealActivity: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);

    return (
        <>
            <IncentivesCard />
            <TradeCard />
            <VppActivityVehicleProtectionCard />
            <AccessoriesCard />
            <CreditAppCard />
            <TestDriveCard />
            <DocUploadCard />
            <ReservationCard />
            {!isDealCentralExperience && (
                <>
                    <FinishDealCard />
                    <ShowWithFeatureToggle name="enableDigitalDealJacket" active={<DigitalDealJacketLink />} />
                    <TemplatesCard />
                </>
            )}
        </>
    );
};
export default DealActivity;
