// externals
import { useEffect, useState, useRef, useCallback } from 'react';
import debounce from 'lodash.debounce';

// libraries
import { Spinner } from '@interstate/components/Spinner';
import { TextInput, TextInputSize } from '@interstate/components/TextInput';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// styles
import { AutocompleteContainer, SuggestionItem, SuggestionList } from './Autocomplete.style';

// types
import { AutocompleteOptions } from '@makemydeal/dr-dash-types';

export interface AutocompleteProps {
    'data-testid'?: string;
    errorMessage?: string;
    hasError?: boolean;
    id?: string;
    isLoading?: boolean;
    label?: string;
    menuOptions: AutocompleteOptions[];
    placeholder?: string;
    position?: 'top' | 'bottom';
    size?: TextInputSize;
    width?: string;
    value?: string;
    onSearch: (input: string) => void;
    onSelect: (input: AutocompleteOptions) => void;
    maxLength?: number;
    disabled?: boolean;
    name?: string;
}

const Autocomplete: React.FC<AutocompleteProps> = (props) => {
    const {
        'data-testid': dataTestId = 'autocomplete',
        errorMessage = '',
        hasError = false,
        id,
        value = '',
        isLoading = false,
        label = '',
        menuOptions,
        placeholder = 'Search...',
        position = 'bottom',
        size,
        width,
        onSearch,
        onSelect,
        maxLength,
        disabled,
        name
    } = props;

    const [inputValue, setInputValue] = useState<string>(value);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);

    const theme = useInterstateTheme();

    const inputRef = useRef<HTMLInputElement>(null);
    const suggestionListRef = useRef<HTMLUListElement>(null);

    const debouncedSearch = useCallback(
        debounce((searchTerm: string) => {
            setActiveSuggestionIndex(-1);
            onSearch(searchTerm);
        }, 300),
        [onSearch]
    );

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const handleInputChange = (e: any) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.length >= 3) {
            setShowSuggestions(true);
            debouncedSearch(value);
        } else {
            setShowSuggestions(false);
            debouncedSearch(value);
        }
    };

    const handleSelectOption = (option: AutocompleteOptions) => {
        setInputValue(option.label);
        setShowSuggestions(false);
        onSelect(option);
    };

    const handleKeyDown = (e: any) => {
        if (!showSuggestions || menuOptions.length === 0) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setActiveSuggestionIndex((prevIndex) => (prevIndex < menuOptions.length - 1 ? prevIndex + 1 : 0));
                break;

            case 'ArrowUp':
                e.preventDefault();
                setActiveSuggestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : menuOptions.length - 1));
                break;

            case 'Enter':
                e.preventDefault();
                if (menuOptions[activeSuggestionIndex]) {
                    handleSelectOption(menuOptions[activeSuggestionIndex]);
                }
                break;

            case 'Escape':
                e.preventDefault();
                setShowSuggestions(false);
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (showSuggestions && suggestionListRef.current) {
            const activeElement = suggestionListRef.current.children[activeSuggestionIndex];
            if (activeElement) {
                (activeElement as HTMLElement).scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }
    }, [activeSuggestionIndex, showSuggestions]);

    const highlightInput = (label: string, input: string) => {
        const regex = new RegExp(`(${input})`, 'gi');
        const parts = label.split(regex).filter(Boolean);

        return parts.map((part: string) => {
            const shouldHighlight = input.toLowerCase() === part.toLowerCase();
            return (
                <span
                    key={part}
                    style={{
                        fontWeight: shouldHighlight ? 600 : 400
                    }}
                >
                    {part}
                </span>
            );
        });
    };

    const getDropdownContent = () => {
        if (!showSuggestions || !menuOptions.length || inputValue.length < 3) {
            return null;
        }

        return (
            <SuggestionList
                data-testid={`${dataTestId}-suggestion-list`}
                ref={suggestionListRef}
                role="menu"
                $position={position}
                theme={theme}
                onMouseDown={(e) => e.preventDefault()}
            >
                {menuOptions.map((option, index) => {
                    return (
                        <SuggestionItem
                            key={index}
                            $active={index === activeSuggestionIndex}
                            role="menuitem"
                            onClick={() => handleSelectOption(option)}
                            onMouseEnter={() => setActiveSuggestionIndex(index)}
                        >
                            <p>{highlightInput(option.label, inputValue)}</p>
                            {option.additionalText && <p>{option.additionalText}</p>}
                        </SuggestionItem>
                    );
                })}
            </SuggestionList>
        );
    };

    return (
        <AutocompleteContainer id={id} data-testid={dataTestId}>
            <TextInput
                errorMessage={errorMessage}
                hasError={hasError}
                inputSuffix={isLoading && <Spinner data-testid={`${dataTestId}-spinner`} size={30} />}
                label={label}
                name={name || 'autocomplete-input'}
                data-testid={`${dataTestId}-input`}
                placeholder={placeholder}
                ref={inputRef}
                size={size}
                sx={{ width: width ?? '100%' }}
                value={inputValue}
                onBlur={() =>
                    setTimeout(() => {
                        setShowSuggestions(false);
                        debouncedSearch.cancel();
                    }, 300)
                }
                onChange={handleInputChange}
                onFocus={handleInputChange}
                onKeyDown={handleKeyDown}
                maxLength={maxLength}
                disabled={disabled}
            />

            {getDropdownContent()}
        </AutocompleteContainer>
    );
};

export default Autocomplete;
