export const getDocumentReferrer = (): string => {
    return document.referrer || 'NO_REFERRER';
};

/**
 * Retrieves a global AppConfig feature toggle from the window object. Used for toggles needed before the React application is initialized
 *
 * Does not work with dealer-specific toggles
 * @param name
 * @returns
 */
export const getGlobalFeatureToggle = (name: string): boolean | undefined => {
    return window.DealWorkflow?.toggles?.get(name);
};
