// externals
import { FC, useMemo, useState } from 'react';

// libraries
import { PriceInputInterstate, customInterstateValidationRules as rules } from '@makemydeal/dr-dash-components';

// interfaces/types
import {
    ColumnMapping,
    ValidateInterstateValidationRulesHandler,
    InputChangeInterstateEventHandler
} from '../manualAccessoriesTypes';

// consts/enums
import * as deskingConstants from '../../../../constants';

export type ManualAccessoryCostFieldStateProps = {
    deleted: boolean;
    index: number;
    cost: number;
    costColumnMapping: ColumnMapping<number>;
    weOwe: boolean;
};

export type ManualAccessoryCostFieldDispatchProps = {
    handleInputChange: InputChangeInterstateEventHandler;
    validateRules: ValidateInterstateValidationRulesHandler;
};

export type ManualAccessoryCostFieldProps = ManualAccessoryCostFieldDispatchProps & ManualAccessoryCostFieldStateProps;

const getValidationRules = (weOwe: boolean) => {
    const required = rules.getRequiredValidationRule(deskingConstants.REQUIRED);
    const minValue = rules.getMinValueValidationRule(0, deskingConstants.REQUIRED, false);
    const maxValue = rules.getMaxValueValidationRule(
        deskingConstants.ACCESSORIES_NUMERIC_MAX_VALUE,
        deskingConstants.ACCESSORIES_NUMERIC_MAX_VALUE_MESSAGE,
        true
    );
    const conditionalRules = weOwe ? [] : [required, minValue];
    return [maxValue, ...conditionalRules];
};

export const ManualAccessoryCostField: FC<ManualAccessoryCostFieldProps> = ({
    deleted,
    index,
    cost = 0,
    costColumnMapping: { fieldName },
    weOwe,
    handleInputChange,
    validateRules
}) => {
    const [isCostTouched, setIsCostTouched] = useState(false);

    const validationRules = useMemo(() => getValidationRules(weOwe), [weOwe]);

    return (
        <PriceInputInterstate
            allowDecimal={true}
            displayLabel={true}
            className={'base-accessories-label'}
            data-testid={`${fieldName}-${index}`}
            label={deskingConstants.COST}
            name={`${fieldName}-${index}`}
            onChange={(e) => {
                handleInputChange(e, fieldName);
            }}
            onFocus={() => {
                setIsCostTouched(true);
            }}
            required={!weOwe}
            getError={(_label, value) => {
                const isInitialValueEmpty = !isCostTouched && cost === 0;
                return validateRules(value, validationRules, fieldName, isInitialValueEmpty);
            }}
            value={cost}
            disabled={deleted}
        />
    );
};
