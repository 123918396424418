import styled, { css } from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { BaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';

export const IncentiveTotalsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const IncentiveTotalsColumn = styled.div<{
    isBaseDealScreenExperience?: boolean;
    BaseBreakPoint?: BaseDealBreakpoint;
}>`
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 16px 0 16px 0;

    @media (min-width: ${BreakPoint.SMALL}) {
        margin: 16px 16px 16px 0;
    }

    svg {
        color: ${({ theme }) => theme.linkColor};
    }

    /* override styles when on base deal screen and mobile view */
    ${(props) =>
        props.isBaseDealScreenExperience &&
        props.BaseBreakPoint &&
        css`
            width: 225px !important;
            @media (max-width: ${props.BaseBreakPoint.SMALL}) {
                width: 100% !important;
            }
        `}
`;

export const IncentiveCurrentTotal = styled.div`
    display: flex;
    align-items: center;
`;

export const IncentiveTotalsRow = styled.div`
    display: flex;
    justify-content: space-between;
`;
