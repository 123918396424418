// externals
import React, { PropsWithChildren, createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { restApiCaller } from '@makemydeal/dr-shared-network';
import { logNewRelic, NrSpaApiEnum } from '@makemydeal/dr-activities-common';

// selectors
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import {
    AssetsContextType,
    InsuranceProvidersFormatted,
    InsuranceProviders,
    LendersFormatted,
    Lenders
} from '@makemydeal/dr-dash-types';

declare global {
    interface Window {
        jsonFileMap: {
            insuranceProviders: string;
            lenders: string;
        };
    }
}

async function fetchProviderAssets<T>(URL: string): Promise<T | null> {
    try {
        const response = await restApiCaller.getResource<T>(URL);

        // check if response is ok
        if (!response.ok) {
            throw new Error(response.rawError);
        }

        return response.response ?? null;
    } catch (error) {
        console.error(`Failed to load Asset - ${URL}`, error);

        // log an error message into New Relic
        logNewRelic('JsonLoadFailure', `Failed to fetch Assests Providers data: ${URL}, ${error}`, NrSpaApiEnum.ERROR);
        return null;
    }
}

export const initialContextValue: AssetsContextType = {
    insuranceProviders: [],
    lenders: [],
    getInsuranceProviders: () => Promise.resolve(),
    getLenders: () => Promise.resolve()
};

export const AssetsContext = createContext<AssetsContextType>(initialContextValue);

export const AssetsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [insuranceProviders, setInsuranceProviders] = useState<InsuranceProvidersFormatted[]>([]);
    const [lenders, setLenders] = useState<LendersFormatted[]>([]);

    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const getInsuranceProviders = async () => {
        if (isBaseDealScreenExperience && !insuranceProviders.length) {
            const insuranceProvidersData = await fetchProviderAssets<InsuranceProviders[]>(window.jsonFileMap.insuranceProviders);

            if (insuranceProvidersData?.length) {
                const formattedInsuranceProvidersData: InsuranceProvidersFormatted[] = insuranceProvidersData.map((item) => {
                    return {
                        label: item.Name,
                        additionalText: [item.Address, item.City, `${item.State} ${item.ZIP}`, item.Phone].join(', '),
                        ...item
                    };
                });
                setInsuranceProviders(formattedInsuranceProvidersData);
            }
        }
    };

    const getLenders = async () => {
        if (isBaseDealScreenExperience && !lenders.length) {
            const lendersData = await fetchProviderAssets<Lenders[]>(window.jsonFileMap.lenders);

            if (lendersData?.length) {
                const formattedLendersData: LendersFormatted[] = lendersData.map((item) => {
                    return {
                        label: item.Name,
                        additionalText: [item.Address, item.City, `${item.State} ${item.ZIP}`, item.Phone].join(', '),
                        ...item
                    };
                });
                setLenders(formattedLendersData);
            }
        }
    };

    return (
        <AssetsContext.Provider value={{ insuranceProviders, getInsuranceProviders, getLenders, lenders }}>
            {children}
        </AssetsContext.Provider>
    );
};

export function useAssetsData() {
    const context = useContext(AssetsContext);
    return context;
}
