import { Comparison } from '@makemydeal/dr-dash-bff-types';
import { StateTree } from '@makemydeal/dr-dash-types';

export const getComparisonList = (state: StateTree): Comparison[] => {
    return state.comparison.comparisonList;
};

/**
 *  Will return the active comparison. Will only be populated if the comparison matches the latest DXG version
 * */
export const getActiveComparison = (state: StateTree): Comparison | undefined => {
    return state.comparison.activeComparison;
};

/**
 * Return the published comparison with the given deal version if there is one; undefined otherwise,
 * Note that if save draft is enabled, a comparison in the baggage may not be published.
 */
export const getComparisonByDealVersion = (dealVersion: string, state: StateTree): Comparison | undefined => {
    const list = getComparisonList(state) || [];
    const comparison = list.find((item: any) => {
        return item?.isPublished && item.dealXgVersionId === dealVersion;
    });

    return comparison;
};

export const isComparisonSavedViaScenarioEndpoint = (comparison: Comparison): boolean => {
    return !!comparison?.enableDxgScenariosEndpoint;
};
