import { Reducer } from 'redux';
import { DealTemplatesState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import {
    DELETE_DEAL_TEMPLATE_FAILURE,
    DELETE_DEAL_TEMPLATE_REQUEST,
    DELETE_DEAL_TEMPLATE_SUCCESS,
    DROP_DELETE_DEAL_TEMPLATE,
    GET_DEAL_TEMPLATES_FAILURE,
    GET_DEAL_TEMPLATES_REQUEST,
    GET_DEAL_TEMPLATES_SUCCESS,
    SAVE_DEAL_TEMPLATE_FAILURE,
    SAVE_DEAL_TEMPLATE_REQUEST,
    SAVE_DEAL_TEMPLATE_SUCCESS,
    SHOW_TEMPLATE_SLIDEOUT
} from '../actionTypes/dealTemplateActionTypes';

export type DealTemplateReducer = Reducer<DealTemplatesState>;

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const initialState: DealTemplatesState = {
    draft: {
        id: '',
        name: '',
        overwritten: false,
        isError: false,
        isSaving: false,
        isSaved: false
    },
    applied: {
        id: '',
        name: '',
        scenariosCount: 0
    },
    list: {
        value: [],
        isLoading: false,
        isError: false
    },
    removing: {
        id: '',
        name: '',
        isError: false,
        isRemoving: false,
        isRemoved: false
    },
    showTemplateSlideOut: false
};

export const reducer: DealTemplateReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            const { template } = action.payload;
            return {
                ...state,
                ...(template && {
                    applied: {
                        id: template.id,
                        name: template.name,
                        scenariosCount: template.scenariosCount
                    }
                })
            };
        }
        case SAVE_DEAL_TEMPLATE_SUCCESS:
            return {
                ...state,
                draft: {
                    id: action.payload.id,
                    name: action.payload.name,
                    overwritten: action.payload.overwritten,
                    isError: false,
                    isSaving: false,
                    isSaved: true
                }
            };
        case SAVE_DEAL_TEMPLATE_REQUEST:
            return {
                ...state,
                draft: {
                    ...initialState.draft,
                    isSaving: true
                }
            };
        case SAVE_DEAL_TEMPLATE_FAILURE:
            return {
                ...state,
                draft: {
                    ...initialState.draft,
                    isError: true
                }
            };
        case GET_DEAL_TEMPLATES_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: true,
                    isError: false
                }
            };
        case GET_DEAL_TEMPLATES_SUCCESS:
            return {
                ...state,
                list: {
                    value: action.payload,
                    isLoading: false,
                    isError: false
                }
            };
        case GET_DEAL_TEMPLATES_FAILURE:
            return {
                ...state,
                list: {
                    value: [],
                    isLoading: false,
                    isError: true
                }
            };
        case DELETE_DEAL_TEMPLATE_REQUEST: {
            const { id, name } = action.meta.template;
            return {
                ...state,
                removing: {
                    ...initialState.removing,
                    id,
                    name,
                    isRemoving: true
                }
            };
        }
        case DELETE_DEAL_TEMPLATE_SUCCESS:
            return {
                ...state,
                removing: {
                    ...state.removing,
                    isRemoving: false,
                    isError: false,
                    isRemoved: true
                }
            };
        case DELETE_DEAL_TEMPLATE_FAILURE:
            return {
                ...state,
                removing: {
                    ...state.removing,
                    isError: true,
                    isRemoving: false,
                    isRemoved: false
                }
            };
        case DROP_DELETE_DEAL_TEMPLATE:
            return {
                ...state,
                removing: {
                    ...initialState.removing
                }
            };

        case SHOW_TEMPLATE_SLIDEOUT:
            return {
                ...state,
                showTemplateSlideOut: action.payload.showTemplateSlideOut
            };

        default:
            return state;
    }
};
