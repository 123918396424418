export const CONTRACTING_OPEN_MODAL = 'CONTRACTING_OPEN_MODAL';
export const CONTRACTING_IN_PROGRESS = 'CONTRACTING_IN_PROGRESS';
export const CONTRACTING_CLOSE_MODAL = 'CONTRACTING_CLOSE_MODAL';
export const CONTRACTING_EVENT_RECEIVED = 'CONTRACTING_EVENT_RECEIVED';
export const CONTRACTING_TIMEOUT_FAILURE = 'CONTRACTING_TIMEOUT_FAILURE';
export const CONTRACTING_VERIFY_SUCCESS_EVENT = 'CONTRACTING_VERIFY_SUCCESS';
export const CONTRACTING_CANCEL_FAILURE_EVENT = 'CONTRACTING_CANCEL_FAILURE';
export const CONTRACTING_START_FAILURE_EVENT = 'CONTRACTING_START_FAILURE';
export const CONTRACTING_VERIFY_FAILURE_EVENT = 'CONTRACTING_VERIFY_FAILURE';
export const CONTRACTING_OFFER_SAVE_FAILURE_EVENT = 'CONTRACTING_OFFER_SAVE_FAILURE';
export const CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT = 'CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT';
export const CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT = 'CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT';
export const CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT = 'CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT';

export const CONTRACTING_STAGE_CANCEL_REQUEST = 'CONTRACTING_STAGE_CANCEL_REQUEST';
export const CONTRACTING_STAGE_CANCEL_SUCCESS = 'CONTRACTING_STAGE_CANCEL_SUCCESS';
export const CONTRACTING_STAGE_CANCEL_FAILURE = 'CONTRACTING_STAGE_CANCEL_FAILURE';

export const CONTRACTING_STAGE_START_REQUEST = 'CONTRACTING_STAGE_START_REQUEST';
export const CONTRACTING_STAGE_START_SUCCESS = 'CONTRACTING_STAGE_START_SUCCESS';
export const CONTRACTING_STAGE_START_FAILURE = 'CONTRACTING_STAGE_START_FAILURE';

export const CONTRACTING_STAGE_VERIFY_REQUEST = 'CONTRACTING_STAGE_VERIFY_REQUEST';
export const CONTRACTING_STAGE_VERIFY_SUCCESS = 'CONTRACTING_STAGE_VERIFY_SUCCESS';
export const CONTRACTING_STAGE_VERIFY_FAILURE = 'CONTRACTING_STAGE_VERIFY_FAILURE';
