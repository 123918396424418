import { useSelector } from 'react-redux';
import { contractingActionTypes, contractingSelectors } from '@makemydeal/dr-dash-store';
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';

const SUCCESS_MESSAGE = 'Contract Generated and Verified by Lender';
const SUCCESS_WITH_WARNINGS_MESSAGE =
    'Contract Verified with Warnings. The Lender may have additional stipulations or requirements.';

export const ContractSuccessAlert = () => {
    const status = useSelector(contractingSelectors.getStatus);
    const successEvent = useSelector(contractingSelectors.getSuccessEvent);
    const isVerifySuccess = successEvent?.toString() === contractingActionTypes.CONTRACTING_VERIFY_SUCCESS_EVENT;

    if (!status || !isVerifySuccess) {
        return null;
    }

    const message = status === 'success' ? SUCCESS_MESSAGE : SUCCESS_WITH_WARNINGS_MESSAGE;
    const alertType = status === 'success' ? 'success' : 'caution';
    return (
        <Alert type={alertType} data-testid="contracting-success" disableSuccessFade>
            <Typography variant="body-sm">{message}</Typography>
        </Alert>
    );
};
