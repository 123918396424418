import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { useEffect, useState } from 'react';

/**
 * NOTE: This is used to fix DE543326.
 * Problem statement: Tax list UI jumps on each payment request when we override total tax amount.
 * This creates bad UI experience for the user.
 * We clear tax items in redux store on each payment request, because we have some logic we handle on UI on payment response.
 * So when we override total tax amount we need to preserve the tax items in the UI.
 */
export default function useDisplayTaxData(data: paymentServicesTypes.TaxItems, taxOverrideEnabled?: boolean) {
    const [lastShownData, setLastShownData] = useState<paymentServicesTypes.TaxItem[]>([]);

    useEffect(() => {
        if (taxOverrideEnabled && data.length > 0) {
            setLastShownData(data);
        }

        if (!taxOverrideEnabled) {
            setLastShownData(data);
        }
    }, [data, taxOverrideEnabled]);

    return taxOverrideEnabled && data.length === 0 ? lastShownData : data;
}
