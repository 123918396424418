// externals
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

// styles
import { FooterContainer, VehicleInsuranceInfoSlideOutContentWrapper } from './VehicleInsuranceSlideOut.style';

// libraries
import { formatAsYYYYMMDD } from '@makemydeal/dr-common-utils';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

// components
import { Grid } from '@interstate/components/Grid';
import { SelectInput } from '@interstate/components/SelectInput';
import { DatePicker } from '@interstate/components/DatePicker';
import { TextInput } from '@interstate/components/TextInput';
import { Button } from '@interstate/components/Button';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { PhoneInput } from '@interstate/components/PhoneInput';
import { getStateCodesSelectInputValues } from '../utils/getStateCodesSelectValues';
import { StateCodes } from '../constants';
import { Typography } from '@interstate/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { baseDealActionCreators, baseDealSelectors, offerReduxSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { PriceInput } from '@interstate/components/PriceInput';
import { PostalCodeInput } from '@interstate/components/PostalCodeInput';
import Autocomplete from '../autoComplete/Autocomplete';

// state
import { useAssetsData } from '../dealActivity/activities/AssetsProvider';

// types
import { AutocompleteOptions, InsuranceProvidersFormatted } from '@makemydeal/dr-dash-types';

const InsuranceForm: FC = () => {
    // insurance context
    const { getInsuranceProviders, insuranceProviders } = useAssetsData();

    const vehicleInsuranceInfo = useSelector(baseDealSelectors.getVehicleInsuranceInfo);
    const showVehicleInsurance = useSelector(baseDealSelectors.getVehicleInsuranceStatus);
    const sessionId = useSelector(offerReduxSelectors.getSessionId);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const dealXgVersion = useSelector(offerReduxSelectors.getDealXgVersion);
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const vin = useSelector(vehicleSelectors.getVehicleVin);

    const [insuranceProvider, setInsuranceProvider] = useState(vehicleInsuranceInfo?.insuranceCompany || '');
    const [insuranceAgentName, setInsuranceAgentName] = useState(vehicleInsuranceInfo?.agentName || '');
    const [insuranceStreetAddress, setInsuranceStreetAddress] = useState(vehicleInsuranceInfo?.agentAddress?.addressLine1 || '');
    const [insuranceState, setInsuranceState] = useState(vehicleInsuranceInfo?.agentAddress?.stateProvince || '');
    const [insuranceZipCode, setInsuranceZipCode] = useState(vehicleInsuranceInfo?.agentAddress?.postalCode || '');
    const [insuranceCity, setInsuranceCity] = useState(vehicleInsuranceInfo?.agentAddress?.city || '');
    const [insurancePolicyNumber, setInsurancePolicyNumber] = useState(vehicleInsuranceInfo?.policyNumber || '');
    const [insuranceExpiration, setInsuranceExpiration] = useState(vehicleInsuranceInfo?.policyExpirationDate || null);
    const [insuranceCollision, setInsuranceCollision] = useState(vehicleInsuranceInfo?.deductibles?.collisionDeductible || 0);
    const [insuranceComprehensive, setInsuranceComprehensive] = useState(
        vehicleInsuranceInfo?.deductibles?.comprehensiveDeductible || 0
    );
    const [insurancePhone, setInsurancePhone] = useState(vehicleInsuranceInfo?.agentPhoneNumber || '');
    const [fieldValidationStatus, setFieldValidationStatus] = useState({});

    const [filteredInsuranceProviders, setFilteredInsuranceProviders] = useState<InsuranceProvidersFormatted[]>([]);
    const [isSearchingProvider, setIsSearchingProvider] = useState(false);

    const theme = useInterstateTheme();
    const stateSelectInputValues = useMemo(getStateCodesSelectInputValues, StateCodes);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!insuranceProviders.length) {
            getInsuranceProviders();
        }
    }, []);

    useEffect(() => {
        setFilteredInsuranceProviders(insuranceProviders);
    }, [insuranceProviders]);

    // Function to update the validation status of field
    const updateFieldValidationStatus = (fieldName: string, value: boolean) => {
        setFieldValidationStatus((prevFields) => ({
            ...prevFields,
            [fieldName]: value
        }));
    };

    // - `insuranceZipCode` is empty (required field)
    // - Check if all fields are valid
    const isSaveDisabled = Object.values(fieldValidationStatus).includes(false);

    const handleSaveButton = () => {
        dispatch(baseDealActionCreators.setVehicleInsuranceStatus(!showVehicleInsurance));
        dispatch(
            baseDealActionCreators.saveVehicleInsuranceInfo({
                insuranceCompany: insuranceProvider,
                policyNumber: insurancePolicyNumber,
                agentName: insuranceAgentName,
                agentAddress: {
                    addressLine1: insuranceStreetAddress,
                    city: insuranceCity,
                    postalCode: insuranceZipCode,
                    stateProvince: insuranceState
                },
                agentPhoneNumber: insurancePhone,
                deductibles: {
                    comprehensiveDeductible: Number(insuranceComprehensive),
                    collisionDeductible: Number(insuranceCollision)
                },
                policyExpirationDate: insuranceExpiration && formatAsYYYYMMDD(new Date(insuranceExpiration))
            })
        );
    };

    const onChangeInsuranceField = (e: any, field: string) => {
        updateFieldValidationStatus(field, e.isValid);
        switch (field) {
            case 'provider':
                setInsuranceProvider(e.target.value);
                break;
            case 'agentName':
                setInsuranceAgentName(e.target.value);
                break;
            case 'streetAddress':
                setInsuranceStreetAddress(e.target.value);
                break;
            case 'state':
                setInsuranceState(e.target.value);
                break;
            case 'zipCode':
                setInsuranceZipCode(e.target.value);
                break;
            case 'city':
                setInsuranceCity(e.target.value);
                break;
            case 'policyNumber':
                setInsurancePolicyNumber(e.target.value);
                break;
            case 'expiration':
                setInsuranceExpiration(e.target.value.dateValue);
                break;
            case 'collisionDeductible':
                setInsuranceCollision(e.target.value);
                break;
            case 'comprehensiveDeductible':
                setInsuranceComprehensive(e.target.value);
                break;
            case 'phone':
                setInsurancePhone(e.target.value);
                break;
        }
    };

    const logNRAction = useCallback(
        (name: string, searchTerm: string, selection?: string) => {
            const action = {
                sessionId,
                dealXgId,
                dealXgVersion,
                dealerId,
                vin,
                searchTerm,
                ...(selection && { selection })
            };
            logNewRelicPageAction(name, {
                sessionId,
                dealXgId,
                dealXgVersion,
                dealerId,
                vin,
                searchTerm,
                ...(selection && { selection })
            });
        },
        [dealXgId, dealXgVersion, dealerId, sessionId, vin]
    );

    const onSearchProvider = (input: string) => {
        setIsSearchingProvider(true);

        // set local provider state
        onChangeInsuranceField({ target: { value: input } }, 'provider');

        // filter provider list when input length is greater than 3
        if (input.length >= 3) {
            const filterData = insuranceProviders.filter((option) => option.label.toLowerCase().includes(input.toLowerCase()));
            setFilteredInsuranceProviders(filterData);

            // log to NewRelic
            if (filterData.length > 0) {
                logNRAction('MV:InsuranceForm - Found match', input);
            } else {
                logNRAction('MV:InsuranceForm - No match', input);
            }
        } else {
            setFilteredInsuranceProviders([]);
        }

        setIsSearchingProvider(false);
    };

    const provider = (
        <Grid key="insurance-provider" xs={6}>
            <TextInput
                id="vehicle-insurance-provider"
                label="Provider"
                name="insurance-provider"
                onChange={(e) => onChangeInsuranceField(e, 'provider')}
                value={insuranceProvider}
                data-testid="vehicle-insurance-provider"
            />
        </Grid>
    );

    const changeInsuranceProvider = (e: AutocompleteOptions) => {
        const provider = e as InsuranceProvidersFormatted;
        const currentSearchTerm = insuranceProvider;

        // clear filtered list
        setFilteredInsuranceProviders([]);

        // set all the fields
        onChangeInsuranceField({ target: { value: provider.label } }, 'provider');
        onChangeInsuranceField({ target: { value: provider.Address } }, 'streetAddress');
        onChangeInsuranceField({ target: { value: provider.City } }, 'city');
        onChangeInsuranceField({ target: { value: provider.ZIP } }, 'zipCode');
        onChangeInsuranceField({ target: { value: provider.State } }, 'state');
        onChangeInsuranceField({ target: { value: (provider.Phone ?? '').replace(/\D/g, '') } }, 'phone');
        onChangeInsuranceField({ target: { value: provider.Address } }, 'streetAddress');

        // log to NewRelic
        logNRAction('MV:InsuranceForm - Provider selected', currentSearchTerm, provider.label);
    };

    const providerWithAutocomplete = (
        <Grid key="insurance-provider" xs={6}>
            <Autocomplete
                label="Provider"
                menuOptions={filteredInsuranceProviders}
                id="vehicle-insurance-provider"
                isLoading={isSearchingProvider}
                data-testid="vehicle-insurance-provider"
                value={insuranceProvider}
                onSearch={onSearchProvider}
                onSelect={changeInsuranceProvider}
            />
        </Grid>
    );

    const agentName = (
        <Grid key="agent-name" xs={6}>
            <TextInput
                id="vehicle-agent-name"
                label="Agent Name"
                name="agent-name"
                onChange={(e) => onChangeInsuranceField(e, 'agentName')}
                value={insuranceAgentName}
                data-testid="vehicle-agent-name"
            />
        </Grid>
    );

    const streetAddress = (
        <Grid key="street-address" xs={12}>
            <TextInput
                id="vehicle-street-address"
                label="Street Address"
                name="street-address"
                onChange={(e) => onChangeInsuranceField(e, 'streetAddress')}
                value={insuranceStreetAddress}
                data-testid="vehicle-street-address"
                maxLength={60}
                customErrorMessages={{
                    exceedsMaxLength: 'Exceeds maximum length'
                }}
            />
        </Grid>
    );

    const state = (
        <Grid key="state" xs={3}>
            <SelectInput
                label="State"
                name="state"
                onChange={(e) => onChangeInsuranceField(e, 'state')}
                options={stateSelectInputValues}
                data-testid="vehicle-state"
                value={insuranceState}
                maxHeight="250px"
                placeholder="Select"
            />
        </Grid>
    );

    const zipCode = (
        <Grid key="zip-code" xs={3}>
            <PostalCodeInput
                id="vehicle-zip-code"
                label="ZIP Code"
                name="zip-code"
                onChange={(e) => onChangeInsuranceField(e, 'zipCode')}
                value={insuranceZipCode}
                data-testid="vehicle-zip-code"
                country="US"
                customErrorMessages={{ invalidPostalCode: 'ZIP Code is invalid' }}
            />
        </Grid>
    );

    const city = (
        <Grid key="city" xs={6}>
            <TextInput
                id="vehicle-city"
                label="City"
                name="city"
                onChange={(e) => onChangeInsuranceField(e, 'city')}
                value={insuranceCity}
                data-testid="vehicle-city"
                maxLength={30}
                customErrorMessages={{
                    exceedsMaxLength: 'Exceeds maximum length'
                }}
            />
        </Grid>
    );

    const policyNumber = (
        <Grid key="policy-number" xs={6}>
            <TextInput
                id="vehicle-policy-number"
                label="Policy Number"
                name="policy-number"
                onChange={(e) => onChangeInsuranceField(e, 'policyNumber')}
                value={insurancePolicyNumber}
                data-testid="vehicle-policy-number"
            />
        </Grid>
    );

    const expiration = (
        <Grid key="expiration" xs={6}>
            <DatePicker
                id="vehicle-expiration"
                label="Expiration"
                onChange={(e) => onChangeInsuranceField(e, 'expiration')}
                value={insuranceExpiration && new Date(insuranceExpiration)}
                data-testid="vehicle-expiration"
                name="expiration"
            />
        </Grid>
    );

    const collisionDeductible = (
        <Grid key="collision-deductible" xs={6}>
            <PriceInput
                id="vehicle-collision"
                label="Collision Deductible"
                name="collision-deductible"
                onChange={(e) => onChangeInsuranceField(e, 'collisionDeductible')}
                value={insuranceCollision}
                data-testid="vehicle-collision"
                autoInsertCommas
            />
        </Grid>
    );

    const comprehensiveDeductible = (
        <Grid key="comprehensive-deductible" xs={6}>
            <PriceInput
                id="vehicle-comprehensive"
                label="Comprehensive Deductible"
                name="comprehensive-deductible"
                onChange={(e) => onChangeInsuranceField(e, 'comprehensiveDeductible')}
                value={insuranceComprehensive}
                data-testid="vehicle-comprehensive"
                autoInsertCommas
            />
        </Grid>
    );

    const phone = (
        <Grid key="phone" xs={6}>
            <PhoneInput
                id="vehicle-phone"
                label="Phone"
                name="phone"
                onChange={(e) => onChangeInsuranceField(e, 'phone')}
                value={insurancePhone}
                data-testid="vehicle-phone"
            />
        </Grid>
    );

    const slideOutFooterActions = (
        <FooterContainer theme={theme}>
            <Button
                data-testid="vehicle-insurance-slideout-cancel-btn"
                id="vehicle-insurance-slideout-cancel-btn"
                buttonStyle="tertiary"
                onClick={() => {
                    dispatch(baseDealActionCreators.setVehicleInsuranceStatus(!showVehicleInsurance));
                }}
            >
                Cancel
            </Button>
            <Button
                disabled={isSaveDisabled}
                data-testid="vehicle-insurance-slideout-save-btn"
                id="vehicle-insurance-slideout-save-btn"
                buttonStyle="primary"
                size="medium"
                onClick={() => handleSaveButton()}
            >
                Apply
            </Button>
        </FooterContainer>
    );

    return (
        <VehicleInsuranceInfoSlideOutContentWrapper>
            <Grid container spacing={2} paddingBottom={4} paddingTop={1}>
                {insuranceProviders.length ? providerWithAutocomplete : provider}
                {agentName}
                <Grid xs={12}>
                    <Typography variant="h5">Mailing Address</Typography>
                </Grid>
                {streetAddress}
                {state}
                {zipCode}
                {city}
                <Grid xs={12}>
                    <Typography variant="h5">Policy</Typography>
                </Grid>
                {policyNumber}
                {expiration}
                {collisionDeductible}
                {comprehensiveDeductible}
                <Grid xs={12}>
                    <Typography variant="h5">Contact</Typography>
                </Grid>
                {phone}
            </Grid>
            {slideOutFooterActions}
        </VehicleInsuranceInfoSlideOutContentWrapper>
    );
};

export default InsuranceForm;
