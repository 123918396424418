import { FC, KeyboardEventHandler, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// interstate components
import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';
import { InformationCircleIcon } from '@interstate/components/Icons/InformationCircleIcon';
import { InterstateOnChangeCallback } from '@interstate/components/InterstateEvents';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { NumericInput } from '@interstate/components/NumericInput';
import { Typography } from '@interstate/components/Typography';

// CSS style components
import { kebabCase } from '@makemydeal/dr-common-utils';
import {
    RollToOptionsTabbedContentWrapper,
    StyledRollToOptionsBannerRegion,
    StyledRollToOptionsTextInput
} from '../RollToOptions.interstate.style';

type DisplayProps = {
    option: string;
    currentValue: number;
    instruction: string;
};

type RollToOptionTargetProps = {
    displayProps: DisplayProps;
    dispatchAction: (value: number) => void;
    clearAction?: () => void;
};

const RollToOptionTarget: FC<RollToOptionTargetProps> = ({ displayProps, dispatchAction, clearAction }) => {
    const { option, currentValue, instruction } = displayProps;
    const dispatch = useDispatch();
    const theme = useInterstateTheme();
    const [targetInputValue, setTargetInputValue] = useState<string>('');
    const [targetInputFieldWasDirtied, setTargetInputFieldWasDirtied] = useState<boolean>(false);

    const handleSubmitTargetInput = useCallback(() => {
        const targetPayment = Number(targetInputValue);

        if (!targetPayment || isNaN(targetPayment)) {
            return;
        }
        setTargetInputFieldWasDirtied(true);
        dispatch(dispatchAction(targetPayment));
    }, [dispatch, dispatchAction, targetInputValue]);

    const handleClearTargetInput = () => {
        setTargetInputValue('');
        setTargetInputFieldWasDirtied(false);
        if (clearAction) {
            dispatch(clearAction());
        }
    };

    const handleChangeTargetPayment: InterstateOnChangeCallback<string> = useCallback(
        (event) => {
            const value = event.target.value;

            setTargetInputValue(value);
            if (targetInputFieldWasDirtied && value !== targetInputValue) {
                setTargetInputFieldWasDirtied(false);
            }
        },
        [targetInputFieldWasDirtied, targetInputValue]
    );

    const handleKeyDownTargetPayment: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (document.activeElement !== e.target) {
            return;
        }
        const key = e.key;
        const keyCode = e.keyCode;

        if (key === 'Enter' || keyCode === 13) {
            handleSubmitTargetInput();
        }
    };

    return (
        <RollToOptionsTabbedContentWrapper id="roll-to-options-tab-content" data-testid="roll-to-options-tab-content">
            <StyledRollToOptionsBannerRegion id="roll-to-options-banner-region" data-testid="roll-to-options-banner-region">
                <Typography variant="h3" tag="div">
                    {instruction}
                </Typography>

                <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-between" flexWrap="nowrap">
                    <div>
                        <span id={`current-payment-label`}>{`Current ${option}`}</span>
                        <span id={`current-payment-value`} data-testid={`current-payment-value-${kebabCase(option)}`}>
                            ${currentValue}
                        </span>
                    </div>

                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <span id="target-payment-input-label">{`Target ${option}`}</span>
                        <span>
                            <InformationCircleIcon data-testid="target-payment-info-icon" color={theme.tokens.BaseColorBlue500} />
                        </span>

                        <StyledRollToOptionsTextInput>
                            <NumericInput
                                displayLabel={false}
                                id="target-payment-text-input"
                                data-testid="target-payment-text-input"
                                inputPrefix="$"
                                value={targetInputValue}
                                name="roll-to-options-text-input"
                                onChange={handleChangeTargetPayment}
                                onKeyDown={handleKeyDownTargetPayment}
                            />
                        </StyledRollToOptionsTextInput>
                    </Box>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <div>
                        {targetInputFieldWasDirtied ? (
                            <Button
                                data-testid="btn-target-payment-clear-input"
                                buttonStyle="secondary"
                                size="small"
                                onClick={handleClearTargetInput}
                            >
                                Clear
                            </Button>
                        ) : (
                            <Button
                                data-testid="btn-target-payment-set-target"
                                buttonStyle="secondary"
                                size="small"
                                onClick={handleSubmitTargetInput}
                            >
                                Set Target
                            </Button>
                        )}
                    </div>
                </Box>
            </StyledRollToOptionsBannerRegion>
        </RollToOptionsTabbedContentWrapper>
    );
};

export default RollToOptionTarget;
