import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

// externals
import { useDispatch, useSelector } from 'react-redux';
import { SelectInputOption } from '@interstate/components/SelectInput';
import { TextInput } from '@interstate/components/TextInput';

// libraries
import {
    tradeInSelectors,
    tradeInComponentActionCreators,
    tradeInComponentSelectors,
    tradeInActionCreators
} from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// components
import { SelectInput } from './TradeInSelectInput';

// selectors
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// constants/enums
import { TRADE_IN_MAKE } from '@makemydeal/dr-dash-components';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { BreakPoint } from '@makemydeal/dr-activities-common';

interface MakeFieldProps {
    isManualFormOpen: boolean;
}

const MakeField: React.FC<MakeFieldProps> = ({ isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleMakeId = useSelector(tradeInSelectors.getTradeMakeId);
    const tradeInVehicleMakes = useSelector(tradeInComponentSelectors.getTradeInComponentMakes);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const vinHasBeenDecoded = useSelector(tradeInSelectors.vinHasBeenDecoded);
    const hasMultipleVin = useSelector(tradeInSelectors.hasMultipleVin);
    const makeValue = Number(vehicleYear) === 0 || vehicleMake === 'default' ? '' : vehicleMake;
    const disabled = hasMultipleVin || (tradeInVehicleMakes.options.length === 0 && !makeValue) || !Number(vehicleYear);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isPhoneScreen = useMediaQuery({ query: `(max-width: ${BreakPoint.PHONE})` });

    useEffect(() => {
        const make = tradeInVehicleMakes.options.find((option) => vehicleMake === option.name);

        if (make) {
            dispatch(tradeInActionCreators.updateTradeInMakeId(make.id));
        }
    }, [dispatch, tradeInVehicleMakes, vehicleMake]);

    useEffect(() => {
        if (vinHasBeenDecoded || (vehicleYear && vehicleMake && vehicleMakeId)) {
            dispatch(
                tradeInComponentActionCreators.fetchTradeInComponentModels(MANAGER_VIEW_APP_PREFIX, vehicleYear, vehicleMakeId)
            );
        }
    }, [vinHasBeenDecoded, vehicleYear, vehicleMake, vehicleMakeId, dispatch]);

    const getTradeInMakes = (): SelectInputOption[] => {
        const makesOptions =
            makeValue && !tradeInVehicleMakes.options.find((make) => make.name === makeValue)
                ? [...tradeInVehicleMakes.options, { name: makeValue }]
                : tradeInVehicleMakes.options;

        return makesOptions.map((make) => ({ label: make.name, value: make.name })).sort();
    };

    const onMakeChange = (event: any) => {
        const make = tradeInVehicleMakes.options.find((make) => make.name === event.target.value);
        dispatch(tradeInActionCreators.updateTradeInMake(make));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                make,
                model: { id: '', name: '' },
                trim: { id: '', name: '', vehicleId: '' }
            })
        );
    };

    return (
        <>
            {isBaseDealScreenExperience && (
                <label
                    style={{ fontSize: isPhoneScreen ? '0.857rem' : '1rem' }}
                    data-testid="trade-in-make-label"
                    htmlFor="trade-makes-select-input"
                >
                    Make (required)
                </label>
            )}
            {isManualFormOpen && (
                <SelectInput
                    id="trade-makes-select-input"
                    data-testid="trade-makes-select-input"
                    label={TRADE_IN_MAKE}
                    name={TRADE_IN_MAKE}
                    onChange={onMakeChange}
                    options={getTradeInMakes()}
                    value={makeValue}
                    placeholder="Select"
                    disabled={disabled}
                    required
                    displayDeselectOption={false}
                    displayLabel={!isBaseDealScreenExperience}
                />
            )}
        </>
    );
};

export default MakeField;
