import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

export const getCashDealLabel = (totalAmountDue: number) => {
    return `C, ${formatDollarsAndCents(totalAmountDue)}`;
};

export const getLeaseDealLabel = ({
    amountPaidByCustomer,
    months = 0,
    monthlyPayment,
    rateTypeVal,
    rateTypeLabel
}: {
    amountPaidByCustomer: number;
    months?: number;
    monthlyPayment: number;
    rateTypeVal: number;
    rateTypeLabel: string;
}) => {
    return [
        `L${months}, ${formatDollarsAndCents(monthlyPayment)}/mo - ${rateTypeVal}${rateTypeLabel}`,
        `Amount Paid by Customer: ${formatDollarsAndCents(amountPaidByCustomer)}`
    ];
};

export const getFinanceDealLabel = ({
    downPayment,
    months = 0,
    monthlyPayment,
    rateTypeVal,
    rateTypeLabel
}: {
    downPayment: number;
    months?: number;
    monthlyPayment: number;
    rateTypeVal: number;
    rateTypeLabel: string;
}) => {
    return [
        `F${months}, ${formatDollarsAndCents(monthlyPayment)}/mo - ${rateTypeVal}${rateTypeLabel}`,
        `Cash Down: ${formatDollarsAndCents(downPayment)}`
    ];
};
