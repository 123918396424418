import { useDispatch } from 'react-redux';
import { baseDealActionCreators } from '@makemydeal/dr-dash-store';
import { Action } from '@interstate/components/Action';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { DocumentTextIcon } from '@interstate/components/Icons/DocumentTextIcon';
import { Typography } from '@interstate/components/Typography';
import { AnchorFlexRowCentered } from './CoBuyerLink.styles';
import VehicleInsuranceSlideOut from '../vehicleInsurance/VehicleInsuranceSlideOut';

export const VehicleInsuranceLink = () => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();

    return (
        <>
            <Typography
                variant="body-md"
                tag="span"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '.4rem',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    marginTop: '2px'
                }}
            >
                <Action
                    size="md"
                    data-testid="vehicle-insurance-btn"
                    onClick={() => {
                        dispatch(baseDealActionCreators.setVehicleInsuranceStatus(true));
                    }}
                >
                    <span style={{ fontWeight: 400 }}>Insurance</span>
                </Action>
            </Typography>
            <VehicleInsuranceSlideOut />
        </>
    );
};
