// externals
import React from 'react';
import { MouseEvent } from 'react';

// interstate components
import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';

// CSS style components
import { RollToWarningOverlay } from '../RollToStyles';

interface Props {
    handleDiscardChanges: (event: MouseEvent<HTMLButtonElement>) => void;
    handleKeepEditing: () => void;
}

const RollToDiscardWarning: React.FC<Props> = ({ handleDiscardChanges, handleKeepEditing }) => {
    return (
        <RollToWarningOverlay data-testid="roll-to-warning-overlay">
            <React.Fragment data-testid="roll-to-discard-warning">
                <Typography variant="h3" data-testid="roll-to-discard-warning-title" sx={{ paddingBottom: '2rem' }}>
                    Roll-To Calculator: Lease Deal
                </Typography>
                <Typography variant="body-md" data-testid="roll-to-discard-warning-text" sx={{ paddingBottom: '2rem' }}>
                    You have unsaved changes in the Roll-To calculator. Are you sure you want to discard them?
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'right',
                        gap: '1rem'
                    }}
                >
                    <Button
                        onClick={handleKeepEditing}
                        size="small"
                        buttonStyle="tertiary"
                        data-testid="roll-to-keep-editing-button"
                        sx={{ minWidth: '5rem' }}
                    >
                        Keep Editing
                    </Button>
                    <Button
                        onClick={handleDiscardChanges}
                        size="small"
                        data-testid="roll-to-discard-changes-button"
                        sx={{ minWidth: '5rem' }}
                    >
                        Discard changes
                    </Button>
                </div>
            </React.Fragment>
        </RollToWarningOverlay>
    );
};

export default RollToDiscardWarning;
