// externals
import { useEffect } from 'react';
import hotkeys from 'hotkeys-js';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Action } from '@interstate/components/Action';
import { BaseDealHeaderRoot, BaseDealHeaderTitle, BaseDealBreakdown } from './BaseDealActivity.interstate.style';
import { ChevronRightIcon, ChevronLeftIcon } from '@interstate/components/Icons';
import { DeskingAlerts } from '../DeskingAlerts';
import { ContractFailureAlert, PaymentStatus } from '@makemydeal/dr-dash-components';
import { Typography } from '@interstate/components/Typography';

// selectors
import { baseDealSelectors, baseDealActionCreators, baseDealAnalyticsActionCreators } from '@makemydeal/dr-dash-store';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import BaseDealTabContent from './baseDealContainer/BaseDealTabContent';
import BreakdownSlideout from './baseDealBreakdown/BreakdownSlideout';

const BaseDealHeader: React.FC = () => {
    const dispatch = useDispatch();
    const showBreakdown = useSelector(baseDealSelectors.getBreakdownStatus);
    const enableGenerateContracts = useSelector(dealerSelectors.getEnableGenerateContracts);
    const isBreakDownSlideoutOpen = useSelector(baseDealSelectors.getBreakdownSlideoutStatus);
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);

    const handleBreakdownStatusAndAnalytics = (action: 'click' | 'hotkey') => {
        const { breakdownExpand, breakdownCollapse, breakdownExpandHotkey, breakdownCollapseHotkey } =
            baseDealAnalyticsActionCreators;
        const shouldShowBreakdown = !showBreakdown;
        dispatch(baseDealActionCreators.setBreakdownStatus(shouldShowBreakdown));
        if (shouldShowBreakdown) {
            dispatch(action === 'click' ? breakdownExpand() : breakdownExpandHotkey());
        } else {
            dispatch(action === 'click' ? breakdownCollapse() : breakdownCollapseHotkey());
        }
    };

    // hotkey for breakdown
    useEffect(() => {
        hotkeys('alt+d,CapsLock+alt+d', function (event) {
            event.preventDefault();
            handleBreakdownStatusAndAnalytics('hotkey');
        });

        return () => {
            hotkeys.unbind('alt+d,CapsLock+alt+d');
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBreakdown]);

    return (
        <>
            <PaymentStatus sx={{ marginBottom: 2 }} />
            <DeskingAlerts sx={{ marginBottom: 2 }} />
            {enableGenerateContracts && <ContractFailureAlert />}
            {isBreakDownSlideoutOpen && <BreakdownSlideout />}
            <BaseDealHeaderRoot isDealCentralExperience={isDealCentralExperience}>
                <BaseDealHeaderTitle>Deal Details</BaseDealHeaderTitle>
                <BaseDealBreakdown>
                    <Action data-testid="base-deal-breakdown" onClick={() => handleBreakdownStatusAndAnalytics('click')}>
                        <Typography variant="anchor-block-md" color="base.color.blue.500" className="base-deal-breakdown-icon">
                            {showBreakdown ? null : <ChevronLeftIcon />}
                            Breakdown
                            {showBreakdown ? <ChevronRightIcon /> : null}
                        </Typography>
                    </Action>
                </BaseDealBreakdown>
            </BaseDealHeaderRoot>
            {isDealCentralExperience && <BaseDealTabContent isDealCentralExperienceTabs={true} />}
        </>
    );
};

export default BaseDealHeader;
