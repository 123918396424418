/**
 * PURPOSE: Co-ordinates post-bootstrap initialization.  This is needed because feature toggles and other data are not available
 *   when the app first loads.
 */
import OktaAuth, { AccessToken, IDToken, OktaAuthOptions, TokenManagerInterface, UserClaims } from '@okta/okta-auth-js';

// libraries
import {
    bridgeUserSelectors,
    configSelectors,
    creditDecisionSelectors,
    dealLifecycleSelectors,
    dealXgDetailsSelectors,
    DMS_PLUS,
    multiScenarioActionCreators,
    offerInfoSelectors,
    offerReduxSelectors,
    offerSelectors,
    tradeInSelectors,
    vehicleActionCreators,
    vehicleProtectionActionTypes,
    vehicleSelectors
} from '@makemydeal/dr-dash-store';
import { BridgeUser } from '@makemydeal/dr-dash-types';
import { type AnyFSA } from '@makemydeal/dr-platform-shared';
import {
    bootstrapActionTypes,
    bridgeUserActionTypes,
    DashMiddleware,
    DashNext,
    dealerSelectors,
    featureToggleSelectors,
    initActionTypes
} from '@makemydeal/dr-shared-store';

// config
import { CommonDataLayerV3Props, initDrCdlV3, initDrGtm } from '@makemydeal/dr-common-data-layer-utils';
import { setCustomAttributesFromState } from '@makemydeal/dr-dash-ui-utils';
import { ApplicationCodes } from '@makemydeal/dr-platform-types';
import {
    environmentNameUtils,
    HostEnvironment,
    hostUrlHelper,
    locHrefUtil,
    MANAGER_VIEW_APP_PREFIX
} from '@makemydeal/dr-shared-ui-utils';

const DATA_LAYER_NAME = 'dataLayer';
const GTM_DATA_READY = 'GTM_DATA_READY';
const DOMAINS = ['@coxautoinc.com', '@coxinc.com'];

async function getUserSub(authClient: OktaAuth): Promise<string | undefined> {
    try {
        const tokenManager: TokenManagerInterface = authClient.tokenManager;
        const tokens = await authClient.tokenManager.getTokens();

        if (tokens) {
            const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;

            if (accessToken?.claims) {
                return accessToken.claims.sub;
            }
        }
    } catch (err) {
        console.error('Error getting user sub:', err);
    }

    return undefined;
}

async function getUserEmail(authClient: OktaAuth): Promise<string | undefined> {
    try {
        const tokenManager: TokenManagerInterface = authClient.tokenManager;
        const accessToken: AccessToken = (await tokenManager.get('accessToken')) as AccessToken;
        const idToken: IDToken = (await tokenManager.get('idToken')) as IDToken;
        const userInfo: UserClaims = await authClient.token.getUserInfo(accessToken, idToken);

        return userInfo?.email;
    } catch (error) {
        console.error('Error getting user data:', error);
    }
    return undefined;
}

const initializePendo = (bridgeUser: BridgeUser) => {
    try {
        window.pendo.initialize({
            visitor: {
                id: bridgeUser.bridgeId,
                full_name: bridgeUser.fullName
            },
            account: {
                id: bridgeUser.accountId ? `${bridgeUser.accountId}` : 'dc_manager_view',
                dc_dealerName: bridgeUser.dealerName,
                dc_dealXgId: bridgeUser.dealXgId,
                dc_gtmVariation: bridgeUser.gtmVariation,
                dc_isTestDealer: bridgeUser.isTestDealer
            }
        });
    } catch (error) {
        console.error('Failed to initialize Pendo:', error);
    }
};

export const postBootstrapMiddleware: DashMiddleware = (store: any) => (next: DashNext) => async (action: AnyFSA) => {
    // need reducers to execute first so that we can use the state that's updated by INIT_PENCIL_SUCCESS
    const nextResult = next(action);
    let state;
    const config: OktaAuthOptions = {
        issuer: 'https://authorize.coxautoinc.com/oauth2/aus132uaxy2eomhmi357'
    };

    switch (action.type) {
        case bridgeUserActionTypes.BRIDGE_USER_SUCCESS: {
            state = store.getState();
            const gtmId = configSelectors.getGtmId(state);

            const isPendoEnabled = featureToggleSelectors.enablePendoInMV(state);
            const enableCommonOrgIdForPendo = featureToggleSelectors.enableCommonOrgIdForPendo(state);
            const enableMetadataForPendo = featureToggleSelectors.enableMetadataForPendo(state);

            if (isPendoEnabled) {
                const bridgeId = bridgeUserSelectors.getBridgeId(state);
                const fullName = bridgeUserSelectors.getFullName(state);
                const accountId = offerReduxSelectors.getCommonOrgId(state);

                let pendoObj: BridgeUser = { bridgeId, fullName };
                if (enableCommonOrgIdForPendo) pendoObj = { ...pendoObj, accountId };
                if (enableMetadataForPendo)
                    pendoObj = {
                        ...pendoObj,
                        dealerName: dealerSelectors.getDealerName(state),
                        dealXgId: offerReduxSelectors.getDealXgId(state),
                        gtmVariation: dealXgDetailsSelectors.getIsEcommerceDeal(state)
                            ? 'Ecommerce'
                            : dealerSelectors.getDMSIntegrationToggle(state) === DMS_PLUS
                            ? DMS_PLUS
                            : 'DealCentral',
                        isTestDealer: dealerSelectors.getIsTestDealer(state)
                    };

                initializePendo(pendoObj);
            }

            const isCDL3EnabledInMV = featureToggleSelectors.isCDL3EnabledInMV(state);
            const environmentEnum = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());
            let isUserInternal = undefined;
            if (isCDL3EnabledInMV) {
                try {
                    const authClient: OktaAuth = new OktaAuth(config);
                    const userEmail = await getUserEmail(authClient);
                    if (userEmail) {
                        isUserInternal = DOMAINS.some((domain) => domain.includes(userEmail.split('@')[1])) || undefined;
                    }
                    const currentOfferType = offerReduxSelectors.getCurrentOfferType(state);
                    const cdlV3Data: CommonDataLayerV3Props = {
                        application: {
                            componentId: 'CI2355894',
                            isProduction: environmentEnum === HostEnvironment.Prod,
                            name: 'Manager View',
                            workloadId: 'CI2355893'
                        },
                        client: {
                            clientName: dealerSelectors.getDealerName(state),
                            commonOrgId: offerReduxSelectors.getCommonOrgId(state),
                            isTestDealer: dealerSelectors.getIsTestDealer(state),
                            vinDealerId: dealerSelectors.getVinSolutionsDealerId(state)
                        },
                        user: {
                            bridgePlatformId: bridgeUserSelectors.getBridgeId(state),
                            bridgeUsername: bridgeUserSelectors.getBridgeUsername(state),
                            isInternalUser: isUserInternal,
                            type: 'dealer'
                        },
                        vehicle: {
                            vin: vehicleSelectors.getVehicleVin(state),
                            make: vehicleSelectors.getVehicleMake(state),
                            model: vehicleSelectors.getVehicleModel(state),
                            year: vehicleSelectors.getVehicleYear(state)
                        },
                        deal: {
                            dealId: offerReduxSelectors.getDealXgId(state),
                            dealVersion: offerReduxSelectors.getDealXgVersion(state),
                            dealStatus: dealLifecycleSelectors.getDealLifecycleStatus(state),
                            offerPrice: offerReduxSelectors.getOfferPrice(state),
                            downPayment: offerReduxSelectors.getDownPaymentByOfferType(state, currentOfferType),
                            termLength: offerReduxSelectors.getTermIdByOfferType(state, currentOfferType),
                            lenderDecision: creditDecisionSelectors.getLenderCreditDecisionsByOfferType(state, currentOfferType),
                            monthlyPayment: offerReduxSelectors.getBaseMonthlyPaymentTotal(state),
                            paymentFrequency: offerSelectors.getPaymentFrequency(state),
                            sellRate: offerReduxSelectors.getSellRate(state),
                            buyRate: offerReduxSelectors.getBuyRate(state),
                            residualValue: offerReduxSelectors.getBaseResidualDollar(state),
                            acquisitionFee: offerReduxSelectors.getAcquisitionFeeFromTerm(state),
                            maxResidualMSRP: offerReduxSelectors.getMaxResidualizedMsrp(state),
                            securityDeposit: offerReduxSelectors.getSecurityDepositOverride(state, true),
                            commonConsumerId: offerReduxSelectors.getShopperCommonConsumerId(state),
                            globalCustomerId: dealXgDetailsSelectors.getGlobalCustomerId(state),
                            incentivesValue: offerReduxSelectors.getAllIncentiveTotal(state),
                            incentives: offerReduxSelectors.getAppliedIncentives(state),
                            tradeValue: tradeInSelectors.getTradeValue(state),
                            trade: tradeInSelectors.getTradeIn(state),
                            paymentMethod: currentOfferType,
                            autoLeadId: dealXgDetailsSelectors.getAutoLeadId(state),
                            leaseMileage: offerReduxSelectors.getLeaseMileage(state).toString()
                        }
                    };
                    initDrCdlV3(cdlV3Data);
                    store.dispatch({ type: 'CDLV3_DATA_READY' });
                } catch (error) {
                    console.error('Could not intialize initDrCdlV3', error);
                }
            } else if (gtmId.length) {
                const environmentString = environmentNameUtils.getEnvironmentName(environmentEnum);
                const isGtmDataLayerV2Enabled = featureToggleSelectors.isGtmDataLayerV2Enabled(state);

                if (isGtmDataLayerV2Enabled) {
                    const authClient: OktaAuth = new OktaAuth(config);
                    const userEmail = await getUserEmail(authClient);
                    const bridgePlatformId = await getUserSub(authClient);
                    if (userEmail) {
                        isUserInternal = DOMAINS.some((domain) => domain.includes(userEmail.split('@')[1])) || undefined;
                    }
                    initDrGtm({
                        gtmId,
                        dataLayerName: DATA_LAYER_NAME,
                        environment: environmentString,
                        applicationName: ApplicationCodes.MV,
                        isUserInternal,
                        isProduction: environmentEnum === HostEnvironment.Prod,
                        userType: 'dealer',
                        version: 2,
                        bridgeUsername: bridgeUserSelectors.getBridgeUsername(state),
                        commonOrgId: offerReduxSelectors.getCommonOrgId(state),
                        bridgePlatformId: bridgePlatformId
                    });

                    store.dispatch({ type: GTM_DATA_READY });
                } else {
                    initDrGtm({
                        gtmId,
                        dataLayerName: DATA_LAYER_NAME,
                        environment: environmentString,
                        applicationName: ApplicationCodes.MV,
                        isUserInternal,
                        isProduction: environmentEnum === HostEnvironment.Prod,
                        userType: 'dealer'
                    });

                    store.dispatch({ type: GTM_DATA_READY });
                }
            }

            break;
        }
        case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
            state = store.getState();
            store.dispatch({ type: vehicleProtectionActionTypes.DASH_MENU_SKIPPED });

            if (offerInfoSelectors.getIsMakingCurrent(state)) {
                store.dispatch(multiScenarioActionCreators.handleFetchMakeCurrentMultipleScenarios());
                break;
            }

            store.dispatch(multiScenarioActionCreators.handleFetchMultipleScenarios());

            break;
        }
        case initActionTypes.INIT_SUCCESS: {
            state = store.getState();
            setCustomAttributesFromState(state);

            const orgId = offerReduxSelectors.getCommonOrgId(state);

            if (!orgId) return;

            const vin = vehicleSelectors.getVehicleVin(state);
            const stockNumber = vehicleSelectors.getVehicleStockNumber(state);

            const query = vin || stockNumber;

            if (query) {
                const bffEndpoint = configSelectors.getServicesBff(state);
                store.dispatch(vehicleActionCreators.fetchCviVehiclesResults(query, orgId, bffEndpoint));
            }
        }
    }

    return nextResult;
};
