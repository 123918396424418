// externals
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// libraries
import { BootstrapLoadingState } from '@makemydeal/dr-shared-types';
import { bootstrapSharedActionCreators, sharedBootstrapSelectors } from '@makemydeal/dr-shared-store';
import {
    locHrefUtil,
    hostUrlHelper,
    HostEnvironment,
    loadingIndicatorUtils,
    MANAGER_VIEW_APP_PREFIX
} from '@makemydeal/dr-shared-ui-utils';
import { LoadingPage } from '@makemydeal/dr-dash-components';

export const Bootstrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const loadingState = useSelector(sharedBootstrapSelectors.getBootstrapLoadingState);
    const loadingErrorMessage = useSelector(sharedBootstrapSelectors.getBootstrapErrorMessage);
    const environment = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());

    useEffect(() => {
        // load the deal props from QS
        dispatch(bootstrapSharedActionCreators.initialLoad());
        // fetch the deal
        dispatch(bootstrapSharedActionCreators.fetchBootstrapData(MANAGER_VIEW_APP_PREFIX, { loadDraftScenario: true }));
    }, [dispatch]);

    useEffect(() => {
        if (loadingState !== BootstrapLoadingState.LoadedSuccessfully && loadingState !== BootstrapLoadingState.LoadFailed) return;

        loadingIndicatorUtils.removeAllLoadingIndicatorPanels();
    }, [loadingState]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        });
    }, [location]);

    if (loadingErrorMessage) return <>{loadingErrorMessage}</>;

    return (
        <>
            {loadingState === BootstrapLoadingState.Loading && <LoadingPage />}
            {loadingState === BootstrapLoadingState.LoadedSuccessfully && children}
            {loadingState === BootstrapLoadingState.LoadFailed && (
                <>
                    {environment === HostEnvironment.Local ? (
                        <>
                            An error occurred when bootstrapping the app- you will see this when running locally if (1) the BFF
                            hasn't yet started up, (2) it isn't running properly, or (3) you have invalid query params. Try
                            refreshing the browser if the first scenario applies.
                        </>
                    ) : (
                        <>Unable to bootstrap the application - either a network error or service availability issue occurred.</>
                    )}
                </>
            )}
            {loadingState.toString() === '' && 'Error occurred bootstrapping the app - unsupport state.'}
        </>
    );
};
