export const SET_SHOW_DEAL_MENTIONS = 'SET_SHOW_DEAL_MENTIONS';

export const SET_SHOW_MENTIONS_RED_DOT = 'SET_SHOW_MENTIONS_RED_DOT';

export const setShowDealMentions = (show: boolean) => ({
    type: SET_SHOW_DEAL_MENTIONS,
    payload: show
});

export const setShowMentionsRedDot = (show: boolean) => ({
    type: SET_SHOW_MENTIONS_RED_DOT,
    payload: show
});
