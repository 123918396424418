import React, { ReactNode, useMemo, createContext, useContext } from 'react';
export interface DealSummaryProviderContextType {
    showDetailedView: boolean;
}

interface DealSummaryProviderProps {
    children: ReactNode;
    showDetailedView?: boolean;
}

export const DealSummaryProviderContext = createContext<DealSummaryProviderContextType | null>(null);

export const DealSummaryProvider: React.FC<DealSummaryProviderProps> = ({ children, showDetailedView = false }) => {
    const value = useMemo(() => ({ showDetailedView }), [showDetailedView]);
    return <DealSummaryProviderContext.Provider value={value}>{children}</DealSummaryProviderContext.Provider>;
};

// Hook to access the deal summary context
export const useDealSummary = (): DealSummaryProviderContextType => {
    const context = useContext(DealSummaryProviderContext);

    // Return default context if not inside provider
    return context || { showDetailedView: false };
};
