// components
import { EditedTaxFieldInputContainer } from './EditedTaxField.style';

// actions
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { TaxTotalAmountFieldProps } from '../types';
import { useEditableProp } from './helpers';
import { PriceInput } from '@interstate/components/PriceInput';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

const InputLabelStyle = {
    width: '9rem',
    '.ids-input-component-label': {
        justifyContent: 'end !important'
    }
};

export default function TaxItemTotalField<Props extends TaxTotalAmountFieldProps>({
    data,
    handleBlur,
    shouldDisableInput = false
}: Props) {
    const formattedAmount = formatDollarsAndCents(data.taxAmount);
    const [taxAmount, setTaxAmount, isEdited] = useEditableProp(formattedAmount, data.manualChanges?.taxAmount);

    const handleBlurWrapper = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (!taxAmount && !data.taxAmount) {
            setTaxAmount('0');
        }
        handleBlur(e);
    };
    return (
        <EditedTaxFieldInputContainer $isEdited={isEdited}>
            <PriceInput
                sx={InputLabelStyle}
                id="tax-item-tax-total"
                data-testid="tax-item-tax-total"
                name={'detail-total'}
                label="Tax Total"
                onChange={(e) => setTaxAmount(e.target.value)}
                value={taxAmount || 0}
                onBlur={handleBlurWrapper}
                allowDecimal={true}
                decimalMaxLength={2}
                maxValue={999999.99}
                allowNegative={false}
                autoInsertCommas={true}
                textAlign="right"
                disabled={shouldDisableInput}
            />
        </EditedTaxFieldInputContainer>
    );
}
