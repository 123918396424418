export const SAVE_DEAL_TEMPLATE = 'SAVE_DEAL_TEMPLATE';
export const SAVE_DEAL_TEMPLATE_REQUEST = 'SAVE_DEAL_TEMPLATE_REQUEST';
export const SAVE_DEAL_TEMPLATE_SUCCESS = 'SAVE_DEAL_TEMPLATE_SUCCESS';
export const SAVE_DEAL_TEMPLATE_FAILURE = 'SAVE_DEAL_TEMPLATE_FAILURE';
export const GET_DEAL_TEMPLATES = 'GET_DEAL_TEMPLATES';
export const GET_DEAL_TEMPLATES_REQUEST = 'GET_DEAL_TEMPLATES_REQUEST';
export const GET_DEAL_TEMPLATES_SUCCESS = 'GET_DEAL_TEMPLATES_SUCCESS';
export const GET_DEAL_TEMPLATES_FAILURE = 'GET_DEAL_TEMPLATES_FAILURE';
export const DELETE_DEAL_TEMPLATE = 'DELETE_DEAL_TEMPLATE';
export const DELETE_DEAL_TEMPLATE_REQUEST = 'DELETE_DEAL_TEMPLATE_REQUEST';
export const DELETE_DEAL_TEMPLATE_SUCCESS = 'DELETE_DEAL_TEMPLATE_SUCCESS';
export const DELETE_DEAL_TEMPLATE_FAILURE = 'DELETE_DEAL_TEMPLATE_FAILURE';
export const DROP_DELETE_DEAL_TEMPLATE = 'DROP_DELETE_DEAL_TEMPLATE';
export const SHOW_TEMPLATE_SLIDEOUT = 'SHOW_TEMPLATE_SLIDEOUT';
