import { SlideOut } from '@interstate/components/SlideOut';
import { FooterButtonContainer, FooterContainer } from './removeDrawerFooter.style';
import { Button } from '@interstate/components/Button';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

export const TemplateRemoveDrawer = ({
    template,
    hideHandler,
    removeHandler
}: {
    template: { id: string; name: string };
    hideHandler: () => void;
    removeHandler: () => void;
}) => {
    const theme = useInterstateTheme();
    const isTabletSize = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenLg} ) and (max-width: 1167px)` });
    const isLargeSize = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenXl})` });

    const getPanelWidth = () => {
        if (isLargeSize) return '786px';
        if (isTabletSize) return '504px';
        return '480px';
    };

    return (
        <SlideOut
            data-testid={'remove-deal-template'}
            show
            onHide={hideHandler}
            position="right"
            header="Manage Deal Template"
            panelWidth={getPanelWidth()}
            footer={{
                footerComponent: (
                    <FooterContainer>
                        <FooterButtonContainer>
                            <Button
                                data-testid="remove-deal-template-cancel-btn"
                                buttonStyle="tertiary"
                                size="medium"
                                block={true}
                                onClick={hideHandler}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="remove-deal-template-btn"
                                buttonStyle="primary"
                                size="medium"
                                block
                                disabled={false}
                                onClick={removeHandler}
                                sx={{ padding: '0 20px' }}
                            >
                                Yes, Delete
                            </Button>
                        </FooterButtonContainer>
                    </FooterContainer>
                )
            }}
        >
            <div> Are you sure you want to delete the "{template.name}" template?</div>
        </SlideOut>
    );
};
