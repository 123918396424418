import { FlexCol } from '@makemydeal/dr-dash-components';
import { IncentivesCard } from './IncentivesCard';
import { Programs } from './Programs';
import { ProgramsAndIncentivesHeader } from './ProgramsAndIncentivesHeader';
import { ProgramsAndIncentivesFooter } from './ProgramsAndIncentivesFooter';
import { useSelector } from 'react-redux';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

export const ProgramsAndIncentives = () => {
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    return (
        <FlexCol gap="1rem">
            <ProgramsAndIncentivesHeader />
            {!isDealCentralExperience && <IncentivesCard />}
            <Programs />
            <ProgramsAndIncentivesFooter />
        </FlexCol>
    );
};
