import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IDeskingActivity } from '../types/IDeskingActivity';
import { getAllRoutes } from '../utils/routes';

import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { ActivityRouteContainer } from '@makemydeal/dr-dash-components';
import InterstateDeskingActivity from './DeskingActivity.interstate';
import BaseDealActivity from './baseDealActivity/BaseDealActivity.interstate';

const Root = (props: IDeskingActivity) => {
    const { root } = getAllRoutes(props.baseRoute);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isBaseDealer = useSelector(dealerSelectors.isBaseDealExperience);

    const getRootComponent = () => {
        if (isBaseDealScreenExperience && isBaseDealer) {
            return BaseDealActivity;
        }
        return InterstateDeskingActivity;
    };

    return (
        <ActivityRouteContainer
            data-testid="activities-desking-container"
            className="activities-desking"
            isNewAppWidthEnabled={isABPencilEnabled}
        >
            <Route path={root} component={getRootComponent()} />
        </ActivityRouteContainer>
    );
};

Root.displayName = 'DeskingActivity';

export default Root;
