/* eslint-disable no-console */
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { type DashMiddleware, type DashNext, type DashStore } from '@makemydeal/dr-shared-store';
import { getContractingTimeout } from '../selectors/config';
import { OFFER_SAVE_SUCCESS, OFFER_SAVE_FAILURE } from '../actionTypes/offerActionTypes';
import {
    CONTRACTING_CANCEL_FAILURE_EVENT,
    CONTRACTING_EVENT_RECEIVED,
    CONTRACTING_IN_PROGRESS,
    CONTRACTING_OFFER_SAVE_FAILURE_EVENT,
    CONTRACTING_VERIFY_SUCCESS_EVENT,
    CONTRACTING_STAGE_CANCEL_FAILURE,
    CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT,
    CONTRACTING_STAGE_START_FAILURE,
    CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT,
    CONTRACTING_STAGE_VERIFY_FAILURE,
    CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT,
    CONTRACTING_START_FAILURE_EVENT,
    CONTRACTING_TIMEOUT_FAILURE,
    CONTRACTING_VERIFY_FAILURE_EVENT,
    CONTRACTING_CLOSE_MODAL,
    CONTRACTING_STAGE_CANCEL_SUCCESS
} from '../actionTypes/contractingActionTypes';
import {
    contractingCloseModal,
    contractingSendCancel,
    contractingSendStart,
    contractingSendVerify,
    contractingStartProgress
} from '../actions/contractingActionCreators';

import { navigationActionCreators, offerActionCreators } from '../actions';

import { getDealXgId, getCurrentOfferType } from '../selectors/offerRedux';
import { getDealXgVersion } from '../selectors/offerInfoSelectors';
import { currentOfferHasUnsavedChanges } from '../selectors/smartOfferSaveSelectors';

import { getContractingStage, getStartTime, isInProgress, getContractingCorrelationId } from '../selectors/contractingSelectors';
import { CASH } from '@makemydeal/dr-platform-types';

/** one minute */
const CONTRACTING_DEFAULT_TIMEOUT_MS = 60e3;
const CONTRACTING_CANCEL_TIMEOUT_MS = 15e3;

let timeoutTimer: any = null;
let cancelTimer: any = null;

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    const prevState = store.getState();

    next(action);

    const state = store.getState();

    switch (action.type) {
        /**
         * Cash deals may not receive a cancel event, so wait 15 seconds before proceeding with the start call
         */
        case CONTRACTING_STAGE_CANCEL_SUCCESS: {
            const currentOfferType = getCurrentOfferType(state);
            if (currentOfferType === CASH) {
                cancelTimer = setTimeout(() => {
                    const delayedState = store.getState();
                    if (getContractingStage(delayedState) === 'cancel') {
                        store.dispatch(contractingSendStart());
                    }
                }, CONTRACTING_CANCEL_TIMEOUT_MS);
            }
            break;
        }
        case CONTRACTING_CLOSE_MODAL: {
            clearTimeout(timeoutTimer);
            const inProgress = isInProgress(prevState);
            if (inProgress) {
                const stage = getContractingStage(prevState);
                const startTime = getStartTime(prevState);
                const durationSeconds = (Date.now() - startTime!) / 1000;

                const attributes = {
                    durationSeconds,
                    stage,
                    success: action.meta?.success ?? null
                };
                const message = 'MV:Contracting - Workflow complete';

                console.debug(message, attributes);
                logNewRelicPageAction(message, attributes);
            } else {
                logNewRelicPageAction('MV:Contracting - modal dismissed', { inProgress: false });
            }
            break;
        }
        case OFFER_SAVE_SUCCESS: {
            // Saving unsaved changes was successful
            if (action.meta?.source === CONTRACTING_IN_PROGRESS) {
                store.dispatch(contractingStartProgress());
            }
            break;
        }

        case OFFER_SAVE_FAILURE: {
            if (action.meta?.source === CONTRACTING_IN_PROGRESS) {
                store.dispatch(contractingCloseModal(false));
                store.dispatch({
                    type: CONTRACTING_OFFER_SAVE_FAILURE_EVENT,
                    payload: action.payload
                });
            }
            break;
        }

        case CONTRACTING_EVENT_RECEIVED: {
            const eventName: string = action.payload?.eventName;
            const dealXgId = getDealXgId(state);
            const dealXgVersion = getDealXgVersion(state);
            const stage = getContractingStage(state);
            const inProgress = isInProgress(state);
            const eventCorrelationId = action.payload?.eventKeyData?.correlationId;
            const currentCorrelationId = getContractingCorrelationId(state);

            // Add correlation ID check
            if (eventCorrelationId !== currentCorrelationId && !eventName.includes('ContractingDecisions:Verified')) {
                console.debug('Contracting: Correlation ID mismatch', {
                    eventCorrelationId,
                    currentCorrelationId,
                    eventName
                });
                logNewRelicPageAction('MV:Contracting - Correlation ID Mismatch', {
                    dealXgId,
                    dealXgVersion,
                    eventName,
                    eventCorrelationId,
                    currentCorrelationId
                });
                break;
            }
            const logUnexpectedEvent = () => {
                console.debug('Contracting: Unexpected Event', { eventName, stage, ...action.payload, inProgress });
                logNewRelicPageAction('MV:Contracting - Unexpected Event', {
                    dealXgId,
                    dealXgVersion,
                    eventName,
                    stage,
                    inProgress
                });
            };
            if (!inProgress) {
                logUnexpectedEvent();
                break;
            }

            const logEvent = () => {
                logNewRelicPageAction('MV:Contracting - Received Event', { dealXgId, dealXgVersion, eventName, inProgress });
                console.debug('Contracting: Received Event', { eventName, stage, ...action.payload, inProgress });
            };

            switch (eventName) {
                case 'Contracting:Cancelled':
                case 'Contracting:CancelFailed': {
                    if (stage !== 'cancel') {
                        logUnexpectedEvent();
                        break;
                    }
                    logEvent();
                    clearTimeout(cancelTimer);
                    if (eventName === 'Contracting:CancelFailed' && action.payload.payload?.message?.current_state != null) {
                        // We failed to cancel the contract
                        store.dispatch(contractingCloseModal(false));
                        store.dispatch({
                            type: CONTRACTING_CANCEL_FAILURE_EVENT,
                            payload: action.payload
                        });
                        break;
                    }
                    // Successfully cancelled contract OR no existing contract,
                    // continue next stage of starting a new contract
                    store.dispatch(contractingSendStart());
                    break;
                }
                case 'Contracting:SavedSuccessfully': {
                    if (stage !== 'start') {
                        logUnexpectedEvent();
                        break;
                    }
                    logEvent();
                    // Successfully saved contract, start verification stage
                    store.dispatch(contractingSendVerify());
                    break;
                }
                case 'Contracting:SaveFailed': {
                    if (stage !== 'start') {
                        logUnexpectedEvent();
                        break;
                    }
                    logEvent();
                    store.dispatch(contractingCloseModal(false));
                    store.dispatch({
                        type: CONTRACTING_START_FAILURE_EVENT,
                        payload: action.payload
                    });

                    break;
                }
                case 'ContractingDecisions:Verified':
                case 'ContractingDecisions:VerifiedWithWarnings': {
                    if (stage !== 'verify') {
                        logUnexpectedEvent();
                        break;
                    }
                    logEvent();
                    store.dispatch(contractingCloseModal(true));
                    store.dispatch({
                        type: CONTRACTING_VERIFY_SUCCESS_EVENT,
                        payload: action.payload
                    });
                    store.dispatch(navigationActionCreators.navigateToDDJ());
                    break;
                }
                case 'ContractingDecisions:VerificationFailed': {
                    if (stage !== 'verify') {
                        logUnexpectedEvent();
                        break;
                    }
                    logEvent();
                    store.dispatch(contractingCloseModal(false));
                    store.dispatch({
                        type: CONTRACTING_VERIFY_FAILURE_EVENT,
                        payload: action.payload
                    });
                    break;
                }
                default: {
                    logUnexpectedEvent();
                    break;
                }
            }
            break;
        }

        case CONTRACTING_IN_PROGRESS: {
            if (getContractingStage(state) === undefined && currentOfferHasUnsavedChanges(state)) {
                store.dispatch(offerActionCreators.saveOffer({ source: CONTRACTING_IN_PROGRESS }));
                break;
            }

            // Start timeout
            clearTimeout(timeoutTimer);
            timeoutTimer = setTimeout(() => {
                store.dispatch(contractingCloseModal(false));
                store.dispatch({ type: CONTRACTING_TIMEOUT_FAILURE });
            }, getContractingTimeout(state) ?? CONTRACTING_DEFAULT_TIMEOUT_MS);

            // Send initial cancel contract request
            store.dispatch(contractingSendCancel());

            break;
        }

        case CONTRACTING_STAGE_CANCEL_FAILURE:
            store.dispatch(contractingCloseModal(false));
            store.dispatch({
                type: CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT,
                payload: action.payload
            });
            break;
        case CONTRACTING_STAGE_START_FAILURE:
            store.dispatch(contractingCloseModal(false));
            store.dispatch({
                type: CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT,
                payload: action.payload
            });
            break;
        case CONTRACTING_STAGE_VERIFY_FAILURE: {
            // The synchronous calls failed, we assume that the whole process
            // failed
            store.dispatch(contractingCloseModal(false));
            store.dispatch({
                type: CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT,
                payload: action.payload
            });
            break;
        }
    }
};
