import { shallowEqual, useSelector } from 'react-redux';
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';

import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { contractingSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { ContractFailureAlertContainer, ContractFailureReasons, ContractingFailureTitle } from './ContractAlert.style';

export const ContractFailureAlert = () => {
    const dealRefId = useSelector(offerSelectors.getDealRefId);
    const alertInfo = useSelector(contractingSelectors.getFailureAlertInfo, shallowEqual);

    if (alertInfo.status !== 'failure') {
        return null;
    }

    const reasons = alertInfo.reasons;
    const openContractDeepLink = () => {
        logNewRelicPageAction('MV:Contracting - Clicked DT deep link', { dealRefId });
        window.open(alertInfo.deepLinkUrl, '_blank');
    };

    return (
        <ContractFailureAlertContainer>
            <Alert type="error" data-testid="contracting-failure" disableSuccessFade>
                <ContractingFailureTitle variant="h6">{alertInfo.title}</ContractingFailureTitle>
                <Typography variant="body-sm">
                    {alertInfo.message}
                    {alertInfo.deepLinkTexts && ' '}
                    {alertInfo.deepLinkTexts && (
                        <Action onClick={openContractDeepLink} data-testid="contract-deep-link">
                            <Typography variant="anchor-inline-sm" tag="a">
                                {alertInfo.deepLinkTexts}
                            </Typography>
                        </Action>
                    )}
                </Typography>
                {reasons && (
                    <ContractFailureReasons>
                        {reasons.map((reason, index) => (
                            <li key={index}>{reason}</li>
                        ))}
                    </ContractFailureReasons>
                )}
            </Alert>
        </ContractFailureAlertContainer>
    );
};
