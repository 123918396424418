import { FC } from 'react';
// components
import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';
import { Box } from '@interstate/components/Box';

// styles
import { ButtonContainer, ContentContainer } from './TemplateSaveStatusDialog.style';
import { COMPONENT_ID, TEMPLATE_EXCLUDED_ITEMS, TEMPLATE_SAVED_ITEMS } from './constants';

export type TemplateSaveStatusDialogProps = {
    show: boolean;
    onHide: () => void;
    handleSave: () => void;
};

const renderSavedItems = (savedItems: (string | string[])[][]) => {
    return savedItems.map((itemGroup, groupIndex) => {
        return itemGroup.map((item, itemIndex) => {
            if (Array.isArray(item)) {
                return (
                    <Box key={`${groupIndex}-${itemIndex}`} ml={4}>
                        {item.map((subItem, subIndex) => (
                            <Typography key={subIndex} variant="body-md">
                                {subItem}
                            </Typography>
                        ))}
                    </Box>
                );
            }
            return (
                <Typography key={`${groupIndex}-${itemIndex}`} variant="list-md">
                    {item}
                </Typography>
            );
        });
    });
};

const TemplateSaveStatusDialog: FC<TemplateSaveStatusDialogProps> = ({ show, onHide, handleSave }) => (
    <>
        <Modal
            size="large"
            id={COMPONENT_ID}
            data-testid={COMPONENT_ID}
            show={show}
            sx={{
                '.interstate-x-mark-icon': {
                    cursor: 'pointer'
                }
            }}
            header={
                <Box mt={0.5}>
                    <Typography variant="h3" data-testid={`${COMPONENT_ID}-header`}>
                        Template Save Status
                    </Typography>
                </Box>
            }
            onHide={onHide}
        >
            <ContentContainer>
                <Box>
                    <Typography variant="h4">Items Saved in Template:</Typography>
                    {renderSavedItems(TEMPLATE_SAVED_ITEMS)}
                </Box>
                <Box mt={2}>
                    <Typography variant="h4">Note:</Typography>
                    <Typography variant="body-md">
                        For lease deals, the template will only save: lease terms, mileage plan, non-rated VPPs, manually added
                        accessories and rebates, dealer cash. Lease payments will continue to be sourced from your configured
                        lenders and their available programs.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h4">Items not Saved in Template:</Typography>
                    {TEMPLATE_EXCLUDED_ITEMS.map((item, index) => (
                        <Typography key={index} variant="list-md">
                            {item}
                        </Typography>
                    ))}
                </Box>
                <ButtonContainer>
                    <Box mr={1}>
                        <Button
                            buttonStyle="tertiary"
                            data-testid={`${COMPONENT_ID}-cancel-button`}
                            size="default"
                            onClick={onHide}
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Button data-testid={`${COMPONENT_ID}-confirm-button`} size="default" onClick={handleSave}>
                        Save Template
                    </Button>
                </ButtonContainer>
            </ContentContainer>
        </Modal>
    </>
);

export default TemplateSaveStatusDialog;
