import styled from 'styled-components';

export const TaxOverrideHeaderContainer = styled.div`
    border-bottom: 1px solid #b2b2b2;
`;

export const TaxOverrideHeaderBaseContainer = styled.div`
    padding-top: 1.25rem;
    border-bottom: 1px solid #b2b2b2;
`;
