import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';

type TotalSummary = {
    totalType: string;
    totalValue: number;
    paddingRight: number;
};

const TotalSummary: React.FC<TotalSummary> = ({ totalType, totalValue, paddingRight }) => {
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    return (
        <>
            {isBaseDealScreenExperience ? (
                <Box display="grid" textAlign="end" alignItems="center" justifyContent="end" gap={'0.7rem'}>
                    <Typography variant="label-md" color="sem.color.on-surface.muted">
                        {`Total ${totalType}`}
                    </Typography>
                    <Typography variant="h5" color="base.color.black" sx={{ textAlign: 'right' }}>
                        {formatDollarsAndCents(totalValue)}
                    </Typography>
                </Box>
            ) : (
                <Box display="flex" alignItems="center" justifyContent="end">
                    <Typography variant="h5" color="base.color.black" sx={{ paddingRight }}>
                        {`Total ${totalType}: ${formatDollarsAndCents(totalValue)}`}
                    </Typography>
                </Box>
            )}
        </>
    );
};
export default TotalSummary;
