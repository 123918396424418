export const APPLY_ROLL_TO_OPTIONS = 'APPLY_ROLL_TO_OPTIONS';
export const APPLY_ROLL_TO_OPTIONS_FAILURE = 'APPLY_ROLL_TO_OPTIONS_FAILURE';
export const APPLY_ROLL_TO_OPTIONS_PAYMENT_FAILED = 'APPLY_ROLL_TO_OPTIONS_PAYMENT_FAILED';
export const APPLY_ROLL_TO_OPTIONS_SUCCESS = 'APPLY_ROLL_TO_OPTIONS_SUCCESS';
export const CLEAR_ROLL_TO_OPTIONS = 'CLEAR_ROLL_TO_OPTIONS';
export const REQUEST_ROLL_TO_OPTIONS = 'REQUEST_ROLL_TO_OPTIONS';
export const REQUEST_ROLL_TO_PAYMENTS = 'REQUEST_ROLL_TO_PAYMENTS';
export const REQUEST_ROLL_TO_PAYMENTS_FAILURE = 'REQUEST_ROLL_TO_PAYMENTS_FAILURE';
export const REQUEST_ROLL_TO_PAYMENTS_SUCCESS = 'REQUEST_ROLL_TO_PAYMENTS_SUCCESS';
export const REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPQ = 'REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPQ';
export const REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR = 'REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR';
export const RESET_ROLL_TO_OPTIONS = 'RESET_ROLL_TO_OPTIONS';
export const REVERT_ROLL_TO_ORIGINAL = 'REVERT_ROLL_TO_ORIGINAL';
export const ROLL_TO_PAYMENT_TOGGLE = 'ROLL_TO_PAYMENT_TOGGLE';
export const SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES';
export const UPDATE_ADJUST_ROLL_TO_CHANGES = 'UPDATE_ADJUST_ROLL_TO_CHANGES';
export const UPDATE_ROLL_ADJUST = 'UPDATE_ROLL_ADJUST';
export const UPDATE_ROLL_TARGET_PAYMENT = 'UPDATE_ROLL_TARGET_PAYMENT';
export const UPDATE_ROLL_TARGET_BALANCE_DUE = 'UPDATE_ROLL_TARGET_BALANCE_DUE';
export const UPDATE_ROLL_TO_OPTIONS = 'UPDATE_ROLL_TO_OPTIONS';
export const CLEAR_ROLL_TO_ERRORS = 'CLEAR_ROLL_TO_ERRORS';
