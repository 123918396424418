import {
    DELETE_DEAL_TEMPLATE,
    DELETE_DEAL_TEMPLATE_FAILURE,
    DELETE_DEAL_TEMPLATE_REQUEST,
    DELETE_DEAL_TEMPLATE_SUCCESS,
    DROP_DELETE_DEAL_TEMPLATE,
    GET_DEAL_TEMPLATES,
    GET_DEAL_TEMPLATES_FAILURE,
    GET_DEAL_TEMPLATES_REQUEST,
    GET_DEAL_TEMPLATES_SUCCESS,
    SAVE_DEAL_TEMPLATE,
    SAVE_DEAL_TEMPLATE_FAILURE,
    SAVE_DEAL_TEMPLATE_REQUEST,
    SAVE_DEAL_TEMPLATE_SUCCESS,
    SHOW_TEMPLATE_SLIDEOUT
} from '../actionTypes/dealTemplateActionTypes';
import { DraftDealTemplate, RemovingTemplateData, StateTree } from '@makemydeal/dr-dash-types';
import { locHrefUtil, apiConfigHelper, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { RSAA } from 'redux-api-middleware';
import { SavedOfferPayloadObject } from '../types/comparisonUtilsTypes';
import { buildFromConfig, IConfigObj } from '@makemydeal/dr-common-utils';
import { RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';

type BridgeUserData = {
    bridgeId: string;
    name: string;
};

export type TemplateSavePayload = SavedOfferPayloadObject & { user: BridgeUserData };

export type saveTemplateRSAAOptions = {
    state: StateTree;
    templateSavePayload: TemplateSavePayload;
    source?: string;
};

export const saveDealTemplateSuccess = (template: DraftDealTemplate) => ({
    type: SAVE_DEAL_TEMPLATE_SUCCESS,
    payload: template
});

export const saveTemplate = () => {
    return {
        type: SAVE_DEAL_TEMPLATE
    };
};

export const getDealTemplates = () => {
    return {
        type: GET_DEAL_TEMPLATES
    };
};

export const removeDealTemplate = (template: RemovingTemplateData) => {
    return {
        type: DELETE_DEAL_TEMPLATE,
        payload: { template }
    };
};

export const dropRemoveDealTemplateData = () => {
    return {
        type: DROP_DELETE_DEAL_TEMPLATE
    };
};

export const setTemplateSlideOutStatus = (show: boolean) => ({
    type: SHOW_TEMPLATE_SLIDEOUT,
    payload: { showTemplateSlideOut: show }
});

export const saveTemplateRSAA = (options: saveTemplateRSAAOptions) => {
    const { templateSavePayload, source } = options;
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'template/save');
    const endpoint = bffApiUrlResult.url;

    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(templateSavePayload),
            types: [
                { type: SAVE_DEAL_TEMPLATE_REQUEST },
                {
                    type: SAVE_DEAL_TEMPLATE_SUCCESS,
                    meta: { source }
                },
                {
                    type: SAVE_DEAL_TEMPLATE_FAILURE,
                    meta: { source }
                }
            ]
        }
    };
};

export const getTemplatesRSAA = (dealerId: number, bffEndpointConfig: IConfigObj) => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, '');
    const dealerIdEscaped = encodeURIComponent(dealerId);
    const endpoint = `${proxyUrl}/dealer/${dealerIdEscaped}/deal-templates`;

    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [GET_DEAL_TEMPLATES_REQUEST, GET_DEAL_TEMPLATES_SUCCESS, GET_DEAL_TEMPLATES_FAILURE],
            // Overriding the fetch function to ensure compatibility with the module's context.
            // When working with a federated module, the global `fetch` instance (window.fetch) may differ from the host application's instance.
            // This can cause issues with interceptors
            fetch: async (...args) => {
                return await fetch(...args);
            }
        } satisfies RSAACallRequest
    };
};

export const deleteTemplatesRSAA = (dealerId: number, template: RemovingTemplateData, bffEndpointConfig: IConfigObj) => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, '');
    const dealerIdEscaped = encodeURIComponent(dealerId);
    const templateIdEscaped = encodeURIComponent(template.id);
    const endpoint = `${proxyUrl}/dealer/${dealerIdEscaped}/deal-templates/${templateIdEscaped}`;

    return {
        [RSAA]: {
            endpoint,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            types: [
                {
                    type: DELETE_DEAL_TEMPLATE_REQUEST,
                    meta: { template }
                },
                DELETE_DEAL_TEMPLATE_SUCCESS,
                DELETE_DEAL_TEMPLATE_FAILURE
            ],
            // Overriding the fetch function to ensure compatibility with the module's context.
            // When working with a federated module, the global `fetch` instance (window.fetch) may differ from the host application's instance.
            // This can cause issues with interceptors
            fetch: async (...args) => {
                return await fetch(...args);
            }
        } satisfies RSAACallRequest
    };
};
