import { Grid } from '@interstate/components/Grid';
import React from 'react';

// Components
import {
    AcquisitionFeeAmountField,
    AdjustedResidualField,
    BaseResidualField,
    CashDownField,
    CreditDecisionLenderNameField,
    DaysToFirstPaymentField,
    ExcessMileageChargeField,
    FinanceTermField,
    GENERIC_LAST_FIELD_CHANGED_ERROR,
    GridTable,
    LeaseTermField,
    LoadingSectionParent,
    MaxResidualizedMsrpAppliedROField,
    MaxResidualizedMsrpROField,
    MilesPerYearField,
    NO_VALID_PAYMENT_EXIST,
    PaymentLoading,
    ProfitInfo,
    RetailPriceField,
    SecurityDepositField,
    SellingPriceField,
    SellRateField
} from '@makemydeal/dr-dash-components';
import { Popover } from '@interstate/components/Popover';
import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { useSelector } from 'react-redux';
import { CASH, FINANCE, LEASE, OfferType } from '@makemydeal/dr-platform-types';
import {
    accessoriesSelectors,
    baseDealSelectors,
    offerReduxSelectors,
    paymentSelectors,
    vehicleProtectionSelectors
} from '@makemydeal/dr-dash-store';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

import TradeInAllowance from './TradeInFields/TradeAllowance.interstate';
import ACV from './TradeInFields/ACV.interstate';
import Payoff from './TradeInFields/Payoff.interstate';
import ReadOnlyInput from './ReadOnlyInputs.interstate';
import {
    BaseDealSummaryFieldsContainer,
    BaseDealSummaryMainContainer,
    BaseDealSummarySectionContainer,
    SummaryField
} from './BaseDealSummary.style';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { BaseTabs } from '../baseDealTypes';
import { useBaseDealBreakpoint } from '../../../utils/useBaseDealBreakpoint';
import { PaymentGridToggle } from '@makemydeal/dr-dash-components';
import { IPaymentGridErrorState, StateTree } from '@makemydeal/dr-dash-types';
import { usePaymentGridValidation } from '@makemydeal/dr-dash-components';
import { Alert } from '@interstate/components/Alert';
import { AlertError } from '@makemydeal/dr-dash-components';
import { Box } from '@interstate/components/Box';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

type ColumnConfig = {
    desktop: number;
    tablet: number;
    mobile: number;
    paymentGridOpen: {
        desktop: number;
        tablet: number;
        mobile: number;
    };
};
const paymentGridOpenConfig = () => {
    return {
        desktop: 3,
        tablet: 3,
        mobile: 2
    };
};
const summaryLeftColumnConfig: Record<OfferType, ColumnConfig> = {
    [LEASE]: {
        desktop: 3,
        tablet: 3,
        mobile: 2,
        paymentGridOpen: paymentGridOpenConfig()
    },
    [FINANCE]: {
        desktop: 5,
        tablet: 3,
        mobile: 2,
        paymentGridOpen: paymentGridOpenConfig()
    },
    [CASH]: {
        desktop: 5,
        tablet: 3,
        mobile: 2,
        paymentGridOpen: paymentGridOpenConfig()
    }
};
const summaryRightColumnConfig: Record<OfferType, ColumnConfig> = {
    [LEASE]: {
        desktop: 2,
        tablet: 3,
        mobile: 2,
        paymentGridOpen: paymentGridOpenConfig()
    },
    [FINANCE]: {
        desktop: 4,
        tablet: 3,
        mobile: 2,
        paymentGridOpen: paymentGridOpenConfig()
    },
    [CASH]: {
        desktop: 2,
        tablet: 3,
        mobile: 2,
        paymentGridOpen: paymentGridOpenConfig()
    }
};

type SectionField = {
    component: React.ReactElement;
    desktopSpan?: number;
    tabletSpan?: number;
    mobileSpan?: number;
    fieldSpan?: number;
    condition?: (type: OfferType) => boolean;
};

export enum Sections {
    Left = 'LEFT',
    Right = 'RIGHT'
}

const BaseDealSummary = () => {
    const isMobile = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });

    //incentives
    const totalRebates = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const incentives = formatDollarsAndCents(totalRebates);

    //accessories
    const totalAccessoriesAmount = useSelector(accessoriesSelectors.getAccessoriesTotalAmount);
    const accessories = formatDollarsAndCents(totalAccessoriesAmount);

    //VPP
    const { totalProductPrice } = useSelector(vehicleProtectionSelectors.getVppSummary);
    const vpp = formatDollarsAndCents(totalProductPrice);

    //fees
    const totalFees = useSelector(offerReduxSelectors.getTotalFee);
    const totalFeesFormatted = formatDollarsAndCents(totalFees);

    // total tax
    const taxTotal = useSelector(offerReduxSelectors.getTotalTax);
    const formattedTotalTax = formatDollarsAndCents(taxTotal);

    // current offer type
    const currentOfferType: OfferType = useSelector(offerReduxSelectors.getCurrentOfferType);

    // paymentGrid
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);

    const paymentGridErrorState: IPaymentGridErrorState = useSelector(offerReduxSelectors.getPaymentGridErrorState);
    const isPaymentError = useSelector(paymentSelectors.getIsError);
    const { shouldDisablePaymentGridToggle } = usePaymentGridValidation(isPaymentError, paymentGridErrorState);
    const paymentGridEnabledToggle = useSelector((state: StateTree) =>
        offerReduxSelectors.getPaymentGridToggle(state, currentOfferType)
    );
    const isCreditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);
    const isPaymentGridEnabled = usePaymentGridMV && paymentGridEnabledToggle && isDealCentralExperience;

    const breakpoints = useBaseDealBreakpoint(true, isPaymentGridEnabled);
    const isBreakdownOpen = useSelector(baseDealSelectors.getBreakdownStatus);

    const isPaymentGridDisabled = () => {
        if (currentOfferType === CASH) return true;
        else if (currentOfferType === FINANCE)
            return shouldDisablePaymentGridToggle || (!paymentGridEnabledToggle && isPaymentError);
        else return shouldDisablePaymentGridToggle || isCreditDecisionEnabled || (!paymentGridEnabledToggle && isPaymentError);
    };
    const paymentGridInputSpan = isPaymentGridEnabled ? 2 : 1;

    const leftSectionFields = [
        { component: <RetailPriceField /> },
        { component: <SellingPriceField /> },
        {
            condition: (type: OfferType) => type !== CASH,
            component: <CashDownField />
        },
        { component: <TradeInAllowance /> },
        { component: <ACV /> },
        { component: <Payoff /> },
        { condition: (type: OfferType) => type === LEASE, component: <SecurityDepositField /> },
        {
            condition: (type: OfferType) => type === LEASE,
            component: <BaseResidualField />,
            tabletSpan: paymentGridInputSpan,
            desktopSpan: paymentGridInputSpan
        },
        {
            condition: (type: OfferType) => type === LEASE,
            component: <AcquisitionFeeAmountField />,
            tabletSpan: paymentGridInputSpan,
            desktopSpan: paymentGridInputSpan
        },
        {
            condition: (type: OfferType) => type === LEASE,
            component: <AdjustedResidualField />
        },
        {
            condition: (type: OfferType) => type === LEASE,
            component: <MilesPerYearField isSummaryTab={true} />
        },
        {
            condition: (type: OfferType) => type === LEASE,
            component: (
                <div className="base-deal-lease-field">
                    <MaxResidualizedMsrpROField />
                    <MaxResidualizedMsrpAppliedROField />
                </div>
            ),
            tabletSpan: paymentGridInputSpan,
            desktopSpan: paymentGridInputSpan
        },
        { condition: (type: OfferType) => type === LEASE, component: <ExcessMileageChargeField /> },

        { component: <ReadOnlyInput label="Fees" inputValue={totalFeesFormatted} tab={BaseTabs.FEES} /> },
        { component: <ReadOnlyInput label="Taxes" inputValue={formattedTotalTax} tab={BaseTabs.TAXES} /> }
    ];

    const rightSectionFields = [
        {
            condition: (type: OfferType) => type !== CASH,
            component: <CreditDecisionLenderNameField isSummaryTab={true} />,
            mobileSpan: 1,
            ...(currentOfferType === LEASE && { desktopSpan: 2 }),
            ...(currentOfferType !== LEASE && { fieldSpan: 2 })
        },
        { condition: (type: OfferType) => type === LEASE, component: <LeaseTermField isSummaryTab={true} /> },
        { condition: (type: OfferType) => type === FINANCE, component: <FinanceTermField isSummaryTab={true} /> },
        { condition: (type: OfferType) => type !== CASH, component: <SellRateField isSummaryTab={true} /> },
        { condition: (type: OfferType) => type !== CASH, component: <DaysToFirstPaymentField /> },
        { component: <ReadOnlyInput label="Incentives" inputValue={incentives} tab={BaseTabs.INCENTIVES} /> },
        { component: <ReadOnlyInput label="Protection Product" inputValue={vpp} tab={BaseTabs.PROTECTION} /> },
        { component: <ReadOnlyInput label="Accessories" inputValue={accessories} tab={BaseTabs.ACCESSORIES} /> }
    ];

    const renderField = (field: SectionField, index: number) => {
        const desktopSpan = field.desktopSpan || field.fieldSpan || 1;
        const tabletSpan = field.tabletSpan || field.fieldSpan || 1;
        const mobileSpan = field.mobileSpan || field.fieldSpan || 1;

        return (
            (!field.condition || field.condition(currentOfferType)) && (
                <SummaryField
                    lg={desktopSpan}
                    md={tabletSpan}
                    sm={mobileSpan}
                    key={index}
                    breakpoints={breakpoints}
                    offerType={currentOfferType}
                >
                    {field.component}
                </SummaryField>
            )
        );
    };

    const getDealTypeTitle = () => {
        switch (currentOfferType) {
            case CASH:
                return 'Cash';
            case LEASE:
                return 'Lease';
            case FINANCE:
                return 'Finance';
        }
    };

    const getColumnConfig = (summaryColConfig: Record<OfferType, ColumnConfig>, isPaymentGridEnabled: boolean) =>
        isPaymentGridEnabled ? summaryColConfig[currentOfferType].paymentGridOpen : summaryColConfig[currentOfferType];

    return (
        <LoadingSectionParent>
            <PaymentLoading testId="loading-indicator" />
            <Grid padding={'0px'} sx={{ containerName: 'base-deal-summary-container', containerType: 'inline-size' }}>
                {usePaymentGridMV && isDealCentralExperience && (
                    <Box>
                        {isPaymentGridEnabled && !isCreditDecisionEnabled && currentOfferType === FINANCE && (
                            <Grid xs={12} padding="0" marginBottom="1rem">
                                <Alert role="banner" type="info" data-testid="upq-alert-warning">
                                    <Typography variant="label-md">
                                        'Payments may not be honoring a lender program. Go to Programs and Incentives to select.'
                                    </Typography>
                                </Alert>
                            </Grid>
                        )}
                        {!isCreditDecisionEnabled && currentOfferType === LEASE && (
                            <Grid xs={12} padding="0" marginBottom="1rem">
                                <Alert role="banner" type="info" data-testid="upq-alert-warning">
                                    <Typography variant="label-md">
                                        'To be able to use Payment Grid, go to Programs and Incentives to select.'
                                    </Typography>
                                </Alert>
                            </Grid>
                        )}
                        {paymentGridErrorState.hasError && isPaymentGridEnabled && (
                            <AlertError message={GENERIC_LAST_FIELD_CHANGED_ERROR} />
                        )}
                        {shouldDisablePaymentGridToggle && (
                            <AlertError message={NO_VALID_PAYMENT_EXIST} testId="no-valid-payment" />
                        )}
                    </Box>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: isDealCentralExperience ? 'space-between' : 'flex-start'
                    }}
                >
                    <Typography variant={'h3'}>{getDealTypeTitle()}</Typography>
                    {usePaymentGridMV && isDealCentralExperience && (
                        <PaymentGridToggle currentOfferType={currentOfferType} disabled={isPaymentGridDisabled()} />
                    )}
                </div>

                <BaseDealSummaryMainContainer
                    breakpoints={breakpoints}
                    isBreakdownOpen={isBreakdownOpen}
                    isPaymentGridOpen={isPaymentGridEnabled}
                >
                    <Grid container padding={`${isMobile ? 0 : 4}`} justifyContent="space-between">
                        <BaseDealSummarySectionContainer
                            isPaymentGridEnabled={isPaymentGridEnabled}
                            data-testid="base-deal-summary-section-container"
                            breakpoints={breakpoints}
                            offerType={currentOfferType}
                        >
                            <BaseDealSummaryFieldsContainer
                                section={Sections.Left}
                                offerType={currentOfferType}
                                lg={getColumnConfig(summaryLeftColumnConfig, isPaymentGridEnabled).desktop}
                                md={getColumnConfig(summaryLeftColumnConfig, isPaymentGridEnabled).tablet}
                                sm={getColumnConfig(summaryLeftColumnConfig, isPaymentGridEnabled).mobile}
                                breakpoints={breakpoints}
                                isPaymentGridEnabled={isPaymentGridEnabled}
                            >
                                {leftSectionFields.map((field, index) => renderField(field, index))}
                            </BaseDealSummaryFieldsContainer>

                            <BaseDealSummaryFieldsContainer
                                section={Sections.Right}
                                offerType={currentOfferType}
                                lg={getColumnConfig(summaryRightColumnConfig, isPaymentGridEnabled).desktop}
                                md={getColumnConfig(summaryRightColumnConfig, isPaymentGridEnabled).tablet}
                                sm={getColumnConfig(summaryRightColumnConfig, isPaymentGridEnabled).mobile}
                                breakpoints={breakpoints}
                                isPaymentGridEnabled={isPaymentGridEnabled}
                            >
                                {rightSectionFields.map((field, index) => renderField(field, index))}
                            </BaseDealSummaryFieldsContainer>
                        </BaseDealSummarySectionContainer>
                    </Grid>

                    {isPaymentGridEnabled && isDealCentralExperience && (
                        <Grid
                            data-testid="payment-grid-container"
                            sx={{ margin: '0px', padding: '0px', minWidth: isMobile ? '100%' : '50%' }}
                        >
                            <GridTable />
                            {isMobile && <hr style={{ marginBottom: '0px' }}></hr>}
                        </Grid>
                    )}
                </BaseDealSummaryMainContainer>

                <hr />

                <Grid
                    sx={{
                        paddingTop: '0.714rem',
                        paddingBottom: '0.714rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column'
                    }}
                >
                    <Typography sx={{ marginBottom: '0.714rem' }} variant="label-md">
                        Profit
                    </Typography>
                    <Popover
                        padding={true}
                        header="Profit Details"
                        data-testid="profit-details-popover"
                        position="right-start"
                        trigger="outsideClick"
                        popoverContent={<ProfitInfo />}
                    >
                        <Action data-testid="profit-details-btn" size="sm">
                            Details
                        </Action>
                    </Popover>
                </Grid>
            </Grid>
        </LoadingSectionParent>
    );
};

export default BaseDealSummary;
