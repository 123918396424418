export const COMPONENT_ID = 'template-save-status';

export const TEMPLATE_SAVED_ITEMS = [
    ['Inventory type (New/CPO) and make of the vehicle'],
    ['Template is saved for single vehicle configuration (primary one)'],
    ['Used vehicles exclude make and template will apply to all used vehicles'],
    [
        'Up to 3 deal scenarios with the following configurations:',
        [
            'Down payment amounts or percentage (for finance deals)',
            'Financing terms (APR, loan term) (for finance deals)',
            'Non-rated Vehicle protection products (VPPs)',
            'Manually added accessories',
            'Manually entered rebates and dealer cash'
        ]
    ]
];

export const TEMPLATE_EXCLUDED_ITEMS = [
    'Customer Information',
    'Offer History',
    'Rated Vehicle protection products (VPPs)',
    'Digital Deal Jacket Documents',
    'Contract',
    'Credit application',
    'Trade-In'
];
