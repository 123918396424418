// externals
import { FC, useMemo, useState } from 'react';

// libraries
import { PriceInputInterstate, customInterstateValidationRules as rules } from '@makemydeal/dr-dash-components';

// interfaces/types
import {
    ColumnMapping,
    InputChangeInterstateEventHandler,
    ValidateInterstateValidationRulesHandler
} from './../manualAccessoriesTypes';

// consts/enums
import * as deskingConstants from '../../../../constants';

export type ManualAccessorySaleFieldStateProps = {
    deleted: boolean;
    index: number;
    amount: number;
    saleColumnMapping: ColumnMapping<number>;
    weOwe: boolean;
};

export type ManualAccessorySaleFieldDispatchProps = {
    handleInputChange: InputChangeInterstateEventHandler;
    validateRules: ValidateInterstateValidationRulesHandler;
};

export type ManualAccessorySaleFieldProps = ManualAccessorySaleFieldDispatchProps & ManualAccessorySaleFieldStateProps;

const getValidationRules = (weOwe: boolean) => {
    const required = rules.getRequiredValidationRule(deskingConstants.REQUIRED);
    const minValue = rules.getMinValueValidationRule(0, deskingConstants.REQUIRED, false);
    const maxValue = rules.getMaxValueValidationRule(
        deskingConstants.ACCESSORIES_NUMERIC_MAX_VALUE,
        deskingConstants.ACCESSORIES_NUMERIC_MAX_VALUE_MESSAGE,
        true
    );
    const conditionalRules = weOwe ? [] : [required, minValue];
    return [maxValue, ...conditionalRules];
};

export const ManualAccessorySaleField: FC<ManualAccessorySaleFieldProps> = ({
    deleted,
    index,
    amount = 0,
    saleColumnMapping: { fieldName },
    weOwe,
    handleInputChange,
    validateRules
}) => {
    const [isSaleTouched, setIsSaleTouched] = useState(false);

    const validationRules = useMemo(() => getValidationRules(weOwe), [weOwe]);

    return (
        <PriceInputInterstate
            allowDecimal={true}
            displayLabel={true}
            className={'base-accessories-label'}
            data-testid={`${fieldName}-${index}`}
            label={deskingConstants.SALE}
            name={`${fieldName}-${index}`}
            onChange={(e) => {
                handleInputChange(e, fieldName);
            }}
            onFocus={() => {
                setIsSaleTouched(true);
            }}
            required={!weOwe}
            getError={(_label, value) => {
                const isInitialValueEmpty = !isSaleTouched && amount === 0;
                return validateRules(value, validationRules, fieldName, isInitialValueEmpty);
            }}
            value={amount}
            disabled={deleted}
        />
    );
};
