import type { ContractingFailureAlertInfo, ContractingStage, StateTree, Status } from '@makemydeal/dr-dash-types';
import * as configSelectors from './config';
import * as offerSelectors from './offerSelectors';
import {
    CONTRACTING_CANCEL_FAILURE_EVENT,
    CONTRACTING_OFFER_SAVE_FAILURE_EVENT,
    CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT,
    CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT,
    CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT,
    CONTRACTING_START_FAILURE_EVENT,
    CONTRACTING_TIMEOUT_FAILURE,
    CONTRACTING_VERIFY_FAILURE_EVENT
} from '../actionTypes/contractingActionTypes';
import { CASH } from '@makemydeal/dr-platform-types';
import { getCreditAppId, getCurrentOfferType } from './offerRedux';
import { isDashboardCurrentRoute } from './router';

export const getState = (state: any) => state.contracting;

export const getStatus = (state: StateTree): Status => getState(state).status;

export const getFailureCurrentState = (state: StateTree): Status => getState(state).failureCurrentState;

export const getFailureEvent = (state: StateTree): Status => getState(state).failureEvent;

export const getSuccessEvent = (state: StateTree): Status => getState(state).successEvent;

export const getFailureReasons = (state: StateTree): string[] | undefined => getState(state).failureReasons;

export const isGenerateContractingUiOptionEnabled = (state: StateTree): boolean => {
    const isDashboard = isDashboardCurrentRoute(state);
    if (!isDashboard) {
        return false;
    }

    if (!offerSelectors.getDealRefId(state)) {
        return false;
    }

    const offerType = getCurrentOfferType(state);
    if (offerType === CASH) {
        // Cash deals with a dealRefId always have contracting enabled
        return true;
    }

    // Finance and lease deals require having a creditAppId
    const creditAppId = getCreditAppId(state);
    return Boolean(creditAppId);
};

export const isContractingModalOpen = (state: StateTree): boolean => state.contracting.modalOpen;

export const isContractingInProgress = (state: StateTree): boolean => state.contracting.isInProgress;

export const getAlertTitle = (state: StateTree, event: string | undefined): string => {
    switch (event) {
        case CONTRACTING_CANCEL_FAILURE_EVENT:
            return 'Contract Generation Failed';
        case CONTRACTING_START_FAILURE_EVENT:
            return 'Unable to Generate Contract';
        case CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT: {
            const failureReasons = getFailureReasons(state);
            if (!failureReasons?.length) {
                return 'Service Unavailable';
            }
            return 'Unable to Generate Contract';
        }
        case CONTRACTING_VERIFY_FAILURE_EVENT: {
            const failureReasons = getFailureReasons(state);
            if (!failureReasons?.length) {
                return 'Unable to Verify Contract: Error Details Unavailable';
            }
            return 'Unable to Verify Contract';
        }
        case CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT: {
            const failureMessages = getFailureReasons(state);
            if (!failureMessages?.length) {
                return 'Service Unavailable';
            }
            return 'Unable to Verify Contract';
        }
        case CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT:
        case CONTRACTING_OFFER_SAVE_FAILURE_EVENT:
            return 'Service Unavailable';
        case CONTRACTING_TIMEOUT_FAILURE: {
            return 'Timeout Error';
        }
        default:
            return '';
    }
};

export const getAlertMessage = (state: StateTree, event: string | undefined): string => {
    switch (event) {
        case CONTRACTING_CANCEL_FAILURE_EVENT: {
            let message = 'A contract for this deal cannot be generated because it is already ';
            const currentState = getFailureCurrentState(state);
            message += currentState ? `${currentState.toLowerCase()}.` : '.';
            return message;
        }
        case CONTRACTING_START_FAILURE_EVENT:
            return 'The contract cannot be generated at this time. Please address the following issues before proceeding:';
        case CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT: {
            const failureReasons = getFailureReasons(state);
            if (failureReasons?.length) {
                return 'The contract cannot be generated at this time. Please address the following issues before proceeding or ';
            }
            return '';
        }
        case CONTRACTING_VERIFY_FAILURE_EVENT: {
            const failureReasons = getFailureReasons(state);
            if (!failureReasons?.length) {
                return 'We encountered one or more errors that cannot be displayed here. Please click here to ';
            }
            return 'The contract failed Lender Verification. Please address the following issues before proceeding or ';
        }
        case CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT: {
            const failureMessages = getFailureReasons(state);
            if (failureMessages?.length) {
                return 'The contract failed Lender Verification. Please address the following issues before proceeding or ';
            }
            return '';
        }
        case CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT:
        case CONTRACTING_OFFER_SAVE_FAILURE_EVENT:
            return '';
        case CONTRACTING_TIMEOUT_FAILURE: {
            if (getContractingStage(state) === 'verify') {
                return 'Generate Contract timed out during Lender Verification.';
            }

            return 'Generate Contract timed out. Please try again later.';
        }
        default:
            return '';
    }
};

export const getDeepLinkTexts = (state: StateTree, event: string | undefined): string => {
    switch (event) {
        case CONTRACTING_VERIFY_FAILURE_EVENT: {
            const failureReasons = getFailureReasons(state);
            if (!failureReasons?.length) {
                return 'view the contract verification error details on Dealertrack.';
            }
            return 'View Contract on Dealertrack:';
        }
        case CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT:
        case CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT: {
            const failureReasons = getFailureReasons(state);
            if (!failureReasons?.length) {
                return 'Please contact support.';
            }
            return 'contact support.';
        }
        case CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT:
        case CONTRACTING_OFFER_SAVE_FAILURE_EVENT:
            return 'Please contact support.';
        case CONTRACTING_TIMEOUT_FAILURE: {
            if (getContractingStage(state) === 'verify') {
                return 'View Contract on Dealertrack.';
            }

            return '';
        }
        default:
            return '';
    }
};

export const getDeepLinkUrl = (state: StateTree, event: string | undefined): string => {
    switch (event) {
        case CONTRACTING_VERIFY_FAILURE_EVENT: {
            const dealRefId = offerSelectors.getDealRefId(state);
            const configUrl = configSelectors.getDealertrackUrl(state);
            return `${configUrl}/deeplink/contract_select_lender_form/FD/${dealRefId}/`;
        }
        case CONTRACTING_TIMEOUT_FAILURE: {
            if (getContractingStage(state) === 'verify') {
                const dealRefId = offerSelectors.getDealRefId(state);
                const configUrl = configSelectors.getDealertrackUrl(state);
                return `${configUrl}/deeplink/contract_select_lender_form/FD/${dealRefId}/`;
            }

            return '';
        }
        case CONTRACTING_STAGE_CANCEL_REQUEST_FAILURE_EVENT:
        case CONTRACTING_STAGE_START_REQUEST_FAILURE_EVENT:
        case CONTRACTING_STAGE_VERIFY_REQUEST_FAILURE_EVENT:
        case CONTRACTING_OFFER_SAVE_FAILURE_EVENT:
            return 'https://ww2.dealertrack.app.coxautoinc.com/contactus/';
        default:
            return '';
    }
};

export const getFailureAlertInfo = (state: StateTree): ContractingFailureAlertInfo => {
    const failureEvent = getFailureEvent(state);
    const deepLinkTexts = getDeepLinkTexts(state, failureEvent);
    const message = getAlertMessage(state, failureEvent);
    const reasons = getFailureReasons(state);
    const status = getStatus(state);
    const title = getAlertTitle(state, failureEvent);
    const deepLinkUrl = getDeepLinkUrl(state, failureEvent);

    return {
        deepLinkTexts,
        deepLinkUrl,
        message,
        reasons,
        status,
        title
    };
};

export const getContractingStage = (state: StateTree): ContractingStage => state.contracting.stage;

export const isInProgress = (state: StateTree): boolean => state.contracting.isInProgress;

export const getContractingCorrelationId = (state: StateTree): string => state.contracting.correlationId;

export const getStartTime = (state: StateTree): number | null => state.contracting.startTime;
