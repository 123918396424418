import styled from 'styled-components';
import { toRem } from '../utils/pixelUtils';
import { Typography } from '@interstate/components/Typography';

export const ContractFailureReasons = styled.ul`
    margin-top: ${toRem(16)};
`;

export const ContractingFailureTitle = styled(Typography)`
    margin-bottom: ${toRem(5)} !important;
`;

export const ContractFailureAlertContainer = styled.div`
    margin-bottom: ${toRem(16)};

    a {
        color: #005ba8 !important; // override MUI theme
    }
    button {
        vertical-align: bottom;
    }
`;
