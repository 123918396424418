// externals
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

// selectors and actions
import {
    offerReduxActionCreators,
    offerReduxSelectors,
    offerSelectors,
    paymentSelectors,
    vehicleSelectors
} from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components and formatters
import { OpenRollToPaymentSlideOutButton, RollToOptionsInterstate } from '@makemydeal/dr-dash-components';
import { getCashDealLabel, getFinanceDealLabel, getLeaseDealLabel } from './utils/getDealTypeDropdownLabels';

// constants
import { CASH, FINANCE, LEASE, OfferType } from '@makemydeal/dr-platform-types';

// interstate components
import { Typography } from '@interstate/components/Typography';
import { Menu, MenuReferenceType } from '@interstate/components/Menu';
import { ChevronDownIcon, CheckmarkSolidIcon } from '@interstate/components/Icons';

// css styling
import {
    DealLabelContainer,
    DealTypeDropdownContainer,
    DealTypeLabel,
    DealTypeLabelAndRollToButton,
    DealTypeSelectTrigger
} from './DealTypeDropdown.style';
import './DealTypeDropdown.scss';
import { APR, DEAL_TYPE, MONEY_FACTOR, MONEY_FACTOR_TYPE } from '../../constants';

export const DealTypeDropdown = () => {
    const dispatch = useDispatch();
    const enableGetRollToPayment = useSelector(featureToggleSelectors.enableGetRollToPayment);
    const useDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const currentOfferType: OfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const apr = useSelector(offerReduxSelectors.getApr);
    const months = useSelector(offerReduxSelectors.getSelectedTermMonths);
    const totalAmountDue = useSelector(offerReduxSelectors.getCashTotalAmountDueAfterCredits);
    const amountPaidByCustomer = useSelector(offerReduxSelectors.getCashDueFromCustomer);
    const downPayment = useSelector((state) => offerReduxSelectors.getDownPaymentByOfferType(state, FINANCE));
    const showLoader = useSelector(paymentSelectors.isCalculatingPayment);
    const rateType = useSelector(offerReduxSelectors.getRateType);
    const moneyFactor = useSelector(offerReduxSelectors.getMoneyFactor);

    const isMoneyFactor = rateType === MONEY_FACTOR_TYPE;
    const rateTypeLabel = isMoneyFactor ? `${MONEY_FACTOR}` : `%${APR}`;
    const rateTypeVal = isMoneyFactor ? moneyFactor : apr;

    const [dealTypeDropdown, setDealTypeDropdown] = useState<null | HTMLElement>(null);
    const [menuWidth, setMenuWidth] = useState<number>(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isUpdateRequested, setIsUpdateRequested] = useState(false);

    const isNew: boolean = useSelector(vehicleSelectors.isVehicleNew);
    const hasLeasing: boolean = useSelector(dealerSelectors.hasLeasing);
    const isLeaseEnabled: boolean = isNew && hasLeasing;
    const isCashEnabled: boolean = useSelector(dealerSelectors.isCashOfferEnabled);
    const dealTypes = [FINANCE, ...(isLeaseEnabled ? [LEASE] : []), ...(isCashEnabled ? [CASH] : [])];

    const handleDealTypeButtonClose = () => setIsOpen(false);
    const handleDealTypeButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setIsOpen(true);
        setDealTypeDropdown(e.currentTarget);
        setMenuWidth(e.currentTarget.clientWidth + 2);
        setSelectedItemClass(currentOfferType);
    };

    const setSelectedItemClass = (offerType: string) => {
        setTimeout(() => {
            dealTypes.forEach((item, index) => {
                const isSelected = item.toLowerCase() === offerType.toLowerCase();
                const itemEl = document.querySelector(`#ids-menu-item-deal-type-selector-${index}`) as HTMLElement;
                if (isSelected) {
                    itemEl?.classList.add('selected-item');
                } else {
                    itemEl?.classList.remove('selected-item');
                }
            });
        }, 10);
    };

    useEffect(() => {
        if (!showLoader && isUpdateRequested && dealTypeDropdown) {
            dealTypeDropdown.focus();
            setIsUpdateRequested(false);
        }
    }, [showLoader, isUpdateRequested]);

    const menuRefProps = useMemo(
        () => ({
            open: isOpen,
            anchorEl: dealTypeDropdown as MenuReferenceType,
            onClose: handleDealTypeButtonClose
        }),
        [isOpen, dealTypeDropdown]
    );
    const menuOptions = useMemo(
        () =>
            dealTypes.map((offerType) => ({
                value: offerType.toLowerCase(),
                label: offerType.charAt(0).toUpperCase() + offerType.slice(1),
                onSelect: () => {
                    if (currentOfferType !== offerType) {
                        dispatch(offerReduxActionCreators.updatedOfferType(offerType.toLowerCase() as OfferType));
                        setSelectedItemClass(offerType);
                    }
                    setIsUpdateRequested(true);
                }
            })),
        [currentOfferType]
    );

    const getDealTypeLabel = () => {
        switch (currentOfferType) {
            case CASH:
                return [getCashDealLabel(totalAmountDue)];
            case LEASE:
                return getLeaseDealLabel({ amountPaidByCustomer, months, monthlyPayment, rateTypeVal, rateTypeLabel });
            case FINANCE:
                return getFinanceDealLabel({ downPayment, months, monthlyPayment, rateTypeVal, rateTypeLabel });
        }
    };

    const renderDealTypeLabel = () => {
        const [line1, line2] = getDealTypeLabel();
        const label = (
            <>
                <span>{line1}</span>
                <span>{line2 && line2}</span>
            </>
        );
        return <DealLabelContainer>{label}</DealLabelContainer>;
    };

    return (
        <DealTypeDropdownContainer>
            <DealTypeLabelAndRollToButton>
                <DealTypeLabel>{DEAL_TYPE}</DealTypeLabel>
                {enableGetRollToPayment && useDealScreenExperience && (
                    <>
                        <OpenRollToPaymentSlideOutButton />
                        <RollToOptionsInterstate />
                    </>
                )}
            </DealTypeLabelAndRollToButton>
            <DealTypeSelectTrigger
                endIcon={<ChevronDownIcon />}
                buttonStyle="secondary"
                data-testid="deal-type-dropdown"
                onClick={handleDealTypeButtonClick}
            >
                {renderDealTypeLabel()}
            </DealTypeSelectTrigger>
            <Menu
                id={'deal-type-selector'}
                width={menuWidth}
                icon={<CheckmarkSolidIcon />}
                referenceProps={menuRefProps}
                position={'bottom-start'}
                menuOptions={menuOptions}
            />
        </DealTypeDropdownContainer>
    );
};
