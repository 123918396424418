import styled, { css } from 'styled-components';

import { BreakPoint } from '@makemydeal/dr-activities-common';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

export const ActionsContainer = styled.div<{ isNewAppWidthEnabled: boolean; isDealCentralExperience: boolean }>`
    display: flex;
    flex-direction: column-reverse;
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    gap: 1rem;
    justify-content: flex-end;
    width: 100%;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    box-shadow: ${({ theme }) => ` 0 0 4px 0 ${theme.tokens.BaseColorGray300}`};
    padding: 16px;
    .push-container-buttons {
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
    }
    .actions-dropdown-button,
    .push-to-dropdown-button,
    .print-offer-button {
        padding: 15px;
    }
    @media (min-width: ${BreakPoint.SMALL}) {
        margin-top: 10px;
        flex-direction: row;
        .push-container-buttons {
            flex-direction: row;
        }
    }

    ${({ isDealCentralExperience }) =>
        isDealCentralExperience &&
        css`
            @media (max-width: ${BreakPoint.PHONE}) {
                justify-content: flex-start;
            }
        `}

    @media (max-width: ${BreakPoint.SMALL}) {
        margin-top: 10px;
        flex-direction: row;
        padding: 1.14rem;

        .actions-dropdown-button,
        .push-to-dropdown-button,
        .print-offer-button {
            padding: 15px;
        }
    }
`;

// NOTE: This is a fix to show the shadow on the top side of the container only
export const CutOffShadowContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    width: 100%;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    overflow: hidden;
`;

export const DropdownsContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const FooterContainer = styled.div`
    position: fixed;
    bottom: 0px;
    width: 100%;
`;

export const SnackbarWrapper = styled.div`
    & .save-snackbar-position,
    .print-snackbar-position {
        position: absolute !important;
        bottom: 4.375rem !important;
    }
`;
