// externals
import { useSelector } from 'react-redux';

// components
import { Table } from '@interstate/components/Table';

// libraries
import { vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// utils
import { transformProductToModel } from '@makemydeal/dr-activities-vehicle-protection';

// types
import { InterstateTableProps } from '@interstate/components/Table/Types/tableTypes';

export const getVPPTableColumns = (isBaseDealScreenExperience: boolean): InterstateTableProps['columns'] => [
    {
        title: isBaseDealScreenExperience ? 'Protection Name' : 'Product Name',
        dataIndex: 'productName',
        ellipsis: { showTitle: true },
        width: '35%'
    },
    { title: 'Months', dataIndex: 'productCoverageLength' },
    { title: 'Miles', dataIndex: 'productCoverageMiles' },
    { title: 'Deductible', dataIndex: 'productDeductible', align: isBaseDealScreenExperience ? 'right' : 'left' },
    { title: 'Price', dataIndex: 'productPrice', align: isBaseDealScreenExperience ? 'right' : 'left' }
];

export const VehicleProtectionPreviewTable = () => {
    const products = useSelector(vehicleProtectionSelectors.getVppProducts);
    const isFetchingRates = useSelector(vehicleProtectionSelectors.isFetchingVppRates);
    const isFetchingVppProducts = useSelector(vehicleProtectionSelectors.isFetchingVppProducts);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const menuProducts = products
        .filter((product) => product.selected)
        .map((product) => transformProductToModel(product, isFetchingRates || isFetchingVppProducts));

    return (
        <Table
            scrollX={640}
            enablePagination={false}
            columns={getVPPTableColumns(isBaseDealScreenExperience)}
            data={menuProducts}
            dataDensity="small"
            id="vpp-preview-table"
        />
    );
};
