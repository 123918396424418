// externals
import { memo } from 'react';

// components
import { DeskingCardBlock, DeskingCardBlocksContainer, DeskingCardLeftPanel } from './DeskingCard.style';
import { DeskingCardPaymentDetailsSidePanel } from './DeskingCardPaymentDetailsSidePanel';
import { DeskingCardPaymentDetailsAccordion } from './DeskingCardPaymentDetailsAccordion';
import { DeskingCardHeader } from './DeskingCardHeader';
import { DeskingCardBody } from './DeskingCardBody';

export interface DeskingCardContentProps {
    scopeId: string;
    isLargeScreen: boolean;
    isSingleCardView: boolean;
}

export const DeskingCardContent = memo(({ scopeId, isLargeScreen, isSingleCardView }: DeskingCardContentProps) => {
    const isPaymentDetailsInSidePanel = isSingleCardView && isLargeScreen;

    return (
        <>
            <DeskingCardHeader scopeId={scopeId} />

            {isPaymentDetailsInSidePanel && (
                <DeskingCardLeftPanel>
                    <DeskingCardPaymentDetailsSidePanel isSingleCardView={isSingleCardView} />
                </DeskingCardLeftPanel>
            )}

            <DeskingCardBlocksContainer>
                {!isPaymentDetailsInSidePanel && (
                    <DeskingCardBlock rowName="payment-details">
                        <DeskingCardPaymentDetailsAccordion />
                    </DeskingCardBlock>
                )}
                <DeskingCardBody scopeId={scopeId} />
            </DeskingCardBlocksContainer>
        </>
    );
});
