import { useState, FC, useEffect, useCallback } from 'react';
import { ContainerGrid, ContainerGridItem } from '@makemydeal/dr-dash-components';
import { Box } from '@interstate/components/Box';
import { TextInput } from '@interstate/components/TextInput';
import { SelectInput } from '@interstate/components/SelectInput';
import { PostalCodeInput } from '@interstate/components/PostalCodeInput';
import { locationCodeList } from '../vehicleProtection/constants';
import { DraftDealProduct } from '@makemydeal/dr-dash-types';

export interface ProviderAddressDetailsProps {
    providerAddress: DraftDealProduct['productProviderAddress'];
    onProviderAddressChange: (address: DraftDealProduct['productProviderAddress']) => void;
    onValidationError: (hasError: boolean) => void;
}

const ADDRESS_MAX_LENGTH = 60;
const CITY_MAX_LENGTH = 30;
const ZIP_CODE_LENGTH = 5;

const validationIconStyles = {
    '.interstate-exclamation-circle-icon': { flexShrink: 0 }
};

const combineProviderAddressLines = (address: DraftDealProduct['productProviderAddress']): string => {
    // NOTE: it has 3 address lines, they can be nullish, so we need to prevent showing 'undefined' or 'null'
    return [address?.addressLine1, address?.addressLine2, address?.addressLine3].filter(Boolean).join(', ');
};

const resetProviderAddressUnsupportedFields = (
    address: DraftDealProduct['productProviderAddress']
): DraftDealProduct['productProviderAddress'] => {
    return {
        ...address,
        addressLine1: combineProviderAddressLines(address),
        addressLine2: null,
        addressLine3: null,
        addressType: 'Other',
        countyDistrict: null,
        country: 'US'
    };
};

export const ProviderAddressDetails: FC<ProviderAddressDetailsProps> = ({
    providerAddress,
    onProviderAddressChange,
    onValidationError
}) => {
    const [address, setAddress] = useState<DraftDealProduct['productProviderAddress']>(
        providerAddress || {
            addressType: 'Other',
            country: 'US'
        }
    );

    const [hasAddressError, setHasAddressError] = useState(false);
    const [hasCityError, setHasCityError] = useState(false);
    const [hasZipError, setHasZipError] = useState(false);

    const hasError = hasAddressError || hasCityError || hasZipError;

    useEffect(() => {
        onValidationError(hasError);
    }, [hasError, onValidationError]);

    useEffect(() => {
        setAddress(providerAddress);
    }, [providerAddress]);

    const updateAddressAndNotify = useCallback(
        (newAddress: DraftDealProduct['productProviderAddress']) => {
            onProviderAddressChange(resetProviderAddressUnsupportedFields(newAddress));
        },
        [onProviderAddressChange]
    );

    const onAddressChange = useCallback(
        (addressLine: string) => {
            setHasAddressError(addressLine.length > ADDRESS_MAX_LENGTH);
            if (addressLine !== combineProviderAddressLines(address)) {
                updateAddressAndNotify({
                    ...address,
                    addressLine1: addressLine
                });
            }
        },
        [address, updateAddressAndNotify]
    );

    const onStateChange = useCallback(
        (state?: string) => {
            if (state !== address?.stateProvince) {
                updateAddressAndNotify({
                    ...address,
                    stateProvince: state
                });
            }
        },
        [address, updateAddressAndNotify]
    );

    const onCityChange = useCallback(
        (city: string) => {
            setHasCityError(city.length > CITY_MAX_LENGTH);
            if (city !== address?.city) {
                updateAddressAndNotify({
                    ...address,
                    city: city
                });
            }
        },
        [address, updateAddressAndNotify]
    );

    const onZipChange = useCallback(
        (zip: string) => {
            setHasZipError(Boolean(zip.length && zip.length !== ZIP_CODE_LENGTH));
            if (zip !== address?.postalCode) {
                updateAddressAndNotify({
                    ...address,
                    postalCode: zip
                });
            }
        },
        [address, updateAddressAndNotify]
    );

    return (
        <Box data-testid="edit-product-drawer-vpp-provider-address-container" mb={2}>
            <Box mb={2}>
                <TextInput
                    label="Address"
                    name="address"
                    maxLength={ADDRESS_MAX_LENGTH}
                    data-testid="edit-product-drawer-vpp-provider-address"
                    value={combineProviderAddressLines(address)}
                    onChange={(event) => onAddressChange(event.target.value)}
                    placeholder="Enter address"
                    customErrorMessages={{
                        exceedsMaxLength: `Value exceeds ${ADDRESS_MAX_LENGTH} characters.`
                    }}
                />
            </Box>
            <ContainerGrid $alignItems="flex-start">
                <ContainerGridItem md={4}>
                    <TextInput
                        label="City"
                        name="city"
                        maxLength={CITY_MAX_LENGTH}
                        data-testid="edit-product-drawer-vpp-provider-address-city"
                        value={address?.city || ''}
                        sx={validationIconStyles}
                        onChange={(event) => onCityChange(event.target.value)}
                        placeholder="Enter city"
                        customErrorMessages={{
                            exceedsMaxLength: `Value exceeds ${CITY_MAX_LENGTH} characters.`
                        }}
                    />
                </ContainerGridItem>
                <ContainerGridItem md={4}>
                    <SelectInput
                        data-testid="edit-product-drawer-vpp-provider-address-state"
                        label="State"
                        name="state"
                        value={address?.stateProvince || ''}
                        onChange={(event) => onStateChange(event.target.value)}
                        options={locationCodeList.map((state) => ({
                            value: state,
                            label: state
                        }))}
                        displayDeselectOption={false}
                    />
                </ContainerGridItem>
                <ContainerGridItem md={4}>
                    <PostalCodeInput
                        label="ZIP"
                        name="zip"
                        data-testid="edit-product-drawer-vpp-provider-address-zip"
                        value={address?.postalCode || ''}
                        onChange={(event) => onZipChange(event.target.value)}
                        placeholder="Enter zip"
                        errorMessage="error"
                        country="US"
                        sx={validationIconStyles}
                        customErrorMessages={{
                            invalidPostalCode: `Only ${ZIP_CODE_LENGTH} digits are allowed`
                        }}
                    />
                </ContainerGridItem>
            </ContainerGrid>
        </Box>
    );
};
