// TODO: use centralized utils between here and activities-desking

// externals
import { formatUtils } from '@makemydeal/dr-common-utils';
import numeral from 'numeral';
import { SKEW_DELIMETER } from '../constants';
import { OfferType, RequestType } from '@makemydeal/dr-platform-types';
import { ScenarioReference } from '@makemydeal/dr-dash-bff-types';

export const convertToNumber = (dollars: string): number => Number(dollars.replace(/[^0-9.]/g, ''));

export const isNaNish = (text: string): boolean => /^[\D]+$/.test(text) || text.trim() === ''; // Test for only non-numerics

export const formatMileageCharge = (amount: number): string => {
    return numeral(amount).format('0,0.0[00]');
};

export const formatAprRate = (amount: number): string => {
    return numeral(amount).format('0,0.000');
};

export const formatMoneyFactor = (amount: number): string => {
    return numeral(amount).format('0,0.00000');
};

export const formatPayment = (payment: number) => {
    const formattedDollar = formatUtils.formatDollars(payment);
    const numeralFormat = numeral(formattedDollar).format('0,0.00');
    return numeralFormat;
};

export const skewWords = (words: string[], delimiter: string = SKEW_DELIMETER): string => {
    return words.filter((w) => w?.trim()?.length).join(delimiter);
};

export const formatValue = (label: string, value: number | string): string => {
    if (label === 'APR') {
        return `${value}%`;
    } else if (
        ['Down Payment', 'Selling Price', 'Trade 1 Allowance', 'Total Taxes and Fees', 'Total Add Ons', 'Payment'].includes(label)
    ) {
        return `${value}`;
    }
    return String(value);
};
export const getDisplayOfferType = (offerType: OfferType) => {
    const offerTypesForDisplay = {
        cash: RequestType.CASH,
        finance: RequestType.FINANCE,
        lease: RequestType.LEASE
    };

    return offerTypesForDisplay[offerType] || RequestType.FINANCE;
};

export const showPlaceholder = (value: number | string) => {
    if (!value) return 'N/A';
    return value;
};

export const getScenarioIdsByDealVersion = (scenarios?: ScenarioReference[], dealVersion?: string): string[] => {
    if (!scenarios || !dealVersion) {
        return [];
    }

    return scenarios.map((scenario) => scenario.id).filter((id) => id !== dealVersion);
};
