import styled from 'styled-components';

import { SlideOut } from '@interstate/components/SlideOut';
import { Box } from '@interstate/components/Box';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const StyledVehicleInsuranceSlideOut = styled(SlideOut)`
    .ids-slideout-close-button {
        cursor: pointer;
    }

    .ids-SlideOut-content {
        padding-bottom: 0;
    }
    @media (max-width: ${BreakPoint.PHONE}) {
        .ids-SlideOut-content {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
`;

export const VehicleInsuranceInfoSlideOutContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const FooterContainer = styled(Box)`
    display: flex;
    width: 100%;
    padding: 32px 0;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.tokens.ComponentCardPadding};
`;
