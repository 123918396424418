// externals
import React from 'react';

// CSS style components
import {
    firstColumnStyle,
    gridContainerStyle,
    gridHeaderStyle,
    leftAlignColumnStyle,
    leftAlignGridHeaderStyle,
    visualLineStyle
} from '../RollToStyles';

// interstate components and type
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

// utils
import { formatValue } from '../../utils/formatUtils';

// custom component
import AdjustCell from './AdjustCell';

// types
import { Row } from '../../types/RollToPaymentOptionRow';

const LEFT_ALIGN_HEADERS = new Set(['Current', 'Roll-To']);

type RollToPaymentGridComponentProps = {
    rows: Row[];
    headers: string[];
    getAdjustedFieldInputValue: (index: number, event: InterstateOnChangeEvent<TextInputEventValue>) => void;
};

const RollToPaymentGrid: React.FC<RollToPaymentGridComponentProps> = ({ headers, rows, getAdjustedFieldInputValue }) => {
    return (
        <div style={gridContainerStyle} data-testid="roll-to-payment-table">
            {/* Grid Headers */}
            {headers.map((header, index) => (
                <div
                    data-testid={`header-${index}`}
                    key={index}
                    style={LEFT_ALIGN_HEADERS.has(header) ? leftAlignGridHeaderStyle : gridHeaderStyle}
                >
                    {header}
                </div>
            ))}
            {/* Grid columns */}
            {rows.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    <div style={firstColumnStyle}>{row.label}</div>
                    <div style={leftAlignColumnStyle}>
                        {typeof row.current === 'number' ? formatValue(row.label, row.current) : row.current}
                    </div>
                    <div style={leftAlignColumnStyle}>
                        {typeof row.rollTo === 'number' ? formatValue(row.label, row.rollTo) : row.rollTo}
                    </div>
                    <AdjustCell row={row} rowIndex={rowIndex} getAdjustedFieldInputValue={getAdjustedFieldInputValue} />
                    {row.dividerBelow && <div data-testid="divider" style={visualLineStyle}></div>}
                </React.Fragment>
            ))}
        </div>
    );
};

export default RollToPaymentGrid;
