import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { logNewRelic, NrSpaApiEnum } from '@makemydeal/dr-activities-common';
import { offerReduxSelectors, configSelectors, calculationDetailsSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { ShowWithFeatureToggle, ContractSuccessAlert } from '@makemydeal/dr-dash-components';
import { HostEnvironment, hostUrlHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

import { DIGITAL_DEAL_JACKET_PARTNER_ID, DIGITAL_DEAL_JACKET_SOURCE } from '../constants';
import '../custom.d';
import { DDJWrapper } from './DDJRouteContainer.style';

const DigitalDealJacket = () => {
    //states
    const [error, setError] = useState(false);
    const dealxgId = useSelector(offerReduxSelectors.getDealXgId);
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const partnerCode = useSelector(calculationDetailsSelectors.getPartnerCode);
    const partnerDealerId = useSelector(calculationDetailsSelectors.getPartnerDealerId);
    const redirectBaseUrl = `https://${window.location.hostname}`;
    const sourceUrl = useSelector(configSelectors.getDDJSourceUrl);
    const environment = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());

    const ddjEnv = useMemo(() => {
        const environments = {
            [HostEnvironment.Dev]: 'dev',
            [HostEnvironment.Dev2]: 'dev',
            [HostEnvironment.Local]: 'dev',
            [HostEnvironment.QA]: 'uat',
            [HostEnvironment.DarkProd]: 'prod',
            [HostEnvironment.UAT]: 'uat',
            [HostEnvironment.Prod]: 'prod',
            [HostEnvironment.None]: '',
            [HostEnvironment.Unknown]: ''
        };
        return environments[environment];
    }, [environment]);

    useEffect(() => {
        // Check if the custom element 'ddj-app' has already been defined
        if (!customElements.get('ddj-app')) {
            const script = document.createElement('script');
            script.src = sourceUrl;
            script.type = 'text/javascript';
            script.id = 'ddj-pbc-url';

            // Handling script failure
            script.onerror = function () {
                setError(true);

                // log an error message into New Relic
                logNewRelic('DDJ_Script_Load_Failure', 'Failure while loading Digital Deal Jacket script', NrSpaApiEnum.ERROR);
            };
            // Append the script to the body
            document.body.appendChild(script);
        }

        return () => {
            const ddjScript = document.getElementById('ddj-pbc-url');
            // Just to ensure safe removal without unnecessary exceptions
            if (ddjScript) {
                ddjScript.remove();
            }
        };
    }, [sourceUrl]);

    return (
        <DDJWrapper>
            {error && (
                <Alert type="caution" sx={{ marginTop: '40px' }} data-testid="ddj-alert">
                    <Typography variant="h6" sx={{ marginBottom: '2px' }}>
                        An Error has Occurred
                    </Typography>
                    <Typography variant="body-sm" sx={{ marginBottom: '2px' }}>
                        Sorry, the system is currently unable to process your request.
                    </Typography>
                    <Typography variant="body-sm">Please try again later.</Typography>
                </Alert>
            )}
            <ShowWithFeatureToggle name="enableMVGenerateContracts">
                <ContractSuccessAlert />
            </ShowWithFeatureToggle>
            {!error && (
                <ddj-app
                    data-testid="digital-deal-jacket-pbc"
                    external-id={dealxgId}
                    source={DIGITAL_DEAL_JACKET_SOURCE}
                    env={ddjEnv}
                    redirect-url={redirectBaseUrl}
                    partner-id={partnerCode || DIGITAL_DEAL_JACKET_PARTNER_ID}
                    partner-dealer-id={partnerDealerId || dealerId}
                ></ddj-app>
            )}
        </DDJWrapper>
    );
};

export default DigitalDealJacket;
