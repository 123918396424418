import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '@interstate/components/Action';
import { Box } from '@interstate/components/Box';
import { CardHeader } from '@interstate/components/Card';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { LineItem, LoadingSectionParent, PaymentLoading, TOTAL_TAX } from '@makemydeal/dr-dash-components';
import {
    compositeSelectors,
    deskingGlobalActionCreators,
    deskingGlobalSelectors,
    offerReduxSelectors,
    offerSelectors
} from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { LEASE } from '@makemydeal/dr-platform-types';

import { TAXES } from '../../../constants';
import { TypoRightAligned } from './TaxCard.style';
import TaxDetails from './TaxDetails';
import { StretchHeightCard } from '../../deskingActivity/DeskingCard.style';
import { TaxCardAlerts } from './TaxCardAlerts';

const TaxCard = () => {
    const dispatch = useDispatch();
    const taxTotal = useSelector(offerReduxSelectors.getTotalTax);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const monthlyUseTax = useSelector(offerSelectors.getMonthlyUseTaxTotal);
    const upfrontTaxTotal = useSelector(offerReduxSelectors.getUpfrontTaxTotal);
    const hasManualTotalTax = useSelector(offerReduxSelectors.hasManualTotalTax);
    const capitalizedTaxTotal = useSelector(compositeSelectors.getCapitalizedTax);
    const isAccordionOpen = useSelector(deskingGlobalSelectors.getTaxesAccordionExpanded);
    const isUseOverrideTaxAmountForPayment = useSelector(featureToggleSelectors.useOverrideTaxAmountForPayment);

    const [taxOverrideEnabled, setTaxOverrideEnabled] = useState(hasManualTotalTax);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    useEffect(() => {
        setTaxOverrideEnabled(hasManualTotalTax);
    }, [hasManualTotalTax]);

    const isLeaseDeal = offerType === LEASE;

    const taxesItems = useMemo(() => {
        const items: LineItem[] = [
            {
                label: TOTAL_TAX,
                value: formatDollarsAndCents(taxTotal)
            }
        ];

        if (isLeaseDeal) {
            items.unshift(
                {
                    label: 'Capitalized Tax',
                    value: formatDollarsAndCents(capitalizedTaxTotal)
                },
                {
                    label: 'Monthly Use Tax',
                    value: formatDollarsAndCents(monthlyUseTax)
                },
                {
                    label: 'Upfront Tax',
                    value: formatDollarsAndCents(upfrontTaxTotal)
                }
            );
        }
        return items;
    }, [taxTotal, capitalizedTaxTotal, monthlyUseTax, upfrontTaxTotal, isLeaseDeal]);

    const onAccordionChange = () => {
        dispatch(deskingGlobalActionCreators.toggleTaxesAccordion());
    };

    return (
        <LoadingSectionParent>
            {/* When isAccordionOpen is true the inner components have their own payment spinners.*/}
            {!isAccordionOpen && <PaymentLoading testId="taxes-loading-indicator" />}

            <div>
                {isUseOverrideTaxAmountForPayment && !isBaseDealScreenExperience && <TaxCardAlerts />}
                {!isBaseDealScreenExperience ? (
                    <StretchHeightCard
                        data-testid="taxes-card"
                        header={
                            <CardHeader
                                title={TAXES}
                                action={
                                    <Action data-testid="manage-taxes-link" onClick={onAccordionChange}>
                                        <Typography variant="body-sm" color="base.color.blue.700">
                                            {isAccordionOpen ? 'Show Less' : 'Show More'}
                                        </Typography>
                                    </Action>
                                }
                            />
                        }
                        content={
                            <Box display="flex" height="100%">
                                {!isAccordionOpen && (
                                    <Grid display="grid" gridTemplateColumns="repeat(2, minmax(0, auto))" gap=".5rem">
                                        {taxesItems.map((item: LineItem, i: number) => (
                                            <React.Fragment key={`item-${i}`}>
                                                <TypoRightAligned variant="body-sm">{item.value}</TypoRightAligned>
                                                <Typography variant="body-sm">{item.label}</Typography>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                )}
                                {isAccordionOpen && (
                                    <Box width="100%" height="100%">
                                        <TaxDetails
                                            taxOverrideEnabled={taxOverrideEnabled}
                                            setTaxOverrideEnabled={setTaxOverrideEnabled}
                                        />
                                    </Box>
                                )}
                            </Box>
                        }
                    />
                ) : (
                    <StretchHeightCard
                        isBaseDealScreenExperience={isBaseDealScreenExperience}
                        data-testid="taxes-card-base"
                        header={<CardHeader className="base-tab-title" title={<Typography variant="h3">{TAXES}</Typography>} />}
                        content={
                            <Box width="100%" height="100%">
                                <TaxDetails taxOverrideEnabled={taxOverrideEnabled} setTaxOverrideEnabled={setTaxOverrideEnabled} />
                            </Box>
                        }
                    />
                )}
            </div>
        </LoadingSectionParent>
    );
};

export default TaxCard;
