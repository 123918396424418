import { TableColumn } from '@interstate/components/Table';

export const leaseColumnsOrder = [
    'selectActionColumn',
    'lenderCode',
    'appliedIncentiveAmount',
    'sellRate',
    'buyRate',
    'term',
    'acqFeeFormatted',
    'baseResidualPercent',
    'securityDepositFormated',
    'annualMilesFormatted',
    'monthlyPayment',
    ['lenderProgram', 'programName'],
    'creditTierDescription',
    'frontEndProfit',
    'backEndProfit',
    'actions'
];

export const financeColumnsOrder = [...leaseColumnsOrder.slice(0, 5), ...leaseColumnsOrder.slice(9)];
export const financeOnlyColumns = ['lenderLegalName', 'rateType', 'totalProfit', 'baseResidualDollar'];

export const leaseOnlyColumns = [
    'acqFeeFormatted',
    'baseResidualPercent',
    'baseResidualDollar',
    'securityDepositFormated',
    'annualMilesFormatted'
];

export const cashExemptColumns = [
    'lenderCode',
    'lenderLegalName',
    'rateType',
    'buyRate',
    'sellRate',
    'term',
    'monthlyPayment',
    ...leaseOnlyColumns
];

export const columns: TableColumn[] = [
    {
        dataIndex: 'selectActionColumn',
        title: ''
    },
    {
        dataIndex: 'lenderCode',
        title: 'Lender'
    },
    {
        dataIndex: 'lenderLegalName',
        title: 'Lender Name'
    },
    {
        dataIndex: ['lenderProgram', 'programName'],
        title: 'Program Name'
    },
    {
        dataIndex: 'creditTierDescription',
        title: 'Tier'
    },
    {
        dataIndex: 'appliedIncentiveAmount',
        title: 'Rebates'
    },
    {
        dataIndex: 'sellRate',
        title: 'Rate/MF'
    },
    {
        dataIndex: 'rateType',
        title: 'Rate Type'
    },
    {
        dataIndex: 'buyRate',
        title: 'Buy Rate'
    },
    {
        dataIndex: 'term',
        title: 'Term'
    },
    {
        dataIndex: 'acqFeeFormatted',
        title: 'Acq. Fee'
    },
    {
        dataIndex: 'baseResidualPercent',
        title: 'Residual %'
    },
    {
        dataIndex: 'baseResidualDollar',
        title: 'Residual Amt'
    },
    {
        dataIndex: 'securityDepositFormated',
        title: 'Security Deposit'
    },
    {
        dataIndex: 'annualMilesFormatted',
        title: 'Miles/Year'
    },
    {
        dataIndex: 'monthlyPayment',
        title: 'Payment',
        defaultSortOrder: 'ascend'
    },
    {
        dataIndex: 'frontEndProfit',
        title: 'Front Gross'
    },
    {
        dataIndex: 'backEndProfit',
        title: 'Back Gross'
    },
    {
        dataIndex: 'totalProfit',
        title: 'Total Gross'
    },
    {
        dataIndex: 'actions',
        fixed: 'right',
        title: 'Actions',
        width: 100
    }
];

export const nestedColumnForDealCentral: TableColumn[] = [
    {
        dataIndex: 'program',
        title: 'Programs #'
    },
    {
        dataIndex: 'name',
        title: 'Incentives Name'
    },
    {
        dataIndex: 'code',
        title: 'Code'
    },
    {
        dataIndex: 'type',
        title: 'Type'
    },
    {
        dataIndex: 'amount',
        title: 'Amount'
    },
    {
        dataIndex: 'edit-icon',
        fixed: 'right',
        width: 100,
        align: 'right'
    }
];
