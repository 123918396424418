// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { ToggleUseSource, toggleUseRegistry } from '@makemydeal/dr-feature-toggles-client';

// interfaces/types
import { FeatureTogglesAndUrlOnly } from '../types';

// utils
import { clientLogger } from '@makemydeal/dr-shared-ui-utils';
import {
    getFeatureToggleFromSlice,
    isIncentivesActivityEnabledFromSlice,
    isTradeActivityEnabledFromSlice
} from '../reducers/featureToggleReducer';

// selectors
import { wasBootstrapSuccessful } from './sharedBootstrapSelectors';
import { FeatureToggles } from '@makemydeal/dr-platform-shared';

export type FeatureTogglePreconditionCheckOptions = {
    throwError?: boolean;
    logMessage?: boolean;
};

const PRECONDITION_CHECK_DEFAULTS = { throwError: true };

/**
 * Every feature toggle selector should include this check.  By default this will throw an error if toggled code is used before
 * the app has been bootstrapped.  If there's a scenario where it is "safe" to avoid this you can override the behavior by using
 * the options argument.
 *
 * NOTE: "safe" scenarios are defined as code that can be executed regardless of the toggle state- this is rare because the most
 * common use for feature toggles is to prevent untoggled code from executing at all times.  One possible "safe" use-case is if the
 * UI checks the state initially and hides elements and then later shows elements but this doesn't cause undesirable flicker.
 *
 * IMPORTANT: Any "unsafe" feature toggle selectors (those that do not throw exceptions if they are used before bootstrap completes
 *   should have a name like `isXyzEnabledAfterBootstrap` instead of just `isXyzEnabled`).
 */
export const featureTogglePreconditionCheck = (
    state: StateTree,
    options: FeatureTogglePreconditionCheckOptions = PRECONDITION_CHECK_DEFAULTS
) => {
    if (!areFeatureTogglesAvailable(state)) {
        if (options.logMessage) {
            clientLogger.clientWarnMessage('Feature toggles retrieved before app has successfully bootstrapped.');
        }
        if (options.throwError) {
            throw new Error('Feature toggles retrieved before app has successfully bootstrapped.');
        }
    }
};

export const areFeatureTogglesAvailable = (state: StateTree) => {
    return wasBootstrapSuccessful(state);
};

/**
 * Returns feature toggles with the ability to set pre-condition checks to various levels defined by the options parameter.  If
 * omitted the default behavior will protect the calling code from using feature toggles prior to bootstrap completion.
 */
export const getFeatureToggles = (
    state: StateTree,
    options: FeatureTogglePreconditionCheckOptions = PRECONDITION_CHECK_DEFAULTS
): Partial<FeatureTogglesAndUrlOnly> => {
    featureTogglePreconditionCheck(state, options);
    return state.featureToggles || ({} as Partial<FeatureTogglesAndUrlOnly>);
};

/**
 * Similar to getFeatureToggles but converts feature toggles into a more loosely typed object keyed by the toggle name.  This is
 * useful when passing the feature toggles to some activities.
 *
 * NOTE: In future we will not support this- the feature toggles should not be passed directly into the activity, they should
 *   instead use feature toggle selectors and pass the toggles in via the activity's additionalSelectors.
 */
export const getSimpleFeatureToggles = (
    state: StateTree,
    options: FeatureTogglePreconditionCheckOptions = PRECONDITION_CHECK_DEFAULTS
): FeatureToggles => {
    const featureToggles = getFeatureToggles(state, options);
    return featureToggles as FeatureToggles;
};

/**
 * Returns feature toggle data without error before bootstrap has completed.  Using this incorrectly could result in UI flicker,
 * skipped business logic or race conditions.  Only use this if you're sure that the logic isn't prone to race conditions and will
 * handle the initial "false" toggle value that could change to "true" after bootstrap completion.
 */
export const getTogglesWithoutSafetyChecks = (state: StateTree): Partial<FeatureTogglesAndUrlOnly> => getFeatureToggles(state, {});

/**
 * To make it easier to port code from shopper platform this function is aliased.  See how `getFeatureToggles` is defined above
 * for more information.
 */
export const getFeatureToggleState = (state: StateTree): Partial<FeatureTogglesAndUrlOnly> => getFeatureToggles(state);

/**
 * Get a feature toggle by name in a type-safe way - this will ensure that the caller uses a feature toggle name that exists in the
 * FeatureTogglesAndUrlOnly type.
 */
export const getFeatureToggle = (
    state: StateTree,
    name: keyof FeatureTogglesAndUrlOnly,
    source: ToggleUseSource = ToggleUseSource.Selector
): boolean => {
    toggleUseRegistry.registerToggleUse(name, source);
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return getFeatureToggleFromSlice(featureToggles, name);
};

/**
 * NOTE: This should only be used by the useFeatureToggle hook.
 */
export const featureEnabledSelectorForHook = (name: keyof FeatureTogglesAndUrlOnly) => (state: StateTree) =>
    getFeatureToggle(state, name, ToggleUseSource.Hook);

export const isCreditBureauEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isCreditBureauEnabled');

export const isShowUniFiDeepLinkEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'showUniFiDeepLink');

export const isAccessoriesEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'accessories');

export const showRecommendedOnVppRates = (state: StateTree): boolean => getFeatureToggle(state, 'showRecommendedOnVppRates');

export const isChangeVehicleEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableChangeVehicle');

export const enableMenuOnCash = (state: StateTree): boolean => getFeatureToggle(state, 'enableMenuOnCash');

export const enableMenuSelectionsRevertLogic = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableMenuSelectionsRevertLogic');

export const isCashEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'showCashOffer');

export const isMenuPaymentsDisclaimerEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'isMenuPaymentsDisclaimerEnabled');

export const isMenuUpdateWhenSdpTermsChangeEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'menuUpdateWhenSdpTermsChange');

export const isNewPaymentWorkFlow = (state: StateTree): boolean => getFeatureToggle(state, 'enableNewPaymentWorkFlowChange');

export const isVppMonthlyPaymentEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isVppMonthlyPaymentEnabled');

export const showMenuPaymentError = (state: StateTree): boolean => getFeatureToggle(state, 'showMenuPaymentError');

export const viewDetailsVppNonRatedLongText = (state: StateTree): boolean =>
    getFeatureToggle(state, 'viewDetailsVppNonRatedLongText');

export const isPaymentBuildingCardsEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enablePaymentBuildingCards');

export const isCreditOverwriteBlockEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'creditOverwriteBlock');

export const isSnapshotEnabledForCMD = (state: StateTree): boolean => getFeatureToggle(state, 'enableSnapshotForCMD');

export const isEnableLenderDecisionsInCreditBureau = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableLenderDecisionsInCreditBureau');

export const isEnableItemizedIncentivesSDP = (state: StateTree): boolean => getFeatureToggle(state, 'enableItemizedIncentivesSDP');

export const testDriveAfterTradeIn = (state: StateTree): boolean => getFeatureToggle(state, 'testDriveAfterTradeIn');

export const isPencilServiceEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isPencilServiceEnabled');

export const usePaymentOnlyMenuSelections = (state: StateTree): boolean => getFeatureToggle(state, 'dashPaymentOnlyMenuSelections');

export const isToggleFsDealRefIdEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'toggleFsDealRefId');

export const isVAutoEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isVAutoEnabled');

export const isEnableDealerCommentsEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDealerComments');

export const isEnableBuyRateForCreditDecision = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableBuyRateForCreditDecision');

export const isDpmEnabledAfterBootstrap = (state: StateTree): boolean => getTogglesWithoutSafetyChecks(state).enableDpm || false;

export const isDealLifeCycleEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDealLifeCycle');

export const isInterstateUiEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableInterstateUi');

export const isLenderLogosEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableLenderLogos');

// #region Activity Enabled Toggles
export const isIncentivesActivityEnabledAfterBootstrap = (state: StateTree): boolean =>
    isIncentivesActivityEnabledFromSlice(getTogglesWithoutSafetyChecks(state));

export const isTradeActivityEnabledAfterBootstrap = (state: StateTree): boolean =>
    isTradeActivityEnabledFromSlice(getTogglesWithoutSafetyChecks(state));

export const getEnableAppliedLenderInfo = (state: StateTree): boolean => getFeatureToggle(state, 'enableAppliedLenderInfo');

export const isEnableCustomerCardNotifications = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCustomerCardNotifications');

// #endregion

export const isGtmDataLayerV2Enabled = (state: StateTree): boolean => getFeatureToggle(state, 'isGtmDataLayerV2Enabled');

export const isDCAccessoriesEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDCaccessories');

export const isUsePSTotalAmountDueForCashDealsEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'usePSTotalAmountDueForCashDeals');

export const isPushToDmsSmartSaveEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'pushToDmsSmartSave');

export const usePaymentProductsMonthlyPayment = (state: StateTree): boolean =>
    getFeatureToggle(state, 'usePaymentProductsMonthlyPayment');

export const useCoOrgIdInVAutoAppraisalUrl = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useCoOrgIdInVAutoAppraisalUrl');

export const useAccessoriesFromInitCall = (state: StateTree): boolean => getFeatureToggle(state, 'useAccessoriesFromInitCall');

export const isPlanSelectionForVppEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enablePlanSelectionForVPP');

export const isOverrideTaxAmountForPaymentEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useOverrideTaxAmountForPayment');

export const isOverrideAdditonalTaxesForPaymentEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useOverrideAdditonalTaxesForPayment');

export const simplifyMonthlyUsageTax = (state: StateTree): boolean => getFeatureToggle(state, 'simplifyMonthlyUsageTax');

export const enableHideRouteOneCreditBureau = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableHideRouteOneCreditBureau');

export const enableEnhancedPushToDmsPlusMV = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableEnhancedPushToDmsPlusMV');

export const enableCreditBureauAndDecisionPBCs = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCreditBureauAndDecisionPBCs');

export const enableCreditBureauAndDecisionPBCRouteOne = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCreditBureauAndDecisionPBCRouteOne');

export const enablePendoInMV = (state: StateTree): boolean => getFeatureToggle(state, 'enablePendoInMV');

export const enablePatchDealRefId = (state: StateTree): boolean => getFeatureToggle(state, 'enablePatchDealRefId');

export const useDealScreenExperience = (state: StateTree): boolean => getFeatureToggle(state, 'useDealScreenExperience');

export const enableLenderSearch = (state: StateTree): boolean => getFeatureToggle(state, 'enableLenderSearch');

export const enableCommonOrgIdForPendo = (state: StateTree): boolean => getFeatureToggle(state, 'enableCommonOrgIdForPendo');

export const convertDecimalTaxRateToPercentInMV = (state: StateTree): boolean =>
    getFeatureToggle(state, 'convertDecimalTaxRateToPercentInMV');

export const enablePushToR1 = (state: StateTree): boolean => getFeatureToggle(state, 'enablePushToR1');

export const enableOptionalPbcUi = (state: StateTree): boolean => getFeatureToggle(state, 'enableOptionalPbcUi');

export const usePaymentGridMV = (state: StateTree): boolean => getFeatureToggle(state, 'usePaymentGridMV');

export const useOverrideTaxAmountForPayment = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useOverrideTaxAmountForPayment');

export const isABPencilStateMVEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableABPencilStateMV') && state.__isScoped === true;

export const enableMultiscenarioPrintMV = (state: StateTree): boolean => getFeatureToggle(state, 'enableMultiscenarioPrintMV');

export const isPushToDarwinEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enablePushToDarwin');

export const enableMetadataForPendo = (state: StateTree): boolean => getFeatureToggle(state, 'enableMetadataForPendo');

export const enableNoPsRawData = (state: StateTree): boolean => getFeatureToggle(state, 'enableNoPsRawData');

export const isCDL3EnabledInMV = (state: StateTree): boolean => getFeatureToggle(state, 'enableCDL3InMV');

export const enableDigitalDealJacket = (state: StateTree): boolean => getFeatureToggle(state, 'enableDigitalDealJacket');

export const getEnhancedDealDetails = (state: StateTree): boolean => getFeatureToggle(state, 'enhancedDealDetails_F209893');

export const getEnableCustomLeaseMileageByDealer = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCustomLeaseMileage_byDealer');

export const getEnableCustomLeaseMileageByFeature = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCustomLeaseMileage_F209898');

export const isTargetOutOfPocketLeaseEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableTargetOutOfPocketLease');

export const enableMultiScenarioPersistence = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableMultiScenarioPersistence');

export const enable9boxPersistence = (state: StateTree): boolean => getFeatureToggle(state, 'enable9boxPersistence');

export const enableSendProposalToDJ = (state: StateTree): boolean => getFeatureToggle(state, 'enableSendProposalToDJ');

export const enableDraftScenarioPersistence = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableDraftScenarioPersistence');

export const isPrintOfferAndResidualUpdatesEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'printOfferAndResidualUpdates');

export const updateCalcEngineOnDealTypeChange = (state: StateTree): boolean =>
    getFeatureToggle(state, 'updateCalcEngineOnDealTypeChange');

export const isFormattedPSErrorsEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'useFormattedPSErrors');

export const enableGetRollToPayment = (state: StateTree): boolean => getFeatureToggle(state, 'enableGetRollToPayment');

export const isGetRollToBalanceDueEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableGetRollToBalanceDue');

export const areDealTemplatesCreateEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDealTemplatesCreate');

export const areDealTemplatesApplyEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDealTemplatesApply');

export const enableGenerateContracts = (state: StateTree): boolean => getFeatureToggle(state, 'enableMVGenerateContracts');

export const isVppProviderAddressEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableVppProviderAddress');

export const enableInsuranceProviderSearch = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableInsuranceProviderSearch');

export const isBuildVehicleOnCreateNewDealEnabled = (state: StateTree) =>
    getFeatureToggle(state, 'enableBuildVehicleOnCreateNewDeal');

export const isDealNotesWithMentions = (state: StateTree): boolean => getFeatureToggle(state, 'dealNotesWithMentions');
export const useManualTotalTaxEdited = (state: StateTree): boolean => getFeatureToggle(state, 'useManualTotalTaxEdited');

export const enablePaymentRecalculationForDesking = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enablePaymentRecalculationForDesking');

export const combineReserveProfitWithBackEndProfit = (state: StateTree): boolean =>
    getFeatureToggle(state, 'combineReserveProfitWithBackEndProfit');

export const useCalcOverridesFixes = (state: StateTree): boolean => getFeatureToggle(state, 'useCalcOverridesFixes');

export const useDealHistoryScenariosScreen = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useDealHistoryScenariosScreen');

export const enableDealSummaryLayoutV2 = (state: StateTree): boolean => getFeatureToggle(state, 'dealSummaryLayoutV2');
