// external imports
import { isNil } from 'lodash';
import React from 'react';

// Custom Hooks
import { TextInputEventValue, TextInputValue } from '@interstate/components/TextInput';
import { isBlurEvent } from '../../utils/eventUtils';

// interstate components
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { NumericInput } from '@interstate/components/NumericInput';

// utils
import { formatValue } from '../../utils/formatUtils';

// CSS style components
import { leftAlignColumnStyle, rightAlignColumnStyle } from '../RollToStyles';

// types
import { Row } from '../../types/RollToPaymentOptionRow';

type AdjustCellProps = {
    row: Row;
    rowIndex: number;
    getAdjustedFieldInputValue: (index: number, event: InterstateOnChangeEvent<TextInputEventValue>) => void;
};

const getAdjustedValue = (adjustedRowValue: number | string | null | undefined): number | string | null => {
    if (typeof adjustedRowValue === 'number') {
        return adjustedRowValue;
    }

    if (typeof adjustedRowValue === 'string') {
        const parsed = parseFloat(adjustedRowValue);
        return !isNaN(parsed) ? parsed : adjustedRowValue;
    }

    return null;
};

const AdjustCell: React.FC<AdjustCellProps> = ({ row, rowIndex, getAdjustedFieldInputValue }) => {
    const adjust = getAdjustedValue(row.adjust);
    const isEmpty = isNil(adjust);

    const handleChange = (event: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (isBlurEvent(event)) {
            return;
        }
        getAdjustedFieldInputValue(rowIndex, event);
    };

    if (isEmpty) {
        return <div style={leftAlignColumnStyle}></div>;
    }

    if (row.isAdjustableField) {
        return (
            <div style={leftAlignColumnStyle} data-testid={`adjust-cell-${row.label}`}>
                <NumericInput
                    name={`adjust-cell-${row.label}`}
                    allowDecimal={true}
                    displayLabel={false}
                    autoInsertCommas={true}
                    decimalMaxLength={5}
                    decimalMinLength={0}
                    onChange={handleChange}
                    value={adjust}
                    size="small"
                    textAlign="right"
                />
            </div>
        );
    }

    return (
        <div
            style={{
                ...rightAlignColumnStyle,
                fontWeight: row.bold ? 'bold' : 'normal'
            }}
            data-testid={`adjust-cell-${row.label}`}
        >
            {formatValue(row.label, adjust)}
        </div>
    );
};

export default AdjustCell;
