// externals
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as MaterialUiColors from 'material-ui/styles/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import spacing from 'material-ui/styles/spacing';
import * as MaterialUiColorManipulator from 'material-ui/utils/colorManipulator';
import { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { StyleSheetManager as ScStyleSheetManager, ThemeProvider } from 'styled-components';

// styles
import { BannerWrapper, ModalAttachpointContainer } from './routes.style';

// libraries
import { RouteValues } from '@makemydeal/dr-dash-store';
import type { InitConfigurationStaticAssetsState } from '@makemydeal/dr-dash-types';
import type { FeatureTogglesAndUrlOnly } from '@makemydeal/dr-shared-store';
import { featureToggleReducer, featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import type { Dealer } from '@makemydeal/dr-shared-types';
import { ScopedStateProvider } from '@makemydeal/shared-scoped-store';

// utils
import { getThemeModule } from '../utils/themeUtils';

// config
import { accessoriesCatalogConfiguration } from '../activities/accessories-catalog';
import { changeVehicleConfiguration } from '../activities/change-vehicle';
import { creditBureauConfiguration } from '../activities/credit-bureau';
import { creditDecisionConfiguration } from '../activities/credit-decision';
import { dealHistoryConfiguration } from '../activities/deal-history';
import { deskingConfiguration } from '../activities/desking';
import { tradeConfiguration } from '../activities/trade';
import { vehicleProtectionConfiguration } from '../activities/vehicle-protection';

// components
import {
    CustomerUIProvider,
    Footer,
    GenerateContract,
    HeaderInterstate,
    HeaderMeasureProvider,
    AssetsProvider,
    NavDrawerProvider,
    NavigationDrawerInterstate,
    PushToDarwinPopup,
    SlideoutDrawerProvider,
    UnsavedChangesManager,
    UpdatedDealBanner,
    Banner,
    DealHistoryScenarios
} from '@makemydeal/dr-dash-components';
import { NotificationContainer } from '../components/notification/NotificationContainer';

// style
import { BodyContainer } from './BodyContainer';
import './routing.css';

// selectors
import { ddjConfiguration } from '../activities/digital-deal-jacket';
import { programsConfiguration } from '../activities/programs';

// consts/enums
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { useSelector } from 'react-redux';

export type RoutesStateProps = {
    toggles: FeatureTogglesAndUrlOnly;
    staticAssets: InitConfigurationStaticAssetsState | undefined;
    dealer: Partial<Dealer>;
};

export type RoutesDispatchProps = Record<string, never>;

export type RoutesProps = RoutesStateProps & RoutesDispatchProps;

export type ThemeContent = Record<string, string>;

const StyleSheetManager = ScStyleSheetManager as any;

const routingFlowConfigs = {
    [changeVehicleConfiguration.name]: changeVehicleConfiguration,
    [creditBureauConfiguration.name]: creditBureauConfiguration,
    [dealHistoryConfiguration.name]: dealHistoryConfiguration,
    [deskingConfiguration.name]: deskingConfiguration,
    [tradeConfiguration.name]: tradeConfiguration,
    [vehicleProtectionConfiguration.name]: vehicleProtectionConfiguration,
    [accessoriesCatalogConfiguration.name]: accessoriesCatalogConfiguration,
    [programsConfiguration.name]: programsConfiguration,
    [creditDecisionConfiguration.name]: creditDecisionConfiguration,
    [ddjConfiguration.name]: ddjConfiguration
};

export const Routes: FC<RoutesProps> = ({ staticAssets, toggles, dealer }) => {
    const [theme, setTheme] = useState<ThemeContent>({});

    const getRoutingFromConfig = (configName: string, offerSent: boolean, toggles: FeatureTogglesAndUrlOnly) => {
        const config = routingFlowConfigs[configName];
        return <config.routingComponent />;
    };

    const getTheme = () => {
        const primaryFont = theme.muiFontFamily;

        return getMuiTheme({
            spacing,
            fontFamily: primaryFont,
            palette: {
                primary1Color: theme.headerFooterCommonBackgroundGradientColor1,
                primary2Color: theme.headerFooterCommonBackgroundGradientColor1,
                primary3Color: MaterialUiColors.grey400,
                accent1Color: MaterialUiColors.white,
                accent2Color: theme.headerFooterCommonBackgroundGradientColor1,
                accent3Color: MaterialUiColors.grey500,
                textColor: MaterialUiColors.darkBlack,
                alternateTextColor: theme.headerFooterContainerColor,
                canvasColor: MaterialUiColors.white,
                borderColor: MaterialUiColors.grey300,
                disabledColor: MaterialUiColorManipulator.fade(MaterialUiColors.darkBlack, 0.3),
                pickerHeaderColor: theme.headerFooterCommonBackgroundGradientColor1,
                clockCircleColor: MaterialUiColorManipulator.fade(MaterialUiColors.darkBlack, 0.07),
                shadowColor: MaterialUiColors.fullBlack
            }
        });
    };

    const renderDashboard = () => {
        const toggles = {};
        const offerSent = false;
        const configName = deskingConfiguration.name;
        return getRoutingFromConfig(configName, offerSent, toggles);
    };

    const renderDealHistory = () => {
        const toggles = {};
        const offerSent = false;
        const configName = dealHistoryConfiguration.name;
        return getRoutingFromConfig(configName, offerSent, toggles);
    };

    const renderChangeVehicle = () => {
        const toggles = {};
        const offerSent = false;
        const configName = changeVehicleConfiguration.name;
        return getRoutingFromConfig(configName, offerSent, toggles);
    };

    const renderCreditBureau = () => {
        const toggles = {};
        const offerSent = false;
        const configName = creditBureauConfiguration.name;
        return getRoutingFromConfig(configName, offerSent, toggles);
    };

    const renderCreditDecision = () => {
        const toggles = {};
        const offerSent = false;
        const configName = creditDecisionConfiguration.name;
        return getRoutingFromConfig(configName, offerSent, toggles);
    };

    const offerSent = false;
    const changeVehicleRoutingComponent = renderChangeVehicle();
    const dashboardRoutingComponent = renderDashboard();
    const dealHistoryRoutingComponent = renderDealHistory();
    const creditBureauRoutingComponent = renderCreditBureau();
    const creditDecisionRoutingComponent = renderCreditDecision();

    const useAccessoriesCatalogActivity = dealer.isAccessoriesCatalogEnabled;

    const tradeRoutingComponent = featureToggleReducer.isTradeActivityEnabledFromSlice(toggles)
        ? getRoutingFromConfig(tradeConfiguration.name, offerSent, toggles)
        : null;
    const vehicleProtectionRoutingComponent = getRoutingFromConfig(vehicleProtectionConfiguration.name, offerSent, toggles);
    const accessoriesCatalogRoutingComponent = useAccessoriesCatalogActivity
        ? getRoutingFromConfig(accessoriesCatalogConfiguration.name, offerSent, toggles)
        : null;
    const programsRoutingComponent = getRoutingFromConfig(programsConfiguration.name, offerSent, toggles);

    const ddjRoutingComponent = featureToggleReducer.isDigitalDealJacketEnabledFromSlice(toggles)
        ? getRoutingFromConfig(ddjConfiguration.name, offerSent, toggles)
        : null;

    const enablePushToDarwin = featureToggleReducer.isPushToDarwinEnabled(toggles) && dealer.enableDarwin;
    const enableDraftScenarioPersistence = useSelector(featureToggleSelectors.enableDraftScenarioPersistence);
    const isGenerateContractsEnabled = useSelector(dealerSelectors.getEnableGenerateContracts);
    const useDealHistoryScenariosScreen = useSelector(featureToggleSelectors.useDealHistoryScenariosScreen);

    useEffect(() => {
        getThemeModule(staticAssets)
            .then((data: ThemeContent) => {
                setTheme(data);
            })
            .catch((error) => {
                let loadingItemText;
                if (staticAssets) {
                    loadingItemText = staticAssets.legacyThemeJson;
                } else {
                    loadingItemText = '(static assets undefined)';
                }
                // eslint-disable-next-line no-console
                console.error(`Error during loading of ${loadingItemText} theme token: ${error}`);
            });
    }, [staticAssets]);

    return (
        <MuiThemeProvider muiTheme={getTheme()}>
            <StyleSheetManager disableCSSOMInjection>
                <ThemeProvider theme={theme}>
                    <NavDrawerProvider>
                        <ModalAttachpointContainer className="dash-modal-attachpoint">
                            <CustomerUIProvider>
                                {/* SlideoutDrawerProvider is rendered outside of the routes, but, through context, it renders
                                content belonging to pages other than the dashboard.
                                So we have to wrap it into a navigationScope, so that the content it renders properly reflect the scenario that opened it.
                                Then we immediately set the primary scope back to it's children, so the dashboard, header/footer will all reflect the primary scenario.
                                */}
                                <ScopedStateProvider navigationScope>
                                    <SlideoutDrawerProvider>
                                        <ScopedStateProvider primary>
                                            <HeaderMeasureProvider>
                                                <AssetsProvider>
                                                    <NotificationContainer />
                                                    <HeaderInterstate appPrefix={MANAGER_VIEW_APP_PREFIX} />
                                                    <NavigationDrawerInterstate />
                                                    <Redirect to={RouteValues.DASHBOARD} />
                                                    <BodyContainer>
                                                        {enableDraftScenarioPersistence && <UnsavedChangesManager />}
                                                        {isGenerateContractsEnabled && <GenerateContract />}
                                                        <BannerWrapper>
                                                            <UpdatedDealBanner />
                                                        </BannerWrapper>
                                                        <Banner />
                                                        {dashboardRoutingComponent}
                                                        {/* All routes, except the dashboard need to be inside this navigation scope,
                                            otherwise they will only affect the primary scope */}
                                                        <ScopedStateProvider navigationScope>
                                                            {changeVehicleRoutingComponent}
                                                            {programsRoutingComponent}
                                                            {dealHistoryRoutingComponent}
                                                            {tradeRoutingComponent}
                                                            {creditBureauRoutingComponent}
                                                            {creditDecisionRoutingComponent}
                                                            {accessoriesCatalogRoutingComponent}
                                                            {ddjRoutingComponent}
                                                            {/* NOTE: Wrap all legacy activites with this div for styling selectors. */}
                                                            <div className="legacy-activities">
                                                                {vehicleProtectionRoutingComponent}
                                                            </div>
                                                        </ScopedStateProvider>
                                                    </BodyContainer>
                                                    <Footer />
                                                    {enablePushToDarwin && <PushToDarwinPopup />}
                                                    {useDealHistoryScenariosScreen && <DealHistoryScenarios />}
                                                </AssetsProvider>
                                            </HeaderMeasureProvider>
                                        </ScopedStateProvider>
                                    </SlideoutDrawerProvider>
                                </ScopedStateProvider>
                            </CustomerUIProvider>
                        </ModalAttachpointContainer>
                    </NavDrawerProvider>
                </ThemeProvider>
            </StyleSheetManager>
        </MuiThemeProvider>
    );
};
