// externals
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { DealPulseInterstate, Terms } from '@makemydeal/dr-dash-components';

// components
import { FeesCard } from '../fees/FeesCard';
import DeskingActionsInterstate from '../DeskingActions.interstate';
import { DealWrapper, HeaderRowWrapper } from '../DeskingActivity.styles';
import { DeskingAlerts } from '../DeskingAlerts';
import OfferCommentsInterstate from '../OfferComments.interstate';
import InterstateAccessoriesCard from '../accessories/AccessoriesCard.interstate';
import InterstateDealSummary from '../dealSummary/DealSummary.interstate';
import { RebatesIndex } from '../rebates/index';
import InterstateTradeFormCard from '../tradeForm/TradeFormCard.interstate';
import TaxCard from '../taxes/Taxes/TaxCard';
import { VehicleProtectionPreview } from '../vehicleProtection/VehicleProtectionPreview';
import { DealDetailsHeaderWrapper } from '../dealSummary/DealSummary.interstate.style';

// selectors
import { dealerSelectors } from '@makemydeal/dr-shared-store';

export const DeskingActivityDealSingle = () => {
    const dealPulseAdminToggle = useSelector(dealerSelectors.getDealPulseToggle);

    return (
        <Grid container columnGap={2} rowGap={2}>
            <DealWrapper columnGap={2} width="100%" data-testid="deal-wrapper">
                {dealPulseAdminToggle && <DealPulseInterstate />}
                <DealDetailsHeaderWrapper>
                    <Typography variant="h3">Deal Details</Typography>
                </DealDetailsHeaderWrapper>
                <InterstateDealSummary />
            </DealWrapper>

            <Grid container data-testid="main-content-container" xs flexDirection="column" flexWrap="nowrap" rowGap={2}>
                <HeaderRowWrapper>
                    <Typography variant="h3">Current Working Offer</Typography>
                </HeaderRowWrapper>
                <DeskingAlerts />
                <Terms />
                <InterstateTradeFormCard />
                <TaxCard />
                <FeesCard />
                {/* NOTE: this duplication of RebatesIndex is purely cosmetic. */}
                <RebatesIndex />
                <VehicleProtectionPreview />
                <Grid container flexDirection="row" rowGap={2} spacing={2}>
                    <Grid xs={12} sm={12} lg={12}>
                        <InterstateAccessoriesCard />
                    </Grid>
                </Grid>
                <OfferCommentsInterstate description="Notes will appear in the offer history and will be visible on Accelerate and Sales View" />
                <DeskingActionsInterstate />
            </Grid>
        </Grid>
    );
};
