import styled, { css } from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { BaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';
import { Grid } from '@interstate/components/Grid';

export const BaseDealHeaderRoot = styled.div<{ isDealCentralExperience: boolean }>`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.isDealCentralExperience ? '1.10rem' : '1.29rem')};
    margin-top: 1.1rem;
    align-items: center;
    @media (max-width: ${BreakPoint.PHONE}) {
        margin-bottom: ${(props) => (props.isDealCentralExperience ? '1.15rem' : '1.64rem')};
    }
`;

export const BaseDealHeaderTitle = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0;
`;

export const BaseDealContainerRoot = styled.div`
    padding: 1.14rem;
    border-radius: 4px;
    border: 1px solid #b2b2b2;
    display: flex;
    margin-bottom: 5rem;

    @media (max-width: ${BreakPoint.PHONE}) {
        padding: 0.57rem;
    }

    @media (max-width: ${BreakPoint.SMALL}) {
        flex-direction: column;
    }

    #expandable-root:focus {
        outline: 2px solid #005ba8;
    }
`;

export const BaseDealContent = styled.div`
    width: 100%;
    flex: 2;
`;

export const BaseDealVehicleInfo = styled.div`
    border-bottom: 1px solid #b2b2b2;
    padding-bottom: 1rem;
`;

export const BaseDealBreakdown = styled.div`
    .base-deal-breakdown-icon {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

export const BaseDealTabs = styled.div<{
    BaseBreakPoint: BaseDealBreakpoint;
    isDealCentralExperienceTabs: boolean;
    isDealCentralExperience: boolean;
}>`
    display: grid;
    & [role='tabpanel'] {
        margin-left: 0;
    }

    [id*='base-deal-tabs-tabs-id-'] {
        display: ${(props) => (props.isDealCentralExperience && props.isDealCentralExperienceTabs ? 'none' : 'block')};
        &[hidden] {
            display: none;
        }
        margin-bottom: 3.57rem;
        margin-top: 1.714rem;
        &[id*='base-deal-tabs-tabs-id-4'] {
            margin-top: 1.42rem;
        }
        &[id*='base-deal-tabs-tabs-id-0'] h3 {
            margin-bottom: 2.214rem;
        }
        [data-testid='fees-card'] .MuiCardHeader-root h3 {
            margin-bottom: 0.571rem !important;
        }
        [data-testid='taxes-card-base'] .MuiCardHeader-root h3 {
            margin-bottom: 0.571rem !important;
        }
        [data-testid='lender-title'] {
            margin-bottom: 0.571rem !important;
        }
        [data-testid='vpp-preview'] .MuiCardHeader-root h3 {
            margin-bottom: 1.071rem !important;
        }
        [data-testid='incentives-card'] .MuiCardHeader-root h3 {
            margin-bottom: 0 !important;
        }

        .card-header-content-container {
            margin-top: 0 !important;
            .MuiCardHeader-root {
                padding-bottom: 0;
                h3 {
                    margin-bottom: 2.214rem;
                }
            }
        }
    }

    #base-deal-tabs {
        display: ${(props) => (props.isDealCentralExperience && !props.isDealCentralExperienceTabs ? 'none' : 'flex')};
        margin-bottom: ${(props) => (props.isDealCentralExperience && props.isDealCentralExperienceTabs ? '1.071' : '0')}rem;
    }

    @media (max-width: ${(props) => props.BaseBreakPoint.PHONE}) {
        ${(props) =>
            props.isDealCentralExperience &&
            props.isDealCentralExperienceTabs &&
            css`
                box-shadow: 0px 0.143rem 0.143rem 0px rgba(0, 0, 0, 0.1);
                border-radius: 0.285rem;
                margin-bottom: 1.14rem;
            `}

        #base-deal-tabs {
            border-radius: 4px;
            border: 1px solid #b2b2b2;
            padding: 0;
            margin-top: 1.14rem;
            box-shadow: 0px 4px 4px 0px #0000001a;
        }

        #base-deal-tabs > div > div {
            flex-direction: column;
            gap: 0px;

            & > button {
                justify-content: flex-start;
                max-width: 100%;
            }

            & > .Mui-selected {
                color: #1976d2;
                background-color: #ebf6ff;
            }
        }

        ${({ isDealCentralExperience }) =>
            isDealCentralExperience &&
            css`
                #base-deal-tabs {
                    border: none;
                    box-shadow: none;
                    margin: 0.57rem;
                }
                #base-deal-tabs > div > div {
                    flex-direction: row;
                    gap: 0.57rem;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    & > button {
                        min-height: 2.286rem;
                        font-size: 1rem;
                        border: 1px solid #1976d2;
                        border-radius: 0.286rem;
                        padding: 0 1rem;
                        justify-content: center;
                        font-weight: 600;

                        &:hover {
                            background-color: #ebf6ff;
                            border-color: #005ba8;
                        }

                        &.Mui-selected {
                            border-color: #1976d2;
                        }
                    }
                }
            `}

        #base-deal-tabs .MuiTabs-indicator {
            display: none;
        }
    }
`;

export const BaseDealLayoutContainer = styled(Grid)`
    padding: 2.07rem 1.14rem 1.14rem !important;
    @media (max-width: ${BreakPoint.PHONE}) {
        padding: 2.57rem 0.57rem 0.57rem !important;
    }
`;
