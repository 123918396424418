import React, { FC, MouseEventHandler } from 'react';
import { ProgramQuote } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors, programActionCreators, programPayloadUtil } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import { CASH, OfferType } from '@makemydeal/dr-platform-types';
import { IPaymentTerm } from '@makemydeal/dr-platform-shared';
import { SelectActionChip } from './styles';
import { Box } from '@interstate/components/Box';

export type SelectedLabelColumnBodyProps = { value: ProgramQuote; index: number };

export const SelectActionColumnBody: FC<SelectedLabelColumnBodyProps> = ({ value, index }) => {
    const dispatch = useDispatch();
    const selectedTerm: IPaymentTerm = useSelector(offerReduxSelectors.getSelectedTerm);
    const offerType: OfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const annualMiles = useSelector(offerReduxSelectors.getAnnualMiles);
    const isApplied = programPayloadUtil.isSelectedProgram(selectedTerm, value, offerType, annualMiles) || offerType === CASH;

    const handleColumnApplyClicked: MouseEventHandler<HTMLButtonElement> = () => {
        if (!isApplied) dispatch(programActionCreators.applySelectedProgram(value));
    };

    return (
        <Box display={'flex'} justifyContent={'center'}>
            <SelectActionChip
                label={isApplied ? 'Selected' : 'Select'}
                data-testid={`select-button-${index}`}
                onClick={handleColumnApplyClicked}
                isApplied={isApplied}
            />
        </Box>
    );
};
