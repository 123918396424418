// libraries
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';

// types
import { CASH } from '@makemydeal/dr-platform-types';

// components
import { Box } from '@interstate/components/Box';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Typography } from '@interstate/components/Typography';
import {
    formatPaymentDetailsCashDown,
    formatSingleCardViewPaymentDetails,
    OpenRollToPaymentSlideOutButton
} from '@makemydeal/dr-dash-components';
import InterstateDealSummary from '../dealSummary/DealSummary.interstate';
import { StyledPaymentTitle } from './DerskingCardTitle.style';
import { DeskingCardLeftPanelContent } from './DeskingCard.style';

interface Props {
    isSingleCardView?: boolean;
}

export const DeskingCardPaymentDetailsSidePanel: React.FC<Props> = ({ isSingleCardView }) => {
    const theme = useInterstateTheme();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const amountToBePaidByCustomer = useSelector((state) => offerReduxSelectors.getDownPaymentByOfferType(state, currentOfferType));

    const paymentDetailLabel = useMemo(() => {
        return formatSingleCardViewPaymentDetails(currentOfferType, monthlyPayment);
    }, [currentOfferType, monthlyPayment]);

    const paymentDetailCashDownLabel = useMemo(() => {
        return formatPaymentDetailsCashDown(currentOfferType, amountToBePaidByCustomer);
    }, [currentOfferType, amountToBePaidByCustomer]);

    return (
        <>
            <StyledPaymentTitle theme={theme} data-testid="desking-card-payment-details" className={'single-card-view'}>
                <Typography sx={{ flexShrink: 0 }} data-testid="desking-card-payment-details-side-panel-title" variant={'h3'}>
                    Payment Details
                </Typography>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography data-testid="desking-card-payment-details-side-panel-detail-label" variant="h3">
                        {paymentDetailLabel}
                    </Typography>
                    {isSingleCardView && <OpenRollToPaymentSlideOutButton />}
                </Box>
                {currentOfferType !== CASH && (
                    <Typography
                        sx={{ color: '#5a5a5a', textAlign: 'right', flexGrow: 1 }}
                        data-testid="desking-card-payment-details-side-panel-cash-down-label"
                        variant="body-xs"
                    >
                        {paymentDetailCashDownLabel}
                    </Typography>
                )}
            </StyledPaymentTitle>

            <DeskingCardLeftPanelContent>
                <InterstateDealSummary />
            </DeskingCardLeftPanelContent>
        </>
    );
};
