import type { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contractingActionCreators, contractingSelectors } from '@makemydeal/dr-dash-store';

import type { FooterActionsType } from '@interstate/components/FooterActions';
import { Typography } from '@interstate/components/Typography';
import { Modal } from '@interstate/components/Modal';
import { Spinner } from '@interstate/components/Spinner';
import { Grid } from '@interstate/components/Grid';

export const GenerateContract = () => {
    const isActive = useSelector(contractingSelectors.isContractingModalOpen);
    const isInProgress = useSelector(contractingSelectors.isContractingInProgress);
    const dispatch = useDispatch();

    if (!isActive) {
        return null;
    }

    const body: ReactElement | string = isInProgress ? (
        <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            textAlign="center"
            rowGap={'1rem'}
            marginBottom={'4rem'}
        >
            <Spinner />
            {'Please wait while loading'}
        </Grid>
    ) : (
        'You are about to generate a contact. Proceeding with this action will cancel ' +
        'all existing contracts. Are you sure you want to proceed?'
    );

    const showFooter = !isInProgress || undefined;
    const footer: FooterActionsType | undefined = showFooter && {
        options: [
            {
                action: () => {
                    dispatch(contractingActionCreators.contractingCloseModal());
                },
                label: 'Cancel',
                buttonStyle: 'tertiary'
            }
        ],
        primary: {
            action: () => {
                dispatch(contractingActionCreators.contractingStartProgress());
            },
            label: 'Proceed'
        }
    };

    return (
        <Modal
            data-testid="generate-contract-dialog"
            show={true}
            size="large"
            header={
                <Typography variant="h3" color="sem.color.on-surface.default">
                    Generate Contract
                </Typography>
            }
            footer={footer}
        >
            {body}
        </Modal>
    );
};
