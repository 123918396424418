import React, { FC } from 'react';
import { Tooltip, TooltipProps } from '@interstate/components/Tooltip';

const EMPTY_VALUE_FALLBACK = '-';

type VehicleProtectionTableCellProps = {
    tooltipContent?: string;
    value: string;
    tooltipSize?: TooltipProps['size'];
};
export const VehicleProtectionTableCell: FC<VehicleProtectionTableCellProps> = ({ value, tooltipContent, tooltipSize = 'lg' }) => {
    if (!tooltipContent || value === EMPTY_VALUE_FALLBACK) {
        return <div>{value}</div>;
    }

    return (
        <Tooltip
            size={tooltipSize}
            position="bottom"
            toolTipContent={tooltipContent}
            sx={{
                '[id="tooltip-title-container-\\"interstate-tooltip"]': {
                    width: 'auto'
                }
            }}
        >
            <div>{value}</div>
        </Tooltip>
    );
};
