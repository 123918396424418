// externals
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Button } from '@interstate/components/Button';

// store
import {
    offerReduxSelectors,
    vehicleProtectionActionCreators,
    vehicleProtectionSelectors,
    vehicleProtectionUtils
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import { DealerProduct, DraftDealProduct, StateTree } from '@makemydeal/dr-dash-types';
import { EditProductDrawerProps, EditProductTermsProps } from '../../types';
import { Rate, RatedProtectionProduct } from '@makemydeal/dr-platform-types';

// styles
import { DrawerSeparator } from './EditProductDrawer.style';
import { StyledSlideOut, StyledSlideOutFooter } from '../vehicleProtection/VehicleProtection.style';

// components
import EditProductAddOns from './EditProductAddOns';
import EditProductInformation from './EditProductInformation';
import EditProductTaxable from './EditProductTaxable';
import EditProductTerms from './EditProductTerms';
import EditOrShowProductPlan from './EditOrShowProductPlan';
import EditProductPrices from './EditProductPrices';

// utils
import { getIncludedAddOnNames, updateDealProductWithRates, getPriceInputErrorMessage, getPlanDefaultRate } from '../../utils';

const EditProductDrawer = ({ productCode: initialProductCode, onClose }: EditProductDrawerProps) => {
    const [productCode, setProductCode] = useState<string | undefined>(undefined);
    const [selectedProductPlan, setSelectedProductPlan] = useState<string | undefined>(undefined);

    const editableProducts = useSelector(vehicleProtectionSelectors.getVppEditableProducts);

    const isPlanSelectionEnabled = useSelector(featureToggleSelectors.isPlanSelectionForVppEnabled);

    const product = useSelector<StateTree, DraftDealProduct | undefined>((state) =>
        vehicleProtectionSelectors.getVppProduct(state, productCode)
    );

    const ratedProduct = useSelector<StateTree, RatedProtectionProduct | undefined>((state) =>
        vehicleProtectionSelectors.getVppRatedProduct(state, productCode)
    );

    const productRates = useSelector<StateTree, Rate[] | undefined>((state) =>
        vehicleProtectionSelectors.getVppProductRates(state, productCode)
    );

    const rateAttributes = useSelector<StateTree, RatedProtectionProduct['rateAttributes']>((state) =>
        vehicleProtectionSelectors.getVppProductRateAttributes(state, productCode)
    );

    const dealerProduct = useSelector<StateTree, Partial<DealerProduct> | undefined>((state) =>
        vehicleProtectionSelectors.getVppDealerProduct(state, productCode)
    );

    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const isRateableProduct = ratedProduct?.rateAttributes && ratedProduct.rateAttributes.length > 0;

    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const [editedProduct, setEditedProduct] = useState<DraftDealProduct | undefined>(undefined);
    const [priceInputErrorMessage, setPriceInputErrorMessage] = useState('');

    const [hasProductInformationError, setHasProductInformationError] = useState(false);
    const [hasPriceError, setHasPriceError] = useState(false);
    const [hasTermsError, setHasTermsError] = useState(false);

    const [isEditedProductChanged, setIsEditedProductChanged] = useState(false);

    const hasError = hasProductInformationError || hasPriceError || hasTermsError;
    const shouldDisableSubmit = hasError || !isEditedProductChanged;

    useEffect(() => {
        if (product) {
            if (ratedProduct) {
                const rate = vehicleProtectionUtils.getSelectedRate(ratedProduct, product, isPlanSelectionEnabled);

                if (rate) {
                    const editedProduct = updateDealProductWithRates(rate, product, dealerProduct);

                    setEditedProduct(editedProduct);

                    return;
                }
            }

            setEditedProduct(product);
        } else {
            setEditedProduct(undefined);
        }
    }, [product, ratedProduct, dealerProduct, isPlanSelectionEnabled]);

    useEffect(() => {
        if (!selectedProductPlan || !product || !ratedProduct) {
            return;
        }

        const defaultRateForSelectedPlan = getPlanDefaultRate(ratedProduct.rates, selectedProductPlan);

        if (defaultRateForSelectedPlan) {
            // it's required to reset the product markup to take the original one from the rate
            const draftProductForUpdate = { ...product, productMarkup: undefined };
            const editedProduct = updateDealProductWithRates(defaultRateForSelectedPlan, draftProductForUpdate, dealerProduct);

            setEditedProduct(editedProduct);
            setIsEditedProductChanged(true);
        }

        setSelectedProductPlan(undefined);
    }, [product, ratedProduct, dealerProduct, selectedProductPlan, isPlanSelectionEnabled]);

    const handleDrawerClose = useCallback(() => {
        setIsEditedProductChanged(false);
        onClose();
    }, [onClose]);

    const onApply = () => {
        if (editedProduct) {
            dispatch(vehicleProtectionActionCreators.updateVppProduct(editedProduct, { skipPayment: true }));

            if (initialProductCode !== productCode) {
                dispatch(vehicleProtectionActionCreators.unselectVppProduct(initialProductCode as string, { skipPayment: true }));
            }

            /*
             * NOTE: only last action should dispatch payment call, because we expect a transaction like
             * product updated -> initial product unselected -> another product of same provider is selected
             * -> now right time to make a payment call
             */
            dispatch(vehicleProtectionActionCreators.selectVppProduct(productCode as string));
        }

        setEditedProduct(undefined);

        handleDrawerClose();
    };

    const onCancel = useCallback(() => {
        setEditedProduct(undefined);
        handleDrawerClose();
    }, [handleDrawerClose]);

    const onProductEdit = useCallback(
        (product: DraftDealProduct) => {
            setIsEditedProductChanged(true);
            setEditedProduct((prev) => {
                const editedProduct = { ...prev, ...product };
                const rate = vehicleProtectionUtils.getSelectedRate(ratedProduct, editedProduct, isPlanSelectionEnabled);

                /**
                 * NOTE: for now this callback is called only for products with associated rate, so rate should be presented
                 * product without rate isn't editable, so no way to test this behaviour
                 * however better safe than sorry
                 */
                // istanbul ignore next
                if (!rate) {
                    return editedProduct;
                }

                return updateDealProductWithRates(rate, editedProduct, dealerProduct);
            });
        },
        [ratedProduct, dealerProduct, isPlanSelectionEnabled]
    );

    // NOTE: wrapper of `onProductEdit` to reset product markup  and later take the original one (before possible user changes)
    const onProductTermsEdit = (productTerms: EditProductTermsProps) => {
        onProductEdit({
            ...productTerms,
            productMarkup: undefined
        });
    };

    const handleProviderChange = useCallback(
        (productCategoryCode: string, productProviderName: string) => {
            // NOTE: for now we take first product
            const [providerProduct] = editableProducts.filter(
                (product) =>
                    product.productCategoryCode === productCategoryCode && product.productProviderName === productProviderName
            );

            // NOTE: when editedProduct is undefined - the flow won't achieve this place
            // istanbul ignore next
            if (providerProduct?.productCode && editedProduct?.productCode !== providerProduct.productCode) {
                setIsEditedProductChanged(true);
                setEditedProduct(providerProduct);
                setProductCode(providerProduct.productCode);
            }
        },
        [editableProducts, editedProduct]
    );

    const handleProductOrPlanChange = useCallback(
        (productCode: string, productProviderPlan: string) => {
            setSelectedProductPlan(productProviderPlan);
            setProductCode(productCode);
        },
        [setProductCode, setSelectedProductPlan]
    );

    useEffect(() => {
        const message = getPriceInputErrorMessage(editedProduct);
        setPriceInputErrorMessage(message || '');
        setHasPriceError(!!message);
    }, [editedProduct]);

    useEffect(() => {
        setProductCode(initialProductCode);
    }, [initialProductCode]);

    const drawerContentItems: ReactElement[] = [];

    if (editedProduct) {
        drawerContentItems.push(
            <EditProductInformation
                data-testid="edit-product-info"
                productProps={{
                    productCode: editedProduct.productCode,
                    productCategoryCode: editedProduct.productCategoryCode,
                    productName: editedProduct.productName,
                    productInitialCost: editedProduct.productInitialCost,
                    productProviderName: editedProduct.productProviderName,
                    includedAddOnNames: getIncludedAddOnNames(editedProduct.productAddOnDetails),
                    productProviderAddress: editedProduct.productProviderAddress
                }}
                onProviderChange={(productProviderName: string) => {
                    if (editedProduct.productCategoryCode) {
                        handleProviderChange(editedProduct.productCategoryCode, productProviderName);
                    }
                }}
                onProviderAddressChange={(address: DraftDealProduct['productProviderAddress']) => {
                    onProductEdit({
                        ...editedProduct,
                        productProviderAddress: address
                    });
                }}
                onValidationError={setHasProductInformationError}
            />,
            <EditOrShowProductPlan
                onEdit={handleProductOrPlanChange}
                productProps={{
                    productCode: editedProduct.productCode,
                    productProviderPlan: editedProduct.productProviderPlan
                }}
            />,
            <EditProductTaxable
                productProps={{
                    isProductTaxable: editedProduct.isProductTaxable
                }}
            />
        );

        if (isRateableProduct) {
            if (productRates) {
                drawerContentItems.push(
                    <EditProductTerms
                        data-testid="edit-product-terms"
                        productProps={{
                            productProviderPlan: editedProduct.productProviderPlan,
                            productCoverageLength: editedProduct.productCoverageLength,
                            productCoverageMiles: editedProduct.productCoverageMiles,
                            productDeductible: editedProduct.productDeductible,
                            productDeductibleMethod: editedProduct.productDeductibleMethod,
                            productServiceInterval: editedProduct.productServiceInterval
                        }}
                        productRates={productRates}
                        rateAttributes={rateAttributes}
                        onEdit={onProductTermsEdit}
                        onValidationError={setHasTermsError}
                    />,
                    <DrawerSeparator theme={theme} />
                );
            }

            drawerContentItems.push(
                <EditProductAddOns productAddOnDetails={editedProduct.productAddOnDetails} onEdit={onProductEdit} />
            );
        }
        drawerContentItems.push(<DrawerSeparator theme={theme} />);
        drawerContentItems.push(
            <EditProductPrices
                data-testid="edit-product-prices"
                productProps={{
                    productCode: editedProduct.productCode,
                    productCost: editedProduct.productCost,
                    productPrice: editedProduct.productPrice,
                    profitLocation: editedProduct.profitLocation,
                    minPriceAllowedByLender: editedProduct.minPriceAllowedByLender,
                    maxPriceAllowedByLender: editedProduct.maxPriceAllowedByLender,
                    isProductPriceCapitalized: editedProduct.isProductPriceCapitalized,
                    productMarkup: editedProduct.productMarkup
                }}
                priceInputErrorMessage={priceInputErrorMessage}
                offerType={currentOfferType}
                onEdit={onProductEdit}
            />
        );
    }

    return (
        <StyledSlideOut
            data-testid={'edit-vehicle-protection-product'}
            show={!!productCode}
            onHide={onCancel}
            position="right"
            header={`Edit ${editedProduct?.productCategoryCode} ${!isRateableProduct ? '(non-rated product)' : ''}`}
            panelWidth={392}
            footer={{
                footerComponent: (
                    <StyledSlideOutFooter>
                        <Button
                            data-testid="edit-product-drawer-cancel-btn"
                            buttonStyle="tertiary"
                            size="medium"
                            block={true}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid="edit-product-drawer-apply-btn"
                            buttonStyle="primary"
                            size="medium"
                            block={true}
                            disabled={shouldDisableSubmit}
                            onClick={onApply}
                        >
                            Apply
                        </Button>
                    </StyledSlideOutFooter>
                )
            }}
        >
            <div className="with-overflow-y">{drawerContentItems}</div>
        </StyledSlideOut>
    );
};

export default EditProductDrawer;
