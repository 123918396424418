import styled from 'styled-components';

import { Typography } from '@interstate/components/Typography';
import { AccordionDetails } from '@interstate/components/AccordionGroup';
import { TableCell } from '@interstate/components/SimpleTable';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const StyledTableCell = styled.div<{ $textAlign?: string; $minWidth?: string; $marginTop?: string }>`
    min-width: ${(props) => props.$minWidth || '3.125rem'};
    text-align: ${(props) => props.$textAlign || 'left'};
    margin-top: ${(props) => props.$marginTop || '0.3rem'};
`;

export const StyledTableCellWithoutBorder = styled(TableCell)<{ $width?: string }>`
    align-content: start;
    border-bottom-style: unset !important;
    padding: 0 0.5rem !important;
    width: ${(props) => props.$width || 'auto'};
`;

export const StyledTableCellBaseWithoutBorder = styled(TableCell)<{ $width?: string }>`
    align-content: center;
    border-bottom-style: unset !important;
    padding: 0 0.8rem !important;
    width: ${(props) => props.$width || 'auto'};
`;

/* istanbul ignore next */
export const StyledTableCellWithBorder = styled(TableCell)<{ $width?: string }>`
    align-content: center;
    padding: 11px 9px !important;
    width: ${(props) => props.$width || 'auto'};
    border-bottom-style: 1px solid black !important;
`;

export const StyledVehicleDetailsContainer = styled.div`
    padding: 1rem;
`;

export const StyledSpan = styled.span<{ paddingLeft: number }>`
    padding-left: ${(props) => props.paddingLeft}rem;
`;

export const TypoRightAligned = styled(Typography)`
    text-align: right;
`;

export const TaxDetailsContainer = styled(AccordionDetails)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const TaxDetailsBaseContainer = styled(AccordionDetails)`
    width: 42rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0 !important;

    @media (max-width: ${BreakPoint.SMALL}) {
        width: 100%;
    }
`;

export const TaxOverrideContainer = styled.div`
    margin-top: auto;
`;
