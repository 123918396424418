import styled from 'styled-components';

export const StyledSkeletonLoader = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-row: -1 / 1;
`;

export const StyledSkeletonHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
`;

export const StyledSkeletonHeaderActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledSkeletonHeaderActionButtons = styled.div`
    display: flex;
    gap: 1rem;
`;

export const StyledSkeletonVehicleGroup = styled.div`
    display: flex;
    gap: 1.2rem;
`;

export const StyledSkeletonVehicleText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
`;

export const StyledSkeletonVehicleTextGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StyledSkeletonVehicleActions = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 0.4rem;
`;
