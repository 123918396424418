// externals
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';

// interstate components
import { Action } from '@interstate/components/Action';
import { ArrowPathIcon } from '@interstate/components/Icons/ArrowPathIcon';

// action creators
import { rollToActionCreators } from '@makemydeal/dr-dash-store';

// selectors
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const StyledAction = styled(Action)`
    && {
        padding: 0;
    }
`;

export const OpenRollToPaymentSlideOutButton = () => {
    const dispatch = useDispatch();
    const rollToPaymentFeatureToggle = useSelector(featureToggleSelectors.enableGetRollToPayment);
    const useDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const handleToggleSlideOut: MouseEventHandler<HTMLAnchorElement> = (event): void => {
        event.stopPropagation();
        dispatch(rollToActionCreators.toggleRollToPayment());
    };

    if (!rollToPaymentFeatureToggle) return null;

    return (
        <StyledAction
            actionIcon={{ icon: <ArrowPathIcon />, start: true }}
            id="roll-to-payment"
            data-testid="roll-to-payment"
            onClick={handleToggleSlideOut}
            size="md"
            sx={{ ml: '1rem' }}
        >
            {useDealScreenExperience && <span>Roll To</span>}
        </StyledAction>
    );
};
