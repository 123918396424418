// types
import { RequestRollToPaymentsPayload, RollToBasicObject } from '@makemydeal/dr-dash-types';

export const getPSErrorLookupKey = (payload: any) => {
    if (payload.error?.errors?.length) {
        const psErrorObj = payload.error.errors[0].originalPSError;
        let psErrorMessage: string | null = null;
        if (psErrorObj?.validationErrors) {
            psErrorMessage = getValidationErrorMessage(psErrorObj.validationErrors);
        } else if (psErrorObj?.message) {
            psErrorMessage = psErrorObj.message;
        }

        if (psErrorMessage && typeof psErrorMessage === 'string') {
            const lowercasePSErrorMsg: any = psErrorMessage.toLowerCase();
            const regex = /[a-z]/g;
            const onlyLowercaseCharacterError = lowercasePSErrorMsg.match(regex).join('');
            const actionForPayment =
                payload.originalAction.meta?.paymentGrid?.mainPaymentActionType || payload.originalAction.type || '';
            const lowercaseActionForPayment = actionForPayment.toLowerCase().match(regex)?.join('');

            const paymentServiceMappingKey = lowercaseActionForPayment + '_' + onlyLowercaseCharacterError;
            return paymentServiceMappingKey;
        }
    }
    return null;
};

export function getValidationErrorMessage(data: any, msg = ''): string {
    if (!data || msg) {
        return msg;
    }
    for (const [_, elm] of Object.entries(data)) {
        if (Array.isArray(elm)) {
            for (const value of elm) {
                if (value && typeof value === 'object') {
                    msg = getValidationErrorMessage(value, msg);
                } else {
                    msg = value;
                    break;
                }
            }
        } else if (elm && typeof elm === 'object') {
            msg = getValidationErrorMessage(elm, msg);
        } else {
            msg = elm as string;
        }
        if (msg) {
            break;
        }
    }
    return msg;
}

// Accoring to US1598052, we need to filter out messages with "***** FATAL" string
function shouldFilterMessage(message: RollToBasicObject): boolean {
    return typeof message.message === 'string' && message.message.includes('***** FATAL');
}

export function parseErrorMessages(payload: RequestRollToPaymentsPayload): RollToBasicObject[] | undefined {
    if (!payload) return undefined;

    const allMessages: RollToBasicObject[] = [...(payload.messages || [])].filter((message) => !shouldFilterMessage(message));

    if (payload.rollProgramItems && Array.isArray(payload.rollProgramItems)) {
        payload.rollProgramItems.forEach((item: any) => {
            if (item.rollProgram && Array.isArray(item.rollProgram.messages)) {
                const filteredMessages = item.rollProgram.messages.filter(
                    (message: RollToBasicObject) => !shouldFilterMessage(message)
                );
                allMessages.push(...filteredMessages);
            }
        });
    }

    return allMessages.length > 0 ? allMessages : undefined;
}
