// externals
import { FC } from 'react';
import { nanoid } from 'nanoid';

// libraries
import { SelectInput } from '@interstate/components/SelectInput';

// interfaces/types
import { ColumnMapping, SelectInputChangeInterstateEventHandler } from '../manualAccessoriesTypes';

// consts/enums
import * as deskingConstants from '../../../../constants';
import { profitOptions } from '../manualAccessoriesConsts';

export type ManualAccessoryProfitFieldStateProps = {
    deleted: boolean;
    index: number;
    profit: string | undefined;
    profitColumnMapping: ColumnMapping<string>;
};

export type ManualAccessoryProfitFieldDispatchProps = {
    handleInputChange: SelectInputChangeInterstateEventHandler;
};

export type ManualAccessoryProfitFieldProps = ManualAccessoryProfitFieldDispatchProps & ManualAccessoryProfitFieldStateProps;

export const ManualAccessoryProfitField: FC<ManualAccessoryProfitFieldProps> = ({
    deleted,
    index,
    profit,
    profitColumnMapping,
    handleInputChange
}) => {
    const uniqueId = nanoid();
    return (
        <SelectInput
            label={deskingConstants.PROFIT}
            displayLabel={true}
            data-testid={`${profitColumnMapping.fieldName}-${index}`}
            displayDeselectOption={false}
            onChange={(e) => {
                handleInputChange(e, profitColumnMapping.fieldName);
            }}
            name={`${profitColumnMapping.fieldName}-${index}`}
            options={profitOptions}
            value={profit}
            required={false}
            disabled={deleted}
            id={`base-accessories-profit-field-${index}-${uniqueId}`}
        />
    );
};
