// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { dealerSelectors, type ReduxNext } from '@makemydeal/dr-shared-store';

// consts/enums
import { DELETE_DEAL_TEMPLATE, GET_DEAL_TEMPLATES, SAVE_DEAL_TEMPLATE } from '../actionTypes/dealTemplateActionTypes';

// actions
import { buildOfferSavePayloadObject } from '../actions/offerActionCreators';

// utils
import {
    deleteTemplatesRSAA,
    getTemplatesRSAA,
    saveTemplateRSAA,
    TemplateSavePayload
} from '../actions/dealTemplateActionCreators';
import { bridgeUserSelectors, configSelectors } from '../selectors';

export const middleware = (store: any) => (next: ReduxNext) => (action: AnyFSA) => {
    next(action);

    switch (action.type) {
        case SAVE_DEAL_TEMPLATE: {
            const state = store.getState();

            const offerSavePayload = buildOfferSavePayloadObject(state);

            const user = {
                bridgeId: bridgeUserSelectors.getBridgeId(state),
                name: `${bridgeUserSelectors.getBridgeFirstName(state)} ${bridgeUserSelectors.getBridgeLastName(state)}`
            };
            const templateSavePayload: TemplateSavePayload = { ...offerSavePayload, user };
            const saveOfferAction = saveTemplateRSAA({ state, source: action.meta?.source, templateSavePayload });

            store.dispatch(saveOfferAction);
            break;
        }
        case GET_DEAL_TEMPLATES: {
            const state = store.getState();

            const dealerId = dealerSelectors.getDealerId(state);
            const bffEndpoint = configSelectors.getServicesBff(state);

            const getTemplatesAction = getTemplatesRSAA(dealerId, bffEndpoint);

            store.dispatch(getTemplatesAction);
            break;
        }
        case DELETE_DEAL_TEMPLATE: {
            const { template } = action.payload;
            const state = store.getState();

            const dealerId = dealerSelectors.getDealerId(state);
            const bffEndpoint = configSelectors.getServicesBff(state);

            const getTemplatesAction = deleteTemplatesRSAA(dealerId, template, bffEndpoint);

            store.dispatch(getTemplatesAction);
            break;
        }

        default:
            break;
    }
};
