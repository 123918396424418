const NA_ERROR_MESSAGE =
    'Adjustment resulted money back to the customer and is currently not supported. Please adjust to continue.';
const term_greater_than_12 = 'Ensure term is greater than or equal to 12';
const term_less_than_108 = 'Ensure term is less than or equal to 108';
const DOWN_PAYMENT_EXCEEDS_ERROR = 'Down Payment cannot exceed Selling Price. Please adjust to continue.';

// Rate Error
export const extractRateErrors = (errors: any): string[] => {
    const messages: string[] = [];

    if (!Array.isArray(errors)) return messages;

    errors.forEach((error) => {
        // checking from user-program-rolls response
        error.details?.psResponse?.messages?.forEach((message: any) => {
            message?.validationErrors?.non_field_errors?.forEach((errorMsg: string) => {
                if (errorMsg.includes('sellrate')) {
                    messages.push('Ensure rate is less than or equal to 50%.');
                }
            });
        });

        // checking from payment-orchestrator-with-dealer-id response
        error.originalPSError?.validationErrors?.non_field_errors?.forEach((errorMsg: string) => {
            if (errorMsg.includes('sellrate')) {
                messages.push('Ensure rate is less than or equal to 50%.');
            }
        });
    });

    return messages;
};

// Negative Amortization error
export const extractNegativeAmortizationErrors = (errors: any): string[] => {
    const messages: string[] = [];

    if (!Array.isArray(errors)) return messages;

    errors.forEach((error) => {
        /* istanbul ignore next */
        if (error.originalPSError?.message === 'Negative Amortization' && error.originalPSError?.messageType === 'Error') {
            messages.push(NA_ERROR_MESSAGE);
        }

        // Check details path for validation errors in goal.cashGoals
        error.details?.validationErrors?.customFieldErrors?.goal?.cashGoals?.['0']?.forEach((msg: string) => {
            if (msg.includes('less than or equal to 999999')) {
                messages.push(NA_ERROR_MESSAGE);
            }
        });

        // Check details path for validation errors in userProgramQuotesRequests
        error.details?.validationErrors?.customFieldErrors?.userProgramQuotesRequests?.goal?.cashGoals?.['0']?.forEach(
            (msg: string) => {
                if (msg.includes('less than or equal to 999999')) {
                    messages.push(NA_ERROR_MESSAGE);
                }
            }
        );

        // Check originalPSError paths
        error.originalPSError?.validationErrors?.goal?.cashGoals?.['0']?.forEach((msg: string) => {
            if (msg.includes('less than or equal to 999999')) {
                messages.push(NA_ERROR_MESSAGE);
            }
        });

        // Check originalPSError validation_errors path
        error.originalPSError?.validation_errors?.userProgramQuotesRequests?.goal?.cashGoals?.['0']?.forEach((msg: string) => {
            if (msg.includes('less than or equal to 999999')) {
                messages.push(NA_ERROR_MESSAGE);
            }
        });
    });

    return messages;
};

// Terms below 12 or over 108 error
export const extractTermsError = (errors: any): string[] => {
    const messages: string[] = [];

    if (!Array.isArray(errors)) return messages;

    errors.forEach((error) => {
        // checking from payment-orchestrator-with-dealer-id response
        error.details?.validationErrors?.customFieldErrors?.userProgram?.termLevels?.forEach((level: any) => {
            level.term?.forEach((value: string) => {
                if (value.includes('less than or equal to 108')) {
                    messages.length = 0;
                    messages.push(term_less_than_108);
                }
            });
        });

        // checking from user-program-rolls response
        error.details?.psResponse?.messages?.forEach((message: any) => {
            message?.validationErrors?.non_field_errors?.forEach((error: string) => {
                if (error === term_greater_than_12) {
                    messages.length = 0;
                    messages.push(term_greater_than_12);
                }
            });
        });

        // making sure to check originalPSError path for both services as well
        error.originalPSError?.validationErrors?.userProgram?.termLevels?.forEach((level: any) => {
            level.term?.forEach((value: string) => {
                if (value.includes('less than or equal to 108')) {
                    messages.length = 0;
                    messages.push(term_less_than_108);
                }
            });
        });
    });

    return messages;
};

// Selling Price above 999999 error
export const extractSellingPriceErrors = (errors: any): string[] => {
    const messages: string[] = [];

    if (!Array.isArray(errors)) return messages;

    errors.forEach((error) => {
        // customFieldErrors path
        error.details?.validationErrors?.customFieldErrors?.goal?.profitDollarGoal?.forEach((msg: string) => {
            if (msg.includes('less than or equal to 999999')) {
                messages.push('Ensure Selling Price is less than or equal to $999,999.');
            }
        });

        // originalPSError path
        error.originalPSError?.validationErrors?.goal?.profitDollarGoal?.forEach((msg: string) => {
            if (msg.includes('less than or equal to 999999')) {
                messages.push('Ensure Selling Price is less than or equal to $999,999.');
            }
        });
    });

    return messages;
};

// Down Payment exceeds Selling Price error
export const extractDownPaymentErrors = (errors: any): string[] => {
    const messages: string[] = [];

    if (!Array.isArray(errors)) return messages;

    errors.forEach((error) => {
        error.details?.psResponse?.messages?.forEach((message: any) => {
            message?.validationErrors?.finance?.overall?.forEach((errorMsg: string) => {
                if (errorMsg.includes('Down Payment exceeds the Selling Price')) {
                    messages.push(DOWN_PAYMENT_EXCEEDS_ERROR);
                }
            });
        });

        /* istanbul ignore next */
        if (error.originalPSError?.message === 'Negative Amortization' && error.originalPSError?.fieldName === 'downPayment') {
            messages.push(DOWN_PAYMENT_EXCEEDS_ERROR);
        }
    });

    return messages;
};
