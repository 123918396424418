// externals
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { SimpleTable } from '@interstate/components/SimpleTable';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

// types
import { TaxDetail } from '../types';

// components
import TableHead from './TableHead';
import TableBody from './TableBody';
import TaxItemRateField from './TaxItemRateField';
import { DefaultFallback } from '../../common/taxesAndFeesComponents';
import TaxItemAmountField from './TaxItemAmountField';

// styles
import { StyledSpan, StyledTableCell, StyledTableCellBaseWithoutBorder, StyledTableCellWithoutBorder } from './TaxCard.style';

// consts/enums
import { DATA_DENSITY_LARGE } from '../../common/taxesAndFeesConsts';
import { TAXTABLECOLUMNS, PRIMARY_CLASS, ADDITIONAL_CLASS, MUT_TAX, TAXBASETABLECOLUMNS } from '../constants';
import { getTaxDetails } from './helpers';

const TaxItemSummaryDetailed: React.FC<{
    taxItem: paymentServicesTypes.TaxItem;
    handleChange: (breakdownType: 'amount' | 'rate') => (e: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => void;
    isTotalTaxRateOverThreshold?: boolean;
    taxOverrideEnabled?: boolean;
}> = ({ taxItem, handleChange, isTotalTaxRateOverThreshold, taxOverrideEnabled }) => {
    const isOverrideTaxAmountForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxAmountForPaymentEnabled);
    const isOverrideAdditonalTaxesForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideAdditonalTaxesForPaymentEnabled);
    let hasManualTotalTax = useSelector(offerReduxSelectors.hasManualTotalTax);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const editableTaxClasses = [PRIMARY_CLASS];
    const shouldDisableFields = isBaseDealScreenExperience && taxOverrideEnabled;

    const taxDetails = useMemo(() => getTaxDetails(taxItem, shouldDisableFields), [taxItem, shouldDisableFields]);

    if (isOverrideAdditonalTaxesForPaymentEnabled) {
        editableTaxClasses.push(ADDITIONAL_CLASS);
    }

    const isEditableTaxClass = editableTaxClasses.includes(taxItem.class || '');
    let showEditableTaxRate = isEditableTaxClass && taxItem.taxBasisAmount !== 0;
    let showEditableTaxAmount = isOverrideTaxAmountForPaymentEnabled && isEditableTaxClass && taxItem.taxName !== MUT_TAX;

    if (isBaseDealScreenExperience) {
        showEditableTaxRate = true;
        showEditableTaxAmount = true;
        hasManualTotalTax = false;
    }
    if (isOverrideTaxAmountForPaymentEnabled) {
        taxDetails.forEach((taxDetail) => {
            taxDetail.hasError = isTotalTaxRateOverThreshold;
        });
    }
    const TaxBorder = isBaseDealScreenExperience ? StyledTableCellBaseWithoutBorder : StyledTableCellWithoutBorder;
    const renderRow = (detail: TaxDetail, index: number) => (
        <React.Fragment key={`detailed-cell-${index}-${detail.name}-${detail.amount}`}>
            <TaxBorder>
                <StyledTableCell $textAlign="left" data-testid={`detailed-table-cell-${detail.name}`}>
                    {hasManualTotalTax ? (
                        <StyledSpan paddingLeft={1}>
                            <DefaultFallback />
                        </StyledSpan>
                    ) : (
                        `${detail.name}`
                    )}
                </StyledTableCell>
            </TaxBorder>
            <TaxBorder key={`detail-cell-${index}-${detail.rate}-${detail.key}-${taxItem.taxName}`}>
                {showEditableTaxRate && detail.isRateEditable ? (
                    <StyledTableCell data-testid={`detailed-table-cell-${detail.rate}-${detail.key}`} $textAlign="right">
                        {hasManualTotalTax ? (
                            <DefaultFallback />
                        ) : (
                            <TaxItemRateField data={detail} handleBlur={handleChange('rate')} />
                        )}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell
                        $textAlign="left"
                        data-testid={`detailed-table-cell-${detail.rate}`}
                        $minWidth="8rem"
                        $marginTop="1rem"
                    >
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax && (detail.rate == undefined ? <DefaultFallback /> : `${detail.rate}%`)}
                    </StyledTableCell>
                )}
            </TaxBorder>
            <TaxBorder key={`detail-cell-amount-${index}-${detail.key}-${taxItem.taxName}`}>
                {showEditableTaxAmount && detail.isAmountEditable ? (
                    <StyledTableCell data-testid={`detailed-table-cell-${detail.amount}-${detail.key}`} $textAlign="right">
                        {hasManualTotalTax ? (
                            <DefaultFallback />
                        ) : (
                            <TaxItemAmountField data={detail} handleBlur={handleChange('amount')} />
                        )}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell
                        $textAlign="right"
                        data-testid={`detailed-table-cell-${detail.amount}`}
                        $minWidth="9rem"
                        $marginTop="1rem"
                    >
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax &&
                            (detail.rate == undefined ? <DefaultFallback /> : formatDollarsAndCents(detail.amount))}
                    </StyledTableCell>
                )}
            </TaxBorder>
        </React.Fragment>
    );

    return (
        <SimpleTable
            data-testid="expanded-table-data-testid"
            dataDensity={DATA_DENSITY_LARGE}
            background="white"
            bordered={false}
            hover={false}
            sx={{ overflowX: 'unset' }}
        >
            <TableHead columns={isBaseDealScreenExperience ? TAXBASETABLECOLUMNS : TAXTABLECOLUMNS} />
            <TableBody data={taxDetails} renderRow={renderRow} />
        </SimpleTable>
    );
};

export default TaxItemSummaryDetailed;
