import styled, { css } from 'styled-components';

/* eslint-disable @typescript-eslint/ban-types */
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

export const HeaderRoot = styled.div`
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
`;

export const HeaderContainer = styled.div`
    background-color: #ffffff;
`;

export const HeaderPanelsRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${BreakPoint.SMALL}) {
        padding: 0 1rem;
        flex-direction: row;
    }
`;

export const HeaderRowContainer = styled.div<{
    isNewAppWidthEnabled: boolean;
    extendedHeaderView: boolean;
    isBaseDealScreenExperience?: boolean;
}>`
    display: flex;
    flex-direction: row;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    padding: ${({ extendedHeaderView }) => (extendedHeaderView ? '0.5rem 1rem 0.875rem 1rem' : '0.6rem 1rem 0.7rem 1rem')};
    padding-left: ${({ isBaseDealScreenExperience }) => (isBaseDealScreenExperience ? '1.14rem' : 'auto')};
    border-bottom: ${({ theme }) => `0.3rem solid ${theme.tokens.BaseColorBlue700}`};
    border-top: ${({ extendedHeaderView, theme }) =>
        extendedHeaderView ? 'initial' : `1px solid ${theme.tokens.BaseColorGray300}}`};
`;

export const DealInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`;

export const ModalWrapperContainer = styled.div`
    position: relative;
`;

export const HeaderContentRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    color: #000;
`;

export const InsuranceSeparator = styled.span`
    margin: 6px 0;
    border-left: 1px solid;
    color: #959595;
`;

export const HeaderCoBuyerInsuranceContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const BackButtonRow = styled.div<{ isNewAppWidthEnabled: boolean; extendedHeaderView: boolean }>`
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin-bottom: 0.625rem;
    margin: 0 auto;
    padding: 0 0.5rem;
    padding-top: ${({ extendedHeaderView }) => (extendedHeaderView ? '0' : '0.6rem')};
`;

export const HeaderContent = styled.div<{ extendedHeaderView: boolean }>`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};

    @media (min-width: ${BreakPoint.PHONE}) {
        padding-top: ${({ extendedHeaderView }) => (extendedHeaderView ? '1rem' : '0')};
    }
`;

export const HeaderBuyerContainer = styled.div<{
    isBaseDealScreenExperience?: boolean;
    enableInsuranceProviderSearch?: boolean;
}>`
    display: flex;
    flex-direction: row;
    gap: 0.6rem 1rem;
    ${({ isBaseDealScreenExperience, enableInsuranceProviderSearch }) =>
        isBaseDealScreenExperience &&
        enableInsuranceProviderSearch &&
        css`
            @media (max-width: ${BreakPoint.PHONE}) {
                flex-direction: column;
            }
        `}
`;

export const ModifiedIndicatorContainer = styled.div`
    position: absolute;
    z-index: 100000;
    top: 0;
    right: 0.3rem;
    font-size: 2rem;
    color: red;
`;

export const BridgeBarContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    width: 100%;
    padding-top: 3px;

    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
`;

export const TopBarContainer = styled.div`
    background-color: #ffffff;
    display: flex;
    padding: 0px;
`;

export const HamburgerMenuContainer = styled.div`
    width: 40px;
`;
