// externals
import { FC } from 'react';

// libraries
import { Button } from '@interstate/components/Button';

// styles
import {
    ManualAccessoriesButtonsBaseContainer,
    ManualAccessoriesButtonsContainer
} from '../../ManualAccessories.interstate.styles';
import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { useBaseDealBreakpoint } from '../../../../utils/useBaseDealBreakpoint';

export type ManualAccessoryControlButtonsProps = {
    handleClose: any;
    handleSave: any;
    isDisabled: boolean;
};
export const ManualAccessoryControlButtons: FC<ManualAccessoryControlButtonsProps> = ({ handleClose, handleSave, isDisabled }) => {
    const BaseBreakPoint = useBaseDealBreakpoint();
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const ButtonContainer = isBaseDealScreenExperience ? ManualAccessoriesButtonsBaseContainer : ManualAccessoriesButtonsContainer;
    return (
        <ButtonContainer id="manual-accessories-button-container" BaseBreakPoint={BaseBreakPoint}>
            {!isBaseDealScreenExperience && (
                <Button data-testid="btn-manual-accessories-cancel-edit" buttonStyle="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            )}

            <Button
                data-testid="btn-manual-accessories-edit-update-offer"
                buttonStyle={isBaseDealScreenExperience ? 'secondary' : 'primary'}
                disabled={isDisabled}
                onClick={handleSave}
            >
                {isBaseDealScreenExperience ? 'Apply Changes' : 'Save'}
            </Button>
        </ButtonContainer>
    );
};
