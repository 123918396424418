import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { ReactNode, FC, ComponentProps } from 'react';
import { ExpandableArea } from '../../common/ExpandableArea';
import { EXPANDABLE_ROOT } from '../../constants';
import { StyledExpandableAreaSection } from './DealSummaryItem.style';
import LineItemRow from './LineItemRow';
import ChevronIcon from './ChevronIcon';

interface DealSummaryExpandableViewProps {
    children: ReactNode;
    containerTestId: string;
    expandableId: string;
    expandableOfferId: string;
    isBaseDealScreenExperience: boolean;
    isDealSummaryDetailsExpandId: boolean;
    label: string;
    value: string;
    valueId: string;
    labelStyles: ComponentProps<typeof Grid>['sx'];
    valueStyles: ComponentProps<typeof Grid>['sx'];
    expandibleButtonStyles: ComponentProps<typeof Grid>['sx'];
    expandibleAreaStyles: ComponentProps<typeof Grid>['sx'];
}

const DealSummaryExpandableView: FC<DealSummaryExpandableViewProps> = ({
    children,
    containerTestId,
    expandableId,
    expandableOfferId,
    isBaseDealScreenExperience,
    isDealSummaryDetailsExpandId,
    label,
    value,
    valueId,
    labelStyles,
    valueStyles,
    expandibleButtonStyles,
    expandibleAreaStyles
}) => {
    return (
        <>
            <StyledExpandableAreaSection
                expandableId={isDealSummaryDetailsExpandId ? EXPANDABLE_ROOT : expandableOfferId}
                $isDealSummaryDetailsExpandId={isDealSummaryDetailsExpandId}
            >
                <LineItemRow
                    styles={{
                        pl: isBaseDealScreenExperience ? '0rem' : '0.571rem',
                        ml: isBaseDealScreenExperience ? '-0.143rem' : '0rem',
                        ...(isBaseDealScreenExperience && { pt: '0.714rem', pb: '0.714rem' }),
                        ...expandibleButtonStyles
                    }}
                >
                    <ChevronIcon
                        expandableId={isDealSummaryDetailsExpandId ? EXPANDABLE_ROOT : expandableOfferId}
                        isDealSummaryDetailsExpandId={isDealSummaryDetailsExpandId}
                        isBaseDealScreenExperience={isBaseDealScreenExperience}
                    />
                    <Typography
                        tag="span"
                        sx={{
                            flexGrow: 1,
                            ...labelStyles
                        }}
                        variant="body-sm"
                        color={isBaseDealScreenExperience ? 'sem.color.on-surface.default' : 'sem.color.on-surface.muted'}
                        data-testid={containerTestId}
                    >
                        {label}
                    </Typography>
                    <Typography
                        tag="span"
                        variant="body-lg"
                        color="sem.color.on-surface.default"
                        data-testid={valueId}
                        sx={{
                            ...valueStyles,
                            ...(isBaseDealScreenExperience && { fontWeight: 600, fontSize: '1.143rem' })
                        }}
                    >
                        {value}
                    </Typography>
                </LineItemRow>
            </StyledExpandableAreaSection>
            <ExpandableArea
                data-testid={expandableId}
                expandableId={isDealSummaryDetailsExpandId ? EXPANDABLE_ROOT : expandableOfferId}
            >
                <Grid
                    sx={{
                        pb: 2,
                        pr: 2,
                        pl: isDealSummaryDetailsExpandId ? 0 : '1.857rem',
                        ...expandibleAreaStyles
                    }}
                >
                    {children}
                </Grid>
            </ExpandableArea>
        </>
    );
};

export default DealSummaryExpandableView;
