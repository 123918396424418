// externals
import { useSelector } from 'react-redux';

// libraries
import { BreakPoint } from '@makemydeal/dr-activities-common';

// selectors
import { baseDealSelectors } from '@makemydeal/dr-dash-store';

export type BaseDealBreakpoint = {
    SMALL: string;
    MEDIUM: string;
    LARGE: string;
    PHONE: string;
    SCREEN_MD_MIN: string;
    SCREEN_SM_MIN: string;
    TABLET_PORTRAIT: string;
    SM: string;
    MD: string;
    LG: string;
    XL: string;
};

export enum BaseDealBreakPoints {
    LARGE = '976px',
    XS = '0px',
    SM = '480px',
    MD = '680px',
    LG = '960px',
    XL = '1168px',
    XXL = '1650px'
}

export const useBaseDealBreakpoint = (summaryTab?: boolean, isPaymentGridEnabled?: boolean): BaseDealBreakpoint => {
    const isBreakdownOpen = useSelector(baseDealSelectors.getBreakdownStatus);
    const applyWideLayout = summaryTab ? isPaymentGridEnabled || isBreakdownOpen : isBreakdownOpen;

    return {
        SMALL: applyWideLayout ? BreakPoint.MEDIUM : BreakPoint.SMALL,
        MEDIUM: applyWideLayout ? BaseDealBreakPoints.XXL : BreakPoint.MEDIUM,
        LARGE: applyWideLayout ? BreakPoint.TABLET_PORTRAIT : BaseDealBreakPoints.LARGE,
        PHONE: applyWideLayout ? BreakPoint.MEDIUM : BreakPoint.PHONE,
        SCREEN_MD_MIN: applyWideLayout ? BreakPoint.TABLET_PORTRAIT : BreakPoint.SCREEN_MD_MIN,
        SCREEN_SM_MIN: applyWideLayout ? BreakPoint.SCREEN_MD_MIN : BreakPoint.SCREEN_SM_MIN,
        TABLET_PORTRAIT: applyWideLayout ? BaseDealBreakPoints.XXL : BreakPoint.TABLET_PORTRAIT,
        SM: applyWideLayout ? BaseDealBreakPoints.MD : BaseDealBreakPoints.SM,
        MD: applyWideLayout ? BaseDealBreakPoints.LG : BaseDealBreakPoints.MD,
        LG: applyWideLayout ? BaseDealBreakPoints.XL : BaseDealBreakPoints.LG,
        XL: applyWideLayout ? '1920px' : BaseDealBreakPoints.XL
    };
};
