// external
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { ViewAllScenarios } from './viewAllScenarios.interstate';

// types
import type { StateTree } from '@makemydeal/dr-dash-types';
import {
    dealHistoryActionCreators,
    dealHistorySelectors,
    multiScenarioActionCreators,
    multiScenarioSelectors,
    offerReduxSelectors
} from '@makemydeal/dr-dash-store';

// utils
import { getScenarioIdsByDealVersion } from '../utils/formatUtils';

const DealHistoryScenarios = memo(() => {
    const dispatch = useDispatch();
    const dealHistoryScenario = useSelector(dealHistorySelectors.getDealHistoryScenarioDrawerDeal);
    const dealHistoryScenariosVersion = dealHistoryScenario?.offer.dealXgVersion || '';

    const comparingDealScenarios = useSelector((state: StateTree) =>
        dealHistorySelectors.getScenariosByDealVersion(state, dealHistoryScenariosVersion)
    );
    const comparisonsForDealVersion = useSelector((state: StateTree) =>
        multiScenarioSelectors.getComparisonByDealVersion(dealHistoryScenariosVersion, state)
    )!;
    const scenarioCount = dealHistorySelectors.getDealScenarioCount(dealHistoryScenario!);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);

    const onDrawerCLose = useCallback(() => dispatch(dealHistoryActionCreators.setDealHistoryScenariosDrawerDeal(undefined)), []);

    useEffect(() => {
        if (dealHistoryScenariosVersion && comparisonsForDealVersion && !comparingDealScenarios.length) {
            const scenarioIds = getScenarioIdsByDealVersion(comparisonsForDealVersion.scenarios, dealHistoryScenariosVersion);

            dispatch(
                multiScenarioActionCreators.fetchScenarios(
                    dealXgId,
                    scenarioIds,
                    { useDxgScenarioEndpoint: true },
                    dealHistoryScenariosVersion
                )
            );
        }
    }, [dealHistoryScenariosVersion, dispatch, comparisonsForDealVersion]);

    return dealHistoryScenariosVersion && scenarioCount ? (
        <ViewAllScenarios
            mainScenario={dealHistoryScenario}
            scenarios={comparingDealScenarios}
            scenarioCount={scenarioCount}
            handleClose={onDrawerCLose}
        />
    ) : null;
});

export default DealHistoryScenarios;
