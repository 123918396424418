import styled from 'styled-components';

export const AutocompleteContainer = styled.div`
    position: relative;
`;

export const SuggestionList = styled.ul<{ $position?: 'top' | 'bottom' }>`
    width: 100%;

    position: absolute;
    ${(props) => (props.$position === 'top' ? 'bottom: 100%;' : 'top: 100%;')}
    margin: ${(props) => (props.$position === 'bottom' ? '-0.3rem 0 0' : '0 0 -0.3rem')};

    max-height: 240px;
    overflow-y: auto;

    padding: 0;

    border: 1px solid #b2b2b2;
    border-radius: 4px;

    background: ${({ theme }) => theme.tokens.BaseColorWhite};

    z-index: 10;
    list-style: none;
`;

export const SuggestionItem = styled.li<{ $active?: boolean }>`
    padding: 10px 12px;
    cursor: pointer;
    background-color: ${(props) => (props.$active ? 'rgb(235, 246, 255)' : 'transparent')};

    &:hover {
        background: rgb(235, 246, 255);
    }
`;
