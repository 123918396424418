// libraries
import { Grid } from '@interstate/components/Grid';

// components
import BaseDealContainer from './baseDealContainer/BaseDealContainer';
import BaseDealHeader from './BaseDealHeader';
import BaseScreenLoader from './BaseScreenLoader/BaseScreenLoader';
import { BaseDealLayoutContainer } from './BaseDealActivity.interstate.style';
import { useSelector } from 'react-redux';
import { deskingSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { isEqual } from 'lodash';

const BaseDealActivity = () => {
    return (
        <Grid container data-testid="base-deal-central" spacing={2}>
            <BaseScreenLoader />
            <BaseDealLayoutContainer xs>
                <BaseDealHeader />
                <BaseDealContainer />
            </BaseDealLayoutContainer>
        </Grid>
    );
};

export default BaseDealActivity;
