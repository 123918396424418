import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { navigationHooks, RouteValues } from '@makemydeal/dr-dash-store';
import { ActionsContainer, CutOffShadowContainer, StickyFooter } from '@makemydeal/dr-dash-components';
import { Button } from '@interstate/components/Button';
import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

export const ProgramsAndIncentivesFooter = () => {
    const theme = useInterstateTheme();
    const handleReturnToOfferButtonClick = navigationHooks.useNavigate(RouteValues.DASHBOARD);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);

    return isBaseDealScreenExperience && isDealCentralExperience ? (
        <StickyFooter>
            <CutOffShadowContainer isNewAppWidthEnabled={true}>
                <ActionsContainer isNewAppWidthEnabled={true} theme={theme} flexDirection="row-reverse">
                    <Button
                        data-testid="return-to-offer-button"
                        size="medium"
                        buttonStyle="secondary"
                        onClick={handleReturnToOfferButtonClick}
                    >
                        Return to Offer
                    </Button>
                </ActionsContainer>
            </CutOffShadowContainer>
        </StickyFooter>
    ) : null;
};
