/* eslint-disable react-hooks/rules-of-hooks */
// externals
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Box } from '@interstate/components/Box';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Card, CardActions } from '@interstate/components/Card';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { dealHistoryActionCreators, dealHistorySelectors, offerInfoSelectors } from '@makemydeal/dr-dash-store';
import { dateUtils } from '@makemydeal/dr-common-utils';
import type { IShopper } from '@makemydeal/dr-platform-types';
import type { DealHistory } from '@makemydeal/dr-dash-types';

// components
import { OfferCardHeader } from '../offerCardHeader/OfferCardHeader.interstate';
import { OfferCardActions } from '../offerCardActions/OfferCardActions';
import { OfferCardContent } from '../offerCardContent/OfferCardContent';
import { StyledABPencilOfferCardContainer, StyledOfferCardWrapper } from './OfferCard.style.interstate';
import { OfferCardStock } from '../offerCardStock/OfferCardStock';
import { OfferCardBadges } from '../offerCardBadges/OfferCardBadges';

/**
 * hasMultiScenarios is for AB Pencil only.
 * Not practically in use currently, but indicating to stack card ui. Can be handled in this component when logic is implemented.
 * Even it is typed as boolean, after logic is implemented,
 * it can be calculated as boolean or as number to specify the scenario value.
 */
export interface IOfferCardProps {
    deal?: DealHistory;
    isDealHistory?: boolean;
    isDefaultOffer?: boolean;
    isMostRecentDealerOffer?: boolean;
    isMostRecentShopperOffer?: boolean;
    hasMultiScenarios?: boolean;
    isCurrentOffer?: boolean;
}

export const OfferCardInterstate: React.FC<IOfferCardProps> = ({
    deal,
    isDealHistory,
    isDefaultOffer,
    isMostRecentDealerOffer,
    isMostRecentShopperOffer,
    hasMultiScenarios = false,
    isCurrentOffer = false
}: IOfferCardProps) => {
    if (!deal) {
        return null;
    }

    const theme = useInterstateTheme();
    const dispatch = useDispatch();
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const socketEventDealVersion = useSelector(offerInfoSelectors.getSocketEventDealVersion);
    const scenarioCount = dealHistorySelectors.getDealScenarioCount(deal) || 0;

    const { offer } = deal;
    const { stockNumber } = offer.vehicle;
    const { shopper, createdOn, offerSource, applicationName, accepted: isAccepted, dealXgVersion } = offer;
    const { firstName, lastName } = shopper as IShopper;
    const isUpdated = !isAccepted && dealXgVersion === socketEventDealVersion;
    const createdOnFormattedDate = createdOn && dateUtils.formatAsMMDDYYYY(new Date(createdOn));
    const createdOnFormattedTime = createdOn && dateUtils.formatAsTime(new Date(createdOn));

    const onHistoryScenariosClick = useCallback(() => {
        dispatch(dealHistoryActionCreators.setDealHistoryScenariosDrawerDeal(deal));
    }, [dispatch, deal]);

    const renderOfferCard = () => {
        // Didn't use OfferCardBadges at here for the old offer card.
        // moving that component to here, can cause css updates in couple of places, which is not necessary.
        return (
            <Card
                className="offer-card-container"
                data-testid="offer-card-interstate"
                sx={{ position: 'relative' }}
                content={<OfferCardContent offer={offer} />}
                footer={
                    <CardActions>
                        <OfferCardActions deal={deal} isDealHistory={isDealHistory} />
                    </CardActions>
                }
            />
        );
    };

    const renderABPencilOfferCard = () => {
        return (
            <StyledABPencilOfferCardContainer theme={theme}>
                <div className="offer-card-container offer-card--ab-pencil">
                    <div className="offer-card__container">
                        <div className="offer-card__content-wrapper">
                            <div className="offer-card__title-bar">
                                <OfferCardStock stockNumber={stockNumber} />
                                <OfferCardBadges
                                    hasMultiScenarios={hasMultiScenarios}
                                    isAccepted={isAccepted}
                                    isUpdated={isUpdated}
                                    scenarioCount={scenarioCount}
                                    toggleModal={onHistoryScenariosClick}
                                    isCurrent={isCurrentOffer}
                                />
                            </div>
                            <OfferCardContent offer={offer} />
                        </div>
                        <div className={`offer-card__actions ${hasMultiScenarios ? 'offer-card__actions--shrink' : ''}`}>
                            <OfferCardActions deal={deal} isDealHistory={isDealHistory} />
                        </div>
                    </div>
                    {hasMultiScenarios && scenarioCount > 1 && (
                        <div className="stack-container">
                            {/* This stack divs can be as much as the total count of scenarios */}
                            <div className="stack-1"></div>
                            <div className="stack-2"></div>
                        </div>
                    )}
                </div>
            </StyledABPencilOfferCardContainer>
        );
    };

    return (
        <StyledOfferCardWrapper
            data-testid="dr-dash-offer-card"
            className={`offer-card ${isABPencilEnabled ? 'offer-card__wide' : ''}`}
        >
            <Box paddingBottom="12px" className={isABPencilEnabled ? 'offer-card-header' : ''}>
                <OfferCardHeader
                    shopperFirstName={firstName}
                    shopperLastName={lastName}
                    createdOnDate={createdOnFormattedDate}
                    createdOnTime={createdOnFormattedTime}
                    offerSource={offerSource}
                    applicationName={applicationName}
                    isDefaultOffer={isDefaultOffer}
                    isMostRecentDealerOffer={isMostRecentDealerOffer}
                    isMostRecentShopperOffer={isMostRecentShopperOffer}
                />
            </Box>

            {isABPencilEnabled ? renderABPencilOfferCard() : renderOfferCard()}
        </StyledOfferCardWrapper>
    );
};

export default OfferCardInterstate;
