// external
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// components
import { Button } from '@interstate/components/Button';
import { Spinner } from '@interstate/components/Spinner';
import OfferCompareColumn from '../offerCompareColumn/OfferCompareColumn.interstate';
import { AllScenarioMockWrapper } from './allScenarioMockWrapper';
import MakeCurrentModalInterstate from '../makeCurrentModal/MakeCurrentModal.interstate';

// styles
import { ViewAllScenariosAction, StyledSlideOutContent, EmptyColumnsContainer, StyledColumnsContainer } from './style';
import { LoadingIndicatorContainer } from '../dealComparison/DealComparison.style';
import { OfferCompareContainer } from '../quickCompare/QuickCompare.interstate.style';

// types
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { MakeCurrentButtonView } from '../makeCurrentModal/types';
import { dealHistoryActionCreators, navigationHooks, quickCompareActionCreators, RouteValues } from '@makemydeal/dr-dash-store';

export interface ViewAllScenariosInterstateProps {
    mainScenario?: DealHistory;
    scenarios?: DealHistory[];
    scenarioCount: number;
    handleClose: () => void;
}

export const ViewAllScenarios = ({ mainScenario, scenarios, scenarioCount, handleClose }: ViewAllScenariosInterstateProps) => {
    const dispatch = useDispatch();
    const isDisabled = !scenarios || !scenarios.length;
    const navigateToDashBoard = navigationHooks.useNavigate(RouteValues.DASHBOARD);

    const lengthOfScenario = useMemo(
        () => (scenarioCount ? Array.from({ length: scenarioCount }, (_, i) => i + 1) : []),
        [scenarioCount]
    );

    const cleanQuickCompareOffer = useCallback(() => {
        dispatch(quickCompareActionCreators.updateQuickCompareOffer({} as DealHistory));
    }, [dispatch]);

    const setCurrentScenario = useCallback(() => {
        if (mainScenario) {
            dispatch(dealHistoryActionCreators.makeCurrentDeal(mainScenario.offer.dealXgVersion));
            cleanQuickCompareOffer();
            navigateToDashBoard();
            handleClose();
        }
    }, [dispatch, mainScenario, cleanQuickCompareOffer, navigateToDashBoard]);

    const updateScenarioComparison = useCallback(() => {
        dispatch(quickCompareActionCreators.updateQuickCompareOffer(mainScenario as DealHistory));
    }, [dispatch, mainScenario]);

    const handleCloseModal = useCallback(() => {
        updateScenarioComparison();
        handleClose();
    }, [updateScenarioComparison, handleClose]);

    const handleSetCurrentScenario = useCallback(() => {
        setCurrentScenario();
    }, [setCurrentScenario]);

    useEffect(() => {
        updateScenarioComparison();
    }, [updateScenarioComparison]);

    const slideOutFooter = (
        <ViewAllScenariosAction gap={2}>
            {!isDisabled && (
                <>
                    <Button
                        size="small"
                        buttonStyle="secondary"
                        sx={{ height: '40px', padding: '12px' }}
                        data-testid="slideout-footer-cancel-button"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <MakeCurrentModalInterstate
                        onMakeCurrentOffer={handleSetCurrentScenario}
                        makeCurrentButtonView={MakeCurrentButtonView.DealHistory}
                    />
                </>
            )}
        </ViewAllScenariosAction>
    );

    return (
        <>
            <StyledSlideOutContent
                data-testid="view-all-scenarios-drawer"
                show
                position="right"
                onHide={handleCloseModal}
                panelWidth="auto"
                header="View All Scenarios"
                footer={{ footerComponent: slideOutFooter, layout: 'rightAligned' }}
            >
                {scenarios?.length ? (
                    <OfferCompareContainer cardsAmount={scenarioCount}>
                        {mainScenario && (
                            <StyledColumnsContainer className="offer-compare-column">
                                <OfferCompareColumn deal={mainScenario} isCompareScenario={true} isPrimary={true} />
                            </StyledColumnsContainer>
                        )}
                        {scenarios.map((scenario) => (
                            <StyledColumnsContainer key={scenario.offer.dealXgVersion} className="offer-compare-column">
                                <OfferCompareColumn
                                    deal={scenario}
                                    isCurrent={false}
                                    isQuickCompare={false}
                                    isCompareScenario={true}
                                    isPrimary={false}
                                />
                            </StyledColumnsContainer>
                        ))}
                    </OfferCompareContainer>
                ) : (
                    <EmptyColumnsContainer container gap={2}>
                        {lengthOfScenario.map((index: number) => (
                            <AllScenarioMockWrapper key={index} />
                        ))}
                        <LoadingIndicatorContainer
                            data-testid="view-all-scenarios-loading-indicator"
                            style={{ position: 'absolute', top: '30%', left: '50%' }}
                        >
                            <Spinner size={60} data-testid="view-all-scenarios-loading-spinner" />
                        </LoadingIndicatorContainer>
                    </EmptyColumnsContainer>
                )}
            </StyledSlideOutContent>
        </>
    );
};
