import { useCallback, useEffect, useState } from 'react';

export const useToastDisappear = (timeout: number) => {
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        let toastTimerId: NodeJS.Timeout | undefined;

        if (showToast) {
            toastTimerId = setTimeout(() => {
                setShowToast(false);
            }, timeout);
        }

        return () => {
            if (toastTimerId) {
                clearTimeout(toastTimerId);
            }
        };
    }, [showToast, timeout]);

    const triggerToast = useCallback(() => setShowToast(true), [setShowToast]);

    return {
        showToast,
        triggerToast
    };
};
