import { FC } from 'react';

import { ChartPieIcon } from '@interstate/components/Icons';
import { Popover } from '@interstate/components/Popover';
import { Action } from '@interstate/components/Action';

import HeaderVehicleImage from '../dealActivity/HeaderVehicleImage.interstate';
import DealInfoInterstate from './DealInfo.interstate';
import { DealInfoContainer, HeaderContentRow, ModalWrapperContainer } from './Header.style.interstate';
import ProfitInfoInterstate from './ProfitInfo.intertstate';
import SummaryInterstate from './Summary.interstate';

export const HeaderRowExtendedInterstate: FC = () => {
    return (
        <>
            <HeaderVehicleImage />
            <HeaderContentRow data-testid="header-row-extended-content">
                <SummaryInterstate />
                <DealInfoContainer>
                    <DealInfoInterstate />
                    <ModalWrapperContainer>
                        <Popover
                            padding={true}
                            header="Profit Details"
                            data-testid="profit-details-popover"
                            position="bottom-end"
                            trigger="outsideClick"
                            popoverContent={<ProfitInfoInterstate />}
                        >
                            <Action
                                size="sm"
                                data-testid="profit-details-btn"
                                sx={{
                                    '.ids-action': {
                                        gap: '4px'
                                    }
                                }}
                                actionIcon={{
                                    icon: <ChartPieIcon />,
                                    start: true
                                }}
                            >
                                Profit
                            </Action>
                        </Popover>
                    </ModalWrapperContainer>
                </DealInfoContainer>
            </HeaderContentRow>
        </>
    );
};
