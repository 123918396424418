// externals
import { useSelector } from 'react-redux';

// libraries
import { Box } from '@interstate/components/Box';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import {
    DealActivityInterstate,
    DealComparisonInterstate,
    DealDatesAndStatus,
    DealTemplateAlert
} from '@makemydeal/dr-dash-components';

// components
import { DealDashboardContainer, InterstateDealActivityContainer } from './DeskingActivity.styles';

// selectors
import { dealLifecycleSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// utils
import { useInterstateToken } from '@interstate/components/InterstateThemeProvider';
import { LenderChangeAlert } from './LenderChangeAlert';
import { DeskingActivityDeal } from './deskingActivity/DeskingActivityDeal';
import RollToChangeAlert from './RollToChangeAlert';

const DeskingActivityInterstate = () => {
    const deskingActivityBackgroundColor = useInterstateToken()('base.color.gray.50');

    const dealLifecycle = useSelector(dealLifecycleSelectors.getDealLifecycle);
    const isDealLifeCycleEnabled = useSelector(featureToggleSelectors.isDealLifeCycleEnabled);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <Grid container data-testid="activities-desking" spacing={2}>
            <InterstateDealActivityContainer
                bgcolor={deskingActivityBackgroundColor}
                container
                flexDirection="column"
                data-testid="deal-activity-container"
                xs={3}
                gap="8px"
                $isABPencilEnabled={isABPencilEnabled}
            >
                <Typography variant="h3">Deal Progress</Typography>
                <DealActivityInterstate className="hidden-xs hidden-sm hidden-md" />
            </InterstateDealActivityContainer>
            <Grid xs paddingTop="40px" paddingBottom="24px">
                <LenderChangeAlert />
                <RollToChangeAlert />
                <Box mb={2}>
                    <DealTemplateAlert />
                </Box>
                <DealDashboardContainer>
                    <Typography variant="h1">{isABPencilEnabled ? 'Deal Scenarios' : 'Deal Dashboard'}</Typography>
                    {isDealLifeCycleEnabled && dealLifecycle.status && (
                        <Grid container justifyContent="flex-end">
                            <DealDatesAndStatus />
                        </Grid>
                    )}
                </DealDashboardContainer>
                <DealComparisonInterstate />
                <DeskingActivityDeal />
            </Grid>
        </Grid>
    );
};

export default DeskingActivityInterstate;
