import styled from 'styled-components';
import { toRem } from '@makemydeal/dr-dash-components';

export const StyledColumnsContainer = styled.div<{ isSelected?: boolean }>`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: -1 / 1;

    grid-auto-rows: max-content;

    border-style: solid;
    padding: ${toRem(16)};
    border-radius: ${toRem(4)};
    gap: ${toRem(8)};
    border: ${(props) => (props.isSelected ? '2px solid #005BA8' : '1px solid #b2b2b2')};

    .offer-compare-column-container {
        margin: 0;
    }
`;

export const OfferCompareContainer = styled.div<{ cardsAmount: number }>`
    display: grid;
    overflow-x: auto;
    grid-template-columns: ${({ cardsAmount }) => `repeat(${cardsAmount}, 432px)`};
    grid-template-rows:
        [header] auto
        [pricing] auto
        [addons] auto
        [finance-reductions] auto
        [financing-terms] auto;

    padding: 0 ${toRem(8)};
    margin: ${toRem(16)} ${toRem(-8)} 0;
    gap: ${toRem(16)};
    margin-top: ${toRem(16)};
    margin-left: ${toRem(-8)};
`;
