// externals
import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

// library
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { Typography } from '@interstate/components/Typography';

// styles
import { Grid } from '@interstate/components/Grid';
import { PopoverRow } from './SliderContainer.style';

export default function ProfitInfo({ className }: HTMLAttributes<HTMLDivElement>) {
    // Selectors to fetch profit details and feature toggle states
    const profit = useSelector(offerReduxSelectors.getSelectedPaymentProfitDetails);
    const isUserProgramQuotes = useSelector(offerReduxSelectors.getIsUserProgramQuotes);
    const combineReserveWithBackEnd = useSelector(featureToggleSelectors.combineReserveProfitWithBackEndProfit);

    const defaultProfit = '$0.00';

    // Format front-end profit
    const frontEndProfit = formatDollarsAndCents(profit?.frontEndProfit, defaultProfit);

    // Calculate back-end profit value based on feature toggle
    const backEndProfitValue = combineReserveWithBackEnd
        ? (profit?.backEndProfit ?? 0) + (profit?.reserveProfit ?? 0)
        : profit?.backEndProfit;

    // Format back-end and reserve profits
    const backEndProfit = formatDollarsAndCents(backEndProfitValue, defaultProfit);
    const reserveProfit = formatDollarsAndCents(profit?.reserveProfit, defaultProfit);

    // Calculate total profit based on conditions

    const totalProfit = formatDollarsAndCents(
        isUserProgramQuotes && combineReserveWithBackEnd
            ? (profit?.frontEndProfit ?? 0) + (backEndProfitValue as number)
            : profit?.totalProfit,
        defaultProfit
    );

    return (
        <Grid
            className={className || ''}
            container
            flexDirection={'column'}
            sx={{
                width: '248px',
                gap: '8px',
                paddingTop: '16px'
            }}
        >
            {/* Display front-end profit */}
            <PopoverRow data-testid="front-end-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Front End
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {frontEndProfit}
                </Typography>
            </PopoverRow>

            {/* Display back-end profit */}
            <PopoverRow data-testid="back-end-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Back End
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {backEndProfit}
                </Typography>
            </PopoverRow>

            {/* Display reserve profit */}
            <PopoverRow data-testid="reserve-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Reserve
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {reserveProfit}
                </Typography>
            </PopoverRow>

            {/* Display total profit */}
            <PopoverRow data-testid="total-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Total
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {totalProfit}
                </Typography>
            </PopoverRow>
        </Grid>
    );
}
