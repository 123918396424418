import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const ContainerGrid = styled.div<{
    $xsBreakpoint?: number;
    $mdBreakpoint?: number;
    $lgBreakpoint?: number;
    $gap?: string;
    $alignItems?: string;
}>`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    container-name: ContainerGrid;
    container-type: inline-size;
    align-items: ${({ $alignItems }) => $alignItems};
    gap: ${({ $gap }) => $gap || '1rem'};

    // FALLBACK FOR BROWSERS WHO NOT SUPPORT CONTAINERS
    @media (min-width: 0px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-xs-${i + 1} {
          display: flex;
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}

        .container-grid-item-xs-hidden {
            display: none;
        }

        .container-grid-item-xs-contents {
            display: contents;
        }

        .container-grid-item-xs-min-content {
            flex: 0 1 min-content;
        }

        .container-grid-item-xs-full {
            flex: 1 0;
            width: 100%;
        }
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-md-${i + 1} {
        display: flex;
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}

        .container-grid-item-md-hidden {
            display: none;
        }

        .container-grid-item-md-contents {
            display: contents;
        }

        .container-grid-item-md-min-content {
            flex: 0 1 min-content;
        }

        .container-grid-item-md-full {
            flex: 1 0;
            width: 100%;
        }
    }

    @media (min-width: ${BreakPoint.MEDIUM}) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-lg-${i + 1} {
          display: flex;
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}

        .container-grid-item-lg-hidden {
            display: none;
        }

        .container-grid-item-lg-contents {
            display: contents;
        }

        .container-grid-item-lg-min-content {
            flex: 0 1 min-content;
        }

        .container-grid-item-lg-full {
            flex: 1 0;
            width: 100%;
        }
    }

    // MAIN CONTAINER
    @container (min-width: ${(props) => (props.$xsBreakpoint ? props.$xsBreakpoint : 0)}px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-xs-${i + 1} {
          display: flex;
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}

        .container-grid-item-xs-hidden {
            display: none;
        }

        .container-grid-item-xs-contents {
            display: contents;
        }

        .container-grid-item-xs-min-content {
            flex: 0 1 min-content;
        }

        .container-grid-item-xs-full {
            flex: 1 0;
            width: 100%;
        }
    }

    @container (min-width: ${(props) => (props.$mdBreakpoint ? props.$mdBreakpoint : 450)}px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-md-${i + 1} {
          display: flex;
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}

        .container-grid-item-md-hidden {
            display: none;
        }

        .container-grid-item-md-contents {
            display: contents;
        }

        .container-grid-item-md-min-content {
            flex: 0 1 min-content;
        }

        .container-grid-item-md-full {
            flex: 1 0;
            width: 100%;
        }
    }

    @container (min-width: ${(props) => (props.$lgBreakpoint ? props.$lgBreakpoint : 900)}px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-lg-${i + 1} {
          display: flex;
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}

        .container-grid-item-lg-hidden {
            display: none;
        }

        .container-grid-item-lg-contents {
            display: contents;
        }

        .container-grid-item-lg-min-content {
            flex: 0 1 min-content;
        }

        .container-grid-item-lg-full {
            flex: 1 0;
            width: 100%;
        }
    }
`;
