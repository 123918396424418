import { offerSessionUtils, hostUrlHelper } from '@makemydeal/dr-shared-ui-utils';

import { configureStore as configureStoreProd } from './configureStore.prod';
import { configureStore as configureStoreDev } from './configureStore.dev';

/**
 * Get the dev or production configureStore for a given app. Defaults to dev if the URL is not for production or dark-prod
 *
 * Allows the use of a session-based query parameter, reduxStoreMode, to override the default by specifying dev or prod
 *
 * @param appPrefix The prefix of the app
 * @returns the prod or dev configureStore function
 */
const getStoreConfigurer = (appPrefix: string) => {
    const reduxStoreMode = offerSessionUtils.getSessionValue('reduxStoreMode');
    switch (reduxStoreMode) {
        case 'prod':
            return configureStoreProd;
        case 'dev':
            return configureStoreDev;
        default:
            break;
    }
    const isProd = hostUrlHelper.isProductionEnvironment(appPrefix, document.location.href);
    return isProd ? configureStoreProd : configureStoreDev;
};

export { configureStoreDev as configureStore, getStoreConfigurer };
