// externals
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { VehicleImage } from './VehicleImage';
import { VehicleDataInputs } from './VehicleDataInputs';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { changeVehicleCreators, changeVehicleSelectors } from '../../store';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { useValidateVehicle } from '../../utils';

// styles
import {
    VehicleListItemContainer,
    VehicleListImageContainer,
    VehicleListInfoContainer,
    VehicleListItemContent,
    VehicleListItemContentContainer
} from './VehicleListItem.interstate.style';

// types
import { CatalogVehicleWithIndex, VehicleCustomData } from '../../types';

export type VehicleListItemProps = {
    vehicle: CatalogVehicleWithIndex;
};

type GetNormalizedRetailPrice = {
    retailPrice: string;
    listedPrice: string;
    isNewVehicle: boolean;
};

const getNormalizedRetailPrice = ({ retailPrice, listedPrice, isNewVehicle }: GetNormalizedRetailPrice) => {
    if (isNewVehicle) {
        return retailPrice ? parseFloat(retailPrice) : 0;
    }

    const listedPriceNumber = listedPrice === '' ? 0 : parseFloat(listedPrice);
    return retailPrice === '' ? listedPriceNumber : parseFloat(retailPrice);
};

export const VehicleListItemInStock = ({ vehicle }: VehicleListItemProps) => {
    const theme = useInterstateTheme();
    const {
        year = '',
        make = '',
        model = '',
        trim = '',
        vin = '',
        stockNumber = '',
        listedPrice = '',
        mileage = '',
        condition = '',
        retailPrice = ''
    } = vehicle;
    const dispatch = useDispatch();

    const [customListedPrice, setCustomListedPrice] = useState(String(listedPrice));
    const [customMileage, setCustomMileage] = useState(String(mileage));
    const [customRetailPrice, setCustomRetailPrice] = useState(String(retailPrice));

    const vehicleWithCustomData = useSelector(changeVehicleSelectors.getVehicleWithCustomData(vehicle.vin!));
    const selectedVehicle = useSelector(changeVehicleSelectors.getSelectedVehicle);

    const isVehicleValid = useValidateVehicle(vehicle);
    const isSelected = useMemo(() => selectedVehicle.cardIndex === vehicle.cardIndex, [selectedVehicle, vehicle]);

    const shouldShowInputs = useMemo(() => isSelected && !isVehicleValid, [isSelected, isVehicleValid]);
    const vehicleListItemTitle = `${condition} ${year} ${make} ${model} ${trim}`;
    const isNewVehicle = condition === 'new';
    const priceType = useMemo(() => (isNewVehicle ? 'MSRP' : 'Selling Price'), [isNewVehicle]);
    const minOdometerValue = useMemo(() => (isNewVehicle ? 0 : 1), [isNewVehicle]);
    const formattedPrice = useMemo(
        () => formatDollarsAndCents(isNewVehicle ? vehicle.retailPrice : vehicleWithCustomData?.listedPrice, '--'),
        [isNewVehicle, vehicle, vehicleWithCustomData]
    );

    const selectVehicle = () => {
        if (!isSelected) {
            dispatch(changeVehicleCreators.newVehicleSelected(vehicle));
        }
    };

    const updateVehicleCustomData = (value: VehicleCustomData) => {
        dispatch(changeVehicleCreators.updateVehicleCustomData(vin, value));
    };

    const getToUpdateNormalizedDataFields = () => ({
        listedPrice: customListedPrice ? parseFloat(customListedPrice) : 0,
        retailPrice: getNormalizedRetailPrice({
            retailPrice: customRetailPrice,
            listedPrice: customListedPrice,
            isNewVehicle
        }),
        mileage: customMileage ? Number(customMileage) : 0
    });

    const handleCustomMileageChange = (value: string) => {
        setCustomMileage(value);
        updateVehicleCustomData({
            ...getToUpdateNormalizedDataFields(),
            mileage: value ? Number(value) : undefined
        });
    };

    const handleCustomSellingPrice = (value: string) => {
        setCustomListedPrice(value);
        updateVehicleCustomData({
            ...getToUpdateNormalizedDataFields(),
            listedPrice: value ? parseFloat(value) : 0
        });
    };

    const handleCustomRetailPrice = (value: string) => {
        setCustomRetailPrice(value);
        updateVehicleCustomData({
            ...getToUpdateNormalizedDataFields(),
            retailPrice: getNormalizedRetailPrice({
                retailPrice: value,
                listedPrice: customListedPrice,
                isNewVehicle
            })
        });
    };

    return (
        <VehicleListItemContainer
            primaryAction={selectVehicle}
            data-testid={`vehicle-list-item-${vin}`}
            className={isSelected ? 'vehicle-list-item-selected' : ''}
            padding="16px 12px"
            selected={isSelected}
            theme={theme}
            content={
                <VehicleListItemContentContainer>
                    <VehicleListItemContent>
                        <VehicleListImageContainer className={shouldShowInputs ? 'reduced-height' : ''}>
                            <VehicleImage vehicle={vehicle} />
                        </VehicleListImageContainer>
                        <VehicleListInfoContainer>
                            <Typography variant="h5" sx={{ textTransform: 'capitalize', marginBottom: '8px' }}>
                                {vehicleListItemTitle}
                            </Typography>
                            <div>
                                <Box>
                                    <Typography variant="body-md" color="sem.color.on-surface.muted" tag="span">
                                        VIN:{' '}
                                    </Typography>
                                    <Typography variant="body-md" tag="span">
                                        {vin}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body-md" color="sem.color.on-surface.muted" tag="span">
                                        Stock #:{' '}
                                    </Typography>
                                    <Typography variant="body-md" tag="span">
                                        {stockNumber}
                                    </Typography>
                                </Box>
                                {!shouldShowInputs && (
                                    <Box>
                                        <Typography variant="body-md" color="sem.color.on-surface.muted" tag="span">
                                            {priceType}:{' '}
                                        </Typography>
                                        <Typography variant="body-md" tag="span">
                                            {formattedPrice}
                                        </Typography>
                                    </Box>
                                )}
                            </div>
                        </VehicleListInfoContainer>
                    </VehicleListItemContent>
                    {shouldShowInputs && (
                        <VehicleDataInputs
                            isMsrpRequired={isNewVehicle}
                            odometer={customMileage}
                            sellingPrice={customListedPrice}
                            msrp={customRetailPrice}
                            onMsrpChange={handleCustomRetailPrice}
                            onOdometerChange={handleCustomMileageChange}
                            onSellingPriceChange={handleCustomSellingPrice}
                            minOdometerValue={minOdometerValue}
                            vin={vin}
                        />
                    )}
                </VehicleListItemContentContainer>
            }
        />
    );
};
