import styled from 'styled-components';
import { List } from '@interstate/components/List';
import { toRem } from '../../utils/pixelUtils';

interface StyledListProps {
    hasDelta?: boolean;
}

export const StyledListBordered = styled(List)<StyledListProps>`
    .ids-list-items-container > .ids-list-group-item > div {
        padding: 0;
    }
`;

export const StyledListNotBordered = styled(List)<StyledListProps>`
    .ids-list-items-container > .ids-list-group-item > div {
        border: none;
        padding: 0;
    }
`;

export const ColumnDetailsBlock = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 2 / -1;
    grid-column-start: 2;
    gap: ${toRem(8)};
    align-self: flex-start;
`;

export const OfferCompareCardBlock = styled.div<{ rowName: string; rowNameEnd?: string }>`
    grid-row-start: ${({ rowName }) => rowName};
    grid-row-end: ${({ rowNameEnd }) => rowNameEnd};
    display: grid;
    gap: ${toRem(8)};
    align-self: flex-start;
`;

export const OfferCompareColumnsHeaderWrapper = styled.div`
    grid-row-start: header;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: ${toRem(8)};
`;

export const OfferCompareColumnsFooterWrapper = styled.div`
    grid-row-start: make-current;
    grid-column: 1 / -1;
`;
