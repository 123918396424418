// externals
import { FC } from 'react';

// libraries
import { FeeCategorySelectInput } from './FeeCategorySelectInput';
import { FeesFormItemsContainer } from './FeesForm.style';
import { FinanceAmountCell } from './FinanceAmountCell';
import { FinanceCodeCell } from './FinanceCodeCell';
import { FinanceDeleteCell } from './FinanceDeleteCell';
import { FinanceNameCell } from './FinanceNameCell';
import { LeaseUpfrontCell } from './LeaseUpfrontCell';

// types
import type { Detail } from './types';

// utils
import { offerSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';
import { DELETED_BY_ACTION_FEE_AMOUNT } from './constants';
import { getFeeCodes, isLeaseFinanceDetail } from './utils';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { Box } from '@interstate/components/Box';
import { useBaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';

type FeeDetailRowProps = {
    detail: Detail;
    index: number;
    isLease?: boolean;
};

export const FeeDetailRow: FC<FeeDetailRowProps> = ({ detail, index, isLease }) => {
    const allItemizedFees = useSelector(offerSelectors.getCombinedItemizedFees);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const appliedFeeCodes = getFeeCodes(allItemizedFees);

    const BaseBreakPoint = useBaseDealBreakpoint();

    if (detail.amount === DELETED_BY_ACTION_FEE_AMOUNT && detail.isEdited === false) return null;

    const isNewFee = !appliedFeeCodes.includes(detail.code ?? -1);

    const feesCategory = isNewFee ? (
        <FeeCategorySelectInput feeCategory={detail.category} feeIndex={index} detail={detail} />
    ) : (
        <FinanceCodeCell detail={detail} index={index} />
    );

    if (!isBaseDealScreenExperience) {
        return (
            <FeesFormItemsContainer data-testid={`edit-fee-row-${detail.category}-${index}`} key={`edit-fee-row-${index}`}>
                <FinanceDeleteCell detail={detail} index={index} />
                {feesCategory}
                <FinanceNameCell detail={detail} index={index} feeCategory={detail.category} />
                <FinanceAmountCell detail={detail} index={index} />
                {isLeaseFinanceDetail(!!isLease, detail) && <LeaseUpfrontCell detail={detail} index={index} />}
            </FeesFormItemsContainer>
        );
    }

    return (
        <FeesFormItemsContainer
            isBaseDealScreenExperience={isBaseDealScreenExperience}
            data-testid={`edit-fee-row-${detail.category}-${index}`}
            key={`edit-fee-row-${index}`}
            BaseBreakPoint={BaseBreakPoint}
        >
            {feesCategory}
            <FinanceNameCell detail={detail} index={index} feeCategory={detail.category} />
            <FinanceAmountCell detail={detail} index={index} />
            <Box display="flex" data-testid="edit-fee-row-upfront-container" className="edit-fee---upfront-container">
                {isLeaseFinanceDetail(!!isLease, detail) && <LeaseUpfrontCell detail={detail} index={index} />}
                {<FinanceDeleteCell detail={detail} index={index} />}
            </Box>
        </FeesFormItemsContainer>
    );
};
