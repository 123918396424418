// externals
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

// components
import { baseDealActionCreators, baseDealSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import InterstateDealSummary from '../../dealSummary/DealSummary.interstate';
import { OfferType, OfferTypeEnum } from '@makemydeal/dr-platform-types';
import { SlideOut } from '@interstate/components/SlideOut';

// utils
import { useBaseDealBreakpoint } from '../../../utils/useBaseDealBreakpoint';

const BreakdownSlideout: FC = () => {
    const showBreakdownSlideout = useSelector(baseDealSelectors.getBreakdownSlideoutStatus);
    const currentOfferType: OfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dispatch = useDispatch();
    const BaseBreakPoint = useBaseDealBreakpoint();
    const isTabletSize = useMediaQuery({
        query: `(min-width: ${BaseBreakPoint.SM}) and (max-width: ${BaseBreakPoint.LG})`
    });
    const isLargeSize = useMediaQuery({ query: `(min-width: ${BaseBreakPoint.LG})` });

    const getPanelWidth = () => {
        if (isTabletSize) return '32.857rem';
        if (isLargeSize) return '67.429rem';
        return '100%';
    };

    const getDealTypeTitle = () => {
        switch (currentOfferType) {
            case OfferTypeEnum.CASH:
                return 'Cash';
            case OfferTypeEnum.LEASE:
                return 'Lease';
            case OfferTypeEnum.FINANCE:
                return 'Finance';
            default:
                return '';
        }
    };

    return (
        <SlideOut
            data-testid="breakdown-slide-out"
            id="breakdown-slide-out"
            show={showBreakdownSlideout}
            position="right"
            panelWidth={getPanelWidth()}
            onHide={() => {
                dispatch(baseDealActionCreators.setBreakdownSlideoutStatus(!showBreakdownSlideout));
            }}
            header={`${getDealTypeTitle()} Breakdown`}
        >
            <InterstateDealSummary showDetailedView={true} />
        </SlideOut>
    );
};

export default BreakdownSlideout;
