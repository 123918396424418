import React, { useMemo, useState } from 'react';
import { useCSSAnimationNameToggleHook } from '../types/AnimatedPanelTypes';

/**
 * This hook provides a simple toggle mechanism that provides (as output) style attributes that can be appended to any DOM component.
 * @param param0 Options for this hook. Namely toggleOnName and toggleOffName (optional)
 * @returns
 */
export const useCSSAnimationNameToggle: useCSSAnimationNameToggleHook = ({ toggleOnName, toggleOffName }) => {
    const [toggled, setToggled] = useState<boolean | undefined>();
    const styleTag = useMemo(() => {
        const newStyle: React.CSSProperties = {};
        if (toggled) newStyle.animationName = toggleOnName;
        else if (toggled === false && toggleOffName != null) newStyle.animationName = toggleOffName;

        return newStyle;
    }, [toggled, toggleOffName, toggleOnName]);

    return { style: styleTag, toggled, setToggled };
};
