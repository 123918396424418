// libraries
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hotkeys from 'hotkeys-js';

// components
import { BaseDealTabs } from '../BaseDealActivity.interstate.style';
import { RebatesIndex } from '../../rebates';
import { Tabs } from '@interstate/components/Tabs';
import { VehicleProtectionPreview } from '../../vehicleProtection/VehicleProtectionPreview';
import BaseDealLender from '../../baseDealLender/BaseDealLender';
import BaseDealSummary from './BaseDealSummary.interstate';
import FeesCard from '../../fees/FeesCard';
import InterstateAccessoriesCard from '../../accessories/AccessoriesCard.interstate';
import InterstateTradeFormCard from '../../tradeForm/TradeFormCard.interstate';
import TaxCard from '../../taxes/Taxes/TaxCard';

// selectors
import {
    baseDealActionCreators,
    baseDealAnalyticsActionCreators,
    baseDealSelectors,
    offerReduxSelectors
} from '@makemydeal/dr-dash-store';

// types
import { BaseTabs } from '../baseDealTypes';
import { CASH, OfferType } from '@makemydeal/dr-platform-types';

// utils
import { findButtonByRoleAndText } from '../../../utils/components';
import { useBaseDealBreakpoint } from '../../../utils/useBaseDealBreakpoint';
import { tabToCdl3ActionMap } from './utils';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

const keys = ['y', 't', 'f', 'x', 'p', 'i', 'l', 'a', 'right', 'left'];
const HOTKEYS = keys.map((key) => `alt+${key},CapsLock+alt+${key}`).join(',');

const getTabs = ({
    activeTab,
    offerType,
    isDealCentralExperience,
    isDealCentralExperienceTabs
}: {
    activeTab: BaseTabs;
    offerType: OfferType;
    isDealCentralExperience: boolean;
    isDealCentralExperienceTabs: boolean;
}) => {
    return [
        { component: <BaseDealSummary />, label: 'Summary', value: BaseTabs.SUMMARY },
        {
            component: (
                <>
                    <InterstateTradeFormCard isEditDisabled={false} />
                </>
            ),
            label: 'Trade In',
            value: BaseTabs.TRADE_IN
        },
        {
            component: (
                <>
                    <FeesCard />
                </>
            ),
            label: 'Fees',
            value: BaseTabs.FEES
        },
        {
            component: (
                <>
                    <TaxCard />
                </>
            ),
            label: 'Taxes',
            value: BaseTabs.TAXES
        },
        {
            component: (
                <>
                    <VehicleProtectionPreview />
                </>
            ),
            label: 'Protection',
            value: BaseTabs.PROTECTION
        },
        {
            component: (
                <>
                    <RebatesIndex />
                </>
            ),
            label: isDealCentralExperience ? 'Programs & Incentives' : 'Incentives',
            value: BaseTabs.INCENTIVES
        },
        ...(offerType === CASH
            ? []
            : [
                  {
                      component: (
                          <>
                              <BaseDealLender />
                          </>
                      ),
                      label: 'Lender',
                      value: BaseTabs.LENDER
                  }
              ]),
        {
            component: (
                <>
                    <InterstateAccessoriesCard />
                </>
            ),
            label: 'Accessories',
            value: BaseTabs.ACCESSORIES
        }
    ].map((item) => ({
        ...item,
        active: activeTab === item.value,
        component: isDealCentralExperienceTabs ? <div /> : item.component
    }));
};

const BaseDealTabContent = ({ isDealCentralExperienceTabs = false }: { isDealCentralExperienceTabs?: boolean }) => {
    const reduxActiveTab = useSelector(baseDealSelectors.getActiveTab) as BaseTabs;
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isDealCentralExperience = useSelector(dealerSelectors.isDealCentralExperience);

    const [activeTab, setActiveTab] = useState<BaseTabs>(reduxActiveTab);
    const [shouldFocus, setShouldFocus] = useState(false);

    const dispatch = useDispatch();

    const tabs = useMemo(
        () => getTabs({ activeTab, offerType, isDealCentralExperience, isDealCentralExperienceTabs }),
        [activeTab, offerType, isDealCentralExperience, isDealCentralExperienceTabs]
    );
    const BaseBreakPoint = useBaseDealBreakpoint();

    // Convert enum values to an array
    const tabOrder = tabs.map((tab) => tab.value);
    const tabOrderLength = tabOrder.length;

    const getNextTab = (currentTab: BaseTabs): BaseTabs => {
        const currentIndex = tabOrder.indexOf(currentTab);
        return tabOrder[(currentIndex + 1) % tabOrderLength];
    };

    const getPrevTab = (currentTab: BaseTabs): BaseTabs => {
        const currentIndex = tabOrder.indexOf(currentTab);
        return tabOrder[(currentIndex - 1 + tabOrderLength) % tabOrderLength];
    };

    const handleTabChange = (tab: BaseTabs, options?: { isClickEvent?: boolean; skipCdl3Call?: boolean }) => {
        dispatch(baseDealActionCreators.handleTabChange(tab));

        // dispatch the action for cdl3
        if (!options?.skipCdl3Call) {
            const tabAction = tabToCdl3ActionMap[tab];
            dispatch(options?.isClickEvent ? tabAction.click() : tabAction.hotkey());
        }
    };

    useEffect(() => {
        setActiveTab(reduxActiveTab);
    }, [reduxActiveTab]);

    useEffect(() => {
        if (offerType === CASH && activeTab === BaseTabs.LENDER) {
            handleTabChange(BaseTabs.SUMMARY, { skipCdl3Call: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerType, activeTab]);

    useEffect(() => {
        if (isDealCentralExperienceTabs || !isDealCentralExperience) {
            hotkeys(HOTKEYS, function (event, handler) {
                event.preventDefault();
                setShouldFocus(true);

                switch (handler.key) {
                    case 'alt+y':
                    case 'CapsLock+alt+y':
                        handleTabChange(BaseTabs.SUMMARY);
                        break;
                    case 'alt+t':
                    case 'CapsLock+alt+t':
                        handleTabChange(BaseTabs.TRADE_IN);
                        break;
                    case 'alt+f':
                    case 'CapsLock+alt+f':
                        handleTabChange(BaseTabs.FEES);
                        break;
                    case 'alt+x':
                    case 'CapsLock+alt+x':
                        handleTabChange(BaseTabs.TAXES);
                        break;
                    case 'alt+p':
                    case 'CapsLock+alt+p':
                        handleTabChange(BaseTabs.PROTECTION);
                        break;
                    case 'alt+i':
                    case 'CapsLock+alt+i':
                        handleTabChange(BaseTabs.INCENTIVES);
                        break;
                    case 'alt+l':
                    case 'CapsLock+alt+l':
                        handleTabChange(BaseTabs.LENDER);
                        break;
                    case 'alt+a':
                    case 'CapsLock+alt+a':
                        handleTabChange(BaseTabs.ACCESSORIES);
                        break;
                    case 'alt+right':
                    case 'CapsLock+alt+right':
                        handleTabChange(getNextTab(activeTab), { skipCdl3Call: true });
                        dispatch(baseDealAnalyticsActionCreators.clickedNextTabHotKey());
                        break;
                    case 'alt+left':
                    case 'CapsLock+alt+left':
                        handleTabChange(getPrevTab(activeTab), { skipCdl3Call: true });
                        dispatch(baseDealAnalyticsActionCreators.clickedPreviousTabHotKey());
                        break;
                }
            });

            return () => {
                hotkeys.unbind(HOTKEYS);
            };
        }
        return undefined;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useEffect(() => {
        // set focus to tabs whenever changed
        if (shouldFocus) {
            const element = findButtonByRoleAndText('tab', activeTab);
            if (element) {
                element.focus();
            }
        }
    }, [activeTab, shouldFocus]);

    useEffect(() => {
        const handleTabKeys = (e: KeyboardEvent) => {
            if (e.key === 'Tab' && !e.shiftKey) {
                dispatch(baseDealAnalyticsActionCreators.nextEventHotkey());
            } else if (e.key === 'Tab' && e.shiftKey) {
                dispatch(baseDealAnalyticsActionCreators.previousEventHotkey());
            }
        };
        window.addEventListener('keydown', handleTabKeys);

        return () => {
            window.removeEventListener('keydown', handleTabKeys);
        };
    }, []);

    useEffect(() => {
        dispatch(tabToCdl3ActionMap[reduxActiveTab].view());
    }, [reduxActiveTab]);

    return (
        <BaseDealTabs
            BaseBreakPoint={BaseBreakPoint}
            isDealCentralExperienceTabs={isDealCentralExperienceTabs}
            isDealCentralExperience={isDealCentralExperience}
        >
            <Tabs
                data-testid="base-deal-tabs"
                id="base-deal-tabs"
                onActivate={(val) => {
                    handleTabChange(val, { isClickEvent: true });
                }}
                tabs={tabs}
            />
        </BaseDealTabs>
    );
};

export default BaseDealTabContent;
