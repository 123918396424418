// externals
import type { Reducer } from 'redux';
import { LOCATION_CHANGE } from 'connected-react-router';

// libraries
import type { DealHistory, DealHistoryState } from '@makemydeal/dr-dash-types';

// actions
import {
    DealHistoryActions,
    DealHistoryCheckedAction,
    DealHistorySelectedUpdate,
    DealHistoryUnCheckedAction,
    DEAL_HISTORY_CHECKED,
    DEAL_HISTORY_UNCHECKED,
    FetchDealHistorySuccessAction,
    ScenariosFetchSuccessAction
} from '../actionTypes/dealHistoryActionTypes';

// constants
import {
    FETCH_DEAL_HISTORY_FAILURE,
    FETCH_DEAL_HISTORY_REQUEST,
    FETCH_DEAL_HISTORY_SUCCESS,
    DEAL_HISTORY_SELECTED_UPDATE,
    SET_DEAL_HISTORY_SCENARIO_DRAWER_DEAL
} from '../actionTypes/dealHistoryActionTypes';
import { NavigationActions } from '@makemydeal/dr-activities-common';
import { BootstrapLoadingState } from '@makemydeal/dr-shared-types';

// types
import type { OfferResponse } from '@makemydeal/dr-dash-bff-types';
import { SCENARIOS_FETCH_SUCCESS } from '../actionTypes/multiScenarioActionTypes';

// utils
import { mapOfferResponseToOffer } from '@makemydeal/dr-offer-redux';
import { AccessoriesAdapter } from '../utils/manualAccessoriesAdapter';

export const dealHistoryInitialState: DealHistoryState = {
    offers: [],
    selectedDeals: []
};

export type DealHistoryReducer = Reducer<DealHistoryState>;

export const reducer: DealHistoryReducer = (state: DealHistoryState = dealHistoryInitialState, action: DealHistoryActions) => {
    switch (action.type) {
        case FETCH_DEAL_HISTORY_FAILURE: {
            const newState: DealHistoryState = {
                ...state,
                isLoading: false
            };
            return newState;
        }
        case FETCH_DEAL_HISTORY_REQUEST: {
            const newState: DealHistoryState = {
                ...state,
                isLoading: true
            };
            return newState;
        }
        case FETCH_DEAL_HISTORY_SUCCESS: {
            const actionTyped = action as FetchDealHistorySuccessAction;
            const payload = actionTyped.payload || [];

            const offers: DealHistory[] = payload.map((offerResponse: OfferResponse) => {
                const {
                    vehicle,
                    dealer,
                    dealXgDetails,
                    trade,
                    testDrive,
                    featureToggles,
                    dealVehicleProtection,
                    accessories: dealAccessories
                } = offerResponse;
                const accessories = AccessoriesAdapter.accessoriesToSourceFormat(dealAccessories || []);
                const { products, surchargesOverrides: surcharges } = dealVehicleProtection || {};
                const offer = mapOfferResponseToOffer(offerResponse);
                offer.messageToDealer = offerResponse.offer.messageToDealer;

                return {
                    accessories,
                    dealXgDetails,
                    sharedBootstrap: { loadingState: BootstrapLoadingState.LoadedSuccessfully },
                    offer,
                    vehicleProtectionV2: { products, surcharges },
                    vehicle,
                    dealer,
                    tradeIn: trade?.trade || {},
                    testDrive,
                    featureToggles
                } as unknown as DealHistory;
            });

            const newState: DealHistoryState = {
                ...state,
                isLoading: false,
                offers
            };
            return newState;
        }
        case DEAL_HISTORY_CHECKED: {
            const dealCheckedAction = action as DealHistoryCheckedAction;

            return dealCheckedAction.payload
                ? {
                      ...state,
                      selectedDeals: [...(state.selectedDeals as unknown as DealHistory[]), dealCheckedAction.payload]
                  }
                : state;
        }
        case DEAL_HISTORY_UNCHECKED: {
            const offerIdToBeRemoved = (action as DealHistoryUnCheckedAction).payload;

            return {
                ...state,
                selectedDeals: [
                    ...(state.selectedDeals as DealHistory[]).filter(
                        (selectedDeal) => selectedDeal.offer.offerId !== offerIdToBeRemoved
                    )
                ]
            };
        }

        case LOCATION_CHANGE: {
            return action.payload?.location?.pathname === '/dealHistory'
                ? {
                      ...state,
                      selectedDeals: []
                  }
                : state;
        }

        case DEAL_HISTORY_SELECTED_UPDATE: {
            const offerIdToBeSelected = (action as DealHistorySelectedUpdate).payload;
            const selectedDeals = (state.selectedDeals as DealHistory[]).map((selectedDeal) => {
                selectedDeal.isSelected = selectedDeal.offer.offerId === offerIdToBeSelected;
                return selectedDeal;
            });

            return {
                ...state,
                selectedDeals
            };
        }

        case NavigationActions.NAVIGATE_TO: {
            return state.selectedDeals?.length
                ? {
                      ...state,
                      selectedDeals: []
                  }
                : state;
        }

        case SCENARIOS_FETCH_SUCCESS: {
            const actionTyped = action as ScenariosFetchSuccessAction;
            const payload = actionTyped.payload;
            const dealXgVersion = actionTyped.meta?.dealXgVersion;

            if (!payload || !payload.scenarios || !dealXgVersion) {
                return state;
            }

            const offers: DealHistory[] = payload.scenarios.map((offerResponse: OfferResponse) => {
                const {
                    vehicle,
                    dealer,
                    dealXgDetails,
                    trade,
                    testDrive,
                    featureToggles,
                    dealVehicleProtection,
                    accessories: dealAccessories
                } = offerResponse;
                const accessories = AccessoriesAdapter.accessoriesToSourceFormat(dealAccessories || []);
                const { products, surchargesOverrides: surcharges } = dealVehicleProtection || {};
                const offer = mapOfferResponseToOffer(offerResponse);
                offer.messageToDealer = offerResponse.offer.messageToDealer;

                return {
                    accessories,
                    dealXgDetails,
                    sharedBootstrap: { loadingState: BootstrapLoadingState.LoadedSuccessfully },
                    offer,
                    vehicleProtectionV2: { products, surcharges },
                    vehicle,
                    dealer,
                    tradeIn: trade?.trade || {},
                    testDrive,
                    featureToggles
                } as unknown as DealHistory;
            });

            const scenarios = { ...state.scenarios, [dealXgVersion]: offers };

            const newState: DealHistoryState = {
                ...state,
                scenarios
            };
            return newState;
        }

        case SET_DEAL_HISTORY_SCENARIO_DRAWER_DEAL: {
            return { ...state, dealHistoryScenarioDrawerDeal: action.payload };
        }

        default: {
            return state;
        }
    }
};
