// externals
import React, { ComponentClass, FC, FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl, getToken, TokenParams, PopupParams, OktaAuthOptions } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';

// libraries
import { hostUrlHelper, locHrefUtil, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { apiConfigConsts } from '@makemydeal/dr-shared-ui-utils';
import { setOktaAuthForInterceptors } from '@makemydeal/dr-shared-network';

// utils
import { AuthConfigCallback, isProductionEnvironment } from './oktaConfig';

// constants
import { OKTA_OAUTH_CALLBACK } from '../constants';

// components
import { Logout } from './Logout';

export const ADDITIONAL_QUERY_PARAMS_WHITE_LIST = [
    apiConfigConsts.BFF_ENV,
    // TODO: SV - add this back later
    // apiConfigConsts.LOAD_AURYC,
    apiConfigConsts.ENABLE_TOGGLES,
    apiConfigConsts.ENABLE_DEALER_TOGGLES,
    apiConfigConsts.DISABLE_DEALER_TOGGLES,
    apiConfigConsts.DISABLE_TOGGLES,
    apiConfigConsts.DASH_CSS,
    apiConfigConsts.DASH_DEBUG,
    apiConfigConsts.TARGET,
    apiConfigConsts.DEAL_TEMPLATES_VIEW,
    apiConfigConsts.REDUX_STORE_MODE,
    apiConfigConsts.SHOW_DEAL_MESSAGES
];

export type OfferReadyContentProps = PropsWithChildren<{
    identified: boolean;
    renewTokens?: () => Promise<boolean>;
}>;

export type AuthManagerProps = PropsWithChildren<{
    appPrefix: string;
    getConfig: AuthConfigCallback;
    offerReadyContent: FunctionComponent<OfferReadyContentProps> | ComponentClass<OfferReadyContentProps> | string;
    byPassOkta?: boolean;
    onSignOut?: () => void;
}>;

/**
 * Manages initialization of Okta and the PCKE oauth workflow before loading the rest of the application
 */
export const AuthManager: FC<AuthManagerProps> = ({
    appPrefix,
    children,
    getConfig,
    offerReadyContent: OfferReadyContent,
    byPassOkta,
    onSignOut
}) => {
    const oktaDisabled = useMemo(() => process.env.BYPASS_OKTA === 'true' || byPassOkta, [byPassOkta]);
    const [offerIdentified, setOfferIdentified] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const environment = hostUrlHelper.getHostUrlEnvironment(appPrefix, locHrefUtil.getLocHref());
    // Setup the OktaAuth engine with config
    const config = { ...getConfig(isProductionEnvironment(environment)) };
    const { oktaAuth, renewTokens } = useMemo(() => {
        if (oktaDisabled) {
            return {
                oktaAuth: undefined,
                renewTokens: undefined
            };
        }
        const authOptions: OktaAuthOptions = {
            issuer: config.issuer,
            clientId: config.clientId,
            redirectUri: `${window.location.origin}${OKTA_OAUTH_CALLBACK}`,
            pkce: true,
            scopes: config.scopes,
            tokenManager: {
                expireEarlySeconds: 60,
                // setting autoRenew AND autoremove to false allows us to silently renew tokens when they expire instead of a page refresh
                autoRenew: false,
                autoRemove: false
            },
            storageManager: {
                token: {
                    storageKey: `okta_token_${config.id}`,
                    storageTypes: ['localStorage', 'sessionStorage', 'cookie']
                },
                cache: {
                    storageKey: `okta_cache_${config.id}`,
                    storageTypes: ['localStorage', 'sessionStorage', 'cookie']
                },
                transaction: {
                    storageKey: `okta_transaction_${config.id}`,
                    storageTypes: ['sessionStorage', 'localStorage', 'cookie']
                },
                'shared-transaction': {
                    storageKey: `okta_shared_transaction_${config.id}`,
                    storageTypes: ['localStorage']
                },
                'original-uri': {
                    storageKey: `okta_original_uri_${config.id}`,
                    storageTypes: ['localStorage']
                }
            }
        };
        // istanbul ignore next
        const auth = new OktaAuth(authOptions);
        setOktaAuthForInterceptors(auth);
        // istanbul ignore next
        async function renewTokens(): Promise<boolean> {
            const options: TokenParams & PopupParams = {
                responseMode: 'okta_post_message',
                scopes: auth.options.scopes,
                timeout: 10000,
                display: null as any // required for renew.
            };

            try {
                const response = await getToken(auth, options);

                auth.tokenManager.setTokens(response.tokens);
                await auth.authStateManager.updateAuthState();
                return true;
            } catch (err: any) {
                await auth.signInWithRedirect();
                return false;
            }
        }
        // istanbul ignore next
        auth.tokenManager.on('expired', renewTokens);

        // istanbul ignore next
        return { oktaAuth: auth, renewTokens };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // istanbul ignore next
    const restoreOriginalUri = async (_okta: OktaAuth, originalUri: string) => {
        // istanbul ignore next
        const originalUriResolved = originalUri || '/';
        const originalUrl = `${window.location.origin}${originalUriResolved}`;
        offerSessionUtils.parseAndWriteAdditionalToggles(window.location.href, { whitelist: ADDITIONAL_QUERY_PARAMS_WHITE_LIST });
        offerSessionUtils.parseAndWriteOfferIdentifiers(originalUrl);
        setOfferIdentified(true);
        history.replace(toRelativeUrl(originalUriResolved, window.location.origin));
    };

    useEffect((): void => {
        offerSessionUtils.parseAndWriteOfferIdentifiers(window.location.href); // full href
        offerSessionUtils.parseAndWriteAdditionalToggles(window.location.href, { whitelist: ADDITIONAL_QUERY_PARAMS_WHITE_LIST });

        setOfferIdentified(true);
    }, [location]);

    // istanbul ignore next
    const onOktaSignOut = useCallback(() => {
        onSignOut?.();
        oktaAuth?.signOut();
    }, [oktaAuth, onSignOut]);

    // if Okta instance isn't available, just return a blank screen when the app is not ready; otherwise, show children without secure routes
    if (oktaAuth == null) {
        return <OfferReadyContent identified={offerIdentified}>{children}</OfferReadyContent>;
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
                <Route path={OKTA_OAUTH_CALLBACK} exact component={LoginCallback} />
                <Route path="/logout" exact>
                    <Logout handleLogout={onOktaSignOut} />
                </Route>
                <SecureRoute path="*">
                    <OfferReadyContent identified={offerIdentified} renewTokens={renewTokens}>
                        {children}
                    </OfferReadyContent>
                </SecureRoute>
            </Switch>
        </Security>
    );
};
