// libraries
import { extractNumberFromInputValue } from '@makemydeal/dr-shared-ui-utils';
import { IVehicle } from '@makemydeal/dr-platform-types';

// utils
import { vehicleInfoFormKeys } from './formContext';

// interfaces/types
import { FMForm, FMGenerator, FMField, FMTouchedInputs } from '../types';

type O = { [key: string]: any };
type isObjectEqualType = (A: O, B?: O) => boolean;

export const isObjectEqual: isObjectEqualType = (A, B = {}) => {
    for (const key of Object.keys(A)) {
        if (A[key] !== B[key]) {
            return false;
        }
    }
    return true;
};

type ValidatedForm = {
    retailPrice?: number;
    sellingPrice?: number;
    vehicle: IVehicle & FMGenerator<number | string>;
};

export const validateForm = (form: FMForm, touched: FMTouchedInputs) => {
    const modified: ValidatedForm = { vehicle: {} };
    let touchedCount = 0;
    vehicleInfoFormKeys.forEach((key) => {
        if (touched[key]) {
            touchedCount++;
            if (key === FMField.msrp) {
                modified.retailPrice = extractNumberFromInputValue(form[key]);
            } else if (key === FMField.sellingPrice) {
                modified.sellingPrice = extractNumberFromInputValue(form[key]);
            } else if (key === FMField.wholesaleBookSource) {
                modified.vehicle.wholesaleBookSource = form[key] as string;
            } else if (key === FMField.stockNumber) {
                modified.vehicle.stockNumber = form[key] as string;
            } else {
                modified.vehicle[key] = form[key] ? extractNumberFromInputValue(form[key]) : undefined;
            }
        }
    });
    const anyPaymentFieldChanged =
        Object.keys(modified).some((key) => ['retailPrice', 'sellingPrice'].includes(key)) ||
        Object.keys(modified.vehicle).some((key) => ['invoiceAmount', 'mileage', 'cost'].includes(key));

    return { payload: modified, withPayment: anyPaymentFieldChanged };
};

export const odometerValidator = (value: string | number) => {
    const parsed = Math.round(Math.abs(extractNumberFromInputValue(value)));
    return String(parsed > 999_999 ? 999_999 : parsed < 1 ? 1 : parsed);
};

export const bookOutValueValidator = (value: string | number) => {
    if (value === '') return '';
    const parsed = Math.abs(extractNumberFromInputValue(value));
    return String(parsed > 9_999_999 ? 9_999_999 : parsed < 1 ? 0 : parsed);
};

export const pricingInputList: { name: keyof FMForm; label: string }[] = [
    { name: 'msrp', label: 'MSRP/Retail Price' },
    { name: 'sellingPrice', label: 'Selling Price' },
    { name: 'invoiceAmount', label: 'Invoice' },
    { name: 'cost', label: 'Cost' }
];
