// externals
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';

// styles
import { StyledVehicleInsuranceSlideOut } from './VehicleInsuranceSlideOut.style';

// library
import { baseDealActionCreators, baseDealSelectors } from '@makemydeal/dr-dash-store';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import InsuranceForm from './InsuranceForm';

const VehicleInsuranceSlideOut: FC = () => {
    const showVehicleInsurance = useSelector(baseDealSelectors.getVehicleInsuranceStatus);
    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const isTabletSize = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenLg} ) and (max-width: 1167px)` });
    const isLargeSize = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenXl})` });

    const getPanelWidth = () => {
        if (isLargeSize) return '572px';
        if (isTabletSize) return '504px';
        return '480px';
    };

    return (
        <StyledVehicleInsuranceSlideOut
            data-testid="vehicle-insurance-slide-out"
            id="vehicle-insurance-slide-out"
            show={showVehicleInsurance}
            position="right"
            panelWidth={getPanelWidth()}
            onHide={() => {
                dispatch(baseDealActionCreators.setVehicleInsuranceStatus(!showVehicleInsurance));
            }}
            header="Edit Insurance"
        >
            <InsuranceForm />
        </StyledVehicleInsuranceSlideOut>
    );
};

export default VehicleInsuranceSlideOut;
