import { RSAA } from 'redux-api-middleware';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { MANAGER_VIEW_APP_PREFIX, apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import {
    CONTRACTING_CLOSE_MODAL,
    CONTRACTING_EVENT_RECEIVED,
    CONTRACTING_IN_PROGRESS,
    CONTRACTING_OPEN_MODAL,
    CONTRACTING_STAGE_CANCEL_FAILURE,
    CONTRACTING_STAGE_CANCEL_REQUEST,
    CONTRACTING_STAGE_CANCEL_SUCCESS,
    CONTRACTING_STAGE_START_FAILURE,
    CONTRACTING_STAGE_START_REQUEST,
    CONTRACTING_STAGE_START_SUCCESS,
    CONTRACTING_STAGE_VERIFY_FAILURE,
    CONTRACTING_STAGE_VERIFY_REQUEST,
    CONTRACTING_STAGE_VERIFY_SUCCESS
} from '../actionTypes/contractingActionTypes';
import { CASH } from '@makemydeal/dr-platform-types';
import { getContractingCorrelationId } from '../selectors/contractingSelectors';
import { getPartnerCode, getPartnerDealerId } from '../selectors/calculationDetailsSelectors';
import { getDealXgVersion } from '../selectors/offerInfoSelectors';
import { getCurrentOfferType, getCreditDecisionLender, getDealXgId } from '../selectors/offerRedux';
import { getDealRefId } from '../selectors/offerSelectors';

const CASH_LENDER_ID = 'DTH';

export const contractingEventReceived = (event: any): AnyFSA => {
    return {
        type: CONTRACTING_EVENT_RECEIVED,
        payload: event.data
    };
};

export const contractingOpenModal = (): AnyFSA => {
    return {
        type: CONTRACTING_OPEN_MODAL
    };
};

export const contractingCloseModal = (success?: boolean): AnyFSA => {
    const action: AnyFSA = {
        type: CONTRACTING_CLOSE_MODAL
    };
    if (success != null) {
        action.meta = { success };
    }
    return action;
};

export const contractingStartProgress = (): AnyFSA => {
    return {
        type: CONTRACTING_IN_PROGRESS
    };
};

export type ContractingCommonOptions = {
    sourcePartnerId: string;
    dealRefId: string;
    dealerId: number;
    /** targetPlatforms.partyId */
    partyId: string;
    /** RSAA action types */
    types: [request: string, success: string, failure: string];
    correlationId: string;
};

export type ContractingCancelOptions = ContractingCommonOptions;

export type ContractingStartOptions = ContractingCommonOptions & {
    dealXgDealId: string;
    dealXgDealVersion: string;
    /**
     * offerType
     */
    financeMethod: string;
    lenderId?: string;
};

export type ContractingVerifyOptions = ContractingCommonOptions & {
    lenderId?: string;
};

const CONTRACTING_API_CANCEL = 'cancel';
const CONTRACTING_API_START = 'start';
const CONTRACTING_API_VERIFY = 'verify';

const TARGET_PLATFORM_ID = 'DTC';
const DEFAULT_PARTNER_CODE = 'MMD';

export const contractingSendCancel = () => (dispatch: any, getState: any) => {
    const state = getState();
    const sourcePartnerId = getPartnerCode(state) || DEFAULT_PARTNER_CODE;
    const dealRefId = getDealRefId(state);
    const dealerId = dealerSelectors.getDealerId(state);
    const partyId = String(getPartnerDealerId(state) || dealerSelectors.getDealerId(state));
    const correlationId = getContractingCorrelationId(state);

    const locHref = locHrefUtil.getLocHref();
    const { url: endpoint } = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '/contracting');

    const body = {
        operation: CONTRACTING_API_CANCEL,
        sourcePartnerId,
        id: TARGET_PLATFORM_ID,
        partyId,
        dealerId,
        dealRefId
    };

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-coxauto-correlation-id': correlationId
            },
            body: JSON.stringify(body),
            types: [CONTRACTING_STAGE_CANCEL_REQUEST, CONTRACTING_STAGE_CANCEL_SUCCESS, CONTRACTING_STAGE_CANCEL_FAILURE]
        }
    });
};

export const contractingSendStart = () => (dispatch: any, getState: any) => {
    const state = getState();
    const financeMethod = getCurrentOfferType(state);
    const dealXgDealId = getDealXgId(state);
    const dealXgDealVersion = getDealXgVersion(state);
    const dealerId = dealerSelectors.getDealerId(state);
    const offerType = getCurrentOfferType(state);
    const lenderId = offerType === CASH ? CASH_LENDER_ID : getCreditDecisionLender(state)?.id;
    const dealRefId = getDealRefId(state);
    const correlationId = getContractingCorrelationId(state);
    const partyId = String(getPartnerDealerId(state) || dealerSelectors.getDealerId(state));
    const sourcePartnerId = getPartnerCode(state) || DEFAULT_PARTNER_CODE;

    const locHref = locHrefUtil.getLocHref();
    const { url: endpoint } = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '/contracting');

    const body = {
        operation: CONTRACTING_API_START,
        sourcePartnerId,
        id: TARGET_PLATFORM_ID,
        partyId,
        dealerId,
        dealRefId,
        dealXgDealId,
        dealXgDealVersion,
        financeMethod,
        lenderId
    };

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-coxauto-correlation-id': correlationId
            },
            body: JSON.stringify(body),
            types: [CONTRACTING_STAGE_START_REQUEST, CONTRACTING_STAGE_START_SUCCESS, CONTRACTING_STAGE_START_FAILURE]
        }
    });
};

export const contractingSendVerify = () => (dispatch: any, getState: any) => {
    const state = getState();
    const offerType = getCurrentOfferType(state);
    const dealerId = dealerSelectors.getDealerId(state);
    const lenderId = offerType === CASH ? CASH_LENDER_ID : getCreditDecisionLender(state)?.id;
    const partyId = String(getPartnerDealerId(state) || dealerSelectors.getDealerId(state));
    const sourcePartnerId = getPartnerCode(state) || DEFAULT_PARTNER_CODE;
    const correlationId = getContractingCorrelationId(state);
    const dealRefId = getDealRefId(state);

    const locHref = locHrefUtil.getLocHref();
    const { url: endpoint } = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '/contracting');

    const body = {
        operation: CONTRACTING_API_VERIFY,
        sourcePartnerId,
        id: TARGET_PLATFORM_ID,
        partyId,
        dealerId,
        dealRefId,
        lenderId
    };

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-coxauto-correlation-id': correlationId
            },
            body: JSON.stringify(body),
            types: [CONTRACTING_STAGE_VERIFY_REQUEST, CONTRACTING_STAGE_VERIFY_SUCCESS, CONTRACTING_STAGE_VERIFY_FAILURE]
        }
    });
};
