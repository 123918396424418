//externals
import styled from 'styled-components';

// interstate components
import { Grid } from '@interstate/components/Grid';

// utils
import { toRem } from '../utils/pixelUtils';

export const StyledColumnsContainer = styled(Grid)`
    &&& div.card-media-header-content-container {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const RollToOptionsTabbedContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledRollToOptionsBannerRegion = styled.div`
    background-color: #f4f4f4;
    padding: ${toRem(8)} ${toRem(16)};
    border-radius: ${toRem(4)};
    height: ${toRem(146)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & span#current-payment-label,
    span#target-payment-input-label {
        font: ${toRem(16)};
        color: #6d6d6d;
        font-weight: 400;
        margin-right: ${toRem(8)};
        text-align: left;
    }

    & span#current-payment-value {
        font: ${toRem(18)};
        color: #000;
        margin-left: ${toRem(8)};
        font-weight: 400;
        line-height: ${toRem(20)};
        text-align: left;
    }

    & svg.interstate-information-circle-icon {
        margin-top: ${toRem(4)};
        margin-right: ${toRem(8)};
    }

    & span#current-payment-value {
        align-text: left;
        text-align: ${toRem(8)};
    }
    & button#btn-target-payment-clear-input {
        border: none;
    }
`;

export const StyledRollToOptionsTextInput = styled.span`
    width: auto;
    max-width: ${toRem(144)};
    border-radius: ${toRem(4)};
`;
export const StyledRollToOptionsClearLink = styled.span`
    font-size: ${toRem(14)};
    padding: ${toRem(8)};
`;
