// external
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// libraries
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { Action } from '@interstate/components/Action';
import { CardContent } from '@interstate/components/Card';
import { BaseDealBreakpoint } from '../../../utils/useBaseDealBreakpoint';

export const VppPreviewSummaryContainer = styled.div<{ isBaseDealScreenExperience?: boolean; BaseBreakPoint: BaseDealBreakpoint }>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
    @media (min-width: ${BreakPoint.SMALL}) {
        margin-right: 16px;
    }

    /* override styles when on base deal screen */
    ${({ isBaseDealScreenExperience, BaseBreakPoint }) =>
        isBaseDealScreenExperience &&
        css`
            flex-direction: column-reverse;

            @media (max-width: ${BaseBreakPoint.SMALL}) {
                width: 100%;
                border-top: ${rem(2.5)} solid #b2b2b2;
                padding-top: ${rem(16)};
                margin-top: 0px;
                gap: 5px;

                > div {
                    width: 100%;
                }
            }
        `}
`;

export const VppPreviewSummaryRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
`;

export const ActionsContainer = styled.div<{ isBaseDealScreenExperience?: boolean }>`
    display: flex;
    gap: ${rem(12)};

    /* override styles when on base deal screen */
    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            @media (max-width: ${BreakPoint.SMALL}) {
                width: fit-content;
                margin-bottom: 10px;
                margin-top: 5px;
            }
        `}
`;

export const StyledAction = styled(Action)`
    margin-right: ${rem(12)};
`;

export const StyledVppCardContent = styled(CardContent)<{ isBaseDealScreenExperience?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* override styles when on base deal screen */
    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            #ids-table-vpp-preview-table {
                margin-top: ${rem(10)};
            }

            #ids-table-vpp-preview-table > div.ant-table-wrapper {
                border-bottom: ${rem(2.5)} solid #b2b2b2 !important;
            }

            #ids-table-vpp-preview-table > div.MuiBox-root {
                display: none;
            }

            #ids-table-vpp-preview-table th.ant-table-cell {
                border-bottom-width: ${rem(1.5)};
            }
        `}
`;

export const ProductColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ProductTitle = styled.p`
    font-size: ${rem(16)};
    font-weight: 400;
    color: #6d6d6d;
    margin: 0px 0px ${rem(2.5)};
`;

export const ProductValue = styled.p`
    font-size: ${rem(18)};
    font-weight: 400;
    color: #000;
`;

export const VehiclePreviewCardGrid = styled.div`
    display: flex;
    flex-direction: column;
`;

export const VehiclePreviewCardRow = styled.div`
    border-top: ${rem(1.5)} solid #b2b2b2;
    padding: 8px 16px;
`;
