import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ShowWithFeatureToggle } from '@makemydeal/dr-dash-components';
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import ItemizedTaxesSummary from './ItemizedTaxesSummary';
import TaxOverride from './TaxOverride';
import { excludeUnamedTaxes } from './helpers';
import TaxesBottomBar from './TaxesBottomBar';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { TaxDetailsBaseContainer, TaxDetailsContainer, TaxOverrideContainer } from './TaxCard.style';

type TaxDetailsProps = {
    taxOverrideEnabled: boolean;
    setTaxOverrideEnabled: (taxOverride: boolean) => void;
};

const TaxDetails = (props: TaxDetailsProps) => {
    const { taxOverrideEnabled, setTaxOverrideEnabled } = props;

    const convertDecimalTaxRateToPercentInMV = useSelector(featureToggleSelectors.convertDecimalTaxRateToPercentInMV);

    const taxItems = useSelector((state) => {
        if (convertDecimalTaxRateToPercentInMV) {
            return offerReduxSelectors.getTaxItemsWithAdjustedRates(state) as paymentServicesTypes.TaxItems;
        } else {
            return offerReduxSelectors.getTaxItemsWithOverridesWithoutFlat(state) as paymentServicesTypes.TaxItems;
        }
    });

    const allItemizedTaxesWithNames = useMemo(() => excludeUnamedTaxes(taxItems), [taxItems]);

    const [taxOverrideFormEdited, setTaxOverrideFormEdited] = useState(taxOverrideEnabled);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const TaxContainer = isBaseDealScreenExperience ? TaxDetailsBaseContainer : TaxDetailsContainer;

    return (
        <TaxContainer sx={{ height: '100%' }}>
            <ItemizedTaxesSummary data={allItemizedTaxesWithNames} taxOverrideEnabled={taxOverrideEnabled} />
            <TaxOverrideContainer>
                <TaxOverride
                    taxOverrideEnabled={taxOverrideEnabled}
                    taxOverrideFormEdited={taxOverrideFormEdited}
                    setTaxOverrideEnabled={setTaxOverrideEnabled}
                    setTaxOverrideFormEdited={setTaxOverrideFormEdited}
                />
            </TaxOverrideContainer>
            <TaxesBottomBar setTaxOverrideFormEdited={setTaxOverrideFormEdited} />
        </TaxContainer>
    );
};

export default TaxDetails;
