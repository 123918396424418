// externals
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// libraries
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { Grid } from '@interstate/components/Grid';

// components
import { BaseDealContainerRoot, BaseDealContent, BaseDealVehicleInfo } from '../BaseDealActivity.interstate.style';
import { VehicleInfoCard } from '../../vehicleInfo/VehicleInfoCard';
import BaseDealTabContent from './BaseDealTabContent';
import InterstateDealSummary from '../../dealSummary/DealSummary.interstate';

// selectors
import { baseDealSelectors } from '@makemydeal/dr-dash-store';

const BaseDealContainer: React.FC = () => {
    const isBreakDownOpen = useSelector(baseDealSelectors.getBreakdownStatus);
    const isMobileScreenSize = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });

    useEffect(() => {
        if (isBreakDownOpen) {
            document.getElementById('expandable-root')?.focus();
        }
    }, [isBreakDownOpen]);

    if (isMobileScreenSize && isBreakDownOpen) {
        return (
            <BaseDealContainerRoot>
                <InterstateDealSummary />
            </BaseDealContainerRoot>
        );
    }

    return (
        <BaseDealContainerRoot>
            <BaseDealContent>
                <BaseDealVehicleInfo>
                    <VehicleInfoCard />
                </BaseDealVehicleInfo>

                <BaseDealTabContent />
            </BaseDealContent>
            {isBreakDownOpen ? (
                <Grid
                    xs={5}
                    sx={{
                        minWidth: '360px',
                        maxWidth: '500px',
                        flex: 1,
                        padding: 0,
                        marginLeft: '10px',
                        backgroundColor: '#f4f4f4',
                        borderRadius: '5px'
                    }}
                >
                    <InterstateDealSummary />
                </Grid>
            ) : null}
        </BaseDealContainerRoot>
    );
};

export default BaseDealContainer;
