// externals
import { useSelector } from 'react-redux';

// libraries
import { Typography } from '@interstate/components/Typography';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// constants
import * as constants from '../../constants';
import { CompareColumnDetailProps } from '../types/compareColumnDetailProps';

// components
import AddsToCapCost from '../../dealSummary/sections/AddsToCapCost.interstate';
import AdjCapCost from '../../dealSummary/sections/AdjCapCost.interstate';
import CashDown from '../../dealSummary/sections/CashDown.interstate';
import Discount from '../../dealSummary/sections/Discount.interstate';
import GrossCapCost from '../../dealSummary/sections/GrossCapCost.interstate';
import MonthlyPayment from '../../dealSummary/sections/MonthlyPayment.interstate';
import MSRP from '../../dealSummary/sections/MSRP.interstate';
import NetTrade from '../../dealSummary/sections/NetTrade.interstate';
import Rebates from '../../dealSummary/sections/Rebates.interstate';
import SellingPrice from '../../dealSummary/sections/SellingPrice.interstate';
import TermLength from '../../dealSummary/sections/TermLength.interstate';
import TotalDueAtSigning from '../../dealSummary/sections/TotalDueAtSigning.interstate';
import TotalResidual from '../../dealSummary/sections/TotalResidual.interstate';
import LeaseTotalTax from '../../dealSummary/sections/LeaseTotalTax.interstate';
import { StyledListBordered, StyledListNotBordered, ColumnDetailsBlock, OfferCompareCardBlock } from './CompareColumnDetail.style';

export const CompareLeaseColumnDetail = ({ deal, deltaList }: CompareColumnDetailProps) => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const StyledList = isABPencilEnabled ? StyledListBordered : StyledListNotBordered;

    return (
        <ColumnDetailsBlock>
            <OfferCompareCardBlock rowName="pricing">
                <Typography variant="h4">{constants.PRICE}</Typography>
                <StyledList>
                    <MSRP deal={deal} />
                    <Discount deal={deal} hasDelta={deltaList.isDiscountDelta} />
                    <SellingPrice deal={deal} hasDelta={deltaList.isSellingPriceDelta} />
                </StyledList>
            </OfferCompareCardBlock>

            <OfferCompareCardBlock rowName="addons">
                <Typography variant="h4">{constants.ADDS_TO_CAP_COST}</Typography>
                <StyledList>
                    <AddsToCapCost deal={deal} hasDelta={deltaList.isAdjustedCapCostDelta} />
                    <LeaseTotalTax deal={deal} hasDelta={deltaList.isTotalTaxDelta} />
                    <GrossCapCost deal={deal} hasDelta={deltaList.isNetSellingPriceDelta} />
                </StyledList>
            </OfferCompareCardBlock>

            <OfferCompareCardBlock rowName="finance-reductions">
                <Typography variant="h4">{constants.CAP_COST_REDUCTION}</Typography>
                <StyledList>
                    <NetTrade deal={deal} hasDelta={deltaList.isNetTradeDelta} />
                    <Rebates deal={deal} hasDelta={deltaList.isRebatesDelta} />
                    <CashDown deal={deal} hasDelta={deltaList.isCashDownDelta} />
                    <AdjCapCost deal={deal} />
                </StyledList>
            </OfferCompareCardBlock>

            <OfferCompareCardBlock rowName="financing-terms">
                <Typography variant="h4">{constants.LEASING_TERMS}</Typography>
                <StyledList>
                    <TotalResidual deal={deal} hasDelta={deltaList.isTotalResidualDelta} />
                    <TermLength deal={deal} hasDelta={deltaList.isTermDelta} />
                    <MonthlyPayment deal={deal} hasDelta={deltaList.isMonthlyPaymentDelta} />
                    <TotalDueAtSigning deal={deal} hasDelta={deltaList.isTotalDueDelta} />
                </StyledList>
            </OfferCompareCardBlock>
        </ColumnDetailsBlock>
    );
};
