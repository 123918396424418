// libraries
import { Reducer } from 'redux';

// consts/enums
import {
    APPLY_ROLL_TO_OPTIONS,
    APPLY_ROLL_TO_OPTIONS_SUCCESS,
    APPLY_ROLL_TO_OPTIONS_FAILURE,
    CLEAR_ROLL_TO_OPTIONS,
    REQUEST_ROLL_TO_OPTIONS,
    REQUEST_ROLL_TO_PAYMENTS,
    REQUEST_ROLL_TO_PAYMENTS_FAILURE,
    REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPQ,
    REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR,
    RESET_ROLL_TO_OPTIONS,
    ROLL_TO_PAYMENT_TOGGLE,
    SET_UNSAVED_CHANGES,
    UPDATE_ADJUST_ROLL_TO_CHANGES,
    UPDATE_ROLL_TARGET_PAYMENT,
    UPDATE_ROLL_TO_OPTIONS,
    CLEAR_ROLL_TO_ERRORS,
    UPDATE_ROLL_TARGET_BALANCE_DUE
} from '../actionTypes/rollToOptionsActionTypes';

// types
import {
    RequestRollToPaymentsPayload,
    RollProgramItem,
    RollToActionValues,
    RollToActions,
    RollToBasicObject,
    RollToOptionsState,
    RollValueToMap,
    TargetStatus
} from '@makemydeal/dr-dash-types';
import { PaymentProfitDetails } from '@makemydeal/dr-platform-types';

// util
import { parseErrorMessages } from '../utils/psErrorUtils';

export type RequestRollToOptionsPayload = { rollToItems: RollToActions; balanceDue?: number };

export type UpdateRollToOptionsPayload = { rollToItems: RollToActions; balanceDue?: number };

export type RequestRollToPaymentsFailurePayload = {
    response?: {
        errors?: RollToBasicObject[];
        message?: string;
    };
};

export type RequestRollToPaymentUPQPayload = {
    profit: PaymentProfitDetails | undefined;
    actionValue: number | undefined;
    totalTaxes: number | 0;
    balanceDue: number | 0;
    totalFees: number | 0;
    VppAndProtectionTotal: number | 0;
};

export type RollToOptionsAction =
    | { type: typeof REQUEST_ROLL_TO_OPTIONS; payload: RequestRollToOptionsPayload }
    | { type: typeof REQUEST_ROLL_TO_PAYMENTS }
    | { type: typeof REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR; payload: RequestRollToPaymentsPayload }
    | { type: typeof REQUEST_ROLL_TO_PAYMENTS_FAILURE; payload: RequestRollToPaymentsFailurePayload }
    | { type: typeof UPDATE_ROLL_TO_OPTIONS; payload: RequestRollToOptionsPayload }
    | { type: typeof UPDATE_ROLL_TARGET_PAYMENT; payload: number }
    | { type: typeof UPDATE_ROLL_TARGET_BALANCE_DUE; payload: number }
    | { type: typeof CLEAR_ROLL_TO_OPTIONS }
    | { type: typeof ROLL_TO_PAYMENT_TOGGLE }
    | { type: typeof SET_UNSAVED_CHANGES; payload: RequestRollToOptionsPayload }
    | { type: typeof UPDATE_ADJUST_ROLL_TO_CHANGES; payload: RollToActionValues | undefined }
    | { type: typeof RESET_ROLL_TO_OPTIONS }
    | { type: typeof APPLY_ROLL_TO_OPTIONS }
    | { type: typeof APPLY_ROLL_TO_OPTIONS_SUCCESS; payload: TargetStatus }
    | { type: typeof APPLY_ROLL_TO_OPTIONS_FAILURE }
    | { type: typeof CLEAR_ROLL_TO_ERRORS }
    | {
          type: typeof REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPQ;
          payload: RequestRollToPaymentUPQPayload;
      };

export type RollToOptionsReducer = Reducer<RollToOptionsState, RollToOptionsAction>;

export const initialState: RollToOptionsState = {
    balanceDue: 0,
    rollToItems: null,
    hasUnsavedChanges: false,
    rollToSlideOutExpanded: false
};

export const reducer: RollToOptionsReducer = (state = initialState, action: RollToOptionsAction) => {
    switch (action.type) {
        case CLEAR_ROLL_TO_ERRORS:
            return {
                ...state,
                errors: []
            };

        case REQUEST_ROLL_TO_OPTIONS: {
            const payload = action.payload;
            return { ...state, ...payload };
        }

        case REQUEST_ROLL_TO_PAYMENTS: {
            return {
                ...state,
                isCalculating: true,
                errors: [] // we will clear errors when starting a new request
            };
        }

        case REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPR: {
            const { rollProgramItems, messages, targetPayment, balanceDue } = action.payload;

            if (!rollProgramItems) {
                return {
                    ...state,
                    isCalculating: false,
                    errors: [],
                    messages
                };
            }

            const rollTo = extractRollValuesFromProgramItems(rollProgramItems);

            rollTo.actionValue = targetPayment ?? balanceDue;

            return {
                ...state,
                isCalculating: false,
                rollToItems: {
                    ...state.rollToItems,
                    rollTo
                },
                errors:
                    parseErrorMessages({
                        ...action.payload,
                        rollProgramItems: rollProgramItems,
                        messages: []
                    }) || [],
                messages
            };
        }

        case REQUEST_ROLL_TO_PAYMENTS_SUCCESS_UPQ: {
            const { actionValue, profit, totalTaxes, balanceDue, totalFees, VppAndProtectionTotal } = action.payload;

            return {
                ...state,
                isCalculating: false,
                rollToItems: {
                    ...state.rollToItems,
                    profit: profit ?? state.rollToItems?.profit,
                    adjust: {
                        ...state.rollToItems?.adjust,
                        actionValue: actionValue ?? state.rollToItems?.adjust?.actionValue,
                        taxesAndFees: totalTaxes + totalFees,
                        addOns: VppAndProtectionTotal + totalFees
                    }
                },
                balanceDue,
                errors:
                    parseErrorMessages({
                        ...action.payload,
                        rollProgramItems: [],
                        messages: []
                    }) || []
            };
        }

        case REQUEST_ROLL_TO_PAYMENTS_FAILURE: {
            const { response } = action.payload;

            const errors = response?.errors || [];

            const errorMessage = !errors.length ? response?.message || 'An unexpected error occurred' : '';

            if (errorMessage) {
                errors.push({ message: errorMessage });
            }

            return {
                ...state,
                isCalculating: false,
                errors: [...(state.errors || []), ...errors] // !IMPORTANT Combine errors from UPQ and UPR
            };
        }

        case UPDATE_ROLL_TO_OPTIONS: {
            const { adjust, ...rest } = action.payload.rollToItems;

            return {
                ...state,
                rollToItems: {
                    ...state.rollToItems,
                    ...rest,
                    adjust: {
                        ...state.rollToItems?.adjust,
                        ...adjust
                    }
                },
                balanceDue: action.payload.balanceDue
            };
        }

        case UPDATE_ADJUST_ROLL_TO_CHANGES: {
            const adjust = action.payload;
            return {
                ...state,
                rollToItems: {
                    ...state.rollToItems,
                    adjust
                }
            };
        }

        case UPDATE_ROLL_TARGET_PAYMENT: {
            const targetPayment = action.payload;

            return { ...state, targetPayment };
        }

        case CLEAR_ROLL_TO_OPTIONS: {
            return { ...initialState, rollToSlideOutExpanded: state.rollToSlideOutExpanded };
        }

        case ROLL_TO_PAYMENT_TOGGLE: {
            return {
                ...state,
                rollToSlideOutExpanded: !state.rollToSlideOutExpanded
            };
        }

        case SET_UNSAVED_CHANGES: {
            return {
                ...state,
                hasUnsavedChanges: Boolean(action.payload)
            };
        }

        case APPLY_ROLL_TO_OPTIONS: {
            return {
                ...state,
                isCalculating: true
            };
        }

        case APPLY_ROLL_TO_OPTIONS_SUCCESS: {
            return {
                ...state,
                targetStatus: action.payload,
                isCalculating: false
            };
        }

        case APPLY_ROLL_TO_OPTIONS_FAILURE: {
            return {
                ...state,
                isCalculating: false
            };
        }

        case RESET_ROLL_TO_OPTIONS: {
            return {
                ...initialState,
                rollToSlideOutExpanded: state.rollToSlideOutExpanded
            };
        }
        case UPDATE_ROLL_TARGET_BALANCE_DUE: {
            const targetBalanceDue = action.payload;
            return {
                ...state,
                balanceDue: targetBalanceDue
            };
        }

        default: {
            return state;
        }
    }
};

function extractRollValuesFromProgramItems(rollProgramItems: RollProgramItem[]) {
    const rollValuesToMap: RollValueToMap = {
        SellingPrice: 'sellingPrice',
        DownPayment: 'downPayment',
        Trade: 'trade',
        Rate: 'rate',
        Term: 'term'
    };

    return rollProgramItems.reduce((acc, item) => {
        const key = rollValuesToMap[item.rollValueType];

        if (key) {
            acc[key] = item.rollValue;
        }

        return acc;
    }, {} as RollToActionValues);
}
