// externals
import {
    buildUserEventV3,
    EventMapV3,
    buildCDLEventMiddlewareV3,
    buildReadyEventV3,
    BaseDataLayerV3
} from '@makemydeal/dr-common-data-layer-utils';

// libraries
// consts/enums
import { baseDealAnalyticsActionTypes, deskingActionTypes } from '../actionTypes';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

export const eventMap: EventMapV3 = {
    CDLV3_DATA_READY: (action: any, currentState: any) => {
        const eventData = {
            // add more data here if avaiable in later stages and needed for initial CDL3 setup (part of 49 fields)
        };
        return buildReadyEventV3(eventData as BaseDataLayerV3);
    },
    [deskingActionTypes.CLICKED_COPY_DEAL_URL_CLICKED]: buildUserEventV3({
        action: 'Click',
        element: 'Get Deal Link - Copy Button',
        location: ['Deal Dashboard', 'Current Working Offer', 'Modal'],
        intention: 'Copy Link To Deal'
    }),
    [deskingActionTypes.CLICKED_PUSH_TO_DMS_CONFIRM]: buildUserEventV3({
        action: 'Click',
        element: 'Push to DMS - Confirm Button',
        location: ['Deal Dashboard', 'Deal Progress', 'Modal'],
        intention: 'Navigate to DMS'
    }),
    [deskingActionTypes.CLICKED_BEGIN_CHECKOUT]: buildUserEventV3({
        action: 'Click',
        element: 'Start Checkout - Button',
        location: ['Deal Dashboard', 'Deal Progress'],
        intention: 'Get Start Checkout Link'
    }),
    [deskingActionTypes.SEND_TO_SALES_VIEW_CONFIRM]: buildUserEventV3({
        action: 'Click',
        element: 'Send To Sales View - Confirm Button',
        location: ['Deal Dashboard', 'Current Working Offer', 'Modal'],
        intention: 'Send Offer To Sales View'
    }),
    [deskingActionTypes.CLICKED_SEND_TO_CUSTOMER]: buildUserEventV3({
        action: 'Click',
        element: 'Send To Customer - Button',
        location: ['Deal Dashboard', 'Current Working Offer'],
        intention: 'Send Offer To Customer'
    }),
    [deskingActionTypes.CLICKED_CONTINUE_IN_DESKING_CONFIRM]: buildUserEventV3({
        action: 'Click',
        element: 'Continue In Desking - Confirm Button',
        location: ['Deal Dashboard', 'Current Working Offer', 'Modal'],
        intention: 'Navigate To Desking'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_SUMMARY_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Summary Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Summary Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_SUMMARY_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Summary Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Summary Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_SUMMARY_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Summary Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Summary Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_TRADEIN_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Trade-in Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Trade-in Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_TRADEIN_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Trade-in Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Trade-in Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_TRADEIN_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Trade-in Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Trade-in Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_FEES_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Fees Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Fees Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_FEES_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Fees Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Fees Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_FEES_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Fees Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Fees Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_TAXES_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Taxes Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Taxes Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_TAXES_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Taxes Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Taxes Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_TAXES_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Taxes Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Taxes Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_PROTECTION_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Protection Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Protection Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_PROTECTION_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Protection Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Protection Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_PROTECTION_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Protection Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Protection Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_INCENTIVES_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Incentives Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Incentives Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_INCENTIVES_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Incentives Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Incentives Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_INCENTIVES_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Incentives Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Incentives Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_LENDER_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Lender Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Lender Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_LENDER_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Lender Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Lender Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_LENDER_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Lender Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Lender Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_ACCESSORIES_TAB]: buildUserEventV3({
        action: 'View',
        element: 'Deal Accessories Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'View Deal Accessories Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_ACCESSORIES_TAB]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Accessories Tab',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Accessories Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_ACCESSORIES_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Accessories Tab Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Navigate to Deal Accessories Tab'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_BREAKDOWN_EXPAND]: buildUserEventV3({
        action: 'View',
        element: 'Deal Breakdown Expand',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Open Deal Breakdown'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_BREAKDOWN_EXPAND]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Breakdown Expand',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Open Deal Breakdown'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_BREAKDOWN_EXPAND_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Breakdown Expand Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Open Deal Breakdown'
    }),
    [baseDealAnalyticsActionTypes.VIEWED_BREAKDOWN_COLLAPSE]: buildUserEventV3({
        action: 'View',
        element: 'Deal Breakdown Collapse',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Close Deal Breakdown'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_BREAKDOWN_COLLAPSE]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Breakdown Collapse',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Close Deal Breakdown'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_BREAKDOWN_COLLAPSE_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Breakdown Collapse Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Close Deal Breakdown'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_UPDATE]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Update',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Update Deal'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_UPDATE_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Deal Update Hotkey',
        location: ['Deal Dashboard', 'Deal Details'],
        intention: 'Update Deal'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_NEXT_EVENT_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Next Event Hotkey',
        location: ['Global'],
        intention: 'Navigate to Next Event'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_PREVIOUS_EVENT_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Previous Event Hotkey',
        location: ['Global'],
        intention: 'Navigate to Previous Event'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_NEXT_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Next Tab Hotkey',
        location: ['Global'],
        intention: 'Navigate to Next Tab'
    }),
    [baseDealAnalyticsActionTypes.CLICKED_PREVIOUS_TAB_HOTKEY]: buildUserEventV3({
        action: 'Click',
        element: 'Previous Tab Hotkey',
        location: ['Global'],
        intention: 'Navigate to Previous Tab'
    })
};

export const cdlV3AnalyticsEventMiddleware = buildCDLEventMiddlewareV3({
    eventMap,
    enabled: true,
    middlewareEnabledSelector: featureToggleSelectors.isCDL3EnabledInMV
});
