import { toRem } from '@makemydeal/dr-dash-components';
import styled from 'styled-components';

export const TaxCategoryHeading = styled.div<{ isBaseDealScreenExperience: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${toRem(8)};

    ${(props) => props.isBaseDealScreenExperience && `padding-right: 1rem;`}

    // Checkbox is adding some margin-bottom value.
    // Overriding margin with !important because of align vertically center with category heading.
    label {
        margin-bottom: 0 !important;
    }
`;
