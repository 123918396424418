import { useEffect, useState } from 'react';

import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { DatePickerEventValue } from '@interstate/components/DatePicker/Types/datePickerTypes';
import { SelectInputOption } from '@interstate/components/SelectInput';
import { TextInputValue } from '@interstate/components/TextInput';

import { LIENHOLDER_FORM_SUMMARY_ADDRESS, LienholderSummaryControlIds } from './constants';
import { LienholderFormData, LienholderStateData } from './types';
import { formatDate, formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { LIEN_HOLDER, LIEN_HOLDER_ACCOUNT_NUMBER, PAYOFF_DUE_DATE, PER_DIEM } from '../../../constants';
import { convertToNumber } from '../../../utils/formatUtils';
import { isBlurEvent, StateCodes } from '@makemydeal/dr-dash-components';

export const getStateCodesSelectInputValues = (): SelectInputOption[] =>
    StateCodes.map((stateCode) => ({
        value: stateCode,
        label: stateCode
    }));

export const checkLienholderInfoAvailability = ({
    lienholder,
    lienholderAccountNumber,
    lienholderAddress: { address, city, postalCode, stateProvince },
    goodThroughDate,
    perDiemInterest
}: LienholderStateData) => {
    return (
        !!lienholder ||
        !!lienholderAccountNumber ||
        !!address ||
        !!city ||
        !!postalCode ||
        !!stateProvince ||
        !!goodThroughDate ||
        !!perDiemInterest
    );
};

export const getLienholderFormData = (sourceLienholderData: LienholderStateData): LienholderFormData => ({
    lienholder: sourceLienholderData.lienholder,
    lienholderAccountNumber: sourceLienholderData.lienholderAccountNumber,
    lienholderPhoneNumber: sourceLienholderData.lienholderPhoneNumber,
    lienholderAddress: sourceLienholderData.lienholderAddress,
    payoffDueDate: sourceLienholderData.goodThroughDate ? new Date(sourceLienholderData.goodThroughDate) : null,
    perDiem: sourceLienholderData.perDiemInterest
});

const formatLienholderSummaryAddress = ({
    address,
    city,
    postalCode,
    stateProvince
}: LienholderFormData['lienholderAddress'] = {}) => {
    const lines = [];

    if (address) {
        lines.push(address);
    }

    let cityStateZip = city || '';

    if (stateProvince) {
        cityStateZip += cityStateZip.length ? ' ' : '';
        cityStateZip += stateProvince;
    }

    if (postalCode) {
        cityStateZip += cityStateZip.length ? ', ' : '';
        cityStateZip += postalCode;
    }

    if (cityStateZip) {
        lines.push(cityStateZip);
    }

    return lines.length ? lines : undefined;
};

export type SummaryData = Partial<ReturnType<typeof getSummaryData>>;

export const getSummaryData = ({
    lienholder,
    lienholderAccountNumber,
    lienholderAddress,
    goodThroughDate,
    perDiemInterest
}: LienholderStateData) => ({
    [LIEN_HOLDER]: {
        value: lienholder,
        testId: LienholderSummaryControlIds.Lienholder
    },
    [LIEN_HOLDER_ACCOUNT_NUMBER]: {
        value: lienholderAccountNumber,
        testId: LienholderSummaryControlIds.AccountNumber
    },
    [PAYOFF_DUE_DATE]: {
        value: (goodThroughDate && formatDate(new Date(goodThroughDate))) || '',
        testId: LienholderSummaryControlIds.PayoffDueDate
    },
    [PER_DIEM]: {
        value: formatDollarsAndCents(perDiemInterest),
        testId: LienholderSummaryControlIds.PerDiem
    },
    [LIENHOLDER_FORM_SUMMARY_ADDRESS]: {
        value: formatLienholderSummaryAddress(lienholderAddress),
        testId: LienholderSummaryControlIds.Address
    }
});

export const extractValueFromDatePickerEvent = (event: InterstateOnChangeEvent<DatePickerEventValue>): Date | null => {
    const stringValue = event.target.value.dateValue;

    return stringValue ? new Date(stringValue) : null;
};

export const getRequiredErrorMessage = (obj: Record<string, boolean>, key: string) => {
    return obj[key] ? 'Required' : undefined;
};

// This workaround is needed until interstate fixes the issue with SelectInput and NumericInput
export const isLienholderAddressInvalid = (field: string, e: InterstateOnChangeEvent<TextInputValue>) => {
    const {
        isValid,
        target: { value }
    } = e;

    if (!isValid && field !== 'stateProvince') {
        return true;
    }

    if (field === 'postalCode' && value && typeof value === 'string' && value.length !== 5) {
        return true;
    }

    return false;
};

// This workaround is needed until interstate fixes the issue with DatePicker
export const isLienholderDataInvalid = (field: string, value?: number | Date | null) => {
    return field === 'payoffDueDate' && value && value < new Date();
};

type onChangeResult = {
    value: string;
    handleChange: InterstateOnChangeCallback<string>;
    handleBlur: InterstateOnChangeCallback<string>;
};

export const useHandlePerDiemChange = (
    initialValue: number,
    onChange: (value: 'perDiem') => InterstateOnChangeCallback<any>
): onChangeResult => {
    const [value, setValue] = useState(initialValue);
    const [textValue, setTextValue] = useState(formatDollarsAndCents(initialValue));

    const handleChange = (e: InterstateOnChangeEvent<string>) => {
        if (isBlurEvent(e)) {
            return;
        }
        setTextValue(e.target.value);
    };
    const handleBlur = (e: InterstateOnChangeEvent<string>) => setTextValue(formatDollarsAndCents(value));

    useEffect(() => {
        const newValue = convertToNumber(textValue);
        setValue(newValue);
        if (newValue !== value) {
            onChange('perDiem')({ target: { value: newValue }, isValid: true });
        }
    }, [onChange, textValue, value]);

    useEffect(() => {
        if (initialValue === value) return;
        setValue(initialValue);
        setTextValue(formatDollarsAndCents(initialValue));
    }, [initialValue, value]);

    return { value: textValue, handleChange, handleBlur };
};

export const tradeInContainerGridPropsConfigWithBreakdownClose = { xs: 4, md: 4, lg: 2, xl: 1.5, sm: 4 };

export const tradeInContainerGridPropsConfigWithBreakdownOpen = { xs: 4, md: 4, lg: 4, xl: 2, sm: 4 };

export const tradeInComponentLargeFieldsConfigWithBreakdownOpen = { sm: 8, xs: 8, md: 8, lg: 8, xl: 4 };

export const tradeInComponentLargeFieldsConfigWithBreakdownClose = { sm: 8, xs: 8, md: 8, lg: 4, xl: 3 };

export const tradeInContainerLargeFieldsProps = (isBreakDownOpen: boolean, isLargeScreen?: boolean) => {
    if (!isLargeScreen) {
        return isBreakDownOpen
            ? { ...tradeInComponentLargeFieldsConfigWithBreakdownOpen, xl: tradeInComponentLargeFieldsConfigWithBreakdownOpen.lg }
            : {
                  ...tradeInComponentLargeFieldsConfigWithBreakdownClose,
                  xl: tradeInComponentLargeFieldsConfigWithBreakdownClose.lg
              };
    }
    return isBreakDownOpen
        ? tradeInComponentLargeFieldsConfigWithBreakdownOpen
        : tradeInComponentLargeFieldsConfigWithBreakdownClose;
};

export const tradeInContainerGridProps = (isBreakDownOpen: boolean, isLargeScreen?: boolean) => {
    if (!isLargeScreen) {
        return isBreakDownOpen
            ? { ...tradeInContainerGridPropsConfigWithBreakdownOpen, xl: tradeInContainerGridPropsConfigWithBreakdownOpen.lg }
            : { ...tradeInContainerGridPropsConfigWithBreakdownClose, xl: tradeInContainerGridPropsConfigWithBreakdownClose.lg };
    }
    return isBreakDownOpen ? tradeInContainerGridPropsConfigWithBreakdownOpen : tradeInContainerGridPropsConfigWithBreakdownClose;
};
