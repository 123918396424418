// externals
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import Button from '@cx/ui/Button';
import VinInput from '@cx/ui/VinInput';

// selectors
import { tradeInSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
// consts
import { VIN } from '../../constants';

// actions
import { tradeInActionCreators } from '@makemydeal/dr-dash-store';
import { RSAAAction } from 'redux-api-middleware';

import { TradeDecode, TradeMultipleVIN, TradeVinDecodeContainer } from './trade.style';
import { FetchVinDecodeResponse } from '../../types/TradeIn';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';

interface TradeVinDecodeProps {
    isManualFormOpen: boolean;
}

const TradeVinDecode: React.FC<TradeVinDecodeProps> = ({ isManualFormOpen }) => {
    const [enableDecode, setEnableDecode] = React.useState(false);
    const [isValidVIN, setIsValidVin] = useState(false);

    const dispatch = useDispatch();
    const vin = useSelector(tradeInSelectors.getVin);
    const hasMultipleVin = useSelector(tradeInSelectors.hasMultipleVin);
    const multipleVinOptions = useSelector(tradeInSelectors.multipleVinOptions);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    useEffect(() => {
        setIsValidVin(vin ? vin.length === 17 : false);
    }, [vin]);

    useEffect(() => {
        setEnableDecode(isValidVIN);
    }, [isValidVIN]);

    const onVinChange = (cxEvent: React.ChangeEvent<HTMLInputElement>) => {
        const value = cxEvent.target.value.toUpperCase();
        const isValid = value.length === 17;

        dispatch(tradeInActionCreators.updateTradeInVin(value));
        setIsValidVin(isValid);
    };

    const onDecodeClick = () => {
        dispatch(tradeInActionCreators.vinDecodeRSAA(MANAGER_VIEW_APP_PREFIX, vin) as unknown as RSAAAction<any, unknown, unknown>);
    };

    const selectMultipleOption = (optionSelected: FetchVinDecodeResponse[0]) => {
        dispatch(tradeInActionCreators.selectMultipleVinOption(optionSelected));
    };

    return (
        <>
            {isManualFormOpen && (
                <TradeVinDecodeContainer id="trade-vin-decode" isBaseDealScreenExperience={isBaseDealScreenExperience}>
                    {isBaseDealScreenExperience && (
                        <TradeDecode>
                            <Action
                                data-testid="vin-decode-btn"
                                onClick={() => {
                                    onDecodeClick();
                                }}
                                disabled={!enableDecode}
                            >
                                <Typography
                                    variant="anchor-block-sm"
                                    color={enableDecode ? 'base.color.blue.500' : 'base.color.gray.500'}
                                >
                                    Decode
                                </Typography>
                            </Action>
                        </TradeDecode>
                    )}
                    {isBaseDealScreenExperience && (
                        <label style={{ fontSize: '1rem' }} data-testid="vin-decode-btn-label" htmlFor={'vin-decode-btn'}>
                            {VIN}
                        </label>
                    )}
                    <VinInput
                        appendChild={
                            !isBaseDealScreenExperience && (
                                <Button
                                    disabled={!enableDecode}
                                    htmlId="vin-decode-btn"
                                    buttonStyle="secondary"
                                    onClick={onDecodeClick}
                                    data-testid="vin-decode-btn"
                                >
                                    Decode
                                </Button>
                            )
                        }
                        style={
                            isBaseDealScreenExperience
                                ? {
                                      height: '38px',
                                      borderRadius: '4px',
                                      border: '1px solid #959595',
                                      marginTop: '2px'
                                  }
                                : {}
                        }
                        data-testid="vin-decode-input"
                        htmlId="vin-decode-input"
                        displayLabel={!isBaseDealScreenExperience}
                        label={VIN}
                        name="vin-decode-input"
                        onChange={onVinChange}
                        value={vin}
                        autoFocus
                    />
                    {hasMultipleVin && multipleVinOptions.length > 0 && (
                        <TradeMultipleVIN data-testid="multiple-vin-options-container">
                            {multipleVinOptions.map((option) => (
                                <p
                                    key={option.id}
                                    data-testid={`multiple-option-${option.id}`}
                                    onClick={() => selectMultipleOption(option)}
                                >
                                    {option.title}
                                </p>
                            ))}
                        </TradeMultipleVIN>
                    )}
                </TradeVinDecodeContainer>
            )}
        </>
    );
};

export default TradeVinDecode;
