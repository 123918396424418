import DealSummaryItem from '../common/DealSummaryItem';
import { expandibleAreaStyles, expandibleButtonStyles, labelStyles } from '../utils/styles';
import * as constants from '../../constants';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { baseDealAnalyticsActionCreators } from '@makemydeal/dr-dash-store';

type detailsProps = {
    children: ReactNode;
};
const Details = ({ children }: detailsProps) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(baseDealAnalyticsActionCreators.viewedBreakdownExpand());

        return () => {
            dispatch(baseDealAnalyticsActionCreators.viewedBreakdownCollapse());
        };
    }, []);

    return (
        <DealSummaryItem
            label={constants.DEAL_SUMMARY_DETAILS}
            value={''}
            labelStyles={labelStyles}
            expandibleButtonStyles={expandibleButtonStyles}
            expandibleAreaStyles={expandibleAreaStyles}
        >
            {children}
        </DealSummaryItem>
    );
};

export default Details;
