// libraries
import type { FSA, FSAWithMeta, SimpleFSA } from '@makemydeal/dr-platform-shared';
import type { DealHistory } from '@makemydeal/dr-dash-types';

// types
import { OfferResponse } from '@makemydeal/dr-dash-bff-types';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SCENARIOS_FETCH_SUCCESS } from './multiScenarioActionTypes';

export const FETCH_DEAL_HISTORY_REQUEST = 'FETCH_DEAL_HISTORY_REQUEST';
export const FETCH_DEAL_HISTORY_SUCCESS = 'FETCH_DEAL_HISTORY_SUCCESS';
export const FETCH_DEAL_HISTORY_FAILURE = 'FETCH_DEAL_HISTORY_FAILURE';

export const DEAL_HISTORY_CHECKED = 'DEAL_HISTORY_CHECKED';
export const DEAL_HISTORY_UNCHECKED = 'DEAL_HISTORY_UNCHECKED';

export const SET_DEAL_HISTORY_SCENARIO_DRAWER_DEAL = 'SET_DEAL_HISTORY_SCENARIO_DRAWER_DEAL';

export const DEAL_HISTORY_SELECTED_UPDATE = 'DEAL_HISTORY_SELECTED_UPDATE';

export const ADD_SCENARIOS_HISTORY_TO_STATE = 'ADD_SCENARIOS_HISTORY_TO_STATE';

export type FetchDealHistorySuccessPayload = OfferResponse[];
export type DealHistoryChangePayload = {
    deal: DealHistory;
};

export type FetchDealHistoryAction = SimpleFSA<typeof FETCH_DEAL_HISTORY_REQUEST>;

export type FetchDealHistorySuccessAction = FSA<typeof FETCH_DEAL_HISTORY_SUCCESS, FetchDealHistorySuccessPayload>;

export type FetchDealHistoryFailureAction = SimpleFSA<typeof FETCH_DEAL_HISTORY_FAILURE>;

export type ScenariosFetchSuccessPayload = {
    scenarios: OfferResponse[];
};

export type ScenariosFetchSuccessAction = FSAWithMeta<
    typeof SCENARIOS_FETCH_SUCCESS,
    ScenariosFetchSuccessPayload,
    { dealXgVersion?: string }
>;

export type DealHistoryCheckedAction = FSA<typeof DEAL_HISTORY_CHECKED, DealHistory>;
export type DealHistoryUnCheckedAction = FSA<typeof DEAL_HISTORY_UNCHECKED, string>;
export type DealHistoryNavigation = FSA<typeof LOCATION_CHANGE, { location: { pathname: string } }>;
export type SetDealHistoryScenarioDrawerDealAction = FSA<typeof SET_DEAL_HISTORY_SCENARIO_DRAWER_DEAL, DealHistory>;

export type DealHistorySelectedUpdate = FSA<typeof DEAL_HISTORY_SELECTED_UPDATE, string>;

export type DealHistoryActions =
    | FetchDealHistoryAction
    | FetchDealHistorySuccessAction
    | FetchDealHistoryFailureAction
    | DealHistoryCheckedAction
    | DealHistoryUnCheckedAction
    | DealHistoryNavigation
    | DealHistorySelectedUpdate
    | ScenariosFetchSuccessAction
    | SetDealHistoryScenarioDrawerDealAction;
