// externals
import React, { FC, useEffect } from 'react';

export const Logout: FC<{ handleLogout: () => void }> = ({ handleLogout }) => {
    useEffect(() => {
        console.log('Logout useEffect handleLogout() before');
        handleLogout();
        console.log('  Logout useEffect handleLogout() after');
    }, []);
    return <>Logging out...</>;
};
