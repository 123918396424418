import { DealMentionsState } from '@makemydeal/dr-dash-types';
import { SET_SHOW_DEAL_MENTIONS, SET_SHOW_MENTIONS_RED_DOT } from '../actions/dealMentionsActionCreators';
import { Reducer } from 'redux';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';

export const dealMentionsInitialState: DealMentionsState = {
    showDealMentions: false,
    showRedDot: false
};

export type dealMentionsReducer = Reducer<DealMentionsState>;

export const reducer: dealMentionsReducer = (state: DealMentionsState = dealMentionsInitialState, action) => {
    switch (action.type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            const showDealMessages = offerSessionUtils.getSessionValue('showDealMessages');
            return {
                ...state,
                showDealMentions: showDealMessages === 'true'
            };
        }
        case SET_SHOW_DEAL_MENTIONS: {
            return {
                ...state,
                showDealMentions: action.payload,
                showRedDot: false
            };
        }

        case SET_SHOW_MENTIONS_RED_DOT: {
            return {
                ...state,
                showRedDot: action.payload
            };
        }
        default:
            return state;
    }
};
