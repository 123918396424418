// external
import styled, { css } from 'styled-components';

// libraries
import { BreakPoint } from '@makemydeal/dr-activities-common';

import { BaseDealBreakpoint } from '../../utils/useBaseDealBreakpoint';

export const ACCESSORY_NAME_CLASSNAME = 'accessory-name-cell';
export const MANUAL_LEGEND_CLASSNAME = 'manual-accessory-legend';
export const MANUAL_ROW_CLASSNAME = 'manual-accessory-row';
export const TABLE_ROW_CLASSNAME = 'accessory-table-row';

export const AccessoryActionIconContainer = styled.div`
    margin-top: 1.7rem;
`;

export const AccessoryActionBaseIconContainer = styled.div`
    margin-top: 0;
    margin-bottom: 5px;
`;

export const AccessoryFieldsContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    width: 100%;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);

    .text-input-name {
        grid-column: span 2;
    }

    @media (max-width: ${BreakPoint.SMALL}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${BreakPoint.PHONE}) {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &.lease-layout {
        grid-template-columns: repeat(4, 1fr);

        .text-input-name {
            grid-column: span 3;
        }

        @media (max-width: ${BreakPoint.SMALL}) {
            grid-template-columns: repeat(2, 1fr);

            .text-input-name {
                grid-column: span 2;
            }
        }

        @media (max-width: ${BreakPoint.PHONE}) {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }
`;
export const BaseContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    color: #6d6d6d;
    font-size: 14px;
    grid-template-columns: repeat(7, 1fr) 90px 50px;
    .text-row-1 {
        grid-column: span 2;
    }

    &.lease-layout {
        grid-template-columns: repeat(8, 1fr) 90px 90px 50px;
    }
    @media (max-width: ${(props) => props.BaseBreakPoint.TABLET_PORTRAIT}) {
        display: none;
    }
`;
export const AccessoryBaseFieldsContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(7, 1fr) 90px 50px;
    align-items: center;
    padding-top: 8px;
    .text-input-name {
        grid-column: span 2;
    }
    .base-accessories-label label,
    label[for='base-accessories-profit-field'] {
        display: none;
    }
    .base-accessories-label div {
        margin-top: 0.5px;
    }
    @media (max-width: ${(props) => props.BaseBreakPoint.TABLET_PORTRAIT}) {
        grid-template-columns: repeat(auto-fit, minmax(176px, 1fr));
        max-width: 100%;
        padding-top: 0px;
        .text-input-name {
            grid-column: span 1;
        }
        .base-accessories-label label,
        label[for='base-accessories-profit-field'] {
            display: inline;
        }
        .base-accessories-label div {
            margin-top: -0.5px;
        }
    }

    @media (max-width: ${(props) => props.BaseBreakPoint.PHONE}) {
        grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
        gap: 1rem;
        padding-top: 0px;

        .base-accessories-label div {
            margin-top: -0.5px;
        }
    }

    .we-owe-form-control label {
        margin-bottom: 15px;
        @media (max-width: ${(props) => props.BaseBreakPoint.PHONE}) {
            margin-bottom: 8px;
        }
    }
    &.lease-layout {
        grid-template-columns: repeat(8, 1fr) 90px 90px 50px;
        .WeOwe-form-control label {
            margin-bottom: 9px;
        }
        @media (max-width: ${(props) => props.BaseBreakPoint.TABLET_PORTRAIT}) {
            grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
            max-width: 100%;
        }

        @media (max-width: ${(props) => props.BaseBreakPoint.PHONE}) {
            grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
            gap: 1rem;
        }
        .we-owe-form-control label {
            margin-bottom: 8px;
        }
    }
`;
export const AccessoriesTotal = styled.div`
    display: flex;
    width: 200px;
    align-items: baseline;
    justify-content: space-between;
`;

export const AccessoriesTotalRow = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: space-between;
    margin: 1.1rem 1.1rem 1.1rem 0;

    @media (max-width: ${BreakPoint.SMALL}) {
        margin: 1.1rem 0;
    }
`;

export const AccessoryUpfrontWeOweContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    display: flex;
    gap: 1.5rem;
    align-items: end;

    padding-left: 1rem;

    grid-column: span 2;

    @media (max-width: ${BreakPoint.SMALL}) {
        grid-column: auto;
    }

    @media (max-width: ${BreakPoint.PHONE}) {
        padding: 1rem 0 0 0;
    }

    &.lease-layout {
        @media (max-width: ${BreakPoint.SMALL}) {
            padding: 1rem 0 0 0;
        }

        @media (max-width: ${BreakPoint.PHONE}) {
            align-items: start;
        }
    }
`;

export const AccessoryUpfrontWeOweBaseContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    display: grid;
    align-items: end;
    grid-template-columns: 90px 10px;
    margin-top: 8px;
    gap: 0.5rem;
    @media (max-width: ${(props) => props.BaseBreakPoint.TABLET_PORTRAIT}) {
        grid-column: span 2;
        margin-top: 22px;
        align-items: end;
    }
    @media (max-width: ${(props) => props.BaseBreakPoint.PHONE}) {
        grid-column: span 2;
        margin-top: 0;
        align-items: center;
    }

    &.lease-layout {
        grid-template-columns: 90px 90px 10px;
        margin-top: 8px;
        align-items: center;
        gap: 0.5rem;
        @media (max-width: ${(props) => props.BaseBreakPoint.TABLET_PORTRAIT}) {
            margin-top: 22px;
        }
        @media (max-width: ${(props) => props.BaseBreakPoint.PHONE}) {
            margin-top: 0;
        }
    }
`;

export const AddAccessoryActionContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    padding-bottom: 1rem;
    border-bottom: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
    white-space: pre;
`;

export const AddAccessoryActionBaseContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    padding-top: 1rem;
    padding-bottom: 3rem;
    border-bottom: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
    white-space: pre;
    @media (max-width: ${(props) => props.BaseBreakPoint.TABLET_PORTRAIT}) {
        padding-top: 0rem;
    }
`;

export const ManualAccessoriesErrorContainer = styled.div<{ opened: boolean; isBaseDealScreenExperience: boolean }>`
    transition: ${({ opened }) => (opened ? 'max-height 0.5s 0.5s;' : 'none')};
    max-height: ${({ opened }) => (opened ? '300px' : 0)};
    overflow: hidden;
    ${({ isBaseDealScreenExperience }) =>
        isBaseDealScreenExperience &&
        css`
            & .MuiAlert-root {
                margin-bottom: 1rem;
            }
        `}
`;

export const ManualAccessoriesButtonsContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;

    @media (min-width: ${BreakPoint.SMALL}) {
        flex-direction: row;
    }
`;

export const ManualAccessoriesButtonsBaseContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;

    @media (min-width: ${(props) => props.BaseBreakPoint.SMALL}) {
        flex-direction: row;
    }
`;

export const ManualAccessoriesFormContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    border-top: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
    padding: 1rem 0;

    &.first-accessory-form {
        border-top: none;
    }
`;

export const ManualAccessoriesFormBaseContainer = styled.div<{ BaseBreakPoint: BaseDealBreakpoint }>`
    &.first-accessory-form {
        border-top: 2px solid #b2b2b2;
    }
    @media (max-width: ${(props) => props.BaseBreakPoint.TABLET_PORTRAIT}) {
        border-top: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
        padding: 1rem 0;
        &.first-accessory-form {
            border-top: none;
        }
    }

    @media (max-width: ${(props) => props.BaseBreakPoint.PHONE}) {
        border-top: ${({ theme }) => '1px solid' + theme.tokens.BaseColorGray400};
        padding: 1rem 0;
        &.first-accessory-form {
            border-top: none;
        }
    }
`;

export const ManualAccessoriesFormRowContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const OptionalActionLinkContainer = styled.span`
    margin-right: 0.5rem;
`;

export const StyledAccessoriesTable = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-table-tbody > tr.${MANUAL_ROW_CLASSNAME} {
        td:first-of-type {
            border-left: ${({ theme }) => `${theme.tokens.BaseSizeBorderMd} solid ${theme.tokens.BaseColorGold500}`};
            padding-left: ${({ theme }) =>
                `calc(${theme.tokens.ComponentTableCellPaddingSmVertical} - ${theme.tokens.BaseSizeBorderMd})`};
        }
    }

    div.${MANUAL_LEGEND_CLASSNAME} {
        border-left: ${({ theme }) => `${theme.tokens.BaseSizeBorderMd} solid ${theme.tokens.BaseColorGold500}`};
        padding: 0 0.6rem;
    }

    td div.${ACCESSORY_NAME_CLASSNAME} {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        white-space: normal;
        overflow: hidden;
    }
`;
