// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREDIT_DECISION_LENDER_NAME, CREDIT_DECISION_LENDER_NAME_FIELD } from '../../constants';
import { kebabCase } from 'lodash';
import {
    lenderListSelectors,
    offerReduxSelectors,
    offerReduxActionCreators,
    baseDealActionCreators,
    baseDealAnalyticsActionCreators
} from '@makemydeal/dr-dash-store';
import { SelectInput } from '@interstate/components/SelectInput';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

const CreditDecisionLenderNameField = ({ isSummaryTab }: { isSummaryTab?: boolean }) => {
    const dispatch = useDispatch();
    const creditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);
    const lender = useSelector(offerReduxSelectors.getCreditDecisionLender);
    const lenderDecisions = useSelector(lenderListSelectors.getLenderList);

    const options = lenderDecisions
        .sort((a, b) => a.lenderName.localeCompare(b.lenderName))
        .map((t) => ({
            value: JSON.stringify({ id: t.lenderId, name: t.lenderName }),
            label: t.lenderName
        }));

    const selectedValue = lender ? JSON.stringify({ id: lender.id, name: lender.name }) : '';

    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);

    const isFieldDisabled = (): boolean => {
        if (!lenderDecisions.length) {
            // if lender decisions not found then disable the field
            return true;
        } else if (isBaseDealScreenExperience) {
            // if new design then do not disabled the field and ignore the other condition
            return false;
        } else if (!creditDecisionEnabled) {
            // if credit decision is disabled then field should be disabled
            return true;
        }
        return false;
    };

    const handleClick = () => {
        dispatch(baseDealActionCreators.handleTabChange('lender'));
        dispatch(baseDealAnalyticsActionCreators.lenderTabClicked());
    };

    const isBaseDealSummaryTab = isBaseDealScreenExperience && isSummaryTab;

    return (
        <>
            {isBaseDealSummaryTab && (
                <label
                    htmlFor={kebabCase(CREDIT_DECISION_LENDER_NAME_FIELD)}
                    data-testid="credit-decision-label"
                    style={{
                        color: '#005BA8',
                        cursor: 'pointer'
                    }}
                    onClick={handleClick}
                >
                    Lender
                </label>
            )}
            <SelectInput
                id={kebabCase(CREDIT_DECISION_LENDER_NAME_FIELD)}
                data-testid={kebabCase(CREDIT_DECISION_LENDER_NAME_FIELD)}
                name={CREDIT_DECISION_LENDER_NAME}
                displayLabel={!isBaseDealSummaryTab}
                onChange={(event) => {
                    let parsedValue = undefined;

                    try {
                        parsedValue = JSON.parse(event.target.value as string);
                    } catch (err) {
                        // Do nothing, fallback to initial value
                    }

                    const action = offerReduxActionCreators.updateCreditDecisionLender(parsedValue);
                    dispatch(action);
                }}
                value={selectedValue}
                disabled={isFieldDisabled()}
                options={options}
                displayDeselectOption={false}
                label={CREDIT_DECISION_LENDER_NAME}
            />
        </>
    );
};

export default CreditDecisionLenderNameField;
