import styled from 'styled-components';
import { FINANCE, LEASE } from '@makemydeal/dr-platform-types';
import { BaseDealBreakpoint } from '../../../utils/useBaseDealBreakpoint';

interface SectionProps {
    offerType: string;
    sm?: number;
    md?: number;
    lg?: number;
    section: string;
    breakpoints: BaseDealBreakpoint;
    isPaymentGridEnabled?: boolean;
}

export const BaseDealSummarySectionContainer = styled.div<{
    breakpoints: BaseDealBreakpoint;
    offerType: string;
    isPaymentGridEnabled: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4rem 4.57rem;
    width: 100vw;

    flex-direction: ${({ isPaymentGridEnabled }) => (isPaymentGridEnabled ? 'column' : 'row')};

    @media (max-width: ${({ breakpoints, offerType }) => (offerType === LEASE ? breakpoints.MEDIUM : breakpoints.LG)}) {
        flex-direction: column;
    }
    @media (max-width: ${({ breakpoints }) => breakpoints.MD}) {
        width: 100%;
    }
`;

export const BaseDealSummaryFieldsContainer = styled.div<SectionProps>`
    width: ${({ offerType, section, isPaymentGridEnabled }) => {
        if (isPaymentGridEnabled) {
            return '100%';
        }

        if (offerType === FINANCE) {
            return '50%'; // For finance, both left and right summary sections should take 50%
        }

        return section === 'LEFT' ? '70%' : '30%';
    }};

    @media (max-width: ${({ breakpoints, offerType }) => (offerType === LEASE ? breakpoints.MEDIUM : breakpoints.LG)}) {
        width: 100%;
    }
    display: grid;
    gap: 0.8rem 1.14rem;

    // sm
    grid-template-columns: ${({ sm }) => `repeat(${sm}, 1fr)`};

    // md
    @media (min-width: ${({ breakpoints }) => breakpoints.SMALL}) {
        grid-template-columns: ${({ md }) => `repeat(${md}, 1fr)`};
    }

    // lg
    @media (min-width: ${({ breakpoints, offerType }) => (offerType === LEASE ? breakpoints.MEDIUM : breakpoints.XL)}) {
        grid-template-columns: ${({ lg }) => `repeat(${lg}, 1fr)`};
    }
`;

interface InputFieldProps {
    sm?: number;
    md?: number;
    lg?: number;
    breakpoints: BaseDealBreakpoint;
    offerType: string;
}

export const SummaryField = styled.div<InputFieldProps>`
    // sm
    grid-column: ${({ sm }) => `span ${sm}`};

    // md
    @media (min-width: ${({ breakpoints }) => breakpoints.SMALL}) {
        grid-column: ${({ md }) => `span ${md}`};
    }

    // lg
    @media (min-width: ${({ breakpoints, offerType }) => (offerType === LEASE ? breakpoints.MEDIUM : breakpoints.XL)}) {
        grid-column: ${({ lg }) => `span ${lg}`};
    }

    label {
        font-size: 1rem !important;
        white-space: nowrap !important;
        span {
            white-space: normal;
        }
    }

    .residual-field-container {
        flex: 1;
        .ids-input-layout-vertical {
            display: flex;
            flex-direction: column;
        }
    }

    .base-deal-lease-field {
        display: grid !important;
        align-items: flex-end;
        gap: 0.857rem;
        grid-template-columns: 2fr 1fr;
        @media (min-width: ${({ breakpoints }) => breakpoints.SMALL}) {
            grid-template-columns: 2fr 2fr;
        }
    }
`;

export const BaseDealSummaryMainContainer = styled.div<{
    breakpoints: BaseDealBreakpoint;
    isBreakdownOpen?: boolean;
    isPaymentGridOpen?: boolean;
}>`
    display: flex;
    width: 100%;
    gap: 1.714rem;
    justify-content: space-between;
    @media (max-width: 1360px) {
        flex-direction: ${({ isBreakdownOpen }) => (isBreakdownOpen ? 'column-reverse' : 'row')};
    }
    @media (max-width: ${({ breakpoints }) => breakpoints.SMALL}) {
        flex-direction: column-reverse;
    }
`;
