// externals
import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import {
    usePaymentCall,
    FlexCol,
    FlexRow,
    StyledVAutoAppraisalStatus,
    ContainerGrid,
    ContainerGridItem
} from '@makemydeal/dr-dash-components';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { ArrowTopRightOnSquareIcon } from '@interstate/components/Icons';
import { BadgeVariant } from '@interstate/components/Badge';
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeCallback } from '@interstate/components/InterstateEvents';
import { Alert } from '@interstate/components/Alert';
import { Grid } from '@interstate/components/Grid';

// libraries
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';

// selectors
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    configSelectors,
    tradeInSelectors,
    tradeShopperActivity,
    deskingSelectors,
    offerReduxSelectors,
    vAutoSelectors,
    tradeActionTypes,
    baseDealSelectors
} from '@makemydeal/dr-dash-store';

// constants
import {
    ACV,
    ALLOWANCE,
    GENERIC_LAST_FIELD_CHANGED_ERROR,
    PAYOFF,
    IS_BUYER_REGISTERED_OWNER,
    VEHICLE_IS_REGISTERED_IN_SAME_STATE
} from '../../../constants';

// actions
import { tradeInActionCreators } from '@makemydeal/dr-dash-store';

import { VAutoAppraisalStatus } from '@makemydeal/dr-dash-types';
import { LienholderFormData } from '../lienholderForm/types';

import LienholderForm from '../lienholderForm/LienholderForm.interstate';
import { NumericInput } from '@interstate/components/NumericInput';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { useMediaQuery } from 'react-responsive';
import { tradeInContainerGridProps } from '../lienholderForm/utils';

const IS_BUYER_REGISTERED_OWNER_ID = 'is-buyer-registered-owner';
const VEHICLE_IS_REGISTERED_IN_SAME_STATE_ID = 'vehicle-is-registered-in-same-state';

export interface TradeInValuationProps {
    isEditDisabled: boolean;
    lienholderData: LienholderFormData;
    setLienholderData: Dispatch<SetStateAction<LienholderFormData>>;
}

const TradeInValuationComponent = ({ isEditDisabled, lienholderData, setLienholderData }: TradeInValuationProps) => {
    const dispatch = useDispatch();

    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const tradeInputsDisabled = !isBaseDealScreenExperience && (!isTradeInCompleted || isEditDisabled);

    const vin = useSelector(tradeInSelectors.getVin);
    const vehicleMileage = useSelector(tradeInSelectors.getTradeMileage);
    const isVAutoEnabled = useSelector(dealerSelectors.isVAutoAppraisalEnabled);
    const useCoOrgIdInVAutoAppraisalUrl = useSelector(featureToggleSelectors.useCoOrgIdInVAutoAppraisalUrl);
    const commonOrgId = useSelector(offerReduxSelectors.getCommonOrgId);
    const currAppraisal = useSelector(vAutoSelectors.getCurrentAppraisalFromVin);
    const vAutoAppraisalUrl = useSelector(configSelectors.getVAutoAppraisalUrl);
    const vAutoAppraisalStatus = currAppraisal?.status;
    const vAutoAppraisalAcv = currAppraisal?.value;
    const failedAllowance = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.ALLOWANCE_CHANGED));
    const isMobile = useMediaQuery({ query: `(max-width: ${BreakPoint.PHONE})` });

    const failedPayoff = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.PAYOFF_CHANGED));

    const failedACV = useSelector(deskingSelectors.wasTheLastFailure(tradeActionTypes.ACV_CHANGED));

    const isBuyerRegisteredOwner = useSelector(tradeInSelectors.getIsBuyerRegisteredOwner);
    const isSellingVehicleRegisteredInSameState = useSelector(tradeInSelectors.getIsSellingVehicleRegisteredInSameState);

    const isBreakdownOpen = useSelector(baseDealSelectors.getBreakdownStatus);
    const isLargeScreen = useMediaQuery({ query: `(min-width: 1360px)` });
    const { xs, sm, md, lg, xl } = tradeInContainerGridProps(isBreakdownOpen, isLargeScreen);
    const {
        value: allowance,
        handleChange: changeAllowance,
        handleBlur: allowanceBlur
    } = usePaymentCall(tradeInSelectors.getTradeValue, tradeShopperActivity.tradeActionCreators.allowanceChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const {
        value: payoff,
        handleChange: changePayoff,
        handleBlur: payoffBlur
    } = usePaymentCall(tradeInSelectors.getTradeInAmountOwed, tradeShopperActivity.tradeActionCreators.payoffChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const {
        value: acv,
        handleChange: changeAcv,
        handleBlur: acvBlur
    } = usePaymentCall(tradeInSelectors.getTradeACV, tradeShopperActivity.tradeActionCreators.acvChanged, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const {
        value: perDiem,
        handleChange: onPerDiemChange,
        handleBlur: perDiemBlur
    } = usePaymentCall(tradeInSelectors.getPerDiem, tradeInActionCreators.updateTradeInPerDiem, {
        formatValueToString: formatDollarsAndCents,
        ignoreTextValue: () => false
    });

    const handleIsBuyerRegisteredOwnerChange: InterstateOnChangeCallback<CheckBoxEventValue> = (event) => {
        /* istanbul ignore next */
        const checked = Boolean(event.target.value?.checked);
        dispatch(tradeInActionCreators.updateTradeInIsBuyerRegisteredOwner(checked));
    };

    const handleIsSellingVehicleRegisteredInSameStateChange: InterstateOnChangeCallback<CheckBoxEventValue> = (event) => {
        /* istanbul ignore next */
        const checked = Boolean(event.target.value?.checked);
        dispatch(tradeInActionCreators.updateTradeInIsSellingVehicleRegisteredInSameState(checked));
    };

    const handleAppraisalClick = useCallback(
        (isAppraisalAvailable: boolean) => {
            const appraisalPayload = JSON.stringify({ Vin: vin, Odometer: vehicleMileage });
            const baseUrl = `${vAutoAppraisalUrl}?${useCoOrgIdInVAutoAppraisalUrl ? `CommonOrgId=${commonOrgId}&` : ''}`;

            const url = isAppraisalAvailable
                ? `${baseUrl}Id=${currAppraisal.id}&AppraisalStatus=${vAutoAppraisalStatus}`
                : `${baseUrl}SelectedExitStrategy=Retail&InitialData=${appraisalPayload}`;

            window.open(url);
        },
        [vin, vehicleMileage, vAutoAppraisalUrl, useCoOrgIdInVAutoAppraisalUrl, commonOrgId, currAppraisal, vAutoAppraisalStatus]
    );

    const getAppraisalStatusTitle = () => {
        if (isVAutoEnabled) {
            let variant: BadgeVariant = 'info';
            let message = '';
            let isAppraisalAvailable = false;

            if (vAutoAppraisalStatus === VAutoAppraisalStatus.InProcess) {
                variant = 'caution';
                message = 'Appraisal in Progress';
                isAppraisalAvailable = true;
            } else if (vAutoAppraisalStatus === VAutoAppraisalStatus.Completed) {
                variant = 'success';
                message = 'Appraised';
                isAppraisalAvailable = true;
            }

            const acvUpdateAlert = isAppraisalAvailable && vAutoAppraisalAcv > 0 && (
                <Alert displayCloseButton data-testid="vauto-appraisal-acv-alert" role="banner" type="info">
                    <Typography variant="body-sm" tag="span">{`ACV was updated to ${formatDollarsAndCents(
                        vAutoAppraisalAcv
                    )}`}</Typography>
                </Alert>
            );

            const badge = (
                <StyledVAutoAppraisalStatus data-testid="vauto-status-badge" variant={variant}>
                    {message}
                </StyledVAutoAppraisalStatus>
            );

            const link = (
                <Action
                    data-testid="vAuto-link"
                    actionIcon={{
                        icon: <ArrowTopRightOnSquareIcon />,
                        ...(isBaseDealScreenExperience ? (isMobile ? { end: true } : { start: true }) : { end: true })
                    }}
                    onClick={() => handleAppraisalClick(isAppraisalAvailable)}
                    disabled={isEditDisabled}
                >
                    <Typography
                        variant="anchor-block-sm"
                        tag={isEditDisabled ? 'div' : 'a'}
                        color={isEditDisabled ? 'base.color.gray.400' : 'base.color.blue.700'}
                    >
                        {isAppraisalAvailable ? 'View in vAuto' : 'Start Appraisal'}
                    </Typography>
                </Action>
            );

            return (
                <FlexCol>
                    {acvUpdateAlert}
                    <FlexRow
                        justifyContent={isMobile && isBaseDealScreenExperience ? 'start' : 'space-between'}
                        gap={isBaseDealScreenExperience ? '1.5rem' : '0rem'}
                        alignItems="center"
                    >
                        <Typography variant={isBaseDealScreenExperience ? 'h4' : 'h6'}>
                            Valuation {isAppraisalAvailable && badge}
                        </Typography>
                        {link}
                    </FlexRow>
                </FlexCol>
            );
        }

        return (
            <FlexCol>
                <FlexRow justifyContent="space-between" alignItems="center">
                    <Typography variant={isBaseDealScreenExperience ? 'h4' : 'h6'}>Valuation</Typography>
                </FlexRow>
            </FlexCol>
        );
    };

    return !isBaseDealScreenExperience ? (
        <>
            <ContainerGrid>
                <ContainerGridItem xs={12}>{getAppraisalStatusTitle()}</ContainerGridItem>
                <ContainerGridItem xs={12} md={4}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="allowance"
                        label={ALLOWANCE}
                        name={kebabCase(ALLOWANCE)}
                        onChange={changeAllowance}
                        placeholder="10,000.00"
                        value={allowance}
                        hasError={failedAllowance}
                        errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                        onBlur={allowanceBlur}
                        disabled={tradeInputsDisabled}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="acv"
                        label={ACV}
                        name={kebabCase(ACV)}
                        onChange={changeAcv}
                        placeholder="10,000.00"
                        value={vAutoAppraisalAcv > 0 ? formatDollarsAndCents(vAutoAppraisalAcv) : acv}
                        onBlur={acvBlur}
                        hasError={failedACV}
                        errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                        disabled={tradeInputsDisabled || !!vAutoAppraisalStatus}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="payoff"
                        label={PAYOFF}
                        name={kebabCase(PAYOFF)}
                        onChange={changePayoff}
                        placeholder="10,000.00"
                        value={payoff}
                        hasError={failedPayoff}
                        errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                        onBlur={payoffBlur}
                        disabled={tradeInputsDisabled}
                    />
                </ContainerGridItem>
            </ContainerGrid>
            <ContainerGrid>
                <ContainerGridItem xs={12}>
                    <LienholderForm
                        lienholderData={lienholderData}
                        setLienholderData={setLienholderData}
                        isEditDisabled={isEditDisabled}
                    />
                </ContainerGridItem>
            </ContainerGrid>
            <ContainerGrid>
                <ContainerGridItem xs={12}>
                    <CheckBox
                        checked={isBuyerRegisteredOwner}
                        data-testid={IS_BUYER_REGISTERED_OWNER_ID}
                        id={IS_BUYER_REGISTERED_OWNER_ID}
                        label={IS_BUYER_REGISTERED_OWNER}
                        onChange={handleIsBuyerRegisteredOwnerChange}
                        value={IS_BUYER_REGISTERED_OWNER_ID}
                        disabled={tradeInputsDisabled}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={12}>
                    <CheckBox
                        checked={isSellingVehicleRegisteredInSameState}
                        label={VEHICLE_IS_REGISTERED_IN_SAME_STATE}
                        onChange={handleIsSellingVehicleRegisteredInSameStateChange}
                        value={VEHICLE_IS_REGISTERED_IN_SAME_STATE_ID}
                        disabled={tradeInputsDisabled}
                    />
                </ContainerGridItem>
            </ContainerGrid>
        </>
    ) : (
        <>
            <Grid columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }} container flexDirection="row" rowSpacing={1}>
                <Grid xs={12}>{getAppraisalStatusTitle()}</Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="allowance"
                        label={ALLOWANCE}
                        name={kebabCase(ALLOWANCE)}
                        onChange={changeAllowance}
                        placeholder="10,000.00"
                        value={allowance}
                        hasError={failedAllowance}
                        errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                        onBlur={allowanceBlur}
                        disabled={tradeInputsDisabled}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="acv"
                        label={ACV}
                        name={kebabCase(ACV)}
                        onChange={changeAcv}
                        placeholder="10,000.00"
                        value={vAutoAppraisalAcv > 0 ? formatDollarsAndCents(vAutoAppraisalAcv) : acv}
                        onBlur={acvBlur}
                        hasError={failedACV}
                        errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                        disabled={tradeInputsDisabled || !!vAutoAppraisalStatus}
                    />
                </Grid>
                <Grid xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="payoff"
                        label={PAYOFF}
                        name={kebabCase(PAYOFF)}
                        onChange={changePayoff}
                        placeholder="10,000.00"
                        value={payoff}
                        hasError={failedPayoff}
                        errorMessage={GENERIC_LAST_FIELD_CHANGED_ERROR}
                        onBlur={payoffBlur}
                        disabled={tradeInputsDisabled}
                    />
                </Grid>
            </Grid>
            <Grid xs={12} container flexDirection="row" columnSpacing={1} rowSpacing={1}>
                <Grid container xs={12}>
                    <LienholderForm
                        isEditDisabled={isEditDisabled}
                        lienholderData={lienholderData}
                        setLienholderData={setLienholderData}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={12}>
                    <CheckBox
                        checked={isBuyerRegisteredOwner}
                        data-testid={IS_BUYER_REGISTERED_OWNER_ID}
                        id={IS_BUYER_REGISTERED_OWNER_ID}
                        label={IS_BUYER_REGISTERED_OWNER}
                        onChange={handleIsBuyerRegisteredOwnerChange}
                        value={IS_BUYER_REGISTERED_OWNER_ID}
                        disabled={tradeInputsDisabled}
                    />
                </Grid>
                <Grid xs={12}>
                    <CheckBox
                        checked={isSellingVehicleRegisteredInSameState}
                        label={VEHICLE_IS_REGISTERED_IN_SAME_STATE}
                        onChange={handleIsSellingVehicleRegisteredInSameStateChange}
                        value={VEHICLE_IS_REGISTERED_IN_SAME_STATE_ID}
                        disabled={tradeInputsDisabled}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export const TradeInValuation = memo(TradeInValuationComponent);
