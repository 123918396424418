import React, { CSSProperties, MouseEventHandler } from 'react';

import { StyledCXCard, CardHeader, CardTitle, CardLink } from './Card.style';

export interface ICardProps {
    htmlId: string;
    children: React.ReactNode;
    scroll: boolean;
    title?: string;
    actionLabel: string;
    disabled?: boolean;
    hasError?: boolean;
    actionHandler?: MouseEventHandler<HTMLButtonElement>;
    display?: CSSProperties['display'];
}

/**
 * Common card component for the main dealmaking screen
 * */
const Card = ({ htmlId, children, title, disabled, actionHandler, actionLabel, hasError, display = 'flex' }: ICardProps) => {
    const handleActionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (actionHandler) actionHandler(e);
    };
    return (
        <StyledCXCard display={display} htmlId={htmlId} data-testid={htmlId} hasError={hasError}>
            {title && (
                <CardHeader>
                    <CardTitle data-testid={`${htmlId}-title`}>{title}</CardTitle>
                    {!disabled && (
                        <CardLink data-testid={`${htmlId}-edit-link`} onClick={handleActionClick}>
                            {actionLabel}
                        </CardLink>
                    )}
                </CardHeader>
            )}
            {children}
        </StyledCXCard>
    );
};

Card.defaultProps = {
    actionLabel: 'View/Edit',
    disabled: false,
    scroll: true
};

export default Card;
