export const VIEWED_SUMMARY_TAB = 'VIEWED_SUMMARY_TAB';
export const CLICKED_SUMMARY_TAB = 'CLICKED_SUMMARY_TAB';
export const CLICKED_SUMMARY_TAB_HOTKEY = 'CLICKED_SUMMARY_TAB_HOTKEY';
export const VIEWED_TRADEIN_TAB = 'VIEWED_TRADEIN_TAB';
export const CLICKED_TRADEIN_TAB = 'CLICKED_TRADEIN_TAB';
export const CLICKED_TRADEIN_TAB_HOTKEY = 'CLICKED_TRADEIN_TAB_HOTKEY';
export const VIEWED_FEES_TAB = 'VIEWED_FEES_TAB';
export const CLICKED_FEES_TAB = 'CLICKED_FEES_TAB';
export const CLICKED_FEES_TAB_HOTKEY = 'CLICKED_FEES_TAB_HOTKEY';
export const VIEWED_TAXES_TAB = 'VIEWED_TAXES_TAB';
export const CLICKED_TAXES_TAB = 'CLICKED_TAXES_TAB';
export const CLICKED_TAXES_TAB_HOTKEY = 'CLICKED_TAXES_TAB_HOTKEY';
export const VIEWED_PROTECTION_TAB = 'VIEWED_PROTECTION_TAB';
export const CLICKED_PROTECTION_TAB = 'CLICKED_PROTECTION_TAB';
export const CLICKED_PROTECTION_TAB_HOTKEY = 'CLICKED_PROTECTION_TAB_HOTKEY';
export const VIEWED_INCENTIVES_TAB = 'VIEWED_INCENTIVES_TAB';
export const CLICKED_INCENTIVES_TAB = 'CLICKED_INCENTIVES_TAB';
export const CLICKED_INCENTIVES_TAB_HOTKEY = 'CLICKED_INCENTIVES_TAB_HOTKEY';
export const VIEWED_LENDER_TAB = 'VIEWED_LENDER_TAB';
export const CLICKED_LENDER_TAB = 'CLICKED_LENDER_TAB';
export const CLICKED_LENDER_TAB_HOTKEY = 'CLICKED_LENDER_TAB_HOTKEY';
export const VIEWED_ACCESSORIES_TAB = 'VIEWED_ACCESSORIES_TAB';
export const CLICKED_ACCESSORIES_TAB = 'CLICKED_ACCESSORIES_TAB';
export const CLICKED_ACCESSORIES_TAB_HOTKEY = 'CLICKED_ACCESSORIES_TAB_HOTKEY';
export const CLICKED_BREAKDOWN_EXPAND = 'CLICKED_BREAKDOWN_EXPAND';
export const VIEWED_BREAKDOWN_EXPAND = 'VIEWED_BREAKDOWN_EXPAND';
export const CLICKED_BREAKDOWN_EXPAND_HOTKEY = 'CLICKED_BREAKDOWN_EXPAND_HOTKEY';
export const CLICKED_BREAKDOWN_COLLAPSE = 'CLICKED_BREAKDOWN_COLLAPSE';
export const VIEWED_BREAKDOWN_COLLAPSE = 'VIEWED_BREAKDOWN_COLLAPSE';
export const CLICKED_BREAKDOWN_COLLAPSE_HOTKEY = 'CLICKED_BREAKDOWN_COLLAPSE_HOTKEY';
export const CLICKED_UPDATE = 'CLICKED_UPDATE';
export const CLICKED_UPDATE_HOTKEY = 'CLICKED_UPDATE_HOTKEY';
export const CLICKED_NEXT_EVENT_HOTKEY = 'CLICKED_NEXT_EVENT_HOTKEY';
export const CLICKED_PREVIOUS_EVENT_HOTKEY = 'CLICKED_PREVIOUS_EVENT_HOTKEY';
export const CLICKED_NEXT_TAB_HOTKEY = 'CLICKED_NEXT_TAB_HOTKEY';
export const CLICKED_PREVIOUS_TAB_HOTKEY = 'CLICKED_PREVIOUS_TAB_HOTKEY';
