import { NumericInput } from '@interstate/components/NumericInput';
import { useDispatch } from 'react-redux';
import { BaseTabs } from '../baseDealTypes';
import { baseDealActionCreators } from '@makemydeal/dr-dash-store';
import { tabToCdl3ActionMap } from './utils';

interface ReadOnlyInputProps {
    label: string;
    inputValue?: string;
    tab?: BaseTabs;
}

const ReadOnlyInput = (props: ReadOnlyInputProps) => {
    const { label, inputValue, tab } = props;
    const dispatch = useDispatch();

    const handleClick = () => {
        if (tab) {
            dispatch(baseDealActionCreators.handleTabChange(tab));
            dispatch(tabToCdl3ActionMap[tab].click());
        }
    };

    return (
        <>
            <label
                data-testid={`${label}-label`}
                style={{
                    color: '#005BA8',
                    cursor: 'pointer',
                    marginBottom: '8px'
                }}
                onClick={handleClick}
            >
                {label}
            </label>
            <NumericInput
                sx={{ lineHeight: '31px' }}
                displayLabel={false}
                name={label}
                data-testid={label}
                readOnly={true}
                value={inputValue}
            />
        </>
    );
};

export default ReadOnlyInput;
